import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">

            <div  id="language" >
        
        <a href=""><NavLink to="/Disaster-Relief">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ഴ ഫൗണ്ടേഷന്റെ ദുരന്ത നിവാരണ പ്രവർത്തനങ്ങൾ</h1>

                <div className='second-mcontent'>
                <img src='../../img/disasterrelief.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>ഭൂകമ്പം, കൊടുങ്കാറ്റ്, വെള്ളപ്പൊക്കം എന്നിവയും അതിലേറെയും പോലുള്ള ദുരന്തങ്ങളാൽ നാശം വിതച്ചവർക്കുള്ള പിന്തുണയുടെ വിളക്കുമാടമായി ഞങ്ങൾ നിലകൊള്ളുന്ന Zha Foundation-ലേക്ക് സ്വാഗതം. വേഗത്തിലുള്ളതും ഫലപ്രദവുമായ ദുരന്ത നിവാരണം നൽകാനുള്ള ഞങ്ങളുടെ പ്രതിബദ്ധത, ഒരു പ്രതിസന്ധിയുടെ അനന്തരഫലങ്ങളെ ആരും ഒറ്റയ്ക്ക് അഭിമുഖീകരിക്കരുത് എന്ന വിശ്വാസത്തിൽ വേരൂന്നിയതാണ്.
                    </p>
                </div>

                <h3>ഞങ്ങളുടെ ദൗത്യം</h3>
                <div className="missi">
                    <h4 className="toleftneed">ദ്രുത പ്രതികരണം, ശാശ്വതമായ ആഘാതം:</h4>
                    <p>ഴ ഫൗണ്ടേഷനിൽ, ദുരന്ത സാഹചര്യങ്ങളുടെ അടിയന്തിരതയും ഗുരുത്വാകർഷണവും ഞങ്ങൾ മനസ്സിലാക്കുന്നു. ഞങ്ങളുടെ ദൗത്യം പ്രകൃതി ദുരന്തങ്ങളാൽ ബാധിതരായ കമ്മ്യൂണിറ്റികൾക്ക് ദ്രുത പ്രതികരണവും പിന്തുണയും നൽകുകയും ദീർഘകാല വീണ്ടെടുപ്പിനും പ്രതിരോധത്തിനും വേണ്ടി പ്രവർത്തിക്കുന്നതോടൊപ്പം ഉടനടി ആശ്വാസം നൽകുകയും ചെയ്യുക എന്നതാണ്.</p>


                </div>


                <h3>ഞങ്ങളുടെ സമീപനം</h3>
                <div className="missi">
                    <h4 className="toleftneed">സമഗ്ര ദുരന്ത നിവാരണം:</h4>
                    <p>ഴ ഫൗണ്ടേഷൻ ദുരന്ത നിവാരണത്തിന് സമഗ്രമായ സമീപനമാണ് സ്വീകരിക്കുന്നത്. ഞങ്ങൾ അടിയന്തര പാർപ്പിടം, ഭക്ഷണം, വൈദ്യസഹായം എന്നിവ നൽകുന്ന പ്രാരംഭ പ്രതികരണ ഘട്ടം മുതൽ വീണ്ടെടുക്കൽ ഘട്ടം വരെ, കമ്മ്യൂണിറ്റികളെ പുനർനിർമ്മിക്കുന്നതിലും ഉപജീവനമാർഗം പുനഃസ്ഥാപിക്കുന്നതിലും ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു, എല്ലാ ഘട്ടത്തിലും ബാധിതരായ വ്യക്തികൾക്ക് ആവശ്യമായ പിന്തുണ ലഭിക്കുന്നുണ്ടെന്ന് ഉറപ്പാക്കാൻ ഞങ്ങളുടെ ടീമുകൾ പ്രതിജ്ഞാബദ്ധരാണ്. .</p>

                    <h4 className="toleftneed">കമ്മ്യൂണിറ്റി കേന്ദ്രീകൃത പരിഹാരങ്ങൾ:</h4>
                    <p>കമ്മ്യൂണിറ്റികളുടെ ശക്തിയിൽ ഞങ്ങൾ വിശ്വസിക്കുന്നു. ഞങ്ങളുടെ ദുരന്ത നിവാരണ പ്രവർത്തനങ്ങൾ തദ്ദേശവാസികളുടെ തനതായ ആവശ്യങ്ങൾ, സാംസ്കാരിക പശ്ചാത്തലം, ഭൂപ്രദേശത്തെക്കുറിച്ചുള്ള അറിവ് എന്നിവയെ മാനിച്ചുകൊണ്ട് അവരുടെ സഹകരണത്തോടെയാണ് രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്. കമ്മ്യൂണിറ്റികളുമായി ചേർന്ന് പ്രവർത്തിക്കുന്നതിലൂടെ, വ്യക്തികളെ അവരുടെ ജീവിതം പുനർനിർമ്മിക്കാൻ പ്രാപ്തരാക്കുന്ന സുസ്ഥിരമായ പരിഹാരങ്ങൾ സൃഷ്ടിക്കുകയാണ് ഞങ്ങൾ ലക്ഷ്യമിടുന്നത്.</p>
                </div>

                <h3>ഞങ്ങളുടെ സംരംഭങ്ങൾ</h3>
                <div className="missi">
                    <h4 className="toleftneed">അടിയന്തര അഭയവും സഹായവും:</h4>
                    <p>ദുരന്തബാധിതർക്ക് അടിയന്തര പാർപ്പിടം, ഭക്ഷണം, ശുദ്ധജലം, വൈദ്യസഹായം എന്നിവ നൽകുന്നതിന് ഴ ഫൗണ്ടേഷൻ അതിവേഗം അണിനിരക്കുന്നു. ഒരു പ്രതിസന്ധിയുടെ പശ്ചാത്തലത്തിൽ അടിസ്ഥാന ആവശ്യങ്ങൾ നിറവേറ്റപ്പെടുന്നുവെന്ന് ഉറപ്പാക്കാൻ ഞങ്ങളുടെ ടീമുകൾ രാപ്പകലില്ലാതെ പ്രവർത്തിക്കുന്നു.</p>

                    <h4 className="toleftneed">ജീവിതം പുനർനിർമ്മിക്കുക:</h4>
                    <p>പ്രാരംഭ പ്രതികരണത്തിനപ്പുറം, ജീവിതം പുനർനിർമ്മിക്കുന്നതിൽ ഞങ്ങൾ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു. പുനർനിർമ്മാണ ശ്രമങ്ങൾ, ഉപജീവനമാർഗ പുനഃസ്ഥാപിക്കുന്നതിനുള്ള വിഭവങ്ങൾ നൽകൽ, കമ്മ്യൂണിറ്റികളെ സാധാരണ നിലയിലാക്കാൻ സഹായിക്കുന്നതിന് വിദ്യാഭ്യാസ-ആരോഗ്യ സേവനങ്ങളെ പിന്തുണയ്ക്കൽ എന്നിവ ഇതിൽ ഉൾപ്പെടുന്നു.</p>

                    <h4 className="toleftneed">കമ്മ്യൂണിറ്റി റെസിലൻസ് പ്രോഗ്രാമുകൾ:</h4>
                    <p>കമ്മ്യൂണിറ്റി പ്രതിരോധശേഷി വർദ്ധിപ്പിക്കുന്ന പ്രോഗ്രാമുകളിൽ Zha ഫൗണ്ടേഷൻ നിക്ഷേപം നടത്തുന്നു. ഭാവിയിലെ ദുരന്തങ്ങളുടെ ആഘാതം ലഘൂകരിക്കുന്നതിനുള്ള ദുരന്ത നിവാരണ, മുൻകൂർ മുന്നറിയിപ്പ് സംവിധാനങ്ങൾ, സുസ്ഥിര സമ്പ്രദായങ്ങൾ എന്നിവയിൽ പ്രദേശവാസികളെ പരിശീലിപ്പിക്കുന്നത് ഇതിൽ ഉൾപ്പെടുന്നു.</p>
                </div>


            </section>
        </div>


    );
}

