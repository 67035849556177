import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
         <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Family-Wellness-Program">English</NavLink></a>

        </div>
         
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>ఝా ఫ్యామిలీ వెల్‌నెస్ ప్రోగ్రామ్: హార్మోనియస్ లివింగ్ కోసం ప్రోయాక్టివ్ కౌన్సెలింగ్</h1>

<div className='second-mcontent'>
<img src='../../img/membershipwellness.webp'></img>
                </div>
 
 


 
<h3>లక్ష్యం:</h3>
<p>ఝా ఫ్యామిలీ వెల్‌నెస్ ప్రోగ్రామ్ అనేది ఒత్తిడి, ఆత్రుత మరియు వాదనలను నివారించడానికి చురుకైన కౌన్సెలింగ్‌ను అందించడం ద్వారా సామరస్య జీవనాన్ని ప్రోత్సహించడానికి రూపొందించబడిన ఒక సమగ్ర చొరవ. ఈ కార్యక్రమం భార్యాభర్తలు, భార్యలు మరియు పిల్లల మానసిక శ్రేయస్సును బలోపేతం చేయడానికి, సవాళ్లను కలిసి అధిగమించగల సామర్థ్యం గల కుటుంబ విభాగాన్ని పెంపొందించడానికి రూపొందించబడింది.
</p>

 

<ol className="goalpoints">
    <h3>ప్రోగ్రామ్ భాగాలు:</h3>

<li><span>ప్రోయాక్టివ్ ఫ్యామిలీ కౌన్సెలింగ్ సెషన్‌లు: </span>  
<ul>
    <li>వ్యక్తిగత మరియు సామూహిక ఒత్తిళ్లను పరిష్కరించడానికి భర్తలు, భార్యలు మరియు పిల్లలకు ప్రత్యేక కౌన్సెలింగ్ సెషన్‌లు.


</li>
    <li>సమర్థవంతమైన కమ్యూనికేషన్‌ను నిర్మించడం, ఒకరి దృక్కోణాలను మరొకరు అర్థం చేసుకోవడం మరియు కుటుంబ గతిశీలతను మెరుగుపరచడం.



</li>
</ul>
</li> 

<li><span>హోలిస్టిక్ వెల్నెస్ వర్క్‌షాప్‌లు:</span>  
<ul>
    <li>ఒత్తిడి నిర్వహణ, సంఘర్షణ పరిష్కారం మరియు భావోద్వేగ స్థితిస్థాపకతను పెంపొందించడంతో సహా అనేక రకాల అంశాలను కవర్ చేసే టైలర్డ్ వర్క్‌షాప్‌లు.



</li>
    <li>కుటుంబం మొత్తం పాల్గొనడానికి మరియు కలిసి నేర్చుకోవడానికి ఇంటరాక్టివ్ సెషన్‌లు.




</li>
</ul>
</li> 

<li><span>కుటుంబ బంధ కార్యకలాపాలు : </span>  
<ul>
    <li>కుటుంబ బంధం మరియు జట్టుకృషిని ప్రోత్సహించే కార్యకలాపాలను చేర్చండి, ఐక్యత యొక్క భావాన్ని బలోపేతం చేయండి.

</li>
    <li>కుటుంబ సంబంధాలను బలోపేతం చేయడానికి అవుట్‌డోర్ ఈవెంట్‌లు, సృజనాత్మక వర్క్‌షాప్‌లు మరియు సహకార ప్రాజెక్ట్‌లు.




</li>
</ul>
</li> 

<li><span>వ్యక్తిగత ఆరోగ్య ప్రణాళికలు: </span>  
<ul>
    <li>ప్రతి కుటుంబ సభ్యులకు వారి ప్రత్యేక అవసరాలు మరియు సవాళ్ల ఆధారంగా వ్యక్తిగతీకరించిన వెల్‌నెస్ ప్లాన్‌లు అభివృద్ధి చేయబడ్డాయి.
</li>
    <li>పరిస్థితులు అభివృద్ధి చెందుతున్నప్పుడు ఈ ప్రణాళికలను అమలు చేయడానికి మరియు సర్దుబాటు చేయడానికి కొనసాగుతున్న మద్దతు.
</li>
</ul>
</li> 

<li><span>తల్లిదండ్రుల మద్దతు మరియు విద్య:</span>  
<ul>
    <li>తల్లిదండ్రుల కోసం ప్రత్యేకంగా రూపొందించిన వర్క్‌షాప్‌లు మరియు వనరులు వారి సంతాన నైపుణ్యాలను మెరుగుపరుస్తాయి.
</li>
    <li>పిల్లలతో సమర్థవంతమైన సంభాషణపై మార్గదర్శకత్వం, సానుకూల క్రమశిక్షణ వ్యూహాలు మరియు ఇంటి వాతావరణాన్ని పెంపొందించడం.
</li>
</ul>
</li> 
<li><span>యువజన సాధికారత సెషన్స్: </span>  
<ul>
    <li>పిల్లలు మరియు యుక్తవయస్కులు ఎదుర్కొంటున్న ప్రత్యేక సవాళ్లను పరిష్కరించడానికి అంకితమైన ప్రత్యేక కౌన్సెలింగ్ సెషన్‌లు మరియు వర్క్‌షాప్‌లు.
</li>
    <li>బహిరంగ సంభాషణ యొక్క ప్రోత్సాహం మరియు కోపింగ్ మెకానిజమ్స్ అభివృద్ధి.
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>లాభాలు:</h3>
<ul className="goalpoints">
  <li>సమర్థవంతమైన కమ్యూనికేషన్ మరియు అవగాహన ద్వారా కుటుంబ బంధాలను బలోపేతం చేసింది.</li>  
  <li>కుటుంబంలో ఒత్తిడి, ఆందోళన మరియు వాదనలు తగ్గుతాయి.</li>  
  <li>తల్లిదండ్రులు మరియు పిల్లలు ఇద్దరికీ మెరుగైన భావోద్వేగ స్థితిస్థాపకత.</li>  
  <li>కుటుంబ యూనిట్‌లో టీమ్‌వర్క్ మరియు సమస్య పరిష్కార నైపుణ్యాల అభివృద్ధి.</li>  
  <li>సానుకూల మరియు సహాయక గృహ వాతావరణాన్ని ప్రోత్సహించడం.

</li>  
 
</ul>


<h3>ఎలా పాల్గొనాలి:</h3>
<ul className="goalpoints">
  <li>ఝా ఫ్యామిలీ వెల్‌నెస్ ప్రోగ్రామ్‌లో ఆసక్తి ఉన్న కుటుంబాలు వారి అంకితమైన కౌన్సెలింగ్ సేవల హాట్‌లైన్ లేదా వెబ్‌సైట్ ద్వారా ఝా ఫౌండేషన్‌ను చేరుకోవడం ద్వారా నమోదు చేసుకోవచ్చు.</li>  
  <li>పాల్గొనడం స్వచ్ఛందంగా, గోప్యంగా ఉంటుంది మరియు సంపూర్ణ శ్రేయస్సు కోసం చురుకైన మద్దతును కోరుకునే కుటుంబాలకు తెరవబడుతుంది.</li>  
 
 
</ul>
     
       
       <p>ఝా ఫ్యామిలీ వెల్‌నెస్ ప్రోగ్రామ్ అనేది సభ్యులందరికీ ఆరోగ్యకరమైన మరియు సహాయక వాతావరణాన్ని పెంపొందించడం ద్వారా, ఒక ఐక్య బృందంగా జీవిత సవాళ్లను నావిగేట్ చేయడానికి కుటుంబాలను శక్తివంతం చేయడానికి రూపొందించబడింది. ఈ ప్రోగ్రామ్‌పై అభిప్రాయం లేదా మెరుగుదల కోసం సూచనలు స్వాగతం.</p>
       
       
        </div>


        </div>




 
        


    );
}

