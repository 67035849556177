import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">



            <div  id="language" >
        
        <a href=""><NavLink to="/Environment">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">झा फाउंडेशन: एक हरित कल का पोषण</h1>

                <div className='second-mcontent'>
                <img src='../../img/environment.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>झा फाउंडेशन में आपका स्वागत है, जहां हम वर्तमान और भविष्य की पीढ़ियों के लिए पर्यावरण की सुरक्षा के बारे में उत्साहित हैं। पर्यावरण संरक्षण के प्रति हमारी प्रतिबद्धता इस विश्वास से प्रेरित है कि प्रत्येक व्यक्ति और समुदाय उस ग्रह को संरक्षित करने में महत्वपूर्ण भूमिका निभाता है जिसे हम घर कहते हैं।
                    </p>
                </div>

                <h3>हमारा नज़रिया</h3>
                <div className="missi">
                    <h4 className="toleftneed">टिकाऊ भविष्य:</h4>
                    <p>झा फाउंडेशन में, हम एक ऐसी दुनिया की कल्पना करते हैं जहां मानवता प्रकृति के साथ सौहार्दपूर्वक सह-अस्तित्व में रहे। हमारा दृष्टिकोण स्थिरता में निहित है, जहां पारिस्थितिकी तंत्र पनपता है, जैव विविधता संरक्षित होती है, और हर क्रिया एक स्वस्थ ग्रह में योगदान देती है।</p>


                </div>


                <h3>हमारा विशेष कार्य</h3>
                <div className="missi">
                    <h4 className="toleftneed">सशक्त परिवर्तन:</h4>
                    <p>हमारा मिशन व्यक्तियों और समुदायों को पर्यावरण का संरक्षक बनने के लिए सशक्त बनाना है। शिक्षा, वकालत और व्यावहारिक पहल के माध्यम से, हमारा लक्ष्य जिम्मेदार और टिकाऊ प्रथाओं के लिए सामूहिक प्रतिबद्धता को बढ़ावा देना है।</p>
 
                </div>

                <h3>हमारी पहल</h3>
                <div className="missi">
                    <h4 className="toleftneed">संरक्षण और पुनरुद्धार:</h4>
                    <p>झा फाउंडेशन संरक्षण और पुनर्स्थापन परियोजनाओं में सक्रिय रूप से शामिल है। पुनर्वनीकरण प्रयासों से लेकर लुप्तप्राय प्रजातियों की रक्षा तक, हमारी पहल पृथ्वी पर जीवन को बनाए रखने वाले प्राकृतिक आवासों को संरक्षित और पुनर्स्थापित करने पर केंद्रित है।</p>

                    <h4 className="toleftneed">अपशिष्ट न्यूनीकरण कार्यक्रम:</h4>
                    <p>हम अपने पारिस्थितिक पदचिह्न को कम करने के महत्व में विश्वास करते हैं। हमारे अपशिष्ट न्यूनीकरण कार्यक्रम पर्यावरण पर मानवीय गतिविधियों के प्रभाव को कम करने के लिए जिम्मेदार अपशिष्ट प्रबंधन, पुनर्चक्रण और पुनर्चक्रण को बढ़ावा देते हैं।</p>

                    <h4 className="toleftneed">हरित ऊर्जा वकालत:</h4>
                    <p>नवीकरणीय ऊर्जा की ओर परिवर्तन जलवायु परिवर्तन को कम करने का एक प्रमुख पहलू है। झा फाउंडेशन हरित ऊर्जा समाधानों को अपनाने की वकालत और समर्थन करता है, जो अधिक टिकाऊ और स्वच्छ ऊर्जा परिदृश्य में योगदान देता है।</p>
                </div>


                <h3>हमारा दृष्टिकोण</h3>
                <div className="missi">
                    <h4 className="toleftneed">शिक्षा और जागरूकता:</h4>
                    <p>ज्ञान परिवर्तन के लिए एक शक्तिशाली उत्प्रेरक है। हम ऐसे शैक्षिक कार्यक्रमों में निवेश करते हैं जो पर्यावरणीय मुद्दों, जलवायु परिवर्तन और टिकाऊ जीवन के बारे में जागरूकता बढ़ाते हैं। हमारा मानना है कि व्यक्तियों को जानकारी से सशक्त बनाकर हम सार्थक कार्रवाई के लिए प्रेरित कर सकते हैं।</p>

                    <h4 className="toleftneed">सामुदायिक व्यस्तता:</h4>
                    <p>पर्यावरण संरक्षण एक सामूहिक प्रयास है। हम पर्यावरणीय पहलों में सक्रिय भागीदारी को प्रोत्साहित करते हुए स्थानीय समुदायों के साथ जुड़ते हैं। समुदाय-आधारित परियोजनाओं और आयोजनों के माध्यम से, हमारा लक्ष्य पर्यावरण चैंपियनों का एक नेटवर्क बनाना है।</p>

                     
                </div>

            </section>
        </div>


    );
}

