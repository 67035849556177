import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>


<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Common-Humans-COPs">English</NavLink></a>

        </div>

        
{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>झा कॉमन मैन सीओपी </h1>
</div>


<div className="internship">
<img src="../img/cops.webp"></img>
        </div>
<div className="goalpointsp">

<p>झा कॉमन मैन कम्युनिटी ऑफ प्रैक्टिसेज (सीओपी) का उद्देश्य वैश्विक स्तर पर व्यक्तियों के बीच एक स्थिरता मानसिकता को बढ़ावा देना है। झा फाउंडेशन के वैश्विक बोर्ड के सदस्यों द्वारा निर्देशित, सीओपी स्थायी प्रथाओं के प्रति शिक्षित करने, चर्चा करने और कार्रवाई को प्रेरित करने के लिए मासिक मंचों पर ध्यान केंद्रित करते हैं। लक्ष्यों में जागरूकता बढ़ाना, सर्वोत्तम प्रथाओं को साझा करना और स्थिरता के लिए प्रतिबद्ध वैश्विक नेटवर्क विकसित करना शामिल है। लाभों में ज्ञान का आदान-प्रदान, सहयोगात्मक पहल और अधिक टिकाऊ दुनिया में योगदान करने के लिए व्यक्तियों को सशक्त बनाना शामिल है।
</p>
<p>
इसलिए हमारे पास प्रत्येक देश के लिए हमारे विभिन्न सीओपी हैं जिन्हें निम्नलिखित एजेंडा के आधार पर सत्र और समाधान परिणाम देने, नवीन पहल करने पर ध्यान केंद्रित करना चाहिए
</p>

<ol className="goalpoints">
    <li><span>शिक्षा और जागरूकता :</span>झा के सीओपी आम लोगों को जलवायु परिवर्तन और स्थिरता के बारे में ज्ञान प्राप्त करने, उनके महत्व की गहरी समझ को बढ़ावा देने के लिए एक मंच प्रदान करते हैं।</li>
    <li><span>स्थानीय प्रभाव : </span> आम व्यक्तियों को शामिल करके, सीओपी स्थानीय समुदायों को वैश्विक स्थिरता प्रयासों में सामूहिक रूप से योगदान करते हुए कार्रवाई करने के लिए सशक्त बनाता है।
</li>
    <li><span>व्यवहार परिवर्तन : </span>सीओपी का उद्देश्य दैनिक आदतों और प्रथाओं को प्रभावित करना है, उन प्रतिभागियों के बीच स्थायी विकल्पों को प्रोत्साहित करना है जो शुरू में जलवायु परिवर्तन के महत्व को नहीं समझ सकते हैं।</li>


    <li><span>सामुदायिक सशक्तिकरण : </span>परामर्श और सहयोग के माध्यम से, सीओपी व्यक्तियों को अपने समुदायों के भीतर स्थिरता के लिए समर्थक बनने के लिए सशक्त बनाता है, जिससे सकारात्मक परिवर्तन का प्रभाव पैदा होता है।

</li>
    <li><span>वैश्विक नेटवर्क : </span>सीओपी विविध पृष्ठभूमि के लोगों को जोड़ता है, वैश्विक जिम्मेदारी की भावना को बढ़ावा देता है और दर्शाता है कि कैसे व्यक्तिगत कार्य सामूहिक रूप से ग्रह को प्रभावित करते हैं।


</li>
    <li><span>संयुक्त राष्ट्र के लक्ष्यों के साथ संरेखण : </span>झा की सीओपी सीधे संयुक्त राष्ट्र के स्थिरता लक्ष्यों के साथ संरेखित होती है, जो व्यापक वैश्विक उद्देश्यों को प्राप्त करने के लिए जमीनी स्तर का दृष्टिकोण प्रदान करती है।


</li>
    <li><span>समावेशी भागीदारी : </span>आम लोगों को लक्षित करके, सीओपी समावेशिता सुनिश्चित करती है, व्यापक जनसांख्यिकीय को शामिल करती है और उन लोगों के बहिष्कार से बचती है जो स्थिरता की भाषा या अवधारणाओं से परिचित नहीं हो सकते हैं।


</li>
    <li><span>मासिक मंच :</span>नियमित मंच चल रहे संवाद और सीखने के लिए एक सतत मंच प्रदान करते हैं, समय के साथ स्थिरता के महत्व को मजबूत करते हैं और इसे प्रतिभागियों की चल रही चेतना का हिस्सा बनाते हैं।
</li>
    <li><span>व्यावहारिक समाधान : </span>सीओपी व्यावहारिक, प्राप्त करने योग्य समाधानों पर ध्यान केंद्रित करते हैं जिन्हें आम लोग अपने दैनिक जीवन में लागू कर सकते हैं, जिससे स्थिरता अधिक सुलभ और कम अमूर्त हो जाती है।
</li>
    <li><span>अनुकूलन और लचीलापन : </span>सीओपी के माध्यम से आम लोगों को शिक्षित करने से समुदायों को बदलती पर्यावरणीय परिस्थितियों के अनुकूल होने और जलवायु परिवर्तन के प्रभावों के खिलाफ लचीलापन विकसित करने में मदद मिलती है।


</li>
 
 </ol>
 
 <p>संक्षेप में, झा के कॉमन मैन सीओपी जलवायु परिवर्तन और स्थिरता लक्ष्यों की अमूर्त अवधारणाओं और रोजमर्रा के व्यक्तियों के व्यावहारिक कार्यों के बीच अंतर को पाटने, एक अधिक टिकाऊ और सूचित वैश्विक समाज बनाने में महत्वपूर्ण भूमिका निभाते हैं।</p>
 </div>

        </div>

    );
}

