import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Students-Sustainability-Club">English</NavLink></a>



</div>
{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ಝಾ ಸ್ಟೂಡೆಂಟ್ಸ್ ಸಸ್ಟೈನಬಿಲಿಟಿ ಕ್ಲಬ್</h1>
</div>


<div className="internship">
<img src="../img/students.webp"></img>
        </div>
<div className="goalpointsp">

<p> ZHA ವಿದ್ಯಾರ್ಥಿಗಳ ಸುಸ್ಥಿರತೆ ಕ್ಲಬ್ ಸದಸ್ಯತ್ವ ಕಾರ್ಯಕ್ರಮವು ZHA ಫೌಂಡೇಶನ್ ಸ್ಥಾಪಿಸಿದ ಸುಸ್ಥಿರತೆಯ ನಾಲ್ಕು ಸ್ತಂಭಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಒಂದು ಸಮಗ್ರ ಉಪಕ್ರಮವಾಗಿದೆ. ಭಾಗವಹಿಸುವ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಸುಸ್ಥಿರತೆಯ ವೈವಿಧ್ಯಮಯ ಅಂಶಗಳನ್ನು ತಿಳಿಸಲು ಈ ಸಮಗ್ರ ಕಾರ್ಯಕ್ರಮವನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.
</p>

<ol className="goalpoints">
    <li><span>ಮಾನವ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span> </li>
    <ul>
        <li>ಕಾರ್ಯಕ್ರಮವು ಸುಸ್ಥಿರ ಜೀವನ ಅಭ್ಯಾಸಗಳು, ಪರಿಸರಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಮಾನಸಿಕ ಆರೋಗ್ಯ ಮತ್ತು ಪರಿಸರ ಪ್ರಜ್ಞೆಯ ಮೌಲ್ಯಗಳೊಂದಿಗೆ ವೈಯಕ್ತಿಕ ಯೋಗಕ್ಷೇಮವನ್ನು ಕೇಂದ್ರೀಕರಿಸುವ ಶೈಕ್ಷಣಿಕ ಸಾಮಗ್ರಿಗಳು ಮತ್ತು ಕಾರ್ಯಾಗಾರಗಳನ್ನು ನೀಡುವ ಮೂಲಕ ಮಾನವ ಸ್ವಾಸ್ಥ್ಯವನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.
 </li>
    </ul>

    <li><span>ಭೂಮಿಯ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span> </li>
    <ul>
        <li>ವಿದ್ಯಾರ್ಥಿಗಳು ಭೂಮಿಯ ಯೋಗಕ್ಷೇಮಕ್ಕೆ ಕೊಡುಗೆ ನೀಡುವ ಚಟುವಟಿಕೆಗಳು ಮತ್ತು ಯೋಜನೆಗಳಲ್ಲಿ ತೊಡಗುತ್ತಾರೆ. ನವೀಕರಿಸಬಹುದಾದ ಶಕ್ತಿ, ತ್ಯಾಜ್ಯ ಕಡಿತ ಮತ್ತು ಪರಿಸರ ಸ್ನೇಹಿ ತಂತ್ರಜ್ಞಾನಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಉಪಕ್ರಮಗಳ ಬಗ್ಗೆ ಕಲಿಯುವುದು ಮತ್ತು ಸಕ್ರಿಯವಾಗಿ ಭಾಗವಹಿಸುವುದನ್ನು ಇದು ಒಳಗೊಂಡಿದೆ.
 </li>
    </ul>

    <li><span>ಪ್ರಕೃತಿ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span> </li>
    <ul>
        <li>ಕಾರ್ಯಕ್ರಮವು ಪ್ರಕೃತಿಯೊಂದಿಗೆ ಸಂಪರ್ಕವನ್ನು ಒತ್ತಿಹೇಳುತ್ತದೆ, ಜೀವವೈವಿಧ್ಯತೆಯನ್ನು ಪ್ರಶಂಸಿಸಲು ಮತ್ತು ರಕ್ಷಿಸಲು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತದೆ. ಮರ ನೆಡುವಿಕೆ, ಆವಾಸಸ್ಥಾನ ಮರುಸ್ಥಾಪನೆ ಮತ್ತು ಪ್ರಕೃತಿ ವಿಹಾರಗಳಂತಹ ಘಟನೆಗಳ ಮೂಲಕ ವಿದ್ಯಾರ್ಥಿಗಳು ನೈಸರ್ಗಿಕ ಪ್ರಪಂಚವನ್ನು ಸಂರಕ್ಷಿಸುವ ಮಹತ್ವದ ಬಗ್ಗೆ ಆಳವಾದ ತಿಳುವಳಿಕೆಯನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳುತ್ತಾರೆ.
 </li>
    </ul>

    <li><span>ಸ್ಥಿರವಾದ ಲಾಭದ ಸ್ತಂಭ :</span> </li>
    <ul>
        <li>ಸ್ಥಿರವಾದ ಲಾಭದ ಸಂದರ್ಭದಲ್ಲಿ ಸುಸ್ಥಿರತೆಯನ್ನು ಸಮರ್ಥನೀಯ ವ್ಯಾಪಾರ ಅಭ್ಯಾಸಗಳ ಬಗ್ಗೆ ಜ್ಞಾನದೊಂದಿಗೆ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಜ್ಜುಗೊಳಿಸುವ ಮೂಲಕ ಪರಿಹರಿಸಲಾಗುತ್ತದೆ. ಈ ಸ್ತಂಭವು ಹಸಿರು ಉದ್ಯಮಶೀಲತೆ, ಜವಾಬ್ದಾರಿಯುತ ಬಳಕೆ ಮತ್ತು ಪರಿಸರ ಮತ್ತು ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿಗಳಿಗೆ ಆದ್ಯತೆ ನೀಡುವಾಗ ವ್ಯವಹಾರಗಳು ಅಭಿವೃದ್ಧಿ ಹೊಂದಲು ಕಾರ್ಯಾಗಾರಗಳನ್ನು ಒಳಗೊಂಡಿದೆ.
 </li>
    </ul>
  </ol>
<p>ಒಟ್ಟಾರೆಯಾಗಿ, ZHA ಸ್ಟೂಡೆಂಟ್ಸ್ ಸಸ್ಟೈನಬಿಲಿಟಿ ಕ್ಲಬ್ ಸದಸ್ಯತ್ವ ಕಾರ್ಯಕ್ರಮವು ಅದರ ಒಳಗೊಳ್ಳುವಿಕೆಗಾಗಿ ಎದ್ದು ಕಾಣುತ್ತದೆ, ಇದು ಸುಸ್ಥಿರತೆಯ ಕಂಬಗಳ ಸಂಪೂರ್ಣ ವರ್ಣಪಟಲವನ್ನು ಒಳಗೊಂಡಿದೆ. ಸ್ಥಿರವಾದ ಲಾಭಕ್ಕಾಗಿ ಪರಿಗಣನೆಯೊಂದಿಗೆ ಮಾನವ, ಭೂಮಿ ಮತ್ತು ಪ್ರಕೃತಿ ಸ್ವಾಸ್ಥ್ಯವನ್ನು ಸಂಯೋಜಿಸುವ ಮೂಲಕ, ಕಾರ್ಯಕ್ರಮವು ಪರಿಸರ ಪ್ರಜ್ಞೆಯ ನಾಯಕರನ್ನು ಪೋಷಿಸುತ್ತದೆ, ಅವರು ಈ ಸ್ತಂಭಗಳ ಪರಸ್ಪರ ಸಂಬಂಧದ ಬಗ್ಗೆ ತಿಳಿದಿರುವುದಿಲ್ಲ ಆದರೆ ಸಮತೋಲಿತ ಮತ್ತು ಸುಸ್ಥಿರ ಭವಿಷ್ಯಕ್ಕೆ ಸಕ್ರಿಯವಾಗಿ ಕೊಡುಗೆ ನೀಡುತ್ತಾರೆ.</p>

<p>ಶಾಲೆಗಳು ಮತ್ತು ಕಾಲೇಜುಗಳು ZHA ವಿದ್ಯಾರ್ಥಿಗಳ ಸುಸ್ಥಿರತೆ ಸದಸ್ಯತ್ವ ಕಾರ್ಯಕ್ರಮವು ಸುಸ್ಥಿರತೆ ಕ್ಲಬ್‌ಗಳ ಪರಿಚಯದ ಮೂಲಕ ಶಾಲೆಗಳಿಗೆ ಹಲವಾರು ಪ್ರಯೋಜನಗಳನ್ನು ನೀಡುತ್ತದೆ:

</p>
<ol className="goalpoints">
    <li><span>ವರ್ಧಿತ ಶಿಕ್ಷಣ : </span>ಶಾಲೆಗಳು ಸುಸ್ಥಿರತೆಯ ತರಬೇತುದಾರರಿಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯುತ್ತವೆ, ಅವರು ಜ್ಞಾನವನ್ನು ನೀಡುವುದು ಮಾತ್ರವಲ್ಲದೆ ಅನುಭವದ ಕಲಿಕೆಯನ್ನು ಸುಗಮಗೊಳಿಸುತ್ತದೆ, ವಿದ್ಯಾರ್ಥಿಗಳಲ್ಲಿ ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿ ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಆಳವಾದ ತಿಳುವಳಿಕೆಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.

 </li>
    <li><span>ಸಮಗ್ರ ಅಭಿವೃದ್ಧಿ : </span>ಸುಸ್ಥಿರತೆಯ ಕ್ಲಬ್ ಪರಿಕಲ್ಪನೆಯು ವಿದ್ಯಾರ್ಥಿಗಳ ಶಿಕ್ಷಣದಲ್ಲಿ ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿ ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಮನಸ್ಥಿತಿಯನ್ನು ಸಂಯೋಜಿಸುವ ಮೂಲಕ ಸಮಗ್ರ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ, ಉದ್ದೇಶ ಮತ್ತು ಜಾಗತಿಕ ಪೌರತ್ವವನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.
 </li>
    <li><span>ಪ್ರಾಯೋಗಿಕ ಅಪ್ಲಿಕೇಶನ್ : </span>ಸದಸ್ಯತ್ವವು ಕ್ಲಬ್ ಚಟುವಟಿಕೆಗಳು, ಯೋಜನೆಗಳು ಮತ್ತು ಈವೆಂಟ್‌ಗಳ ಮೂಲಕ ಸಮರ್ಥನೀಯತೆಯ ತತ್ವಗಳ ಪ್ರಾಯೋಗಿಕ ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ವೇದಿಕೆಯನ್ನು ಒದಗಿಸುತ್ತದೆ, ಇದು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಸೈದ್ಧಾಂತಿಕ ಜ್ಞಾನವನ್ನು ನೈಜ-ಪ್ರಪಂಚದ ಕ್ರಿಯೆಗಳಿಗೆ ಭಾಷಾಂತರಿಸಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ.

 </li>
    <li><span>ಸಮುದಾಯ ಎಂಗೇಜ್‌ಮೆಂಟ್ : </span>ಸಸ್ಟೈನಬಿಲಿಟಿ ಕ್ಲಬ್‌ಗಳು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ತಮ್ಮ ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳೊಂದಿಗೆ ಸಕ್ರಿಯವಾಗಿ ತೊಡಗಿಸಿಕೊಳ್ಳಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತವೆ, ಸಾಮಾಜಿಕ ಕಾರಣಗಳನ್ನು ಉತ್ತೇಜಿಸುತ್ತವೆ ಮತ್ತು ಸುತ್ತಮುತ್ತಲಿನ ಪರಿಸರದಲ್ಲಿ ಸಕಾರಾತ್ಮಕ ಪರಿಣಾಮವನ್ನು ಉಂಟುಮಾಡುತ್ತವೆ.

 </li>
    <li><span>ನಾಯಕತ್ವದ ಅವಕಾಶಗಳು : </span>ಸಮರ್ಥನೀಯ ಕ್ಲಬ್‌ಗಳಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿರುವ ವಿದ್ಯಾರ್ಥಿಗಳು ನಾಯಕತ್ವದ ಪಾತ್ರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು, ನಾಯಕತ್ವ ಕೌಶಲ್ಯಗಳನ್ನು ಬೆಳೆಸಲು ಮತ್ತು ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳ ಕಡೆಗೆ ತಮ್ಮ ಗೆಳೆಯರನ್ನು ಮಾರ್ಗದರ್ಶನ ಮಾಡುವಲ್ಲಿ ಜವಾಬ್ದಾರಿಯ ಪ್ರಜ್ಞೆಯನ್ನು ತುಂಬಲು ಅವಕಾಶವನ್ನು ಹೊಂದಿರುತ್ತಾರೆ.
 </li>
    <li><span>ನೆಟ್‌ವರ್ಕಿಂಗ್ ಮತ್ತು ಸಹಯೋಗ :</span>ಕ್ಲಬ್ ಸದಸ್ಯತ್ವವು ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವ ಶೈಕ್ಷಣಿಕ ಸಂಸ್ಥೆಗಳ ವಿಶಾಲ ಜಾಲದೊಂದಿಗೆ ಶಾಲೆಗಳನ್ನು ಸಂಪರ್ಕಿಸುತ್ತದೆ, ಸಹಯೋಗ, ಜ್ಞಾನ ಹಂಚಿಕೆ ಮತ್ತು ಜಂಟಿ ಯೋಜನೆಗಳಿಗೆ ಅವಕಾಶಗಳನ್ನು ಸೃಷ್ಟಿಸುತ್ತದೆ.

 </li>
    <li><span>ಪಠ್ಯಕ್ರಮದ ಗುರಿಗಳೊಂದಿಗೆ ಜೋಡಿಸಲಾಗಿದೆ : </span>ಸುಸ್ಥಿರತೆಯ ತತ್ವಗಳ ಏಕೀಕರಣವು ಸಮಕಾಲೀನ ಶೈಕ್ಷಣಿಕ ಗುರಿಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತದೆ, ಜಾಗತಿಕ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಲು ಮತ್ತು ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿಗೆ ಕೊಡುಗೆ ನೀಡುವ ಚೌಕಟ್ಟನ್ನು ಶಾಲೆಗಳಿಗೆ ಒದಗಿಸುತ್ತದೆ.
 </li>
    <li><span>ಸಕಾರಾತ್ಮಕ ಶಾಲಾ ಸಂಸ್ಕೃತಿ : </span>ಸುಸ್ಥಿರತೆಯ ಕ್ಲಬ್‌ನ ಉಪಸ್ಥಿತಿಯು ಸಕಾರಾತ್ಮಕ ಮತ್ತು ಮುಂದಕ್ಕೆ ಯೋಚಿಸುವ ಶಾಲಾ ಸಂಸ್ಕೃತಿಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ, ಪರಿಸರದ ಉಸ್ತುವಾರಿ, ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿ ಮತ್ತು ನೈತಿಕ ನಡವಳಿಕೆಯಂತಹ ಮೌಲ್ಯಗಳನ್ನು ಒತ್ತಿಹೇಳುತ್ತದೆ.
 </li>
    <li><span>ಗುರುತಿಸುವಿಕೆ ಮತ್ತು ಮಾನ್ಯತೆ :</span>ZHA ಸುಸ್ಥಿರತೆಯ ಕ್ಲಬ್‌ನೊಂದಿಗೆ ಸಂಯೋಜಿತವಾಗಿರುವ ಶಾಲೆಗಳು ಸಮರ್ಥನೀಯತೆಗೆ ತಮ್ಮ ಬದ್ಧತೆಗೆ ಮನ್ನಣೆಯನ್ನು ಪಡೆಯುತ್ತವೆ, ಸಮರ್ಥನೀಯ ಶೈಕ್ಷಣಿಕ ವಾತಾವರಣವನ್ನು ಉತ್ತೇಜಿಸುವಲ್ಲಿ ಅವರ ಪ್ರಯತ್ನಗಳನ್ನು ಎತ್ತಿ ತೋರಿಸುವ ಮಾನ್ಯತೆ ಅಥವಾ ಪ್ರಶಸ್ತಿಗಳಿಗೆ ಸಂಭಾವ್ಯವಾಗಿ ಕಾರಣವಾಗುತ್ತದೆ.

 </li>
    <li><span>ಭವಿಷ್ಯಕ್ಕಾಗಿ ತಯಾರಿ : </span>ಸುಸ್ಥಿರತೆಯ ಕ್ಲಬ್ ಪರಿಕಲ್ಪನೆಯು ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಜ್ಞಾನ, ಕೌಶಲ್ಯ ಮತ್ತು ಮೌಲ್ಯಗಳೊಂದಿಗೆ ಸಜ್ಜುಗೊಳಿಸುವ ಮೂಲಕ ಪರಿಸರ ಮತ್ತು ಸಾಮಾಜಿಕ ಸುಸ್ಥಿರತೆಯ ಮೇಲೆ ಹೆಚ್ಚು ಗಮನಹರಿಸುವ ಜಗತ್ತನ್ನು ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು ಅಗತ್ಯವಾದ ಭವಿಷ್ಯಕ್ಕಾಗಿ ಸಿದ್ಧಪಡಿಸುತ್ತದೆ.
 </li>
  </ol>

  <p>ಸಾರಾಂಶದಲ್ಲಿ, ZHA ವಿದ್ಯಾರ್ಥಿಗಳ ಸಸ್ಟೈನಬಿಲಿಟಿ ಸದಸ್ಯತ್ವವು ಸುಸ್ಥಿರ ಶಿಕ್ಷಣವನ್ನು ಸಂಯೋಜಿಸುವ ಮೂಲಕ ಶಾಲೆಗಳಿಗೆ ಪ್ರಯೋಜನವನ್ನು ನೀಡುತ್ತದೆ, ಸಮಗ್ರ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ ಮತ್ತು ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಮತ್ತು ಸಾಮಾಜಿಕವಾಗಿ ಜವಾಬ್ದಾರಿಯುತ ಭವಿಷ್ಯಕ್ಕೆ ಸಕ್ರಿಯವಾಗಿ ಕೊಡುಗೆ ನೀಡಲು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುತ್ತದೆ.</p>
     
</div>
        

        </div>

    );
}

