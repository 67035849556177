import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
         <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Students-Sustainability-Club">English</NavLink></a>



</div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ഴ സ്റ്റുഡന്റ്സ് സസ്റ്റൈനബിലിറ്റി ക്ലബ്</h1>
</div>


<div className="internship">
<img src="../img/students.webp"></img>
        </div>
<div className="goalpointsp">

<p> ZHA ഫൗണ്ടേഷൻ സ്ഥാപിച്ച സുസ്ഥിരതയുടെ നാല് തൂണുകൾ ഉൾക്കൊള്ളുന്ന ഒരു സമഗ്ര സംരംഭമാണ് ZHA സ്റ്റുഡന്റ്സ് സസ്റ്റൈനബിലിറ്റി ക്ലബ് അംഗത്വ പരിപാടി. പങ്കെടുക്കുന്ന വിദ്യാർത്ഥികൾക്ക് സുസ്ഥിരവും ഫലപ്രദവുമായ അനുഭവം ഉറപ്പാക്കിക്കൊണ്ട് സുസ്ഥിരതയുടെ വൈവിധ്യമാർന്ന വശങ്ങൾ അഭിസംബോധന ചെയ്യുന്നതിനാണ് ഈ സമഗ്രമായ പ്രോഗ്രാം രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.

</p>

<ol className="goalpoints">
    <li><span>മനുഷ്യ ആരോഗ്യ സ്തംഭം : </span> </li>
    <ul>
        <li>സുസ്ഥിര ജീവിതരീതികൾ, പരിസ്ഥിതിയുമായി ബന്ധപ്പെട്ട മാനസികാരോഗ്യം, പരിസ്ഥിതി ബോധമുള്ള മൂല്യങ്ങളുമായി യോജിപ്പിച്ച് വ്യക്തിഗത ക്ഷേമം എന്നിവയിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്ന വിദ്യാഭ്യാസ സാമഗ്രികളും വർക്ക് ഷോപ്പുകളും വാഗ്ദാനം ചെയ്തുകൊണ്ട് പ്രോഗ്രാം മനുഷ്യന്റെ ആരോഗ്യം പ്രോത്സാഹിപ്പിക്കുന്നു.
 </li>
    </ul>

    <li><span>എർത്ത് വെൽനസ് പില്ലർ : </span> </li>
    <ul>
        <li>ഭൂമിയുടെ ക്ഷേമത്തിന് സംഭാവന ചെയ്യുന്ന പ്രവർത്തനങ്ങളിലും പ്രോജക്ടുകളിലും വിദ്യാർത്ഥികൾ ഏർപ്പെടുന്നു. പുനരുപയോഗ ഊർജം, മാലിന്യ നിർമാർജനം, പരിസ്ഥിതി സൗഹൃദ സാങ്കേതികവിദ്യകൾ എന്നിവയുമായി ബന്ധപ്പെട്ട സംരംഭങ്ങളെ കുറിച്ച് പഠിക്കുന്നതും സജീവമായി പങ്കെടുക്കുന്നതും ഇതിൽ ഉൾപ്പെടുന്നു.
 </li>
    </ul>

    <li><span>പ്രകൃതി ക്ഷേമ സ്തംഭം : </span> </li>
    <ul>
        <li>പ്രോഗ്രാം പ്രകൃതിയുമായുള്ള ബന്ധത്തിന് ഊന്നൽ നൽകുന്നു, ജൈവവൈവിധ്യത്തെ അഭിനന്ദിക്കാനും സംരക്ഷിക്കാനും വിദ്യാർത്ഥികളെ പ്രോത്സാഹിപ്പിക്കുന്നു. വൃക്ഷത്തൈ നടൽ, ആവാസവ്യവസ്ഥ പുനഃസ്ഥാപിക്കൽ, പ്രകൃതി വിനോദയാത്രകൾ തുടങ്ങിയ പരിപാടികളിലൂടെ, പ്രകൃതി ലോകത്തെ സംരക്ഷിക്കേണ്ടതിന്റെ പ്രാധാന്യത്തെക്കുറിച്ച് വിദ്യാർത്ഥികൾ ആഴത്തിലുള്ള ധാരണ വളർത്തിയെടുക്കുന്നു.
 </li>
    </ul>

    <li><span>സ്ഥിരമായ ലാഭ സ്തംഭം : </span> </li>
    <ul>
        <li>സ്ഥിരമായ ലാഭത്തിന്റെ പശ്ചാത്തലത്തിൽ സുസ്ഥിരത അഭിസംബോധന ചെയ്യുന്നത് സുസ്ഥിര ബിസിനസ്സ് രീതികളെക്കുറിച്ചുള്ള അറിവ് വിദ്യാർത്ഥികളെ സജ്ജരാക്കുന്നതിലൂടെയാണ്. ഈ സ്തംഭത്തിൽ ഹരിത സംരംഭകത്വം, ഉത്തരവാദിത്ത ഉപഭോഗം, പാരിസ്ഥിതികവും സാമൂഹികവുമായ ഉത്തരവാദിത്തങ്ങൾക്ക് മുൻഗണന നൽകിക്കൊണ്ട് ബിസിനസുകൾ അഭിവൃദ്ധി പ്രാപിക്കാനുള്ള തന്ത്രങ്ങൾ എന്നിവയെക്കുറിച്ചുള്ള വർക്ക് ഷോപ്പുകൾ ഉൾപ്പെടുന്നു.
 </li>
    </ul>
  </ol>
<p>മൊത്തത്തിൽ, ZHA സ്റ്റുഡന്റ്‌സ് സസ്റ്റൈനബിലിറ്റി ക്ലബ് അംഗത്വ പ്രോഗ്രാം സുസ്ഥിരത സ്തംഭങ്ങളുടെ മുഴുവൻ സ്പെക്‌ട്രവും ഉൾക്കൊള്ളുന്ന അതിന്റെ ഉൾച്ചേർക്കലിനായി വേറിട്ടുനിൽക്കുന്നു. സ്ഥിരമായ ലാഭത്തിനായുള്ള പരിഗണനകളോടെ മനുഷ്യനെയും ഭൂമിയെയും പ്രകൃതിയെയും സംയോജിപ്പിച്ചുകൊണ്ട്, ഈ തൂണുകളുടെ പരസ്പര ബന്ധത്തെക്കുറിച്ച് ബോധവാന്മാരാകുക മാത്രമല്ല, സന്തുലിതവും സുസ്ഥിരവുമായ ഭാവിയിലേക്ക് സജീവമായി സംഭാവന ചെയ്യുന്ന പരിസ്ഥിതി ബോധമുള്ള നേതാക്കളെ പ്രോഗ്രാം പരിപോഷിപ്പിക്കുന്നു.</p>

<p>സ്കൂളുകളും കോളേജുകളും ZHA സ്റ്റുഡന്റ്സ് സസ്റ്റൈനബിലിറ്റി മെമ്പർഷിപ്പ് പ്രോഗ്രാം സുസ്ഥിരത ക്ലബ്ബുകളുടെ ആമുഖം വഴി സ്കൂളുകൾക്ക് നിരവധി ആനുകൂല്യങ്ങൾ വാഗ്ദാനം ചെയ്യുന്നു:

</p>
<ol className="goalpoints">
    <li><span>മെച്ചപ്പെടുത്തിയ വിദ്യാഭ്യാസം : </span>വിദ്യാർത്ഥികൾക്കിടയിൽ സാമൂഹിക ഉത്തരവാദിത്തത്തെക്കുറിച്ചും സുസ്ഥിരതയെക്കുറിച്ചും ആഴത്തിലുള്ള ധാരണ വളർത്തിയെടുക്കുന്ന, അറിവ് പകർന്നുനൽകുക മാത്രമല്ല, അനുഭവപരമായ പഠനം സുഗമമാക്കുകയും ചെയ്യുന്ന ഒരു സുസ്ഥിര പരിശീലകനിലേക്ക് സ്കൂളുകൾ പ്രവേശനം നേടുന്നു.

 </li>
    <li><span>സമഗ്ര വികസനം : </span>സുസ്ഥിരത ക്ലബ്ബ് ആശയം വിദ്യാർത്ഥികളുടെ വിദ്യാഭ്യാസത്തിൽ സാമൂഹിക ഉത്തരവാദിത്തവും സുസ്ഥിരതാ മനോഭാവവും സമന്വയിപ്പിച്ചുകൊണ്ട് സമഗ്രമായ വികസനം പ്രോത്സാഹിപ്പിക്കുന്നു, ലക്ഷ്യബോധവും ആഗോള പൗരത്വവും പ്രോത്സാഹിപ്പിക്കുന്നു.

 </li>
    <li><span>പ്രായോഗിക പ്രയോഗം : </span>ക്ലബ് പ്രവർത്തനങ്ങൾ, പ്രോജക്ടുകൾ, ഇവന്റുകൾ എന്നിവയിലൂടെ സുസ്ഥിരത തത്വങ്ങളുടെ പ്രായോഗിക പ്രയോഗത്തിന് അംഗത്വം ഒരു പ്ലാറ്റ്ഫോം നൽകുന്നു, ഇത് വിദ്യാർത്ഥികളെ സൈദ്ധാന്തിക അറിവ് യഥാർത്ഥ ലോക പ്രവർത്തനങ്ങളിലേക്ക് വിവർത്തനം ചെയ്യാൻ അനുവദിക്കുന്നു.
 </li>
    <li><span>കമ്മ്യൂണിറ്റി ഇടപഴകൽ :</span>സുസ്ഥിരത ക്ലബ്ബുകൾ വിദ്യാർത്ഥികളെ അവരുടെ പ്രാദേശിക കമ്മ്യൂണിറ്റികളുമായി സജീവമായി ഇടപഴകുന്നതിനും സാമൂഹിക കാരണങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്നതിനും ചുറ്റുമുള്ള പരിസ്ഥിതിയിൽ നല്ല സ്വാധീനം സൃഷ്ടിക്കുന്നതിനും പ്രോത്സാഹിപ്പിക്കുന്നു.

 </li>
    <li><span>നേതൃത്വ അവസരങ്ങൾ : </span>സുസ്ഥിരത ക്ലബ്ബുകളിൽ ഉൾപ്പെട്ടിരിക്കുന്ന വിദ്യാർത്ഥികൾക്ക് നേതൃത്വപരമായ റോളുകൾ ഏറ്റെടുക്കാനും നേതൃത്വപരമായ കഴിവുകൾ വളർത്താനും സുസ്ഥിരമായ സമ്പ്രദായങ്ങളിലേക്ക് തങ്ങളുടെ സഹപാഠികളെ നയിക്കുന്നതിൽ ഉത്തരവാദിത്തബോധം വളർത്താനും അവസരമുണ്ട്.

 </li>
    <li><span>നെറ്റ്‌വർക്കിംഗും സഹകരണവും : </span>ക്ലബ്ബ് അംഗത്വം സ്‌കൂളുകളെ സുസ്ഥിര സംരംഭങ്ങളിൽ പങ്കാളികളാകുന്ന വിദ്യാഭ്യാസ സ്ഥാപനങ്ങളുടെ വിശാലമായ ശൃംഖലയുമായി ബന്ധിപ്പിക്കുന്നു, സഹകരണത്തിനും വിജ്ഞാന പങ്കിടലിനും സംയുക്ത പദ്ധതികൾക്കും അവസരങ്ങൾ സൃഷ്ടിക്കുന്നു.

 </li>
    <li><span>പാഠ്യപദ്ധതി ലക്ഷ്യങ്ങളുമായി വിന്യസിച്ചു : </span>സുസ്ഥിരതാ തത്വങ്ങളുടെ സംയോജനം സമകാലിക വിദ്യാഭ്യാസ ലക്ഷ്യങ്ങളുമായി പൊരുത്തപ്പെടുന്നു, ആഗോള വെല്ലുവിളികളെ അഭിമുഖീകരിക്കുന്നതിനും സുസ്ഥിര വികസനത്തിന് സംഭാവന നൽകുന്നതിനുമുള്ള ഒരു ചട്ടക്കൂട് സ്കൂളുകൾക്ക് നൽകുന്നു.
 </li>
    <li><span>പോസിറ്റീവ് സ്കൂൾ സംസ്കാരം :</span>ഒരു സുസ്ഥിരത ക്ലബിന്റെ സാന്നിധ്യം പോസിറ്റീവും മുന്നോട്ട് ചിന്തിക്കുന്നതുമായ സ്കൂൾ സംസ്കാരത്തിന് സംഭാവന നൽകുന്നു, പാരിസ്ഥിതിക കാര്യനിർവഹണം, സാമൂഹിക ഉത്തരവാദിത്തം, ധാർമ്മിക പെരുമാറ്റം തുടങ്ങിയ മൂല്യങ്ങൾക്ക് ഊന്നൽ നൽകുന്നു.

 </li>
    <li><span>അംഗീകാരവും അക്രഡിറ്റേഷനും : </span>ZHA സുസ്ഥിരത ക്ലബ്ബുമായി അഫിലിയേറ്റ് ചെയ്‌തിരിക്കുന്ന സ്‌കൂളുകൾ സുസ്ഥിരതയോടുള്ള പ്രതിബദ്ധതയ്ക്ക് അംഗീകാരം നേടുന്നു, ഇത് സുസ്ഥിരമായ വിദ്യാഭ്യാസ അന്തരീക്ഷം പ്രോത്സാഹിപ്പിക്കുന്നതിനുള്ള അവരുടെ ശ്രമങ്ങളെ ഉയർത്തിക്കാട്ടുന്ന അക്രഡിറ്റേഷനിലേക്കോ അവാർഡുകളിലേക്കോ നയിച്ചേക്കാം.

 </li>
    <li><span>ഭാവിയിലേക്കുള്ള തയ്യാറെടുപ്പ് :</span>പരിസ്ഥിതി, സാമൂഹിക സുസ്ഥിരത എന്നിവയിൽ കൂടുതൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്ന ഒരു ലോകത്തെ നാവിഗേറ്റ് ചെയ്യുന്നതിന് ആവശ്യമായ അറിവും കഴിവുകളും മൂല്യങ്ങളും കൊണ്ട് വിദ്യാർത്ഥികളെ സജ്ജരാക്കുന്നതിലൂടെ സുസ്ഥിരത ക്ലബ്ബ് ആശയം ഭാവിയിലേക്ക് വിദ്യാർത്ഥികളെ സജ്ജമാക്കുന്നു.
 </li>
  </ol>

  <p>ചുരുക്കത്തിൽ, ZHA വിദ്യാർത്ഥികളുടെ സുസ്ഥിരത അംഗത്വം സുസ്ഥിര വിദ്യാഭ്യാസം സമന്വയിപ്പിക്കുന്നതിലൂടെയും, സമഗ്രമായ വികസനം പ്രോത്സാഹിപ്പിക്കുന്നതിലൂടെയും, കൂടുതൽ സുസ്ഥിരവും സാമൂഹിക പ്രതിബദ്ധതയുള്ളതുമായ ഭാവിയിലേക്ക് സജീവമായി സംഭാവന ചെയ്യാൻ വിദ്യാർത്ഥികളെ ശാക്തീകരിക്കുന്നതിലൂടെ സ്കൂളുകൾക്ക് പ്രയോജനം ചെയ്യുന്നു.</p>
     
</div>
        

        </div>

    );
}

