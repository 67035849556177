import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">
            <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Establishment-Story-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Establishment-Story-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Establishment-Story-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Establishment-Story-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Establishment-Story-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
            


                <h1 id="mhead">Zha Establishment Story</h1>
<br></br>
                <div className='second-mcontent'>
                    <img src='../../img/story.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">
                    <br></br>
                    <br></br>
                <p>Zha foundation is founded in 2017 and is recognized as 80G approved NPO & NGO by the Indian Government. The story of the Zha Foundation begins with successful professionals across various industries who desired to contribute to the global development of sustainable living. However, they found a lack of innovative platforms aligning with their values. Simultaneously, successful businessmen had exclusive business clubs that instilled pride in their achievements. Our Zha foundation has been involved in various social services Youth wellness, Rural sports development, Organic Farmers Welfare Activities, Promote Organic Farming, Health awareness programs, Free Coaching for TNPSC & Poor School Students. Apart from this, Recognizing this gap, the founder of Zha, believed in the necessity of forming a strategic social work board to enable the mindset of the people in sustainability practices of our nation for an outcome of creating a circular economy. Our Zha foundation board would consist of accomplished professionals and business leaders at all levels and from diverse locations. The aim was to propagate the Zha sustainability practices, raising awareness among the general public and fostering a global impact through the Zha sustainability practitioners club.</p>



                <p>The founder of Zha, envisioned the creation of a purposeful social work hobby for the people, benefiting the planet and everyone's well-being, inspired by the caring and sharing model embedded in sustainability principles. Thus, the overarching theme of Zha Foundation became sustainability.</p>


                <p>For Zha Sustainability Practitioners Club, Zha Foundation is committed to establishing a benchmark theory of sustainability applicable to all individuals, not just corporations. In alignment with the United Nations' sustainable goals and ESG measurements, Zha has developed a methodology tailored for the general public and students, so as to practice the concepts of Sustainability for people via Zha Methodology for Sustainability Mindset.</p>


                <p>The Zha sustainability programs aim to empower the public, including common individuals, women, men, and students, to embrace the principles and values of the Zha sustainability mindset framework. This framework serves as a methodology for all humans to adopt, allowing them to measure their sustainability mindset capabilities. Leveraging technology and tools, Zha Foundation provides free resources to help the public understand the United Nations' sustainability goals. The methodology includes key practice areas within the Zha sustainability enabler framework, shaping a comprehensive approach to sustainable living.</p>
                    <br>
                    </br>
 
                </div>

                {/* <h3>Our Vision</h3> */}
                <div className="missi">
                    <h4  >The Sustainability outcomes of Zha foundation initiatives:</h4>
                     

                </div>
                
<ol className="goalpoints">
    <li><span>Individual Empowerment : </span>Through Zha's sustainability programs, common individuals, women, men, and students are empowered to adopt a sustainable mindset. The foundation's focus on inclusivity ensures that everyone can contribute to the betterment of the planet.

 </li>
    <li><span>Global Impact : </span>By aligning with the United Nations' sustainable goals and ESG measurements, Zha Foundation contributes to global initiatives for sustainable development. The benchmark theory of sustainability, applicable to individuals worldwide, emphasizes a collective effort towards positive change.

 </li>
    <li><span>Measurable Impact : </span>Zha's methodology provides a tangible way for individuals to measure their sustainability mindset capabilities. This allows people to track and understand their contributions to sustainable living.

 </li>
    <li><span>Education and Awareness : </span>Zha Foundation leverages technology and tools to offer free resources, fostering public understanding of the United Nations' sustainability goals. This educational aspect promotes awareness and encourages a broader adoption of sustainable practices.
 </li>
    <li><span>Comprehensive Framework : </span>The Zha sustainability enabler framework, with its key practice areas, provides a structured and comprehensive approach to sustainable living. This framework guides individuals in incorporating sustainability into various aspects of their lives.

 </li>
    <li><span>Community Building : </span>The caring and sharing model embedded in sustainability principles fosters a sense of community and collective responsibility. Zha Foundation encourages individuals to collaborate, creating a network of like-minded people dedicated to sustainable practices.


 </li>
     
  </ol>

 <p>Overall, following the Zha Foundation story leads to a positive impact on individuals, communities, and the global environment by promoting sustainability, education, and collective action.</p>

 

            </section>
        </div>


    );
}

