import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
         <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Students-Sustainability-Club">English</NavLink></a>



</div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Zha மாணவர்கள் நிலைத்தன்மை கிளப்</h1>
</div>


<div className="internship">
<img src="../img/students.webp"></img>
        </div>
<div className="goalpointsp">

<p> ZHA மாணவர்களின் நிலைத்தன்மை கிளப் உறுப்பினர் திட்டம் என்பது ZHA அறக்கட்டளையால் நிறுவப்பட்ட நிலைத்தன்மையின் நான்கு தூண்களை உள்ளடக்கிய ஒரு முழுமையான முயற்சியாகும். இந்த விரிவான திட்டமானது, நிலைத்தன்மையின் பல்வேறு அம்சங்களை நிவர்த்தி செய்யும் வகையில் வடிவமைக்கப்பட்டுள்ளது, பங்கேற்பாளர்களுக்கு நன்கு வட்டமான மற்றும் தாக்கத்தை ஏற்படுத்தும் அனுபவத்தை உறுதி செய்கிறது.

</p>

<ol className="goalpoints">
    <li><span>மனித ஆரோக்கிய தூண் : </span> </li>
    <ul>
        <li>நிலையான வாழ்க்கை நடைமுறைகள், சுற்றுச்சூழலுடன் தொடர்புடைய மன ஆரோக்கியம் மற்றும் சுற்றுச்சூழல் உணர்வு மதிப்புகளுடன் இணைந்த தனிப்பட்ட நல்வாழ்வு ஆகியவற்றில் கவனம் செலுத்தும் கல்வி பொருட்கள் மற்றும் பட்டறைகளை வழங்குவதன் மூலம் இந்த திட்டம் மனித ஆரோக்கியத்தை மேம்படுத்துகிறது.
 </li>
    </ul>

    <li><span>பூமி ஆரோக்கிய தூண் :</span> </li>
    <ul>
        <li>பூமியின் நல்வாழ்வுக்கு பங்களிக்கும் நடவடிக்கைகள் மற்றும் திட்டங்களில் மாணவர்கள் ஈடுபடுகின்றனர். புதுப்பிக்கத்தக்க எரிசக்தி, கழிவு குறைப்பு மற்றும் சுற்றுச்சூழல் நட்பு தொழில்நுட்பங்கள் தொடர்பான முன்முயற்சிகளைப் பற்றி அறிந்துகொள்வதும் அதில் தீவிரமாக பங்கேற்பதும் இதில் அடங்கும்.
 </li>
    </ul>

    <li><span>இயற்கை ஆரோக்கிய தூண் :</span> </li>
    <ul>
        <li>இந்தத் திட்டம் இயற்கையுடனான தொடர்பை வலியுறுத்துகிறது, பல்லுயிர்களைப் பாராட்டவும் பாதுகாக்கவும் மாணவர்களை ஊக்குவிக்கிறது. மரம் நடுதல், வாழ்விட மறுசீரமைப்பு மற்றும் இயற்கை உல்லாசப் பயணம் போன்ற நிகழ்வுகள் மூலம், இயற்கை உலகைப் பாதுகாப்பதன் முக்கியத்துவத்தைப் பற்றிய ஆழமான புரிதலை மாணவர்கள் உருவாக்குகிறார்கள்.
 </li>
    </ul>

    <li><span>நிலையான இலாபத் தூண் : </span> </li>
    <ul>
        <li>நிலையான லாபத்தின் பின்னணியில் நிலைத்தன்மை என்பது மாணவர்களை நிலையான வணிக நடைமுறைகள் பற்றிய அறிவை வழங்குவதன் மூலம் உரையாற்றப்படுகிறது. இந்த தூணில் பசுமை தொழில் முனைவோர், பொறுப்பான நுகர்வு மற்றும் சுற்றுச்சூழல் மற்றும் சமூகப் பொறுப்புகளுக்கு முன்னுரிமை அளிக்கும் போது வணிகங்கள் வளர்ச்சியடைவதற்கான உத்திகள் ஆகியவை அடங்கும்.
 </li>
    </ul>
  </ol>
<p>ஒட்டுமொத்தமாக, ZHA மாணவர்களின் நிலைத்தன்மை கிளப் உறுப்பினர் திட்டம் அதன் உள்ளடக்கத்திற்காக தனித்து நிற்கிறது, இது நிலைத்தன்மை தூண்களின் முழு நிறமாலையையும் உள்ளடக்கியது. மனித, பூமி மற்றும் இயற்கை ஆரோக்கியத்தை ஒருங்கிணைத்து, நிலையான லாபம் கருதி, இந்தத் தூண்களின் ஒன்றோடொன்று இணைந்திருப்பதை மட்டும் அறிந்திருக்காமல், சீரான மற்றும் நிலையான எதிர்காலத்திற்குத் தீவிரமாகப் பங்களிக்கும் சுற்றுச்சூழல் உணர்வுள்ள தலைவர்களை இந்தத் திட்டம் வளர்க்கிறது.</p>

<p>பள்ளிகள் மற்றும் கல்லூரிகள் ZHA மாணவர்களின் நிலைத்தன்மை உறுப்பினர் திட்டம், நிலைத்தன்மை கிளப்புகளை அறிமுகப்படுத்துவதன் மூலம் பள்ளிகளுக்கு பல நன்மைகளை வழங்குகிறது:
</p>
<ol className="goalpoints">
    <li><span>மேம்படுத்தப்பட்ட கல்வி : </span>மாணவர்கள் மத்தியில் சமூகப் பொறுப்பு மற்றும் நிலைத்தன்மை பற்றிய ஆழமான புரிதலை வளர்த்து, அறிவை வழங்குவதோடு மட்டுமல்லாமல் அனுபவமிக்க கற்றலையும் எளிதாக்கும் நிலைத்தன்மை பயிற்சியாளரை பள்ளிகள் பெறுகின்றன.

 </li>
    <li><span>முழுமையான வளர்ச்சி : </span>நிலைத்தன்மை கிளப் கருத்து, சமூகப் பொறுப்பு மற்றும் நிலைத்தன்மை மனநிலையை மாணவர்களின் கல்வியில் ஒருங்கிணைப்பதன் மூலம் முழுமையான வளர்ச்சியை ஊக்குவிக்கிறது, நோக்கம் மற்றும் உலகளாவிய குடியுரிமையை ஊக்குவிக்கிறது.

 </li>
    <li><span>நடைமுறைப் பயன்பாடு :</span>கிளப் செயல்பாடுகள், திட்டங்கள் மற்றும் நிகழ்வுகள் மூலம் நிலைத்தன்மைக் கொள்கைகளை நடைமுறைப்படுத்துவதற்கான ஒரு தளத்தை உறுப்பினர் வழங்குகிறது, இது மாணவர்களின் தத்துவார்த்த அறிவை நிஜ-உலக செயல்களாக மொழிபெயர்க்க அனுமதிக்கிறது.

 </li>
    <li><span>சமூக ஈடுபாடு : </span>நிலைத்தன்மை கிளப்புகள் மாணவர்களை தங்கள் உள்ளூர் சமூகங்களுடன் தீவிரமாக ஈடுபட ஊக்குவிக்கின்றன, சமூக காரணங்களை மேம்படுத்துகின்றன மற்றும் சுற்றியுள்ள சூழலில் நேர்மறையான தாக்கத்தை உருவாக்குகின்றன.
 </li>
    <li><span>தலைமைத்துவ வாய்ப்புகள் : </span>நிலைத்தன்மை கிளப்புகளில் ஈடுபட்டுள்ள மாணவர்கள் தலைமைப் பாத்திரங்களை ஏற்கவும், தலைமைத்துவ திறன்களை வளர்ப்பதற்கும் மற்றும் நிலையான நடைமுறைகளை நோக்கி தங்கள் சகாக்களை வழிநடத்துவதில் பொறுப்புணர்வு உணர்வைத் தூண்டுவதற்கும் வாய்ப்பு உள்ளது.

 </li>
    <li><span>நெட்வொர்க்கிங் மற்றும் ஒத்துழைப்பு : </span>கிளப் உறுப்பினர் என்பது பள்ளிகளை பரந்த அளவிலான கல்வி நிறுவனங்களுடன் இணைக்கிறது, இது நிலையான முயற்சிகளில் பங்கேற்கிறது, ஒத்துழைப்பு, அறிவு பகிர்வு மற்றும் கூட்டு திட்டங்களுக்கு வாய்ப்புகளை உருவாக்குகிறது.
 </li>
    <li><span>பாடத்திட்ட இலக்குகளுடன் சீரமைக்கப்பட்டது : </span>நிலைத்தன்மைக் கொள்கைகளின் ஒருங்கிணைப்பு சமகால கல்வி இலக்குகளுடன் ஒத்துப்போகிறது, உலகளாவிய சவால்களை எதிர்கொள்ளவும், நிலையான வளர்ச்சிக்கு பங்களிக்கவும் ஒரு கட்டமைப்பை பள்ளிகளுக்கு வழங்குகிறது.
 </li>
    <li><span>நேர்மறை பள்ளி கலாச்சாரம் :</span>ஒரு நிலைத்தன்மை கிளப்பின் இருப்பு ஒரு நேர்மறையான மற்றும் முன்னோக்கி சிந்திக்கும் பள்ளி கலாச்சாரத்திற்கு பங்களிக்கிறது, சுற்றுச்சூழல் பொறுப்புணர்வு, சமூக பொறுப்பு மற்றும் நெறிமுறை நடத்தை போன்ற மதிப்புகளை வலியுறுத்துகிறது.
 </li>
    <li><span>அங்கீகாரம் மற்றும் அங்கீகாரம் : </span>ZHA நிலைத்தன்மை கிளப்புடன் இணைந்த பள்ளிகள், நிலையான கல்விச் சூழலை மேம்படுத்துவதில் அவர்களின் முயற்சிகளை உயர்த்திக் காட்டும் அங்கீகாரம் அல்லது விருதுகளுக்கு வழிவகுக்கும்.

 </li>
    <li><span>எதிர்காலத்திற்கான தயாரிப்பு : </span>நிலைத்தன்மை கிளப் கருத்து, சுற்றுச்சூழல் மற்றும் சமூக நிலைத்தன்மையில் அதிக கவனம் செலுத்தும் உலகிற்கு செல்ல தேவையான அறிவு, திறன்கள் மற்றும் மதிப்புகளுடன் மாணவர்களை சித்தப்படுத்துவதன் மூலம் மாணவர்களை எதிர்காலத்திற்கு தயார்படுத்துகிறது.

 </li>
  </ol>

  <p>சுருக்கமாக, ZHA மாணவர்களின் நிலைத்தன்மை உறுப்பினர் நிலைத்தன்மை கல்வியை ஒருங்கிணைத்து, முழுமையான வளர்ச்சியை ஊக்குவிப்பதன் மூலம் பள்ளிகளுக்கு நன்மை பயக்கும், மேலும் நிலையான மற்றும் சமூகப் பொறுப்புள்ள எதிர்காலத்திற்கு தீவிரமாக பங்களிக்க மாணவர்களை மேம்படுத்துகிறது.</p>
     
</div>
        

        </div>

    );
}

