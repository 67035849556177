import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">English</NavLink></a>
         
        </div>
       
        <h1 id="mhead">ఝా స్కేలింగ్ సస్టైనబిలిటీ మెచ్యూరిటీ ఫ్రేమ్‌వర్క్</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
            <img src='../../img/maturityframework.webp'></img>
            </div>
            <div>
           <p>దిగువ వివరాలతో రూపొందించబడిన ఝా స్కేలింగ్ సస్టైనబిలిటీ మెచ్యూరిటీ ఫ్రేమ్‌వర్క్ ఇక్కడ ఉంది. నాలుగు స్థాయిల పరిపక్వతతో మానవ ఆరోగ్యం, ప్రకృతి వెల్నెస్ మరియు ఎర్త్ వెల్నెస్ కోసం స్కేలింగ్ సస్టైనబిలిటీ ఫ్రేమ్‌వర్క్‌ను రూపొందించడం అనేది జాగ్రత్తగా ప్రణాళిక మరియు పరిశీలనను కలిగి ఉంటుంది. అటువంటి ఫ్రేమ్‌వర్క్‌ను అభివృద్ధి చేయడంలో మీకు సహాయపడే సాధారణ గైడ్ ఇక్కడ ఉంది:


            </p>
            <h2>మెచ్యూరిటీ యొక్క నాలుగు స్థాయిలను నిర్వచించండి</h2>
           
<ol>
    <li><span>దీక్ష : </span>
    <ul>
        <li>సుస్థిరత సూత్రాలపై ప్రాథమిక అవగాహన మరియు నిబద్ధత.</li>
        <li>స్థిరమైన అభ్యాసాలను చేర్చడానికి ప్రారంభ ప్రయత్నాలు.</li>
        <li>పరిమిత పరిధి మరియు ప్రభావం.</li>
    </ul>

</li>


<li><span>అభివృద్ధి : </span>
    <ul>
        <li>మానవుడు, ప్రకృతి మరియు భూమి ఆరోగ్యం యొక్క పరస్పర అనుసంధానంపై పెరుగుతున్న అవగాహన.</li>
        <li>సమగ్ర సుస్థిరత కార్యక్రమాలను అమలు చేయడం.</li>
        <li>వివిధ డొమైన్‌లలో ప్రయత్నాలను విస్తరిస్తోంది.</li>
    </ul>

</li>


<li><span>సర్వోత్తమీకరణం : </span>
    <ul>
        <li>స్థిరమైన అభ్యాసాల యొక్క అధునాతన ఏకీకరణ.</li>
        <li>డేటా మరియు ఫీడ్‌బ్యాక్ ఆధారంగా నిరంతర మెరుగుదల.</li>
        <li>వాటాదారులతో సహకార భాగస్వామ్యం.
</li>
    </ul>

</li>
  
<li><span>పరివర్తన : </span>
    <ul>
        <li>సుస్థిరతకు సంపూర్ణ మరియు రూపాంతర విధానం.
</li>
        <li>పరిశ్రమ ప్రమాణాలను నిర్ణయించడంలో నాయకత్వం.</li>
        <li>కొనసాగుతున్న ఆవిష్కరణ మరియు అభివృద్ధి చెందుతున్న సవాళ్లకు అనుగుణంగా.</li>
    </ul>

</li>
</ol>


<h2>ఫ్రేమ్‌వర్క్ భాగాలు</h2>
           
           <ol>
               <li><span>మానవ ఆరోగ్యం : </span>
               <ul>
                   <li><span>ఆరోగ్యం మరియు సంక్షేమ కార్యక్రమాలు : </span>శారీరక, మానసిక మరియు మానసిక శ్రేయస్సును ప్రోత్సహించే కార్యక్రమాలను అమలు చేయండి.</li>
                   <li><span>పని-జీవిత సంతులనం : </span>పని-జీవిత సమతుల్యత మరియు ఉద్యోగి సంతృప్తికి ప్రాధాన్యతనిచ్చే పర్యావరణాలను ప్రోత్సహించండి.</li>
                   <li><span>కమ్యూనిటీ ఎంగేజ్‌మెంట్ : </span>స్థానిక కమ్యూనిటీలతో నిమగ్నమవ్వడానికి మరియు మద్దతు ఇవ్వడానికి ఉద్యోగులను ప్రోత్సహించండి.</li>
               </ul>
           
           </li>

           <li><span>ప్రకృతి ఆరోగ్యం : </span>
               <ul>
                   <li><span>జీవవైవిధ్య పరిరక్షణ : </span>జీవవైవిధ్యాన్ని సంరక్షించడానికి మరియు మెరుగుపరచడానికి పద్ధతులను అమలు చేయండి.</li>
                   <li><span>పర్యావరణ అనుకూల కార్యకలాపాలు : </span>పర్యావరణ ప్రభావాన్ని తగ్గించడానికి ప్రక్రియలను ఆప్టిమైజ్ చేయండి.</li>
                   <li><span>సస్టైనబుల్ సోర్సింగ్ : </span>స్థిరమైన మూలాధార పదార్థాల వినియోగానికి ప్రాధాన్యత ఇవ్వండి.</li>
               </ul>
           
           </li>

           <li><span>భూమి ఆరోగ్యం : </span>
               <ul>
                   <li><span>కార్బన్ న్యూట్రాలిటీ : </span>పునరుత్పాదక శక్తి మరియు కార్బన్ ఆఫ్‌సెట్ కార్యక్రమాల ద్వారా కార్బన్ న్యూట్రాలిటీ కోసం కృషి చేయండి.</li>
                   <li><span>వ్యర్థాల తగ్గింపు : </span>వ్యర్థాల తగ్గింపు మరియు రీసైక్లింగ్ కార్యక్రమాలను అమలు చేయండి.</li>
                   <li><span>వాతావరణ స్థితిస్థాపకత : </span>వాతావరణ మార్పుల ప్రభావాన్ని స్వీకరించడానికి మరియు తగ్గించడానికి వ్యూహాలను అభివృద్ధి చేయండి.</li>
               </ul>
           
           </li>
           </ol>



           <h2>అమలు దశలు</h2>
           
           <ol>
               <li><span>మూల్యాంకనం : </span>
               <ul>
                   <li>వెల్నెస్ యొక్క ప్రతి కోణంలో ప్రస్తుత పద్ధతుల యొక్క ప్రాథమిక అంచనాను నిర్వహించండి.</li>
                   <li>బలాలు, బలహీనతలు, అవకాశాలు మరియు బెదిరింపులను గుర్తించండి.</li>
                
               </ul>
           
           </li>
           
           
           <li><span>లక్ష్యాన్ని ఏర్పచుకోవడం : </span>
               <ul>
                   <li>మానవ, ప్రకృతి మరియు భూమి ఆరోగ్యానికి సంబంధించిన ప్రతి స్థాయి పరిపక్వత కోసం నిర్దిష్ట, కొలవదగిన, సాధించగల, సంబంధిత మరియు సమయ పరిమితి (SMART) లక్ష్యాలను ఏర్పరచుకోండి.</li>
               </ul>
           
           </li>
           
           
           <li><span>అమలు ప్రణాళికలు : </span>
               <ul>
                   <li>ప్రతి స్థాయి పరిపక్వత, చర్యలు, బాధ్యతలు మరియు సమయపాలనలను పేర్కొనడం కోసం వివరణాత్మక ప్రణాళికలను అభివృద్ధి చేయండి.</li>
                   <li>వివిధ సందర్భాలలో స్కేలబిలిటీ మరియు రెప్లిబిలిటీని పరిగణించండి.</li>
               </ul>
           
           </li>
             
           <li><span>పర్యవేక్షణ మరియు మూల్యాంకనం : </span>
               <ul>
                   <li>బలమైన పర్యవేక్షణ మరియు మూల్యాంకన విధానాలను అమలు చేయండి.</li>
                   <li>క్రమంగా పురోగతిని అంచనా వేయండి, అభిప్రాయాన్ని సేకరించండి మరియు తదనుగుణంగా వ్యూహాలను సర్దుబాటు చేయండి.
</li>
               </ul>
           
           </li>


           <li><span>వాటాదారుల నిశ్చితార్థం : </span>
               <ul>
                   <li>ఉద్యోగులు, సంఘాలు, సరఫరాదారులు మరియు నియంత్రణ సంస్థలతో సహా అన్ని స్థాయిలలోని వాటాదారులతో పరస్పర చర్చ చేయండి.</li>
                   <li>సహకారం మరియు భాగస్వామ్య బాధ్యతను ప్రోత్సహించండి.
</li>
               </ul>
           
           </li>


           <li><span>విద్య మరియు కమ్యూనికేషన్ : </span>
               <ul>
                   <li>అవగాహన పెంచడానికి మరియు స్థిరత్వం యొక్క సంస్కృతిని పెంపొందించడానికి కొనసాగుతున్న విద్య మరియు కమ్యూనికేషన్‌ను అందించండి.</li>
                   <li>విజయాలను జరుపుకోండి మరియు ఉత్తమ అభ్యాసాలను పంచుకోండి.

</li>
               </ul>
           
           </li>

           <li><span>నిరంతర అభివృద్ధి : </span>
               <ul>
                   <li>నిరంతర అభివృద్ధి సంస్కృతిని స్వీకరించండి.</li>
                   <li>అభివృద్ధి చెందుతున్న ప్రమాణాలు మరియు సవాళ్లతో సమలేఖనం చేయడానికి స్థిరత్వ ఫ్రేమ్‌వర్క్‌ను క్రమం తప్పకుండా మళ్లీ సందర్శించండి మరియు నవీకరించండి.
</li>
               </ul>
           
           </li>

           </ol>
           

<p>మీ సంస్థ లేదా చొరవ యొక్క నిర్దిష్ట సందర్భం ఆధారంగా ఫ్రేమ్‌వర్క్‌ను అనుకూలీకరించాలని గుర్తుంచుకోండి మరియు స్థిరత్వం మరియు సంబంధిత రంగాలలో నిపుణుల నుండి ఇన్‌పుట్‌ను కోరండి.</p>
           </div>
        </div>









               
        
       

    </div>
);
}