import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">



            <div  id="language" >
        
        <a href=""><NavLink to="/Environment">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead"> ழ அறக்கட்டளை: பசுமையான நாளை வளர்ப்பது</h1>

                <div className='second-mcontent'>
                <img src='../../img/environment.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>Zha அறக்கட்டளைக்கு வரவேற்கிறோம், தற்போதைய மற்றும் எதிர்கால சந்ததியினருக்காக சுற்றுச்சூழலைப் பாதுகாப்பதில் நாங்கள் ஆர்வமாக உள்ளோம். சுற்றுச்சூழல் பாதுகாப்பிற்கான எங்கள் அர்ப்பணிப்பு ஒவ்வொரு தனிமனிதனும் சமூகமும் நாம் வீடு என்று அழைக்கும் கிரகத்தைப் பாதுகாப்பதில் முக்கிய பங்கு வகிக்கிறது என்ற நம்பிக்கையால் இயக்கப்படுகிறது
                    </p>
                </div>

                <h3>எமது நோக்கம்</h3>
                <div className="missi">
                    <h4 className="toleftneed">நிலையான எதிர்காலம்:</h4>
                    <p> ழ அறக்கட்டளையில், மனிதநேயம் இயற்கையுடன் இணக்கமாக வாழும் உலகத்தை நாங்கள் கற்பனை செய்கிறோம். எங்கள் பார்வை நிலைத்தன்மையில் வேரூன்றியுள்ளது, அங்கு சுற்றுச்சூழல் அமைப்புகள் செழித்து, பல்லுயிர் பாதுகாக்கப்படுகிறது, மேலும் ஒவ்வொரு செயலும் ஆரோக்கியமான கிரகத்திற்கு பங்களிக்கிறது.</p>


                </div>


                <h3>எங்கள் நோக்கம்</h3>
                <div className="missi">
                    <h4 className="toleftneed">மாற்றத்தை மேம்படுத்துதல்:</h4>
                    <p>சுற்றுச்சூழலின் பொறுப்பாளர்களாக மாறுவதற்கு தனிநபர்களையும் சமூகங்களையும் மேம்படுத்துவதே எங்கள் நோக்கம். கல்வி, வக்கீல் மற்றும் நடைமுறை முயற்சிகள் மூலம், பொறுப்பான மற்றும் நிலையான நடைமுறைகளுக்கு ஒரு கூட்டு அர்ப்பணிப்பை வளர்ப்பதை நோக்கமாகக் கொண்டுள்ளோம்.</p>
 
                </div>

                <h3>எங்கள் முன்முயற்சிகள்</h3>
                <div className="missi">
                    <h4 className="toleftneed">பாதுகாப்பு மற்றும் மறுசீரமைப்பு:</h4>
                    <p> ழ அறக்கட்டளை பாதுகாப்பு மற்றும் மறுசீரமைப்பு திட்டங்களில் தீவிரமாக ஈடுபட்டுள்ளது. காடு வளர்ப்பு முயற்சிகள் முதல் அழிந்து வரும் உயிரினங்களைப் பாதுகாப்பது வரை, பூமியில் உயிர்களை நிலைநிறுத்தும் இயற்கை வாழ்விடங்களைப் பாதுகாப்பதிலும் மீட்டெடுப்பதிலும் எங்கள் முயற்சிகள் கவனம் செலுத்துகின்றன.</p>

                    <h4 className="toleftneed">கழிவுகளை குறைக்கும் திட்டங்கள்:</h4>
                    <p>நமது சூழலியல் தடயத்தைக் குறைப்பதன் முக்கியத்துவத்தை நாங்கள் நம்புகிறோம். எங்களின் கழிவுக் குறைப்புத் திட்டங்கள் பொறுப்பான கழிவு மேலாண்மை, மறுசுழற்சி செய்தல் மற்றும் சுற்றுச்சூழலில் மனித நடவடிக்கைகளின் தாக்கத்தைக் குறைப்பதற்காக மேம்படுத்துகிறது.</p>

                    <h4 className="toleftneed">பசுமை ஆற்றல் பரிந்துரை:</h4>
                    <p>புதுப்பிக்கத்தக்க ஆற்றலுக்கு மாறுவது காலநிலை மாற்றத்தைத் தணிப்பதற்கான ஒரு முக்கிய அம்சமாகும். Zha அறக்கட்டளை பசுமை ஆற்றல் தீர்வுகளை ஏற்றுக்கொள்வதை ஆதரிக்கிறது, மேலும் நிலையான மற்றும் தூய்மையான ஆற்றல் நிலப்பரப்புக்கு பங்களிக்கிறது.</p>
                </div>


                <h3>எங்கள் அணுகுமுறை</h3>
                <div className="missi">
                    <h4 className="toleftneed">கல்வி மற்றும் விழிப்புணர்வு:</h4>
                    <p>அறிவு மாற்றத்திற்கான சக்திவாய்ந்த ஊக்கியாக உள்ளது. சுற்றுச்சூழல் பிரச்சினைகள், காலநிலை மாற்றம் மற்றும் நிலையான வாழ்க்கை பற்றிய விழிப்புணர்வை ஏற்படுத்தும் கல்வித் திட்டங்களில் நாங்கள் முதலீடு செய்கிறோம். தகவல் மூலம் தனிநபர்களுக்கு அதிகாரமளிப்பதன் மூலம், அர்த்தமுள்ள செயலை ஊக்குவிக்க முடியும் என்று நாங்கள் நம்புகிறோம்.</p>

                    <h4 className="toleftneed">சமூக ஈடுபாடு:</h4>
                    <p>சுற்றுச்சூழல் பாதுகாப்பு என்பது ஒரு கூட்டு முயற்சி. உள்ளூர் சமூகங்களுடன் நாங்கள் ஈடுபடுகிறோம், சுற்றுச்சூழல் முயற்சிகளில் தீவிரமாக பங்கேற்பதை ஊக்குவிக்கிறோம். சமூகம் தலைமையிலான திட்டங்கள் மற்றும் நிகழ்வுகள் மூலம், சுற்றுச்சூழல் சாம்பியன்களின் வலையமைப்பை உருவாக்குவதை நோக்கமாகக் கொண்டுள்ளோம்.</p>

                     
                </div>

            </section>
        </div>


    );
}

