import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">

            <div  id="language" >
        
        <a href=""><NavLink to="/Disaster-Relief">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ಝಾ ಫೌಂಡೇಶನ್‌ನಿಂದ ವಿಪತ್ತು ಪರಿಹಾರ ಪ್ರಯತ್ನಗಳು</h1>

                <div className='second-mcontent'>
                <img src='../../img/disasterrelief.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>ಝಾ ಫೌಂಡೇಶನ್‌ಗೆ ಸುಸ್ವಾಗತ, ಅಲ್ಲಿ ನಾವು ಭೂಕಂಪಗಳು, ಚಂಡಮಾರುತಗಳು, ಪ್ರವಾಹಗಳು ಮತ್ತು ಹೆಚ್ಚಿನವುಗಳಂತಹ ವಿಪತ್ತುಗಳಿಂದ ಹಾನಿಗೊಳಗಾದವರಿಗೆ ಬೆಂಬಲದ ದಾರಿದೀಪವಾಗಿ ನಿಲ್ಲುತ್ತೇವೆ. ತ್ವರಿತ ಮತ್ತು ಪರಿಣಾಮಕಾರಿ ವಿಪತ್ತು ಪರಿಹಾರವನ್ನು ಒದಗಿಸುವ ನಮ್ಮ ಬದ್ಧತೆಯು ಬಿಕ್ಕಟ್ಟಿನ ನಂತರ ಯಾರೂ ಮಾತ್ರ ಎದುರಿಸಬಾರದು ಎಂಬ ನಂಬಿಕೆಯಲ್ಲಿ ಬೇರೂರಿದೆ.
                    </p>
                </div>

                <h3>ನಮ್ಮ ಮಿಷನ್</h3>
                <div className="missi">
                    <h4 className="toleftneed">ತ್ವರಿತ ಪ್ರತಿಕ್ರಿಯೆ, ಶಾಶ್ವತ ಪರಿಣಾಮ:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್‌ನಲ್ಲಿ, ವಿಪತ್ತು ಸಂದರ್ಭಗಳ ತುರ್ತು ಮತ್ತು ಗುರುತ್ವಾಕರ್ಷಣೆಯನ್ನು ನಾವು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇವೆ. ನೈಸರ್ಗಿಕ ವಿಕೋಪಗಳಿಂದ ಪೀಡಿತ ಸಮುದಾಯಗಳಿಗೆ ತ್ವರಿತ ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಬೆಂಬಲವನ್ನು ಒದಗಿಸುವುದು ನಮ್ಮ ಉದ್ದೇಶವಾಗಿದೆ, ದೀರ್ಘಾವಧಿಯ ಚೇತರಿಕೆ ಮತ್ತು ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವದ ಕಡೆಗೆ ಕೆಲಸ ಮಾಡುವಾಗ ತಕ್ಷಣದ ಪರಿಹಾರವನ್ನು ನೀಡುತ್ತದೆ.</p>


                </div>


                <h3>ನಮ್ಮ ವಿಧಾನ</h3>
                <div className="missi">
                    <h4 className="toleftneed">ಸಮಗ್ರ ವಿಪತ್ತು ಪರಿಹಾರ:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್ ವಿಪತ್ತು ಪರಿಹಾರಕ್ಕೆ ಸಮಗ್ರ ವಿಧಾನವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ನಾವು ತುರ್ತು ಆಶ್ರಯ, ಆಹಾರ ಮತ್ತು ವೈದ್ಯಕೀಯ ಸಹಾಯವನ್ನು ಒದಗಿಸುವ ಆರಂಭಿಕ ಪ್ರತಿಕ್ರಿಯೆ ಹಂತದಿಂದ ಚೇತರಿಕೆಯ ಹಂತದವರೆಗೆ, ನಾವು ಸಮುದಾಯಗಳನ್ನು ಪುನರ್ನಿರ್ಮಾಣ ಮಾಡುವ ಮತ್ತು ಜೀವನೋಪಾಯವನ್ನು ಮರುಸ್ಥಾಪಿಸುವತ್ತ ಗಮನಹರಿಸುತ್ತೇವೆ, ಪೀಡಿತ ವ್ಯಕ್ತಿಗಳು ಪ್ರತಿ ಹಂತದಲ್ಲೂ ಅವರಿಗೆ ಅಗತ್ಯವಿರುವ ಬೆಂಬಲವನ್ನು ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಮ್ಮ ತಂಡಗಳು ಸಮರ್ಪಿತವಾಗಿವೆ. .</p>

                    <h4 className="toleftneed">ಸಮುದಾಯ-ಕೇಂದ್ರಿತ ಪರಿಹಾರಗಳು:</h4>
                    <p>ನಾವು ಸಮುದಾಯಗಳ ಬಲವನ್ನು ನಂಬುತ್ತೇವೆ. ನಮ್ಮ ವಿಪತ್ತು ಪರಿಹಾರ ಪ್ರಯತ್ನಗಳನ್ನು ಸ್ಥಳೀಯ ನಿವಾಸಿಗಳ ಸಹಯೋಗದಲ್ಲಿ ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ಅವರ ಅನನ್ಯ ಅಗತ್ಯತೆಗಳು, ಸಾಂಸ್ಕೃತಿಕ ಸಂದರ್ಭ ಮತ್ತು ಭೂಪ್ರದೇಶದ ಜ್ಞಾನವನ್ನು ಗೌರವಿಸುತ್ತದೆ. ಸಮುದಾಯಗಳೊಂದಿಗೆ ನಿಕಟವಾಗಿ ಕೆಲಸ ಮಾಡುವ ಮೂಲಕ, ತಮ್ಮ ಜೀವನವನ್ನು ಪುನರ್ನಿರ್ಮಿಸಲು ವ್ಯಕ್ತಿಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುವ ಸಮರ್ಥನೀಯ ಪರಿಹಾರಗಳನ್ನು ರಚಿಸುವ ಗುರಿಯನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ.</p>
                </div>

                <h3>ನಮ್ಮ ಉಪಕ್ರಮಗಳು</h3>
                <div className="missi">
                    <h4 className="toleftneed">ತುರ್ತು ಆಶ್ರಯ ಮತ್ತು ನೆರವು:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್ ವಿಪತ್ತುಗಳಿಂದ ಪೀಡಿತರಿಗೆ ತುರ್ತು ಆಶ್ರಯ, ಆಹಾರ, ಶುದ್ಧ ನೀರು ಮತ್ತು ವೈದ್ಯಕೀಯ ಸಹಾಯವನ್ನು ಒದಗಿಸಲು ತ್ವರಿತವಾಗಿ ಸಜ್ಜುಗೊಳಿಸುತ್ತದೆ. ಬಿಕ್ಕಟ್ಟಿನ ತಕ್ಷಣವೇ ಮೂಲಭೂತ ಅಗತ್ಯಗಳನ್ನು ಪೂರೈಸಲಾಗಿದೆಯೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಮ್ಮ ತಂಡಗಳು ಗಡಿಯಾರದ ಸುತ್ತ ಕೆಲಸ ಮಾಡುತ್ತವೆ.</p>

                    <h4 className="toleftneed">ಪುನರ್ನಿರ್ಮಾಣ ಜೀವನ:</h4>
                    <p>ಆರಂಭಿಕ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಮೀರಿ, ನಾವು ಜೀವನವನ್ನು ಪುನರ್ನಿರ್ಮಾಣ ಮಾಡುವತ್ತ ಗಮನಹರಿಸುತ್ತೇವೆ. ಇದು ಪುನರ್ನಿರ್ಮಾಣ ಪ್ರಯತ್ನಗಳು, ಜೀವನೋಪಾಯದ ಪುನಃಸ್ಥಾಪನೆಗಾಗಿ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಒದಗಿಸುವುದು ಮತ್ತು ಸಮುದಾಯಗಳು ಸಹಜತೆಯ ಪ್ರಜ್ಞೆಯನ್ನು ಮರಳಿ ಪಡೆಯಲು ಸಹಾಯ ಮಾಡಲು ಶಿಕ್ಷಣ ಮತ್ತು ಆರೋಗ್ಯ ಸೇವೆಗಳನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ.</p>

                    <h4 className="toleftneed">ಸಮುದಾಯ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ ಕಾರ್ಯಕ್ರಮಗಳು:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್ ಸಮುದಾಯದ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವವನ್ನು ಹೆಚ್ಚಿಸುವ ಕಾರ್ಯಕ್ರಮಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡುತ್ತದೆ. ಇದು ವಿಪತ್ತು ಸನ್ನದ್ಧತೆ, ಮುಂಚಿನ ಎಚ್ಚರಿಕೆ ವ್ಯವಸ್ಥೆಗಳು ಮತ್ತು ಭವಿಷ್ಯದ ವಿಪತ್ತುಗಳ ಪರಿಣಾಮವನ್ನು ತಗ್ಗಿಸಲು ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳಲ್ಲಿ ಸ್ಥಳೀಯ ನಿವಾಸಿಗಳಿಗೆ ತರಬೇತಿ ನೀಡುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.</p>
                </div>


            </section>
        </div>


    );
}

