import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">


      <div  id="language" >
        
        <a href=""><NavLink to="/Rural-Primary-Health-Care">English</NavLink></a>

        </div>

        {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <h1 id="mhead">ഴ ഗ്രാമീണ പ്രാഥമിക ആരോഗ്യ സംരക്ഷണം</h1>

        <div className='second-mcontent'>
        <img src='../../img/healthcare.webp'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>Zha ഫൗണ്ടേഷനിൽ, കമ്മ്യൂണിറ്റികളെ പരിവർത്തനം ചെയ്യുന്നതിനുള്ള ആക്സസ് ചെയ്യാവുന്ന ആരോഗ്യ സംരക്ഷണത്തിന്റെ ശക്തിയിൽ ഞങ്ങൾ വിശ്വസിക്കുന്നു. ആരോഗ്യ പരിപാലന സേവനങ്ങളിലെ വിടവ് നികത്തുക എന്നതാണ് ഞങ്ങളുടെ ദൗത്യം, പ്രത്യേകിച്ച് ഗ്രാമപ്രദേശങ്ങളിൽ, ഓരോ വ്യക്തിക്കും ഗുണനിലവാരമുള്ള പ്രാഥമിക ആരോഗ്യ സംരക്ഷണം ലഭ്യമാണെന്ന് ഉറപ്പാക്കുക.

          </p>
        </div>

        <h3>ഞങ്ങളുടെ പ്രതിബദ്ധത</h3>
        <div className="missi">
          <h4 className="toleftneed">ഗ്രാമീണ ശാക്തീകരണം:</h4>
          <p>മെച്ചപ്പെട്ട ആരോഗ്യ ഫലങ്ങൾക്ക് ആവശ്യമായ ഉപകരണങ്ങളും വിഭവങ്ങളും ഉപയോഗിച്ച് ഗ്രാമീണ സമൂഹങ്ങളെ ശാക്തീകരിക്കാനുള്ള പ്രതിബദ്ധതയാണ് ഴ ഫൗണ്ടേഷന്റെ സംരംഭങ്ങളുടെ കാതൽ. വിദൂര പ്രദേശങ്ങളിലെ വ്യക്തികൾ അഭിമുഖീകരിക്കുന്ന അതുല്യമായ വെല്ലുവിളികൾ ഞങ്ങൾ തിരിച്ചറിയുകയും സുസ്ഥിരമായ ആരോഗ്യ സംരക്ഷണ പരിഹാരങ്ങൾ നൽകുന്നതിന് പ്രതിജ്ഞാബദ്ധരാണ്.</p>

          <h4 className="toleftneed">സമഗ്ര ആരോഗ്യ സംരക്ഷണം:</h4>
          <p>ഞങ്ങളുടെ പ്രാഥമിക ആരോഗ്യ പരിരക്ഷാ സേവനങ്ങൾ വിപുലമായ മെഡിക്കൽ, പ്രതിരോധ, ആരോഗ്യ പരിപാടികൾ ഉൾക്കൊള്ളുന്നു. പതിവ് പരിശോധനകൾ മുതൽ ആരോഗ്യ വിദ്യാഭ്യാസം വരെ, ഗ്രാമീണ ജനതയുടെ വൈവിധ്യമാർന്ന ആവശ്യങ്ങൾ അഭിസംബോധന ചെയ്യുന്ന ഒരു സമഗ്ര ആരോഗ്യ സംരക്ഷണ ആവാസവ്യവസ്ഥ സൃഷ്ടിക്കാൻ ഞങ്ങൾ ലക്ഷ്യമിടുന്നു.</p>
        </div>


        <h3>ഞങ്ങളുടെ സമീപനം</h3>
        <div className="missi">
          <h4 className="toleftneed">കമ്മ്യൂണിറ്റി കേന്ദ്രീകൃത പരിചരണം:</h4>
          <p>ഴ ഫൗണ്ടേഷൻ കമ്മ്യൂണിറ്റി കേന്ദ്രീകൃത ആരോഗ്യ സംരക്ഷണത്തിന്റെ ശക്തിയിൽ വിശ്വസിക്കുന്നു. ഞങ്ങളുടെ പ്രോഗ്രാമുകൾ പ്രാദേശിക കമ്മ്യൂണിറ്റികളുമായി സഹകരിച്ച്, അവരുടെ പ്രത്യേക ആവശ്യങ്ങൾ, സാംസ്കാരിക സൂക്ഷ്മതകൾ, ലഭ്യമായ വിഭവങ്ങൾ എന്നിവ കണക്കിലെടുത്താണ് രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്. താമസക്കാരുമായി കൈകോർത്ത് പ്രവർത്തിക്കുന്നതിലൂടെ, ഉൾക്കൊള്ളുന്നതും ഫലപ്രദവുമായ ഒരു ആരോഗ്യ സംരക്ഷണ മാതൃക സൃഷ്ടിക്കാൻ ഞങ്ങൾ ശ്രമിക്കുന്നു.</p>

          <h4 className="toleftneed">വൈദഗ്ധ്യമുള്ള ഹെൽത്ത് കെയർ പ്രൊഫഷണലുകൾ:</h4>
          <p>ഒരു നല്ല സ്വാധീനം ചെലുത്തുന്നതിൽ അഭിനിവേശമുള്ള ഞങ്ങളുടെ സമർപ്പിത ആരോഗ്യപരിപാലന പ്രൊഫഷണലുകളുടെ ടീമിൽ ഞങ്ങൾ അഭിമാനിക്കുന്നു. ഞങ്ങളുടെ ഡോക്ടർമാരും നഴ്സുമാരും സപ്പോർട്ട് സ്റ്റാഫും ഉയർന്ന വൈദഗ്ധ്യം മാത്രമല്ല, അവർ സേവിക്കുന്ന കമ്മ്യൂണിറ്റികൾക്കുള്ളിൽ ശാശ്വതമായ ബന്ധങ്ങൾ കെട്ടിപ്പടുക്കാൻ പ്രതിജ്ഞാബദ്ധരാണ്.</p>
        </div>

        <h3>ഞങ്ങളുടെ സേവനങ്ങൾ</h3>
        <div className="missi">
          <h4 className="toleftneed">മൊബൈൽ ക്ലിനിക്കുകൾ:</h4>
          <p>ഴ ഫൗണ്ടേഷൻ ഏറ്റവും വിദൂര കോണുകളിൽ എത്തുന്ന മൊബൈൽ ക്ലിനിക്കുകൾ പ്രവർത്തിപ്പിക്കുന്നു, ആരോഗ്യ സംരക്ഷണം ആവശ്യമുള്ളവരുടെ വാതിൽപ്പടിയിലേക്ക് നേരിട്ട് എത്തിക്കുന്നു. ഈ ക്ലിനിക്കുകൾ അത്യാധുനിക മെഡിക്കൽ സൗകര്യങ്ങളോടെ സജ്ജീകരിച്ചിരിക്കുന്നു, കൂടാതെ പരിചയസമ്പന്നരായ ആരോഗ്യ പരിപാലന വിദഗ്ധർ ജോലി ചെയ്യുന്നു.</p>

          <h4 className="toleftneed">ആരോഗ്യ വിദ്യാഭ്യാസ പരിപാടികൾ:</h4>
          <p>പ്രതിരോധം നല്ല ആരോഗ്യത്തിന്റെ താക്കോലാണ്. വ്യക്തികളെ അവരുടെ ക്ഷേമത്തെക്കുറിച്ച് അറിവുള്ള തീരുമാനങ്ങൾ എടുക്കുന്നതിന് ആവശ്യമായ അറിവ് ശാക്തീകരിക്കുന്നതിന് ഞങ്ങൾ പതിവായി ആരോഗ്യ വിദ്യാഭ്യാസ പരിപാടികൾ നടത്തുന്നു. ശുചിത്വ സമ്പ്രദായങ്ങൾ മുതൽ രോഗ പ്രതിരോധം വരെ, ആരോഗ്യകരമായ ജീവിതത്തിന് ഒരു അടിത്തറ ഉണ്ടാക്കുക എന്നതാണ് ഞങ്ങളുടെ ലക്ഷ്യം.</p>

          <h4 className="toleftneed">അടിയന്തര പ്രതികരണം:</h4>
          <p>ഗ്രാമീണ മേഖലയിലെ മെഡിക്കൽ അത്യാഹിതങ്ങളോട് വേഗത്തിൽ പ്രതികരിക്കാൻ ഴ ഫൗണ്ടേഷൻ തയ്യാറാണ്. ഞങ്ങളുടെ എമർജൻസി റെസ്‌പോൺസ് ടീമുകൾക്ക് നിരവധി സാഹചര്യങ്ങൾ കൈകാര്യം ചെയ്യാൻ പരിശീലനം ലഭിച്ചിട്ടുണ്ട്, സഹായം ഏറ്റവും ആവശ്യമുള്ളപ്പോൾ അത് ഒരിക്കലും അകലെയല്ലെന്ന് ഉറപ്പാക്കുന്നു.</p>
        </div>


      </section>
    </div>


  );
}

