import Carousel from 'react-bootstrap/Carousel';
import './Cycle.css';
import './Carousal.css';
import './Carousalcopy.css';
 
import './Mycaro.css';
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carouselll'>

 

    
    <Carousel>
      
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/1.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/2.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/3.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/4.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/5.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/6.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/7.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/8.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/9.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/CYCLE/10.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
  
      
    
     
    </Carousel>

    </div>
  );
}

export default IndividualIntervalsExample;