// Feedback.js

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Multicaros.css'
 

const Feedback = () => {
    const images = [
        <img key="1" className='recentcaro' src='../../img/NSS/1.webp' alt="First slide" />,
        <img key="2" className='recentcaro' src='../../img/NSS/2.webp' alt="Second slide" />,
        <img key="3" className='recentcaro' src='../../img/NSS/3.webp' alt="Third slide" />,
        <img key="4" className='recentcaro' src='../../img/NSS/4.webp' alt="Fourth slide" />,
        <img key="5" className='recentcaro' src='../../img/NSS/5.webp' alt="Fifth slide" />,
        <img key="6" className='recentcaro' src='../../img/NSS/6.webp' alt="Sixth slide" />,
        <img key="7" className='recentcaro' src='../../img/NSS/7.webp' alt="Seventh slide" />,
        <img key="8" className='recentcaro' src='../../img/NSS/8.webp' alt="Eighth slide" />,
        <img key="9" className='recentcaro' src='../../img/NSS/9.webp' alt="Ninth slide" />,
        <img key="10" className='recentcaro' src='../../img/NSS/10.webp' alt="Tenth slide" />,
    ];

    return (
        <div className="marquee-container">
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={{
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 3,
                        slidesToSlide: 3,
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 2,
                        slidesToSlide: 2,
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        slidesToSlide: 1,
                    },
                }}
                ssr={true}
                infinite={true}
                keyBoardControl={false}
                containerClass="marquee-carousel-container"
            >
                {images}
            </Carousel>
        </div>
    );
};

export default Feedback;
