import react from "react";
import React, { useState } from 'react';
import Video from './Videocarousel';
import Media from './Media';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";
import Testimonal from './Testimonal';
import Recue from './Recue';
import Card from 'react-bootstrap/Card';
import './Feedback.css';
import Recent from './Recent';
import Feedback from './Feedback';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Awards from './Feedback copy';
import Button from 'react-bootstrap/Button';



export default function Home() {
  return (
    <div>
      <div className="hmain">


        <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* 

<div  id="language" >
        
        <a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a>
        <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a>
        <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a>
        
        "start": "react-scripts start",

        </div> */}
        <div className="main-content">
          <h1>
         Zha Sustainability Practitioners Club
            {/* Zha Foundation Charitable Trust */}
          </h1>
          <p>"Professionals with Sustainability Mindset"</p>

          {/* <p> Our Zha Foundation is a Non Profit Organization which was founded in 2017, to help the Indian farmers through our Innovative Tech Consulting for free, that helps farmers have value added benefits in scaling their practices which promotes sustainable life of farmers, enables the youth interest in agriculture activities, thus creates food sustainability in the world. </p> */}
        </div>
        <div className="img-align">
          <img src="../../img/hero.webp"></img>
         
        </div>
        <div className="img-align">
        <NavLink to="/hh"><Button variant="success">Zha Sustainability Mindset Self Assessment</Button></NavLink>
        </div>


        <div className="img-align">
      
        <NavLink to="/School-Assesment-Test"><Button variant="success">Assessment for School Management on Sustainability Practices</Button></NavLink>
        
        </div>
      
      </div>
      {/* <div className="whoweare">
        <div className="bar"></div>
        <h4>Who Are We?</h4>
    </div> */}

      {/* <div className="unique">
  <p>80G Unique Registration Number of Zha Foundation - AAATZ1755HF20231
</p>
  <p>12A Unique Registration Number of Zha Foundation - AAATZ1755HE20231</p>
</div> */}

      <section className="visionmi">
        {/* <div className="whoweare">
        <div className="bar"></div>
        <h4>Our Vision</h4>
    </div> */}











{/* sustainability content start  */}

 


        <h3>Introduction</h3>
<div className="goalpointsp">

<p>The Zha Foundation Charitable Trust envisions the establishment of the Zha Sustainability Practitioners Club in various regions worldwide. This initiative aims to promote sustainability principles and values globally, fostering a mindset of environmental consciousness and social responsibility.
</p>


<h3>Background to our Vision</h3>
<p>The Zha Foundation Charitable Trust, based in India, has been at the forefront of advocating for sustainability practices across diverse communities. Recognizing the need for a concerted effort to address global sustainability challenges, the foundation has embarked on a mission to establish the Zha Sustainability Practitioners Club in different parts of the world.</p>

<p>The foundation believes that collaboration with national Non-Resident Indian (NRI) communities is essential to drive sustainable development initiatives effectively. To ensure alignment with its sustainability methodology and principles, the foundation requires NRIs to establish a Non-Disclosure Agreement (NDA) with the parent organization, Zha Foundation in India.</p>

<p>The Zha Sustainability Practitioners Club serves as a platform for individuals and organizations passionate about sustainability to connect, collaborate, and exchange ideas. Through various programs, workshops, and initiatives, the club aims to:</p>

<ol className="goalpoints">
    <li><span> Foster a Sustainability Mindset : </span>By promoting awareness and education on sustainability principles, the club encourages individuals to adopt environmentally conscious and socially responsible behaviors.
 </li>
    <li><span>Showcase Sustainable Practices : </span>Through case studies, best practices, and success stories, the club highlights innovative approaches to sustainability across different industries and sectors.
 </li>
    <li><span>Drive Collective Action : </span>By mobilizing resources and expertise, the club empowers members to initiate impactful projects and campaigns that contribute to sustainable development goals.
 </li>
     
    
</ol>



<p>The Zha Sustainability Practitioners Club operates on the core 12 principle’s such as
  
<ol className="goalpoints">
<li><span>Feeling sufficient : </span>It could relate to concepts like lean thinking or sufficiency in resource consumption.

</li>
<li><span>Encouraging and Rewarding : </span>Rewarding and awarding the recognized people while they are alive and ensure everybody valuable persons in the society are recognized at the right moment

</li>
    
    <li><span>Long-Term Thinking : </span> Emphasize decision-making that considers the long-term impacts on the environment, society, and economy, rather than focusing solely on short-term gains.

 </li>
    <li><span>Risk-based decision making : </span> It’s part of a sustainability strategy, especially in terms of assessing and mitigating environmental and social risks.

 </li>
    <li><span> Gender equality : </span>To enable diverse perspectives, harmony and opportunities 
 </li>
     
 <li><span> Sharing and caring collaboration : </span> It emphasizes cooperation and collective action , creates more sustainable practitioners for environmental and social goals.
</li>
   <li><span>Environmental Stewardship : </span> Take responsibility for protecting and preserving the natural environment, including biodiversity, ecosystems, and natural resources.


</li>
   <li><span>Possessing a fail-fast approach helps in setting an appropriate vision : </span>Adopting a fail-fast approach is pivotal. It fosters an appreciation for adaptability, a core habit in sustainability, essential for crafting an appropriate vision.
</li>

<li><span>Community Engagement : </span>Involve stakeholders, including local communities, in decision-making processes to ensure that sustainability initiatives reflect their needs, concerns, and values.

 </li>
     
 <li><span>Social equality : </span> Strive for fairness and justice in addressing social issues such as poverty, inequality, and access to resources, ensuring that the benefits and burdens of sustainability efforts are distributed equitably.


</li>
   <li><span>Continuous Learning and Improvement : </span>Embrace a culture of learning and reflection, continuously seeking opportunities to improve sustainability practices based on feedback, evaluation, and new information.



</li>
   <li><span>Resource Conservation : </span>Prioritize the efficient use of natural resources, minimizing waste and promoting recycling and reuse wherever possible.

</li>

    
</ol>

  
  and values of inclusivity, innovation, and collaboration. It provides a platform for members to not only learn from each other but also to inspire positive change within their communities and beyond</p>


<h3>Moral of our Vision</h3>


<p>As the world grapples with pressing environmental and social challenges, initiatives like the Zha Sustainability Practitioners Club play a vital role in driving positive change. By fostering a global network of sustainability advocates and practitioners, the club embodies the vision of the Zha Foundation Charitable Trust to create a more sustainable and equitable world for future generations.</p>

  

</div>
        
{/* sustainability cintent end  */}


        {/* <div className="purpose">
          <h4>Vision</h4>
          <p>To build a better future for the next generations, the ZHA Foundation is dedicated to the dual purpose of nurturing a sustainable mindset and conserving the natural resources of our planet.

          </p>
        </div>

        <div className="missi">
          <h4>Mission</h4>
          <p>The Zha Foundation, established in 2017, strives to cultivate a global community committed to fostering a sustainable mindset. We aim to transcend the negative impacts of unchecked greed and jealousy, recognizing their potential harm to nations and the planet's delicate balance. Collaborating with professionals and business leaders, our foundation advocates for a sustainable future, steering away from conflicts and promoting unity through three guiding principles for our zha members : no politics-based discussions, no discussions based on religion or caste, and a commitment to refrain from speaking negatively about fellow members.


          </p>

          <p>Sustainable development is imperative for a better tomorrow, balancing present needs without jeopardizing the well-being of future generations. The survival of our societies and shared planet hinges on fostering a sustainable mindset. The Sustainable Development Goals (SDGs), comprising seventeen interconnected objectives, serve as a "shared blueprint for peace and prosperity for people and the planet, now and into the future.
          </p>

         
        </div>
         */}

      </section>


      {/* <h4>Zha Sustainability Enabler Framework</h4>
      <div className="img-align-ce">
        <img src="../../img/homepa.png"></img>
      </div> */}





      <h1 id="About-head">Our Offerings</h1>
      <h3>Contributing towards Sustainability Social Services</h3>


      <div className="listprimary">
        <div className="listprimarylist">
          <a href="">  <NavLink to="/Rural-Primary-Health-Care"><img src="../../img/PRIMARY/1.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"><img src="../../img/PRIMARY/2.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Disaster-Relief"><img src="../../img/PRIMARY/3.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Clean-Blue-Beach"><img src="../../img/PRIMARY/4.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Successful-NRI-Free-Virtual-Nation-Social-Services-Program"> <img src="../../img/PRIMARY/12.webp"></img></NavLink></a>

        </div>

        <div className="listprimarylist">
          <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students"> <img src="../../img/PRIMARY/6.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Pathfinder-Course">  <img src="../../img/PRIMARY/7.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/zha-farmers-transformation"> <img src="../../img/PRIMARY/8.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Recognition-Awards"> <img src="../../img/PRIMARY/14.png"></img></NavLink></a>
 
          
        </div>

      </div>

      <div className="listsecondary">
        <Carousel>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Rural-Primary-Health-Care"> <img src='../../img/PRIMARY/1.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"> <img src='../../img/PRIMARY/2.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Disaster-Relief"> <img src='../../img/PRIMARY/3.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Clean-Blue-Beach">  <img src='../../img/PRIMARY/4.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">  <img src='../../img/PRIMARY/6.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Pathfinder-Course"> <img src='../../img/PRIMARY/7.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/zha-farmers-transformation"> <img src='../../img/PRIMARY/8.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Successful-NRI-Free-Virtual-Nation-Social-Services-Program"><img src="../../img/PRIMARY/12.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
 
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Recognition-Awards"><img src="../../img/PRIMARY/14.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>


        

      </div>


      

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recognition & Awards</h4>
      </div>

      <div className="feedback">
        <Awards />
      </div>


      <div className="whoweare">
        <div className="bar"></div>
        <h4>Media</h4>
      </div>

      <div className="feedback">
        <Media />
      </div>





      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recent Events</h4>
      </div>
      <div className="feedback">
        <Recent />
      </div>

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Feedback</h4>
      </div>

      <div className="feedback">
        <Feedback />
      </div>



      <Video />


      {/* 
<section>
<div className="whoweare">
        <div className="bar"></div>
        <h4>Testimonial</h4>
    </div>
</section>

 
<div className="videoplayerr">
        <div className="ovall-container">
      <video className="ovall-video"  loop  controls>
     
        <source src={'../img/testimonial.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div> */}




    </div>

  );
}



