import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">


      <div  id="language" >
        
        <a href=""><NavLink to="/Rural-Primary-Health-Care">English</NavLink></a>

        </div>

        {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <h1 id="mhead">ಝಾ ಗ್ರಾಮೀಣ ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ</h1>

        <div className='second-mcontent'>
        <img src='../../img/healthcare.webp'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>ಝಾ ಫೌಂಡೇಶನ್‌ನಲ್ಲಿ, ಸಮುದಾಯಗಳನ್ನು ಪರಿವರ್ತಿಸಲು ಪ್ರವೇಶಿಸಬಹುದಾದ ಆರೋಗ್ಯ ರಕ್ಷಣೆಯ ಶಕ್ತಿಯನ್ನು ನಾವು ನಂಬುತ್ತೇವೆ. ನಮ್ಮ ಧ್ಯೇಯವೆಂದರೆ ಆರೋಗ್ಯ ಸೇವೆಗಳಲ್ಲಿನ ಅಂತರವನ್ನು, ನಿರ್ದಿಷ್ಟವಾಗಿ ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಲ್ಲಿ, ಪ್ರತಿಯೊಬ್ಬ ವ್ಯಕ್ತಿಗೂ ಗುಣಮಟ್ಟದ ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಸೇವೆಗೆ ಪ್ರವೇಶವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು.

          </p>
        </div>

        <h3>ನಮ್ಮ ಬದ್ಧತೆ</h3>
        <div className="missi">
          <h4 className="toleftneed">ಗ್ರಾಮೀಣ ಸಬಲೀಕರಣ:</h4>
          <p>ಝಾ ಫೌಂಡೇಶನ್‌ನ ಉಪಕ್ರಮಗಳ ಹೃದಯಭಾಗದಲ್ಲಿ ಗ್ರಾಮೀಣ ಸಮುದಾಯಗಳನ್ನು ಉತ್ತಮ ಆರೋಗ್ಯ ಫಲಿತಾಂಶಗಳಿಗಾಗಿ ಅಗತ್ಯವಿರುವ ಉಪಕರಣಗಳು ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳೊಂದಿಗೆ ಸಬಲೀಕರಣಗೊಳಿಸುವ ಬದ್ಧತೆ ಇದೆ. ದೂರದ ಪ್ರದೇಶಗಳಲ್ಲಿ ವ್ಯಕ್ತಿಗಳು ಎದುರಿಸುತ್ತಿರುವ ಅನನ್ಯ ಸವಾಲುಗಳನ್ನು ನಾವು ಗುರುತಿಸುತ್ತೇವೆ ಮತ್ತು ಸುಸ್ಥಿರ ಆರೋಗ್ಯ ಪರಿಹಾರಗಳನ್ನು ಒದಗಿಸಲು ಸಮರ್ಪಿತರಾಗಿದ್ದೇವೆ.</p>

          <h4 className="toleftneed">ಸಮಗ್ರ ಆರೋಗ್ಯ:</h4>
          <p>ನಮ್ಮ ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಸೇವೆಗಳು ವ್ಯಾಪಕವಾದ ವೈದ್ಯಕೀಯ, ತಡೆಗಟ್ಟುವಿಕೆ ಮತ್ತು ಕ್ಷೇಮ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಒಳಗೊಳ್ಳುತ್ತವೆ. ದಿನನಿತ್ಯದ ತಪಾಸಣೆಯಿಂದ ಆರೋಗ್ಯ ಶಿಕ್ಷಣದವರೆಗೆ, ಗ್ರಾಮೀಣ ಜನಸಂಖ್ಯೆಯ ವೈವಿಧ್ಯಮಯ ಅಗತ್ಯಗಳನ್ನು ಪರಿಹರಿಸುವ ಸಮಗ್ರ ಆರೋಗ್ಯ ಪರಿಸರ ವ್ಯವಸ್ಥೆಯನ್ನು ರಚಿಸುವ ಗುರಿಯನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ.</p>
        </div>


        <h3>ನಮ್ಮ ವಿಧಾನ</h3>
        <div className="missi">
          <h4 className="toleftneed">ಸಮುದಾಯ ಕೇಂದ್ರಿತ ಆರೈಕೆ:</h4>
          <p>ಝಾ ಫೌಂಡೇಶನ್ ಸಮುದಾಯ ಕೇಂದ್ರಿತ ಆರೋಗ್ಯ ರಕ್ಷಣೆಯ ಬಲವನ್ನು ನಂಬುತ್ತದೆ. ನಮ್ಮ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳ ಸಹಯೋಗದೊಂದಿಗೆ ಅವರ ನಿರ್ದಿಷ್ಟ ಅಗತ್ಯತೆಗಳು, ಸಾಂಸ್ಕೃತಿಕ ಸೂಕ್ಷ್ಮ ವ್ಯತ್ಯಾಸಗಳು ಮತ್ತು ಲಭ್ಯವಿರುವ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಗಣನೆಗೆ ತೆಗೆದುಕೊಂಡು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ. ನಿವಾಸಿಗಳೊಂದಿಗೆ ಕೈಜೋಡಿಸಿ ಕೆಲಸ ಮಾಡುವ ಮೂಲಕ, ಒಳಗೊಳ್ಳುವ ಮತ್ತು ಪರಿಣಾಮಕಾರಿಯಾದ ಆರೋಗ್ಯ ರಕ್ಷಣೆಯ ಮಾದರಿಯನ್ನು ರಚಿಸಲು ನಾವು ಪ್ರಯತ್ನಿಸುತ್ತೇವೆ.</p>

          <h4 className="toleftneed">ನುರಿತ ಆರೋಗ್ಯ ವೃತ್ತಿಪರರು:</h4>
          <p>ಸಕಾರಾತ್ಮಕ ಪರಿಣಾಮ ಬೀರಲು ಉತ್ಸುಕರಾಗಿರುವ ನಮ್ಮ ಸಮರ್ಪಿತ ಆರೋಗ್ಯ ವೃತ್ತಿಪರರ ತಂಡದಲ್ಲಿ ನಾವು ಹೆಮ್ಮೆಪಡುತ್ತೇವೆ. ನಮ್ಮ ವೈದ್ಯರು, ದಾದಿಯರು ಮತ್ತು ಸಹಾಯಕ ಸಿಬ್ಬಂದಿಗಳು ಹೆಚ್ಚು ನುರಿತವರು ಮಾತ್ರವಲ್ಲದೆ ಅವರು ಸೇವೆ ಸಲ್ಲಿಸುವ ಸಮುದಾಯಗಳಲ್ಲಿ ಶಾಶ್ವತ ಸಂಪರ್ಕಗಳನ್ನು ನಿರ್ಮಿಸಲು ಬದ್ಧರಾಗಿದ್ದಾರೆ.</p>
        </div>

        <h3>ನಮ್ಮ ಸೇವೆಗಳು</h3>
        <div className="missi">
          <h4 className="toleftneed">ಮೊಬೈಲ್ ಚಿಕಿತ್ಸಾಲಯಗಳು:</h4>
          <p>ಝಾ ಫೌಂಡೇಶನ್ ಅತ್ಯಂತ ದೂರದ ಮೂಲೆಗಳನ್ನು ತಲುಪುವ ಮೊಬೈಲ್ ಕ್ಲಿನಿಕ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತದೆ, ಅಗತ್ಯವಿರುವವರ ಮನೆ ಬಾಗಿಲಿಗೆ ನೇರವಾಗಿ ಆರೋಗ್ಯವನ್ನು ತರುತ್ತದೆ. ಈ ಚಿಕಿತ್ಸಾಲಯಗಳು ಇತ್ತೀಚಿನ ವೈದ್ಯಕೀಯ ಸೌಲಭ್ಯಗಳೊಂದಿಗೆ ಸುಸಜ್ಜಿತವಾಗಿವೆ ಮತ್ತು ಅನುಭವಿ ಆರೋಗ್ಯ ವೃತ್ತಿಪರರಿಂದ ಸಿಬ್ಬಂದಿಯನ್ನು ಹೊಂದಿವೆ.</p>

          <h4 className="toleftneed">ಆರೋಗ್ಯ ಶಿಕ್ಷಣ ಕಾರ್ಯಕ್ರಮಗಳು:</h4>
          <p>ತಡೆಗಟ್ಟುವಿಕೆ ಉತ್ತಮ ಆರೋಗ್ಯಕ್ಕೆ ಪ್ರಮುಖವಾಗಿದೆ. ಅವರ ಯೋಗಕ್ಷೇಮದ ಬಗ್ಗೆ ತಿಳುವಳಿಕೆಯುಳ್ಳ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಅಗತ್ಯವಿರುವ ಜ್ಞಾನವನ್ನು ಹೊಂದಿರುವ ವ್ಯಕ್ತಿಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡಲು ನಾವು ನಿಯಮಿತ ಆರೋಗ್ಯ ಶಿಕ್ಷಣ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ನಡೆಸುತ್ತೇವೆ. ನೈರ್ಮಲ್ಯದ ಅಭ್ಯಾಸಗಳಿಂದ ರೋಗ ತಡೆಗಟ್ಟುವಿಕೆಯವರೆಗೆ, ಆರೋಗ್ಯಕರ ಜೀವನಕ್ಕಾಗಿ ಅಡಿಪಾಯವನ್ನು ನಿರ್ಮಿಸುವುದು ನಮ್ಮ ಗುರಿಯಾಗಿದೆ.</p>

          <h4 className="toleftneed">ತುರ್ತು ಪ್ರತಿಕ್ರಿಯೆ:</h4>
          <p>ಝಾ ಫೌಂಡೇಶನ್ ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಲ್ಲಿ ವೈದ್ಯಕೀಯ ತುರ್ತುಸ್ಥಿತಿಗಳಿಗೆ ತ್ವರಿತವಾಗಿ ಸ್ಪಂದಿಸಲು ಸಿದ್ಧವಾಗಿದೆ. ನಮ್ಮ ತುರ್ತು ಪ್ರತಿಕ್ರಿಯೆ ತಂಡಗಳು ಹಲವಾರು ಸನ್ನಿವೇಶಗಳನ್ನು ನಿಭಾಯಿಸಲು ತರಬೇತಿ ಪಡೆದಿವೆ, ಸಹಾಯವು ಹೆಚ್ಚು ಅಗತ್ಯವಿರುವಾಗ ಅದು ಎಂದಿಗೂ ದೂರವಿರುವುದಿಲ್ಲ ಎಂದು ಖಚಿತಪಡಿಸುತ್ತದೆ.</p>
        </div>


      </section>
    </div>


  );
}

