import Carousel from 'react-bootstrap/Carousel';
// import './Cycle.css';
// import './Carousal.css';
// import './Carousalcopy.css';
 
// import './Mycaro.css';
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carouselllllll'>

 

    
    <Carousel>
      
      <Carousel.Item interval={2000}>
        <img src='../../img/recue/1.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/2.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/3.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/4.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/5.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/6.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/7.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/recue/8.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/recue/9.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img src='../../img/award.png' text="Second slide" />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
     
    </Carousel>

    </div>

 
  );
}

export default IndividualIntervalsExample;