import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        <a href=""><NavLink to="/Zha-Global-Community-of-Practice">English</NavLink></a>
        </div>
 

            <div className="social-ma">

<h1>ಝಾ ಗ್ಲೋಬಲ್ ಕಮ್ಯುನಿಟಿ ಆಫ್ ಪ್ರಾಕ್ಟೀಸ್</h1>
</div>


<div className="internship">
<img src="../img/globalcommunity.webp"></img>
        </div>
<div className="goalpointsp">

<p>
ಜಾಗತಿಕ ಸುಸ್ಥಿರತೆ ಸಮುದಾಯವನ್ನು ರಚಿಸುವುದು (CoP) ಜ್ಞಾನವನ್ನು ಹಂಚಿಕೊಳ್ಳಲು, ಸಹಯೋಗಿಸಲು ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಅಭ್ಯಾಸಗಳನ್ನು ಮುನ್ನಡೆಸಲು ತಜ್ಞರು ಮತ್ತು ಅಭ್ಯಾಸಕಾರರನ್ನು ಒಟ್ಟುಗೂಡಿಸುವುದು ಒಳಗೊಂಡಿರುತ್ತದೆ. ನಿಮ್ಮ CP ಗಾಗಿ ಸೂಚಿಸಲಾದ ಔಟ್‌ಲೈನ್ ಇಲ್ಲಿದೆ:

</p>


<ol className="goalpoints">
    <li><span>ಉದ್ದೇಶಗಳು : </span> </li>
    <ul>
        <li>ಉದ್ದೇಶವನ್ನು ವಿವರಿಸಿ: ಸುಸ್ಥಿರತೆಯಲ್ಲಿ ವಿಚಾರಗಳು, ಉತ್ತಮ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ನಾವೀನ್ಯತೆಗಳ ವಿನಿಮಯವನ್ನು ಸುಲಭಗೊಳಿಸಿ.
 </li>
        <li>ಜಾಗತಿಕ ಸುಸ್ಥಿರತೆ ಸವಾಲುಗಳ ಮೇಲೆ ಸಹಯೋಗವನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ.
 </li>
 <li>ಕಲಿಕೆ ಮತ್ತು ಅಭಿವೃದ್ಧಿಗಾಗಿ ಬೆಂಬಲ ಸಮುದಾಯವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ.
</li>
    </ul>

    <li><span>ಸದಸ್ಯತ್ವ : </span> </li>
    <ul>
        <li>ಸಮರ್ಥನೀಯತೆಯ ಬಗ್ಗೆ ಆಸಕ್ತಿ ಹೊಂದಿರುವ ವೃತ್ತಿಪರರು, ತಜ್ಞರು ಮತ್ತು ಸಂಸ್ಥೆಗಳನ್ನು ಆಹ್ವಾನಿಸಿ.
 </li>
        <li>ಕೈಗಾರಿಕೆಗಳು, ಭೌಗೋಳಿಕತೆಗಳು ಮತ್ತು ಪರಿಣತಿ ಕ್ಷೇತ್ರಗಳಾದ್ಯಂತ ವೈವಿಧ್ಯಮಯ ಪ್ರಾತಿನಿಧ್ಯ.
 </li>
    </ul>

    <li><span>ಸಂವಹನ :</span> </li>
    <ul>
        <li>ಚರ್ಚೆಗಳು, ಸಂಪನ್ಮೂಲ ಹಂಚಿಕೆ ಮತ್ತು ಸಹಯೋಗಕ್ಕಾಗಿ ಮೀಸಲಾದ ಆನ್‌ಲೈನ್ ವೇದಿಕೆಯನ್ನು ಸ್ಥಾಪಿಸಿ.
 </li>
        <li>ನೈಜ-ಸಮಯದ ಸಂವಹನವನ್ನು ಸುಲಭಗೊಳಿಸಲು ನಿಯಮಿತ ವರ್ಚುವಲ್ ಸಭೆಗಳು, ವೆಬ್‌ನಾರ್‌ಗಳು ಅಥವಾ ಕಾರ್ಯಾಗಾರಗಳು.
 </li>
    </ul>


    <li><span>ವಿಷಯ ಮತ್ತು ಜ್ಞಾನ ಹಂಚಿಕೆ : </span> </li>
    <ul>
        <li>ಕೇಸ್ ಸ್ಟಡೀಸ್, ಯಶಸ್ಸಿನ ಕಥೆಗಳು ಮತ್ತು ಕಲಿತ ಪಾಠಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ.
 </li>
        <li>ಸಂಶೋಧನಾ ಯೋಜನೆಗಳು, ಶ್ವೇತಪತ್ರಗಳು ಅಥವಾ ಪ್ರಕಟಣೆಗಳಲ್ಲಿ ಸಹಕರಿಸಿ.
 </li>
    </ul>

    <li><span>ಕಾರ್ಯನಿರತ ಗುಂಪುಗಳು : </span> </li>
    <ul>
        <li>ನಿರ್ದಿಷ್ಟ ಸಮರ್ಥನೀಯತೆಯ ಸ್ತಂಭಗಳು ಅಥವಾ ಕೈಗಾರಿಕೆಗಳನ್ನು ಉದ್ದೇಶಿಸಿ ಕೇಂದ್ರೀಕೃತ ಉಪಗುಂಪುಗಳನ್ನು ರಚಿಸಿ.
 </li>
        <li>ಯೋಜನೆಗಳು ಅಥವಾ ಉಪಕ್ರಮಗಳಿಗೆ ಸಕ್ರಿಯವಾಗಿ ಕೊಡುಗೆ ನೀಡಲು ಸದಸ್ಯರನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ.
 </li>
    </ul>

    <li><span>ಕಾರ್ಯಕ್ರಮಗಳು :</span> </li>
    <ul>
        <li>ನಾವೀನ್ಯತೆಗಳನ್ನು ಪ್ರದರ್ಶಿಸಲು ಮತ್ತು ಸಾಧನೆಗಳನ್ನು ಆಚರಿಸಲು ವಾರ್ಷಿಕ ಸಮ್ಮೇಳನಗಳು ಅಥವಾ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಆಯೋಜಿಸಿ.
 </li>
        <li>ಸಂಬಂಧಿತ ಸುಸ್ಥಿರತೆಯ ವಿಷಯಗಳ ಕುರಿತು ಪ್ರಮುಖ ಭಾಷಣಕಾರರು ಮತ್ತು ಪ್ಯಾನಲ್ ಚರ್ಚೆಗಳನ್ನು ವೈಶಿಷ್ಟ್ಯಗೊಳಿಸಿ.
 </li>
    </ul>

    <li><span>ಪಾಲುದಾರಿಕೆಗಳು : </span> </li>
    <ul>
        <li>ಪ್ರಭಾವವನ್ನು ವರ್ಧಿಸಲು ಶೈಕ್ಷಣಿಕ ಸಂಸ್ಥೆಗಳು, ಎನ್‌ಜಿಒಗಳು ಮತ್ತು ವ್ಯವಹಾರಗಳೊಂದಿಗೆ ಸಹಕರಿಸಿ.
 </li>
        <li>ಜಂಟಿ ಉಪಕ್ರಮಗಳು ಅಥವಾ ಸಂಶೋಧನಾ ಯೋಜನೆಗಳಿಗಾಗಿ ಪಾಲುದಾರಿಕೆಗಳನ್ನು ಸ್ಥಾಪಿಸಿ.
 </li>
    </ul>

    <li><span>ಮೆಟ್ರಿಕ್ಸ್ ಮತ್ತು ವರದಿ : </span> </li>
    <ul>
        <li>ಸುಸ್ಥಿರತೆಯನ್ನು ಉತ್ತೇಜಿಸುವಲ್ಲಿ CP ಯ ಪ್ರಭಾವವನ್ನು ಅಳೆಯಲು ಮೆಟ್ರಿಕ್‌ಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಿ.
 </li>
        <li>ಸದಸ್ಯರು ಮತ್ತು ಮಧ್ಯಸ್ಥಗಾರರಿಗೆ ನಿಯಮಿತವಾಗಿ ಪ್ರಗತಿ ಮತ್ತು ಸಾಧನೆಗಳನ್ನು ವರದಿ ಮಾಡಿ.
 </li>
    </ul>

    <li><span>ತರಬೇತಿ ಮತ್ತು ಅಭಿವೃದ್ಧಿ :</span> </li>
    <ul>
        <li>ಉದಯೋನ್ಮುಖ ಸುಸ್ಥಿರತೆಯ ಪ್ರವೃತ್ತಿಗಳು ಮತ್ತು ಪರಿಕರಗಳ ಕುರಿತು ತರಬೇತಿ ಅವಧಿಗಳು ಅಥವಾ ಕಾರ್ಯಾಗಾರಗಳನ್ನು ನೀಡಿ.
 </li>
        <li>ಸದಸ್ಯರಲ್ಲಿ ಜ್ಞಾನ ವರ್ಗಾವಣೆಗಾಗಿ ಮಾರ್ಗದರ್ಶನ ಕಾರ್ಯಕ್ರಮಗಳು.
 </li>
    </ul>

    <li><span>ಪ್ರತಿಕ್ರಿಯೆ ಕಾರ್ಯವಿಧಾನ : </span> </li>
    <ul>
        <li>ಸದಸ್ಯರ ಇನ್‌ಪುಟ್ ಆಧರಿಸಿ CoP ಅನ್ನು ನಿರಂತರವಾಗಿ ಸುಧಾರಿಸಲು ಪ್ರತಿಕ್ರಿಯೆ ಲೂಪ್ ಅನ್ನು ಸ್ಥಾಪಿಸಿ.
 </li>
        <li>ಚಟುವಟಿಕೆಗಳ ಪ್ರಸ್ತುತತೆ ಮತ್ತು ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ನಿಯಮಿತವಾಗಿ ಮೌಲ್ಯಮಾಪನ ಮಾಡಿ.
 </li>
    </ul>

    
  </ol>
  <p>ಜಾಗತಿಕ ಸುಸ್ಥಿರತೆಯ ಸಮುದಾಯದ ಅಭ್ಯಾಸದ ಯಶಸ್ಸಿಗೆ ಸದಸ್ಯರ ನಡುವೆ ಒಳಗೊಳ್ಳುವಿಕೆ, ಸಕ್ರಿಯ ಭಾಗವಹಿಸುವಿಕೆ ಮತ್ತು ಹಂಚಿಕೆಯ ಜವಾಬ್ದಾರಿಯ ಪ್ರಜ್ಞೆಯನ್ನು ಉತ್ತೇಜಿಸಲು ಮರೆಯದಿರಿ.</p>
</div>
        
     

        </div>

    );
}

