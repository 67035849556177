import React from "react";
import "./Event.css"
import Slideshow from './Sileds';
import Carousel from './Carousel';
import './Sileds.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Caro from './Carousel copy';
import Check from './Check/Check';
import Rural from './Rural';
import Cycle from './Cycle';
import Bedsheet from './Bedsheet';
import Nss from './Nss';
 

export default function Event(){
    return(
        <div>
          {/* <div className="Tabs">
<h3 id="upevent">Upcoming Events</h3>
            <Tabs
      
      className="mb-3"
    >
      <Tab eventKey="home" title="English">
        <div className="divimg">
        <img src="../../img/zhaenglish/01.png"></img>
        <img src="../../img/zhaenglish/02.png"></img>
        <img src="../../img/zhaenglish/03.png"></img>
        <img src="../../img/zhaenglish/04.png"></img>
        <img src="../../img/zhaenglish/05.png"></img>
        <img src="../../img/zhaenglish/06.png"></img>
        <img src="../../img/zhaenglish/07.png"></img>
        <img src="../../img/zhaenglish/08.png"></img>
        <img src="../../img/zhaenglish/09.png"></img>
        <img src="../../img/zhaenglish/10.png"></img>
        </div>
      </Tab>
      <Tab eventKey="profile" title="Tamil">
      <div className="divimg">
        <img src="../../img/zhatamil/www.zhafoundation.in-01.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-02.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-03.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-04.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-05.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-06.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-07.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-08.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-09.png"></img>
        <img src="../../img/zhatamil/www.zhafoundation.in-10.png"></img>
        </div>
      </Tab>
      
    </Tabs>
            </div> */}
            <h1 id="Eventhead">Zha Initiatives</h1>
            <h1 className="greenspannn"><span>Organic Farmers League</span></h1>
       
        <div className="grid-img">
        <div class="carousel">
                 <Carousel />
            

                  </div>
            <div className="grid-item">
              
          
            <div className="grid-img">
            <h1 className="greenspannn"><span>Other Events</span></h1>
        <div class="carousel">
      
            <Caro />
            </div></div>
              
              </div>
        </div>
        <h1 className="greenspannn"><span>Rural Free Sports Coaching and Prizes</span></h1>
        <Rural />
        <br></br>


        <h1 className="greenspannn"><span>Zha Foundation Clean Environment Initiative</span></h1>
        <div className="feedback">
        <Nss />
        </div>
        <br></br>


        <h1 className="greenspannn"><span>Rural Free Cycle Coaching </span></h1>
        <Cycle />
        <br></br>

        <h1 className="greenspannn"><span>Old and poor people were given new clothes on the occasion of Diwali</span></h1>
        <Bedsheet />
        <br></br>


        <h1 className="greenspannn"><span>MARATHON-2023</span></h1>
<div className="sponsers">


    <div className="video-sports">
    {/* <video src="https://youtu.be/iFOg5sD7XIg" controls></video> */}
    <iframe width="560" height="315" src="https://www.youtube.com/embed/iFOg5sD7XIg?si=pk-fumVi5czZrEDP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    </div>

    {/* <div className="formarcard">
          
            <div className="content">
                <h1 id="formar-card-head">Thanks to the sponsors of</h1>
                <h1><span className="greenspan">MARATHON-2023</span></h1>
                <div class="sileds">
                  <Slideshow />
                  </div>
               
               


              <div>
      
              </div>
               <h1 id="formar-card-head">Other Sponsors</h1>
               <h2><span className="boldspan">Mr.Richard</span><span className="bracket">(chennai)</span></h2>
               <h4><span className="boldspan">Mr.Pravin</span><span className="bracket">(chennai)</span></h4>
               <h4><span className="boldspan">Mr.Sridhar</span><span className="bracket">(chennai)</span></h4>
               <h4><span className="boldspan">Mr.Sundhar</span><span className="bracket">(chennai)</span></h4>
                
            </div>
            </div> */}


 
            
    
</div>




        </div>
    );
    }