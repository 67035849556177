// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkWHWXlqgFqFmqPH3-9kt1Sk80Hlkg6Qk",
  authDomain: "zha-signup-db.firebaseapp.com",
  databaseURL: "https://zha-signup-db-default-rtdb.firebaseio.com",
  projectId: "zha-signup-db",
  storageBucket: "zha-signup-db.appspot.com",
  messagingSenderId: "620296666586",
  appId: "1:620296666586:web:eaf9d0f632ffcea04454e3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default firebaseConfig;