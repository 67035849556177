import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    हमारा फाउंडेशन एक गैर-लाभकारी संगठन है जिसे कृषि व्यवसाय में मुफ्त में गतिशील परामर्श के माध्यम से भारतीय किसानों की मदद करने, किसानों के लिए मूल्य वर्धित लाभ प्राप्त करने और अनुरोधों के आधार पर उनके खेतों का प्रबंधन करने के लिए शुरू किया गया था। इसलिए हमने देखा कि हमें एक ऐसे उत्पाद का आविष्कार करने की ज़रूरत है जो हमारे उपभोक्ताओं को मूल्य प्रदान करे।



                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     हम सतत और समावेशी विकास में तेजी लाते हैं और खाद्य और कृषि कंपनियों और संगठनों के लिए कार्यकुशलता बढ़ाकर, विकास को बढ़ावा देकर, उद्योगों को आकार देकर और संगठनात्मक प्रभावशीलता बढ़ाकर परिवर्तनकारी प्रभाव डालते हैं। हमारे मालिकाना उपकरण और डिजिटल और उन्नत विश्लेषण क्षमताएं, व्यापक अनुभव के साथ मिलकर हमारे ग्राहकों के लिए लाए गए मूल्य की आधारशिला हैं।


     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     झा फाउंडेशन बहु-विषयक अनुसंधान, क्षमता निर्माण, आत्म सुधार में संलग्न है और युवाओं के दिमाग और हृदय स्वास्थ्य की मदद से जुड़े अभियान चलाता है। एक पूरी पीढ़ी अधिक चिंतित, अधिक नाजुक, अधिक उदास है। वे जोखिम लेने में बहुत कम सहज होते हैं। यह एक पीढ़ी में वास्तविक परिवर्तन है।'' इसलिए, हम कल्याण को मापने के तरीके विकसित कर रहे हैं जिसमें लचीलापन, प्रेम, आशा, सांस्कृतिक पहचान, महत्वपूर्ण चेतना, आत्मनिर्णय और कृषि कार्य सुधार के तरीके शामिल हैं।


     </p>
 </div>
            </div>
         

            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">ऑपरेटिंग मॉडल</h2>
  {/* <h4>Operating Model</h4> */}
  <p>भविष्य की पीढ़ियों के लिए एक स्थायी जीवन बनाने और ग्रह को संरक्षित करने के लिए, हमें झा सस्टेनेबिलिटी प्रोफेशनल्स टॉवर/क्लब के मिशन और दृष्टिकोण के साथ संरेखित करने के लिए वैश्विक हितों का आकलन करने के लिए समान विचारधारा वाले विभिन्न मनुष्यों के साथ बातचीत करने की आवश्यकता होगी।
</p>
<p>टिकाऊ जीवन का अर्थ है यह समझना कि हमारे मनुष्य की जीवनशैली हमारे आसपास की दुनिया को कैसे प्रभावित करती है और हर किसी के लिए बेहतर और हल्का जीवन जीने के तरीके खोजना। ZHA स्थिरता पेशेवर बोर्ड के सदस्यों के हमारे संचालन को सक्रिय, सलाहकार और गवाह बोर्ड सदस्यता द्वारा विभाजित किया गया है। हालाँकि झा बोर्ड के सदस्य निम्नलिखित चार स्तंभों से अपने स्थायित्व के हित का पता लगाने के लिए बाध्य हैं
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) मानव कल्याण स्तंभ</p>
<p>2) प्रकृति कल्याण स्तंभ  </p>
<p>3) पृथ्वी कल्याण स्तंभ</p>
<p>4) लगातार लाभ स्तंभ</p>
</div>
</span>
<p>स्थिरता कल्याण के उपरोक्त चार स्तंभों में से, बोर्ड के प्रत्येक सदस्य भविष्य की पीढ़ियों की पृथ्वी, प्रकृति और मानव कल्याण के लिए एक स्थायी जीवन बनाने के लिए किसी भी सामाजिक पहल की खोज करेंगे</p>
<p>इस प्रकार इस जीवन में टिकाऊ जीवन का परिणाम टिकाऊ विकास के सिद्धांत के माध्यम से निर्धारित होता है जो सामाजिक और आर्थिक सुधार को संबोधित करता है जो पर्यावरण की रक्षा करता है और समानता का समर्थन करता है, और इसलिए अर्थव्यवस्था और समाज और पारिस्थितिक प्रणाली परस्पर निर्भर हैं।</p>
 
 
 


<p>झा फाउंडेशन सस्टेनेबिलिटी प्रोफेशनल्स क्लब के लिए यह महत्वपूर्ण है कि वह इस बारे में पारदर्शी हो कि बोर्ड सदस्यता सदस्यता शुल्क का उपयोग कैसे किया जाता है। हम सदस्यों को त्रैमासिक नियमित वित्तीय रिपोर्ट और अपडेट प्रदान करेंगे, जिससे विश्वास बनाने में मदद मिलेगी और यह सुनिश्चित होगा कि वे अपने योगदान के बदले में मिलने वाले मूल्य को समझें और उसी मंच पर हमारी पहल की प्रगति और उपलब्धियों का जश्न मनाएं</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">परिणाम - मान</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>स्थायी विकास के वांछित परिणामों में आर्थिक समृद्धि, पर्यावरण संरक्षण, सामाजिक समानता और गरीबी में कमी शामिल है। इन परिणामों को प्राप्त करने के लिए सरकारों, व्यवसायों और व्यक्तियों के सामूहिक प्रयास की आवश्यकता है।</li>
  <li>झा फाउंडेशन सभी स्तरों के बच्चों और वयस्कों के लिए सामाजिक जिम्मेदारी को सक्षम करने वाला है।</li>
  <li>ZHA किसी को भी अपनी स्थिरता मानसिकता विकसित करने, व्यावसायिक नेटवर्क बढ़ाने और दुनिया भर में समान विचारधारा वाले पेशेवरों से जुड़ने के लिए मूल्यवान संसाधन प्रदान करता है। चाहे आप अभी शुरुआत कर रहे हों या वर्षों से व्यवसाय में हों, आप इस मंच द्वारा प्रदान किए गए ज्ञान, समर्थन और कनेक्शन से लाभ उठा सकते हैं।</li>
  <li>झा फाउंडेशन सामाजिक सेवा + व्यवसाय + ज्ञान + मित्रता और फैलोशिप पर आधारित है जो टिकाऊ जीवन को जोड़ता है और दुनिया भर में गुणवत्ता वाले लोगों को ढूंढता है
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">झा परिवर्तन विधि</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}