import React, { useState, useEffect } from 'react';
import useAuth from '../Login/useAuth';
import { useLocation } from 'react-router-dom';
import firebase from '../firebaseConfig';

function Result() {
  const location = useLocation();
  const allAnswers = location.state.answers;
  const allQuestions = location.state.questions;
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      sendToFirebase();
    }
  }, [currentUser]); 

  const sendToFirebase = async () => {
    try {
      const data = {
        username: currentUser.email,
        percentile: calculatePercentile(),
      };
  
      const response = await fetch(
        'https://zha-signup-db-default-rtdb.firebaseio.com/UserData.json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            
          },
          body: JSON.stringify(data),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to send data to Firebase: ${response.statusText}`);
      }
  
      console.log('Data sent to Firebase successfully!');
    } catch (error) {
      console.error('Error sending data to Firebase:', error);
    }
  };

  const calculatePercentile = () => {
    let correctAnswers = allAnswers.filter(item => item.trueAnswer).length;
    return Math.round((correctAnswers / allQuestions.length) * 100);
  };

  return (
    <div className="result">
      <div className="quizresult">
        <div className="result-box">
          <div className="result-bg">
            <span className="percentile">{calculatePercentile()}%</span>
          </div>
          <p className="result-detail">
            You answered {calculatePercentile()}% out of {allQuestions.length} questions correctly!
          </p>

          {calculatePercentile() < 50 && (
            <p className="result-message">Improve yourself</p>
          )}
          {calculatePercentile() >= 50 && calculatePercentile() < 70 && (
            <p className="result-message">You are doing okay</p>
          )}
          {calculatePercentile() >= 70 && calculatePercentile() < 100 && (
            <p className="result-message">You are a good human being</p>
          )}
          {calculatePercentile() === 100 && (
            <p className="result-message">You are perfect!</p>
          )}
        </div>

        <h2 className="check-answers-title">Check Correct Answers</h2>
        <div className="check-answers-boxes">
          {allQuestions.map((item, key) => (
            <div
              key={key}
              className={
                allAnswers[key].trueAnswer
                  ? 'check-answer-box correct'
                  : 'check-answer-box wrong'
              }
            >
              <div className="check-answer-top">
                <div className="check-texts">
                  <p className="check-answer-count">Question: {key + 1}</p>
                  <h3 className="check-answer-question">{item.question}</h3>
                </div>
                <div className="check-icon">
                  <i
                    className={
                      allAnswers[key].trueAnswer ? 'bi bi-check' : 'bi bi-x'
                    }
                  ></i>
                </div>
              </div>
              <div className="check-answer-bottom">
                <div className="answer-box">
                  <span className="answer-title">Your Answer</span>
                  <span className="answer-text">{allAnswers[key].answer}</span>
                </div>
                <div className="answer-box">
                  <span className="answer-title">Recommendation</span>
                  <span className="answer-text">
                    {allAnswers[key].trueAnswer ? "Keep up the good work" : item.customMessage}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Result;
