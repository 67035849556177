import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">English</NavLink></a>

        </div>

        

<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
   <img src='../../img/certificate.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>बिल्डरों और रीयलटर्स के लिए पर्यावरण-अनुकूल प्रमाणन प्राप्त करने के लिए, इन सामान्य चरणों का पालन करें:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>अनुसंधान झा पावती कार्यक्रम : </span> निर्माण उद्योग के लिए लागू हमारे प्रतिष्ठित पर्यावरण-अनुकूल प्रमाणन कार्यक्रम, पर्यावरण मूल्यांकन मानकों की स्थापना पर अनुसंधान

</li>

<li><span>आवश्यकताओं को समझें : </span>  चुने हुए प्रमाणीकरण के विशिष्ट मानदंडों और आवश्यकताओं से खुद को परिचित करें। इसमें टिकाऊ निर्माण सामग्री, ऊर्जा दक्षता, जल संरक्षण और इनडोर वायु गुणवत्ता शामिल हो सकती है।
</li>
<li><span>प्रशिक्षण और शिक्षा : </span>  सुनिश्चित करें कि आपकी टीम टिकाऊ निर्माण प्रथाओं पर शिक्षित है। कुछ प्रमाणपत्रों के लिए प्रमुख कर्मियों के लिए कुछ प्रशिक्षण की आवश्यकता हो सकती है।

</li>
<li><span>सतत प्रथाओं को लागू करें : </span> अपनी निर्माण प्रक्रियाओं में पर्यावरण-अनुकूल प्रथाओं को एकीकृत करें। इसमें पुनर्चक्रित सामग्रियों का उपयोग करना, ऊर्जा उपयोग को अनुकूलित करना और हरित भवन तकनीकों को अपनाना शामिल हो सकता है।

</li>
<li><span>दस्तावेज़ीकरण : </span> अपने सतत प्रयासों का संपूर्ण रिकॉर्ड रखें। प्रमाणन के लिए दस्तावेज़ीकरण महत्वपूर्ण है, जो स्थापित मानकों के अनुपालन को प्रदर्शित करता है।

</li>
<li><span>हितधारकों को शामिल करें : </span> पर्यावरण-अनुकूल पहल में ठेकेदारों, आपूर्तिकर्ताओं और श्रमिकों सहित सभी हितधारकों को शामिल करें। सभी को प्रमाणन प्राप्त करने के लक्ष्य के साथ जुड़ना चाहिए।

</li>
<li><span>पूर्व-मूल्यांकन : </span> उन क्षेत्रों की पहचान करने के लिए पूर्व-मूल्यांकन पर विचार करें जिनमें आधिकारिक प्रमाणन प्रक्रिया से पहले सुधार की आवश्यकता हो सकती है। यह कदम किसी भी कमी को दूर करने और सफलता की संभावनाओं को बढ़ाने में मदद कर सकता है।

</li>
<li><span>औपचारिक आवेदन : </span> प्रमाणन निकाय को एक औपचारिक आवेदन जमा करें। आवश्यकतानुसार सभी आवश्यक दस्तावेज़, साक्ष्य और शुल्क शामिल करें।

</li>
<li><span>साइट निरीक्षण : </span> प्रमाणन निकाय के प्रतिनिधियों द्वारा साइट निरीक्षण की तैयारी करें। वे यह आकलन करेंगे कि आपकी निर्माण परियोजना निर्दिष्ट पर्यावरण-अनुकूल मानकों के अनुरूप है या नहीं।

</li>
<li><span>प्रमाणन निर्णय : </span> प्रमाणन निर्णय की प्रतीक्षा करें। सफल होने पर, आपको अपने प्रोजेक्ट की पर्यावरण-अनुकूल स्थिति की औपचारिक मान्यता प्राप्त होगी।

</li>

<li><span>प्रमाणीकरण को बढ़ावा दें : </span> एक बार प्रमाणित होने के बाद, अपनी निर्माण परियोजनाओं को बढ़ावा देने के लिए पर्यावरण-अनुकूल लेबल का उपयोग करें। यह आपकी कंपनी की प्रतिष्ठा बढ़ा सकता है और पर्यावरण के प्रति जागरूक ग्राहकों को आकर्षित कर सकता है।
</li>

<li><span>प्रमोशन में जोड़ सकते हैं : </span> एक बार जब आपको ZHA इको-फ्रेंडली प्रमाणीकरण पर ZHA फाउंडेशन से एक पुष्टिकरण ईमेल प्राप्त हो जाता है, तो बिल्डर इसे अपने प्रचार और विज्ञापनों में प्रकाशित कर सकता है। ZHA फाउंडेशन की वेबसाइट उन बिल्डरों को भी सूचीबद्ध करती है जिन्हें ZHA इको-फ्रेंडली सस्टेनेबिलिटी कंस्ट्रक्टर में प्रमाणित किया गया है।</li>

</ol>



<p>याद रखें, चुने गए प्रमाणन कार्यक्रम के आधार पर विशिष्ट चरण भिन्न हो सकते हैं, इसलिए चयनित प्रमाणन निकाय की आवश्यकताओं की पूरी तरह से समीक्षा करना आवश्यक है। </p>


</section>
 

</div>
 
 
);
}

