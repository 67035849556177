import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">

      <div  id="language" >
        
        <a href=""><NavLink to="/Rural-Primary-Health-Care">English</NavLink></a>

        </div>

        {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <h1 id="mhead">ఝా గ్రామీణ ప్రాథమిక ఆరోగ్య సంరక్షణ</h1>

        <div className='second-mcontent'>
        <img src='../../img/healthcare.webp'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>ఝా ఫౌండేషన్‌లో, కమ్యూనిటీలను మార్చడానికి అందుబాటులో ఉండే ఆరోగ్య సంరక్షణ శక్తిని మేము విశ్వసిస్తున్నాము. ప్రతి వ్యక్తికి నాణ్యమైన ప్రాథమిక ఆరోగ్య సంరక్షణ అందుబాటులో ఉండేలా చూడడం, ముఖ్యంగా గ్రామీణ ప్రాంతాల్లో ఆరోగ్య సంరక్షణ సేవలలో అంతరాన్ని తగ్గించడమే మా లక్ష్యం.
          </p>
        </div>

        <h3>మా నిబద్ధత</h3>
        <div className="missi">
          <h4 className="toleftneed">గ్రామీణ సాధికారత:</h4>
          <p>మెరుగైన ఆరోగ్య ఫలితాల కోసం అవసరమైన సాధనాలు మరియు వనరులతో గ్రామీణ సంఘాలను శక్తివంతం చేయాలనే నిబద్ధత ఝా ఫౌండేషన్ యొక్క కార్యక్రమాల గుండె వద్ద ఉంది. మారుమూల ప్రాంతాల్లోని వ్యక్తులు ఎదుర్కొంటున్న ప్రత్యేక సవాళ్లను మేము గుర్తించాము మరియు స్థిరమైన ఆరోగ్య సంరక్షణ పరిష్కారాలను అందించడానికి అంకితభావంతో ఉన్నాము.</p>

          <h4 className="toleftneed">సమగ్ర ఆరోగ్య సంరక్షణ:</h4>
          <p>మా ప్రాథమిక ఆరోగ్య సంరక్షణ సేవలు విస్తృతమైన వైద్య, నివారణ మరియు ఆరోగ్య కార్యక్రమాలను కలిగి ఉంటాయి. సాధారణ తనిఖీల నుండి ఆరోగ్య విద్య వరకు, మేము గ్రామీణ జనాభా యొక్క విభిన్న అవసరాలను తీర్చే సంపూర్ణ ఆరోగ్య సంరక్షణ పర్యావరణ వ్యవస్థను రూపొందించాలని లక్ష్యంగా పెట్టుకున్నాము.</p>
        </div>


        <h3>మన విధానం</h3>
        <div className="missi">
          <h4 className="toleftneed">కమ్యూనిటీ-సెంట్రిక్ కేర్:</h4>
          <p>ఝా ఫౌండేషన్ కమ్యూనిటీ-సెంట్రిక్ హెల్త్‌కేర్ యొక్క బలాన్ని విశ్వసిస్తుంది. మా కార్యక్రమాలు వారి నిర్దిష్ట అవసరాలు, సాంస్కృతిక సూక్ష్మ నైపుణ్యాలు మరియు అందుబాటులో ఉన్న వనరులను పరిగణనలోకి తీసుకుని స్థానిక సంఘాల సహకారంతో రూపొందించబడ్డాయి. నివాసితులతో చేతులు కలిపి పని చేయడం ద్వారా, కలుపుకొని మరియు ప్రభావవంతమైన ఆరోగ్య సంరక్షణ నమూనాను రూపొందించడానికి మేము కృషి చేస్తాము.</p>

          <h4 className="toleftneed">నైపుణ్యం కలిగిన ఆరోగ్య సంరక్షణ నిపుణులు:</h4>
          <p>సానుకూల ప్రభావం చూపడం పట్ల మక్కువ చూపే అంకితభావంతో కూడిన ఆరోగ్య సంరక్షణ నిపుణుల మా బృందం పట్ల మేము గర్విస్తున్నాము. మా వైద్యులు, నర్సులు మరియు సహాయక సిబ్బంది అత్యంత నైపుణ్యం కలిగిన వారు మాత్రమే కాకుండా వారు సేవ చేసే కమ్యూనిటీలలో శాశ్వత కనెక్షన్‌లను నిర్మించడానికి కట్టుబడి ఉన్నారు.</p>
        </div>

        <h3>మా సేవలు</h3>
        <div className="missi">
          <h4 className="toleftneed">మొబైల్ క్లినిక్‌లు:</h4>
          <p>ఝా ఫౌండేషన్ మొబైల్ క్లినిక్‌లను నిర్వహిస్తుంది, ఇది చాలా మారుమూల ప్రాంతాలకు చేరుకుంటుంది, ఆరోగ్య సంరక్షణను నేరుగా అవసరమైన వారి ఇంటి వద్దకే తీసుకువస్తుంది. ఈ క్లినిక్‌లు అత్యాధునిక వైద్య సదుపాయాలను కలిగి ఉంటాయి మరియు అనుభవజ్ఞులైన ఆరోగ్య సంరక్షణ నిపుణులచే సిబ్బందిని కలిగి ఉంటాయి.</p>

          <h4 className="toleftneed">ఆరోగ్య విద్యా కార్యక్రమాలు:</h4>
          <p>మంచి ఆరోగ్యానికి నివారణ కీలకం. వారి శ్రేయస్సు గురించి సమాచార నిర్ణయాలు తీసుకోవడానికి అవసరమైన జ్ఞానంతో వ్యక్తులను శక్తివంతం చేయడానికి మేము రెగ్యులర్ హెల్త్ ఎడ్యుకేషన్ ప్రోగ్రామ్‌లను నిర్వహిస్తాము. పరిశుభ్రత పద్ధతుల నుండి వ్యాధి నివారణ వరకు, ఆరోగ్యకరమైన జీవనానికి పునాదిని నిర్మించడమే మా లక్ష్యం.</p>

          <h4 className="toleftneed">అత్యవసర స్పందన:</h4>
          <p>గ్రామీణ ప్రాంతాల్లో వైద్యపరమైన అత్యవసర పరిస్థితుల్లో వేగంగా స్పందించేందుకు ఝా ఫౌండేషన్ సిద్ధమైంది. మా ఎమర్జెన్సీ రెస్పాన్స్ టీమ్‌లు అనేక రకాల పరిస్థితులను నిర్వహించడానికి శిక్షణ పొందాయి, సహాయం చాలా అవసరం అయినప్పుడు అది ఎప్పటికీ దూరంగా ఉండదని నిర్ధారిస్తుంది.</p>
        </div>


      </section>
    </div>


  );
}

