import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import './Welcomenri.css';


import { NavLink } from "react-router-dom";
export default function Welcomenri() {

    return (
        <div className="welcomenri">

            <div className="social-ma">

                <h1>Join the sustainability practitioners club: Be Part of the "Sustainability for People" Initiative with the Zha Foundation</h1>
            </div>


            <div className="internship">
                <img src="../img/welcomenri.jpg"></img>
            </div>
            <div className="goalpointsp">

                <p>At the Zha Foundation, we are on a mission to instil a sense of purpose among Non-Resident Indians (NRIs) worldwide and inspire them to contribute to our "Sustainability for People" initiatives.

                </p>
                <p>Through the Zha Sustainability Practitioners Club, we aim to educate and mobilise the younger generation, rural entrepreneurs, suburban women, and all who are passionate about sustainable living. Our virtual knowledge sessions are crafted to share insights and foster innovation in sustainable practices.</p>

                <h3>Why Join Us?</h3>

                <ol className="goalpoints">
                    <li><span>Global Impact : </span>Your knowledge and expertise can be instrumental in empowering communities in India and beyond, contributing to a global sustainability movement that bridges cultures and unites us in our efforts to preserve our environment.	</li>
                    <li><span>Personal Satisfaction : </span>Experience the fulfilment that comes with making a real difference in the world. Take pride in being part of a cause that betters the lives of others and safeguards our planet.</li>
                    <li><span>Networking Opportunities : </span>Connect with like-minded individuals who share your commitment to sustainability. Forge partnerships and friendships that span continents and cultures.
                    </li>


                    <li><span>Professional Growth : </span>Enhance your skills in communication, leadership, and cultural understanding by sharing your knowledge with a respected NGO. These experiences are invaluable for personal and professional development.


                    </li>
                    <li><span>Supporting Research and Development : </span>Align with an organization that is at the forefront of sustainable living practices and research in India. Your involvement furthers our mission to protect the earth and promote sustainable government policies.



                    </li>

                </ol>

                <h3>Our Story</h3>

                <p>Picture a village transformed by sustainable innovation: a community where water scarcity and poor crop yields are a thing of the past, thanks to the collaborative efforts of its inhabitants and guidance from sustainability experts, including those from the Zha Sustainability Practitioners Club. This village now thrives, with abundant water, flourishing organic crops, and the local economy blossomed as the village started to sell its surplus produce to neighboring regions. Children grew up understanding the value of conservation and the importance of living sustainably.</p>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="welcomenriimg">
                            <img src="../img/WELCOMENRI/2.png"></img>
                            <img src="../img/WELCOMENRI/3.png"></img>
                            <img src="../img/WELCOMENRI/1.png"></img>
                            </div>
                        </div>
                    </div>

                </div>

                <p>The story of the village is just one example of what can be achieved. Your participation could catalyze the next success story.The Zha Foundation and its club members, including dedicated NRIs, have been catalysts for this change, sharing their knowledge and fostering a culture of sustainability. By joining us, you can contribute to this transformative process, helping to create a prosperous and sustainable future for communities around the world.
                </p>

                <h3>Get Involved</h3>

                <p><span>About Us :</span> ZHA.ngo is a government-recognized NGO in India, founded by Mr. Karki Ashokkumar, an ex-NRI committed to sustainability since 2017.</p>
                <p><span>Engagement :</span> Please share your email and phone number, and let us know if you'd like to join our WhatsApp group. A Zha Foundation representative will reach out to discuss how you can make an impact.
                </p>
                <div className="Donateus">
                    <a href="https://forms.gle/AHk7CVADNuBwC21T6"><button className="donate-buttonheadert">Register Now</button></a>
                </div>

                <p><span>Open Club Membership : </span>The Sustainability Practitioners Club welcomes everyone. There are no membership fees or obligations.</p>
                <p><span>Knowledge Sharing : </span>Though we typically do not offer payment for leading sessions, If you insist we acknowledge your valuable time with a nominal fee of $5 to $10 per hour. Your sessions will reach our 30K+ YouTube subscribers.
                </p>
                <p><span>Financial Contributions : </span>While not required, your donations are appreciated and help our club grow. We suggest a voluntary contribution of 10,000 INR for NRIs willing to support financially.This money  will be used for various club activities improvement and social help. A 80G receipt will also be given which you can use for tax exemptions.
                </p>
                <div className="Donateus">
                    <NavLink to="/Donate"> <button className="donate-buttonheader">Donate </button></NavLink>
                </div>


                <p><span>FCRA Registration : </span>We are in the process of obtaining FCRA registration to facilitate international donations($)
                </p>
                <p><span>
                    Contact us : </span>For any inquiries, collaborations, or concerns, please don't hesitate to reach out to us via email at admin@zhafoundation.in. We value your interest and are eager to connect with you to explore opportunities for engagement and collaboration. Your communication is important to us, and we look forward to hearing from you soon.</p>

                <p>Join the Zha Sustainability Practitioners Club and be a part of a community that is creating a sustainable future for people everywhere. Your participation could ignite the next success story. Embrace this opportunity to make a difference and grow with us. Together, we can build a world where sustainability is not just a concept, but a way of life.
                </p>
            </div>





        </div>

    );
}

