import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
{/* 
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Zha Sustainability Practitioners Club
</h1>
</div>


<div className="internship">
        <img src="../img/planet.jpg"></img>
        </div>


        <h3>Introduction</h3>
<div className="goalpointsp">

<p>The Zha Foundation Charitable Trust envisions the establishment of the Zha Sustainability Practitioners Club in various regions worldwide. This initiative aims to promote sustainability principles and values globally, fostering a mindset of environmental consciousness and social responsibility.
</p>


<h3>Case Study</h3>
<p>The Zha Foundation Charitable Trust, based in India, has been at the forefront of advocating for sustainability practices across diverse communities. Recognizing the need for a concerted effort to address global sustainability challenges, the foundation has embarked on a mission to establish the Zha Sustainability Practitioners Club in different parts of the world.</p>

<p>The foundation believes that collaboration with national Non-Resident Indian (NRI) communities is essential to drive sustainable development initiatives effectively. To ensure alignment with its sustainability methodology and principles, the foundation requires NRIs to establish a Non-Disclosure Agreement (NDA) with the parent organization, Zha Foundation in India.</p>

<p>The Zha Sustainability Practitioners Club serves as a platform for individuals and organizations passionate about sustainability to connect, collaborate, and exchange ideas. Through various programs, workshops, and initiatives, the club aims to:</p>

<ol className="goalpoints">
    <li><span> Foster a Sustainability Mindset : </span>By promoting awareness and education on sustainability principles, the club encourages individuals to adopt environmentally conscious and socially responsible behaviors.
 </li>
    <li><span>Showcase Sustainable Practices : </span>Through case studies, best practices, and success stories, the club highlights innovative approaches to sustainability across different industries and sectors.
 </li>
    <li><span>Drive Collective Action : </span>By mobilizing resources and expertise, the club empowers members to initiate impactful projects and campaigns that contribute to sustainable development goals.
 </li>
     
    
</ol>



<p>The Zha Sustainability Practitioners Club operates on the core values of inclusivity, innovation, and collaboration. It provides a platform for members to not only learn from each other but also to inspire positive change within their communities and beyond.</p>


<h3>Conclusion</h3>


<p>As the world grapples with pressing environmental and social challenges, initiatives like the Zha Sustainability Practitioners Club play a vital role in driving positive change. By fostering a global network of sustainability advocates and practitioners, the club embodies the vision of the Zha Foundation Charitable Trust to create a more sustainable and equitable world for future generations.</p>

  

</div>
        
     

        </div>

    );
}

