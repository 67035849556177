import React from 'react';
import { Link } from 'react-router-dom';

const SuccessMessage = () => {
  return (
    <div style={containerStyle}>
    
      <img src= '../../img/form.jpg' alt="Success"  />

      <h1 style={headingStyle}>Your form has been submitted successfully!</h1>

      <Link to="/"> 
              <button   >
                <span>Back To Home</span> <i className="bi bi-arrow-right"></i>
              </button>
              </Link>
<h1></h1>

    </div>
  );
};

// CSS styles
const containerStyle = {
  textAlign: 'center',
};

const headingStyle = {
  fontSize: '30px',
  marginBottom: '20px',
  color: '#66BB6A',
  fontWeight: 'bold',
};

const imageStyle = {
  maxWidth: '300px',
  maxHeight: '300px',
};
export default SuccessMessage;