import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation() {

    return (
        <div>

            {/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

            <div className="social-ma">

                {/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
                <h1>Professionals awarding program</h1>

                <div className='second-mcontent'>
                    <img src='../../img/awards.jpg'></img>
                </div>

                <h3>Why Receive the Award?</h3>
                <p>Professionals Award Program celebrates individuals from diverse backgrounds, including professionals such as farmers, road contractors, cleaners, common individuals, social workers, IT employees, technology enthusiasts, technology leaders, celebrities, doctors, and more. Our aim is to recognize and honor those who contribute significantly to breaking down siloed workstyles. By including a wide array of professionals, we emphasize the value of equality across all fields and walks of life.</p>

                <p id="awardpara"><span>Scope:</span> The award program aims to recognize successful professionals across diverse industries who actively contribute to breaking out of siloed workstyles. It focuses on fostering a sense of community, promoting mental wellness, and encouraging social engagement among professionals.

                </p>
                <p id="awardpara">
                    <span>Outcome:</span> By acknowledging and rewarding individuals who transcend siloed work cultures, the program seeks to create a more interconnected and socially engaged professional community. Participants are likely to experience improved mental well-being as they connect with others beyond their immediate work spheres.

                </p>






                <h3>Goal</h3>
                 
               
                 <p>Our overarching goal is to foster a society where every human being is not only respected equally but where sustainability is deeply ingrained in our collective values. Through the Professionals Award Program, we strive to break down barriers and promote inclusivity across professions, emphasizing sustainable practices. By celebrating the unique contributions of individuals, we aim to create an environment where equality and sustainability coexist harmoniously. Join us in our commitment to respecting every human being equally and building a sustainable, inclusive world where professionals contribute to a brighter and more interconnected future.
                 </p>    




                <ol className="goalpoints">
                    <h3>Benefits:</h3>


                    <li><span>Enhanced Mental Wellness : </span>Breaking away from siloed workstyles fosters a sense of belonging and reduces feelings of isolation, contributing to improved mental health among participants.
                    </li>

                    <li><span>Community Building : </span>Professionals from various companies coming together creates a vibrant community, fostering collaboration, knowledge exchange, and a supportive network.
                    </li>

                    <li><span>Knowledge Sharing : </span>The cross-industry nature of the program encourages the sharing of insights, best practices, and innovative ideas, enriching participants' professional development.
                    </li>

                    <li><span>Increased Social Impact : </span>As professionals engage more with the broader society, the program can lead to initiatives and projects that contribute positively to social causes, generating a ripple effect of societal benefits.
                    </li>

                    <li><span>Career Advancement Opportunities : </span>Networking with professionals from different sectors opens doors to new career opportunities, partnerships, and collaborations, potentially boosting the participants' career growth.
                    </li>
                </ol>

                <p>In summary, the award program aims to transform the professional landscape by breaking down silos, enhancing mental wellness, fostering community, and promoting social engagement, resulting in a more interconnected and socially conscious professional society.</p>


               



            </div>

           
        </div>








    );
}

