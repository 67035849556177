import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">


            <div  id="language" >
        
        <a href=""><NavLink to="/Disaster-Relief">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ఝా ఫౌండేషన్ ద్వారా డిజాస్టర్ రిలీఫ్ ప్రయత్నాలు</h1>

                <div className='second-mcontent'>
                <img src='../../img/disasterrelief.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>భూకంపాలు, తుఫానులు, వరదలు మరియు మరిన్ని వంటి విపత్తుల వల్ల ప్రభావితమైన వారికి మేము మద్దతుగా నిలిచే ఝా ఫౌండేషన్‌కు స్వాగతం. త్వరిత మరియు ప్రభావవంతమైన విపత్తు సహాయాన్ని అందించడంలో మా నిబద్ధత, సంక్షోభం తర్వాత ఎవరూ ఒంటరిగా ఎదుర్కోవద్దనే నమ్మకంతో పాతుకుపోయింది.
                    </p>
                </div>

                <h3>మా మిషన్</h3>
                <div className="missi">
                    <h4 className="toleftneed">వేగవంతమైన ప్రతిస్పందన, శాశ్వత ప్రభావం:</h4>
                    <p>ఝా ఫౌండేషన్‌లో, మేము విపత్తు పరిస్థితుల యొక్క ఆవశ్యకత మరియు గురుత్వాకర్షణను అర్థం చేసుకున్నాము. మా లక్ష్యం ప్రకృతి వైపరీత్యాల వల్ల ప్రభావితమైన కమ్యూనిటీలకు వేగవంతమైన ప్రతిస్పందన మరియు మద్దతును అందించడం, దీర్ఘకాలిక పునరుద్ధరణ మరియు స్థితిస్థాపకత కోసం పని చేస్తున్నప్పుడు తక్షణ ఉపశమనం అందించడం.</p>


                </div>


                <h3>మన విధానం</h3>
                <div className="missi">
                    <h4 className="toleftneed">సమగ్ర విపత్తు ఉపశమనం:</h4>
                    <p>ఝా ఫౌండేషన్ విపత్తు సహాయానికి సమగ్ర విధానాన్ని తీసుకుంటుంది. మేము అత్యవసర ఆశ్రయం, ఆహారం మరియు వైద్య సహాయం అందించే ప్రారంభ ప్రతిస్పందన దశ నుండి, పునరుద్ధరణ దశ వరకు, మేము సంఘాలను పునర్నిర్మించడం మరియు జీవనోపాధిని పునరుద్ధరించడంపై దృష్టి సారిస్తాము, మా బృందాలు ప్రతి దశలోనూ బాధిత వ్యక్తులకు అవసరమైన సహాయాన్ని అందజేసేలా అంకితభావంతో ఉన్నాయి. </p>

                    <h4 className="toleftneed">కమ్యూనిటీ-కేంద్రీకృత పరిష్కారాలు:</h4>
                    <p>మేము సంఘాల బలాన్ని విశ్వసిస్తాము. మా విపత్తు సహాయక చర్యలు స్థానిక నివాసితుల సహకారంతో, వారి ప్రత్యేక అవసరాలు, సాంస్కృతిక సందర్భం మరియు భూభాగం యొక్క పరిజ్ఞానాన్ని గౌరవిస్తూ రూపొందించబడ్డాయి. కమ్యూనిటీలతో సన్నిహితంగా పని చేయడం ద్వారా, వ్యక్తులు తమ జీవితాలను పునర్నిర్మించుకోవడానికి వీలు కల్పించే స్థిరమైన పరిష్కారాలను రూపొందించాలని మేము లక్ష్యంగా పెట్టుకున్నాము.</p>
                </div>

                <h3>మా చొరవ</h3>
                <div className="missi">
                    <h4 className="toleftneed">అత్యవసర ఆశ్రయం మరియు సహాయం:</h4>
                    <p>విపత్తుల బారిన పడిన వారికి అత్యవసర ఆశ్రయం, ఆహారం, స్వచ్ఛమైన నీరు మరియు వైద్య సహాయం అందించడానికి ఝా ఫౌండేషన్ వేగంగా సమీకరించింది. సంక్షోభం తర్వాత తక్షణమే ప్రాథమిక అవసరాలు తీర్చబడేలా మా బృందాలు అహోరాత్రులు పనిచేస్తాయి.</p>

                    <h4 className="toleftneed">జీవితాలను పునర్నిర్మించడం:</h4>
                    <p>ప్రారంభ ప్రతిస్పందనకు మించి, మేము జీవితాలను పునర్నిర్మించడంపై దృష్టి పెడతాము. ఇందులో పునర్నిర్మాణ ప్రయత్నాలు, జీవనోపాధి పునరుద్ధరణకు వనరులను అందించడం మరియు కమ్యూనిటీలు సాధారణ స్థితిని తిరిగి పొందడంలో సహాయపడటానికి విద్య మరియు ఆరోగ్య సంరక్షణ సేవలను అందించడం వంటివి ఉన్నాయి.</p>

                    <h4 className="toleftneed">కమ్యూనిటీ రెసిలెన్స్ ప్రోగ్రామ్‌లు:</h4>
                    <p>ఝా ఫౌండేషన్ కమ్యూనిటీ స్థితిస్థాపకతను పెంచే కార్యక్రమాలలో పెట్టుబడి పెడుతుంది. విపత్తు సంసిద్ధత, ముందస్తు హెచ్చరిక వ్యవస్థలు మరియు భవిష్యత్ విపత్తుల ప్రభావాన్ని తగ్గించడానికి స్థిరమైన పద్ధతుల్లో స్థానిక నివాసితులకు శిక్షణ ఇవ్వడం ఇందులో ఉంటుంది.</p>
                </div>


            </section>
        </div>


    );
}

