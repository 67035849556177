import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
   <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Family-Wellness-Program">English</NavLink></a>

        </div>
         
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>Zha குடும்ப நலத் திட்டம்: இணக்கமான வாழ்க்கைக்கான ஊக்கமளிக்கும் ஆலோசனை</h1>

<div className='second-mcontent'>
<img src='../../img/membershipwellness.webp'></img>
                </div>
 
 


 
<h3>குறிக்கோள்:</h3>
<p>Zha குடும்ப நலத் திட்டம் என்பது மன அழுத்தம், பதட்டம் மற்றும் வாக்குவாதங்களைத் தடுப்பதற்கு முன்முயற்சியுடன் கூடிய ஆலோசனைகளை வழங்குவதன் மூலம் இணக்கமான வாழ்க்கையை மேம்படுத்த வடிவமைக்கப்பட்ட ஒரு விரிவான முயற்சியாகும். இந்த திட்டம் கணவன், மனைவி மற்றும் குழந்தைகளின் உணர்ச்சி நல்வாழ்வை வலுப்படுத்தவும், சவால்களை ஒன்றாக சமாளிக்கும் திறன் கொண்ட ஒரு மீள் குடும்பத்தை வளர்க்கவும் வடிவமைக்கப்பட்டுள்ளது.
</p>

 

<ol className="goalpoints">
    <h3>நிரல் கூறுகள்:</h3>

<li><span>சுறுசுறுப்பான குடும்ப ஆலோசனை அமர்வுகள்:</span>  
<ul>
    <li>தனிப்பட்ட மற்றும் கூட்டு அழுத்தங்களுக்கு தீர்வு காண கணவன், மனைவி மற்றும் குழந்தைகளுக்கான சிறப்பு ஆலோசனை அமர்வுகள்.



</li>
    <li>பயனுள்ள தகவல்தொடர்புகளை உருவாக்குதல், ஒருவருக்கொருவர் முன்னோக்குகளைப் புரிந்துகொள்வது மற்றும் குடும்ப இயக்கவியலை மேம்படுத்துதல்.



</li>
</ul>
</li> 

<li><span>முழுமையான ஆரோக்கிய பட்டறைகள்: </span>  
<ul>
    <li>மன அழுத்தம் மேலாண்மை, மோதல் தீர்வு, மற்றும் உணர்ச்சி பின்னடைவை வளர்ப்பது உள்ளிட்ட பல்வேறு தலைப்புகளை உள்ளடக்கிய வடிவமைக்கப்பட்ட பட்டறைகள்.



</li>
    <li>முழு குடும்பமும் பங்கேற்க மற்றும் ஒன்றாக கற்றுக்கொள்வதற்கான ஊடாடும் அமர்வுகள்.




</li>
</ul>
</li> 

<li><span>குடும்ப பிணைப்பு நடவடிக்கைகள்:</span>  
<ul>
    <li>குடும்பப் பிணைப்பு மற்றும் குழுப்பணியை ஊக்குவிக்கும் செயல்பாடுகளைச் சேர்த்து, ஒற்றுமை உணர்வை வலுப்படுத்துங்கள்.

</li>
    <li>வெளிப்புற நிகழ்வுகள், ஆக்கப்பூர்வமான பட்டறைகள் மற்றும் குடும்ப உறவுகளை வலுப்படுத்த கூட்டுத் திட்டங்கள்.





</li>
</ul>
</li> 

<li><span>தனிப்பட்ட ஆரோக்கியத் திட்டங்கள்: </span>  
<ul>
    <li>ஒவ்வொரு குடும்ப உறுப்பினருக்கும் அவர்களின் தனிப்பட்ட தேவைகள் மற்றும் சவால்களின் அடிப்படையில் தனிப்பயனாக்கப்பட்ட ஆரோக்கியத் திட்டங்கள் உருவாக்கப்பட்டுள்ளன.
</li>
    <li>சூழ்நிலைகள் உருவாகும்போது இந்தத் திட்டங்களைச் செயல்படுத்தவும் சரிசெய்யவும் தொடர்ந்து ஆதரவு.
</li>
</ul>
</li> 

<li><span>பெற்றோர் ஆதரவு மற்றும் கல்வி: </span>  
<ul>
    <li>பெற்றோர்கள் தங்கள் பெற்றோருக்குரிய திறன்களை மேம்படுத்துவதற்காக வடிவமைக்கப்பட்ட பட்டறைகள் மற்றும் வளங்கள்.
</li>
    <li>குழந்தைகளுடன் பயனுள்ள தொடர்பு, நேர்மறை ஒழுக்க உத்திகள் மற்றும் வளர்ப்பு வீட்டுச் சூழலை வளர்ப்பதற்கான வழிகாட்டுதல்.
</li>
</ul>
</li> 
<li><span>இளைஞர் அதிகாரமளித்தல் அமர்வுகள்:</span>  
<ul>
    <li>குழந்தைகள் மற்றும் பதின்வயதினர் எதிர்கொள்ளும் தனித்துவமான சவால்களை எதிர்கொள்வதற்காக அர்ப்பணிக்கப்பட்ட சிறப்பு ஆலோசனை அமர்வுகள் மற்றும் பட்டறைகள்.
</li>
    <li>திறந்த உரையாடலின் ஊக்கம் மற்றும் சமாளிக்கும் வழிமுறைகளின் வளர்ச்சி.
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>பலன்கள்:</h3>
<ul className="goalpoints">
  <li>பயனுள்ள தொடர்பு மற்றும் புரிதல் மூலம் குடும்ப உறவுகளை பலப்படுத்தியது.</li>  
  <li>குடும்பத்தில் மன அழுத்தம், பதட்டம் மற்றும் வாக்குவாதங்கள் குறையும்.</li>  
  <li>பெற்றோர் மற்றும் குழந்தைகள் இருவருக்கும் உணர்ச்சி ரீதியான பின்னடைவு.</li>  
  <li>குடும்ப அலகுக்குள் குழுப்பணி மற்றும் சிக்கலைத் தீர்க்கும் திறன்களின் வளர்ச்சி.</li>  
  <li>நேர்மறையான மற்றும் ஆதரவான வீட்டுச் சூழலை மேம்படுத்துதல்.

</li>  
 
</ul>


<h3>எப்படி பங்கேற்பது:</h3>
<ul className="goalpoints">
  <li>Zha குடும்ப நலத் திட்டத்தில் ஆர்வமுள்ள குடும்பங்கள், தங்களின் பிரத்யேக ஆலோசனை சேவைகள் ஹாட்லைன் அல்லது இணையதளம் மூலம் Zha அறக்கட்டளையை அணுகி பதிவு செய்யலாம்.</li>  
  <li>பங்கேற்பது தன்னார்வமானது, இரகசியமானது மற்றும் முழுமையான நல்வாழ்வுக்கான செயலூக்கமான ஆதரவைத் தேடும் குடும்பங்களுக்குத் திறந்திருக்கும்.</li>  
 
 
</ul>
     
       
       <p>அனைத்து உறுப்பினர்களுக்கும் ஆரோக்கியமான மற்றும் ஆதரவான சூழலை வளர்த்து, ஒரு ஒருங்கிணைந்த குழுவாக வாழ்க்கையின் சவால்களை வழிநடத்த குடும்பங்களுக்கு அதிகாரம் அளிக்கும் வகையில் Zha குடும்ப நலத் திட்டம் வடிவமைக்கப்பட்டுள்ளது. இந்த திட்டத்தைப் பற்றிய கருத்துகள் அல்லது மேம்படுத்துவதற்கான பரிந்துரைகள் வரவேற்கப்படுகின்றன.</p>
       
       
        </div>


        </div>




 
        


    );
}

