import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">

            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Establishment-Story">English</NavLink></a>

        </div>
            


                <h1 id="mhead">ಝಾ ಸ್ಥಾಪನೆಯ ಕಥೆ</h1>

                <div className='second-mcontent'>
                <img src='../../img/story.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">

                    <p>ಝಾ ಫೌಂಡೇಶನ್‌ನ ಕಥೆಯು ವಿವಿಧ ಕೈಗಾರಿಕೆಗಳಾದ್ಯಂತ ಯಶಸ್ವಿ ವೃತ್ತಿಪರರೊಂದಿಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ, ಅವರು ಸಮರ್ಥನೀಯ ಜೀವನದ ಜಾಗತಿಕ ಅಭಿವೃದ್ಧಿಗೆ ಕೊಡುಗೆ ನೀಡಲು ಬಯಸುತ್ತಾರೆ. ಆದಾಗ್ಯೂ, ಅವರು ತಮ್ಮ ಮೌಲ್ಯಗಳೊಂದಿಗೆ ಜೋಡಿಸುವ ನವೀನ ವೇದಿಕೆಗಳ ಕೊರತೆಯನ್ನು ಕಂಡುಕೊಂಡರು. ಅದೇ ಸಮಯದಲ್ಲಿ, ಯಶಸ್ವಿ ಉದ್ಯಮಿಗಳು ತಮ್ಮ ಸಾಧನೆಗಳಲ್ಲಿ ಹೆಮ್ಮೆಯನ್ನು ತುಂಬುವ ವಿಶೇಷ ವ್ಯಾಪಾರ ಕ್ಲಬ್‌ಗಳನ್ನು ಹೊಂದಿದ್ದರು.
                    </p>

                    <p>ಈ ಅಂತರವನ್ನು ಗುರುತಿಸಿ, ಝಾ ಸಂಸ್ಥಾಪಕರು ಕಾರ್ಯತಂತ್ರದ ಸಾಮಾಜಿಕ ಕಾರ್ಯ ಮಂಡಳಿಯನ್ನು ರಚಿಸುವ ಅಗತ್ಯವನ್ನು ನಂಬಿದ್ದರು. ಈ ಮಂಡಳಿಯು ಎಲ್ಲಾ ಹಂತಗಳಲ್ಲಿ ಮತ್ತು ವಿವಿಧ ಸ್ಥಳಗಳಿಂದ ನಿಪುಣ ವೃತ್ತಿಪರರು ಮತ್ತು ವ್ಯಾಪಾರ ನಾಯಕರನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಝಾ ಸುಸ್ಥಿರತೆಯ ಆಂದೋಲನವನ್ನು ಪ್ರಚಾರ ಮಾಡುವುದು, ಸಾಮಾನ್ಯ ಜನರಲ್ಲಿ ಜಾಗೃತಿ ಮೂಡಿಸುವುದು ಮತ್ತು ಜಾಗತಿಕ ಪರಿಣಾಮವನ್ನು ಬೆಳೆಸುವುದು ಇದರ ಉದ್ದೇಶವಾಗಿತ್ತು.</p>

                    <p>ಝಾ ಸ್ಥಾಪಕರು ಗ್ರಹ ಮತ್ತು ಪ್ರತಿಯೊಬ್ಬರ ಯೋಗಕ್ಷೇಮಕ್ಕೆ ಪ್ರಯೋಜನಕಾರಿಯಾದ ಉದ್ದೇಶಪೂರ್ವಕ ಹವ್ಯಾಸವನ್ನು ರಚಿಸುವುದನ್ನು ರೂಪಿಸಿದರು, ಸಮರ್ಥನೀಯತೆಯ ತತ್ವಗಳಲ್ಲಿ ಹುದುಗಿರುವ ಕಾಳಜಿ ಮತ್ತು ಹಂಚಿಕೆ ಮಾದರಿಯಿಂದ ಸ್ಫೂರ್ತಿ ಪಡೆದಿದ್ದಾರೆ. ಹೀಗಾಗಿ, ಝಾ ಫೌಂಡೇಶನ್‌ನ ಪ್ರಮುಖ ವಿಷಯವು ಸುಸ್ಥಿರತೆಯಾಯಿತು.</p>

                    <p>ಝಾ ಫೌಂಡೇಶನ್ ಕೇವಲ ನಿಗಮಗಳಿಗೆ ಮಾತ್ರವಲ್ಲದೆ ಎಲ್ಲಾ ವ್ಯಕ್ತಿಗಳಿಗೂ ಅನ್ವಯವಾಗುವ ಸುಸ್ಥಿರತೆಯ ಮಾನದಂಡದ ಸಿದ್ಧಾಂತವನ್ನು ಸ್ಥಾಪಿಸಲು ಬದ್ಧವಾಗಿದೆ. ವಿಶ್ವಸಂಸ್ಥೆಯ ಸುಸ್ಥಿರ ಗುರಿಗಳು ಮತ್ತು ESG ಮಾಪನಗಳೊಂದಿಗೆ ಹೊಂದಾಣಿಕೆಯಲ್ಲಿ, ಝಾ ಸಾಮಾನ್ಯ ಜನರಿಗೆ ಅನುಗುಣವಾಗಿ ಒಂದು ವಿಧಾನವನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಿದ್ದಾರೆ.</p>

                    <p>ಝಾ ಸುಸ್ಥಿರತೆ ಕಾರ್ಯಕ್ರಮಗಳು ಸಾಮಾನ್ಯ ವ್ಯಕ್ತಿಗಳು, ಮಹಿಳೆಯರು, ಪುರುಷರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳು ಸೇರಿದಂತೆ ಸಾರ್ವಜನಿಕರಿಗೆ ಝಾ ಸಮರ್ಥನೀಯತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುವ ಚೌಕಟ್ಟಿನ ತತ್ವಗಳು ಮತ್ತು ಮೌಲ್ಯಗಳನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳುವ ಗುರಿಯನ್ನು ಹೊಂದಿವೆ. ಈ ಚೌಕಟ್ಟು ಎಲ್ಲಾ ಮಾನವರು ಅಳವಡಿಸಿಕೊಳ್ಳಲು ಒಂದು ವಿಧಾನವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ, ಇದು ಅವರ ಸಮರ್ಥನೀಯ ಮನಸ್ಥಿತಿಯ ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಅಳೆಯಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ. ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಸಾಧನಗಳನ್ನು ಹತೋಟಿಯಲ್ಲಿಟ್ಟುಕೊಂಡು, ಝಾ ಫೌಂಡೇಶನ್ ಸಾರ್ವಜನಿಕರಿಗೆ ವಿಶ್ವಸಂಸ್ಥೆಯ ಸಮರ್ಥನೀಯ ಗುರಿಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡಲು ಉಚಿತ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ಈ ವಿಧಾನವು Zha ಸಮರ್ಥನೀಯತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುವ ಚೌಕಟ್ಟಿನೊಳಗೆ ಪ್ರಮುಖ ಅಭ್ಯಾಸ ಕ್ಷೇತ್ರಗಳನ್ನು ಒಳಗೊಂಡಿದೆ, ಸುಸ್ಥಿರ ಜೀವನಕ್ಕೆ ಸಮಗ್ರ ವಿಧಾನವನ್ನು ರೂಪಿಸುತ್ತದೆ.</p>
                    
                    {/* <p>The story of the Zha Foundation begins with successful professionals across various industries who desired to contribute to the global development of sustainable living. However, they found a lack of innovative platforms aligning with their values. Simultaneously, successful businessmen had exclusive business clubs that instilled pride in their achievements.</p>

                    <p>Recognizing this gap, the founder of Zha believed in the necessity of forming a strategic social work board. This board would consist of accomplished professionals and business leaders at all levels and from diverse locations. The aim was to propagate the Zha sustainability movement, raising awareness among the general public and fostering a global impact.</p> */}
                </div>

                {/* <h3>Our Vision</h3> */}
                <div className="missi">
                    <h4  >ಝಾ ಫೌಂಡೇಶನ್ ಉಪಕ್ರಮಗಳ ಸುಸ್ಥಿರತೆಯ ಫಲಿತಾಂಶಗಳು:</h4>
                     

                </div>
                
<ol className="goalpoints">
    <li><span>ವೈಯಕ್ತಿಕ ಸಬಲೀಕರಣ : </span>ಝಾ ಅವರ ಸುಸ್ಥಿರತೆಯ ಕಾರ್ಯಕ್ರಮಗಳ ಮೂಲಕ, ಸಾಮಾನ್ಯ ವ್ಯಕ್ತಿಗಳು, ಮಹಿಳೆಯರು, ಪುರುಷರು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿಗಳು ಸುಸ್ಥಿರ ಮನಸ್ಥಿತಿಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಲು ಅಧಿಕಾರವನ್ನು ಹೊಂದಿದ್ದಾರೆ. ಒಳಗೊಳ್ಳುವಿಕೆಯ ಮೇಲೆ ಪ್ರತಿಷ್ಠಾನದ ಗಮನವು ಪ್ರತಿಯೊಬ್ಬರೂ ಗ್ರಹದ ಸುಧಾರಣೆಗೆ ಕೊಡುಗೆ ನೀಡಬಹುದು ಎಂದು ಖಚಿತಪಡಿಸುತ್ತದೆ.

 </li>
    <li><span>ಜಾಗತಿಕ ಪರಿಣಾಮ : </span>ವಿಶ್ವಸಂಸ್ಥೆಯ ಸುಸ್ಥಿರ ಗುರಿಗಳು ಮತ್ತು ESG ಮಾಪನಗಳೊಂದಿಗೆ ಹೊಂದಾಣಿಕೆ ಮಾಡುವ ಮೂಲಕ, ಝಾ ಫೌಂಡೇಶನ್ ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿಗಾಗಿ ಜಾಗತಿಕ ಉಪಕ್ರಮಗಳಿಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ. ವಿಶ್ವಾದ್ಯಂತ ವ್ಯಕ್ತಿಗಳಿಗೆ ಅನ್ವಯವಾಗುವ ಸಮರ್ಥನೀಯತೆಯ ಮಾನದಂಡದ ಸಿದ್ಧಾಂತವು ಧನಾತ್ಮಕ ಬದಲಾವಣೆಯ ಕಡೆಗೆ ಸಾಮೂಹಿಕ ಪ್ರಯತ್ನವನ್ನು ಒತ್ತಿಹೇಳುತ್ತದೆ.
 </li>
    <li><span>ಅಳೆಯಬಹುದಾದ ಪರಿಣಾಮ : </span>ಝಾ ಅವರ ವಿಧಾನವು ವ್ಯಕ್ತಿಗಳಿಗೆ ತಮ್ಮ ಸಮರ್ಥನೀಯತೆಯ ಮನಸ್ಥಿತಿಯ ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಅಳೆಯಲು ಸ್ಪಷ್ಟವಾದ ಮಾರ್ಗವನ್ನು ಒದಗಿಸುತ್ತದೆ. ಇದು ಜನರು ಸುಸ್ಥಿರ ಜೀವನಕ್ಕೆ ಅವರ ಕೊಡುಗೆಗಳನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ.

 </li>
    <li><span>ಶಿಕ್ಷಣ ಮತ್ತು ಜಾಗೃತಿ :</span>ಝಾ ಫೌಂಡೇಶನ್ ತಂತ್ರಜ್ಞಾನ ಮತ್ತು ಸಾಧನಗಳನ್ನು ಉಚಿತವಾಗಿ ಸಂಪನ್ಮೂಲಗಳನ್ನು ನೀಡಲು, ವಿಶ್ವಸಂಸ್ಥೆಯ ಸುಸ್ಥಿರತೆಯ ಗುರಿಗಳ ಬಗ್ಗೆ ಸಾರ್ವಜನಿಕ ತಿಳುವಳಿಕೆಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ. ಈ ಶೈಕ್ಷಣಿಕ ಅಂಶವು ಜಾಗೃತಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ ಮತ್ತು ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳ ವಿಶಾಲವಾದ ಅಳವಡಿಕೆಯನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತದೆ.
 </li>
    <li><span>ಸಮಗ್ರ ಚೌಕಟ್ಟು :</span>Zha ಸಮರ್ಥನೀಯತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುವ ಚೌಕಟ್ಟು, ಅದರ ಪ್ರಮುಖ ಅಭ್ಯಾಸ ಕ್ಷೇತ್ರಗಳೊಂದಿಗೆ, ಸುಸ್ಥಿರ ಜೀವನಕ್ಕೆ ರಚನಾತ್ಮಕ ಮತ್ತು ಸಮಗ್ರ ವಿಧಾನವನ್ನು ಒದಗಿಸುತ್ತದೆ. ಈ ಚೌಕಟ್ಟು ವ್ಯಕ್ತಿಗಳಿಗೆ ತಮ್ಮ ಜೀವನದ ವಿವಿಧ ಅಂಶಗಳಲ್ಲಿ ಸಮರ್ಥನೀಯತೆಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಲು ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತದೆ.

 </li>
    <li><span>ಸಮುದಾಯ ನಿರ್ಮಾಣ : </span>ಸುಸ್ಥಿರತೆಯ ತತ್ವಗಳಲ್ಲಿ ಹುದುಗಿರುವ ಕಾಳಜಿ ಮತ್ತು ಹಂಚಿಕೆ ಮಾದರಿಯು ಸಮುದಾಯ ಮತ್ತು ಸಾಮೂಹಿಕ ಜವಾಬ್ದಾರಿಯ ಪ್ರಜ್ಞೆಯನ್ನು ಬೆಳೆಸುತ್ತದೆ. ಝಾ ಫೌಂಡೇಶನ್ ವ್ಯಕ್ತಿಗಳನ್ನು ಸಹಯೋಗಿಸಲು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತದೆ, ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳಿಗೆ ಸಮರ್ಪಿತವಾದ ಸಮಾನ ಮನಸ್ಸಿನ ಜನರ ಜಾಲವನ್ನು ರಚಿಸುತ್ತದೆ.


 </li>
     
  </ol>

 <p>ಒಟ್ಟಾರೆಯಾಗಿ, ಝಾ ಫೌಂಡೇಶನ್ ಕಥೆಯನ್ನು ಅನುಸರಿಸುವುದು ಸುಸ್ಥಿರತೆ, ಶಿಕ್ಷಣ ಮತ್ತು ಸಾಮೂಹಿಕ ಕ್ರಿಯೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ ವ್ಯಕ್ತಿಗಳು, ಸಮುದಾಯಗಳು ಮತ್ತು ಜಾಗತಿಕ ಪರಿಸರದ ಮೇಲೆ ಸಕಾರಾತ್ಮಕ ಪರಿಣಾಮ ಬೀರುತ್ತದೆ.</p>

 

            </section>
        </div>


    );
}

