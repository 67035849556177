import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Common-Humans-COPs">English</NavLink></a>

        </div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ഴ കോമൺ മാൻ COP യുടെ</h1>
</div>


<div className="internship">
<img src="../img/cops.webp"></img>
        </div>
<div className="goalpointsp">

<p>Zha കോമൺ മാൻ കമ്മ്യൂണിറ്റി ഓഫ് പ്രാക്ടീസ് (COPs) ആഗോളതലത്തിൽ വ്യക്തികൾക്കിടയിൽ സുസ്ഥിരതാ മനോഭാവം വളർത്തിയെടുക്കാൻ ലക്ഷ്യമിടുന്നു. ഴ ഫൗണ്ടേഷന്റെ ഗ്ലോബൽ ബോർഡ് അംഗങ്ങൾ വഴി ഉപദേശം നൽകുന്ന, COP-കൾ സുസ്ഥിരമായ കീഴ്‌വഴക്കങ്ങൾക്കുള്ള പ്രവർത്തനങ്ങളെ ബോധവൽക്കരിക്കാനും ചർച്ച ചെയ്യാനും പ്രചോദിപ്പിക്കാനും പ്രതിമാസ ഫോറങ്ങളിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു. അവബോധം വളർത്തുക, മികച്ച സമ്പ്രദായങ്ങൾ പങ്കിടുക, സുസ്ഥിരതയ്ക്കായി പ്രതിജ്ഞാബദ്ധമായ ഒരു ആഗോള ശൃംഖല വളർത്തിയെടുക്കുക എന്നിവയാണ് ലക്ഷ്യങ്ങൾ. വിജ്ഞാന കൈമാറ്റം, സഹകരണ സംരംഭങ്ങൾ, കൂടുതൽ സുസ്ഥിരമായ ഒരു ലോകത്തിലേക്ക് സംഭാവന ചെയ്യാൻ വ്യക്തികളെ ശാക്തീകരിക്കൽ എന്നിവ പ്രയോജനങ്ങളിൽ ഉൾപ്പെടുന്നു.
</p>
<p>
അതിനാൽ, ഓരോ രാജ്യത്തിനും ഞങ്ങളുടെ വിവിധ COP-കൾ ഉണ്ട്, അത് സെഷനുകളും റെസലൂഷൻ ഫലങ്ങളും നൽകുന്നതിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുകയും ഇനിപ്പറയുന്ന അജണ്ടകളെ അടിസ്ഥാനമാക്കിയുള്ള സംരംഭങ്ങൾ നവീകരിക്കുകയും വേണം.
</p>

<ol className="goalpoints">
    <li><span>വിദ്യാഭ്യാസവും അവബോധവും :</span>Zha- യുടെ COP-കൾ കാലാവസ്ഥാ വ്യതിയാനത്തെക്കുറിച്ചും സുസ്ഥിരതയെക്കുറിച്ചും അറിവ് നേടുന്നതിന് സാധാരണക്കാർക്ക് ഒരു വേദി നൽകുന്നു, അവരുടെ പ്രാധാന്യത്തെക്കുറിച്ച് ആഴത്തിലുള്ള ധാരണ വളർത്തുന്നു.
</li>
    <li><span>പ്രാദേശിക ആഘാതം :</span>സാധാരണ വ്യക്തികളെ ഇടപഴകുന്നതിലൂടെ, COP-കൾ പ്രാദേശിക സമൂഹങ്ങളെ നടപടിയെടുക്കാൻ പ്രാപ്തരാക്കുന്നു, ആഗോള സുസ്ഥിരതാ ശ്രമങ്ങൾക്ക് കൂട്ടായി സംഭാവന ചെയ്യുന്നു.</li>


    <li><span>പെരുമാറ്റ മാറ്റം :</span>COP-കൾ ദൈനംദിന ശീലങ്ങളെയും സമ്പ്രദായങ്ങളെയും സ്വാധീനിക്കാൻ ലക്ഷ്യമിടുന്നു, കാലാവസ്ഥാ വ്യതിയാനത്തിന്റെ പ്രാധാന്യം തുടക്കത്തിൽ മനസ്സിലാക്കാത്ത പങ്കാളികൾക്കിടയിൽ സുസ്ഥിരമായ തിരഞ്ഞെടുപ്പുകൾ പ്രോത്സാഹിപ്പിക്കുന്നു.
</li>
    <li><span>കമ്മ്യൂണിറ്റി ശാക്തീകരണം :</span>മെന്റർഷിപ്പിലൂടെയും സഹകരണത്തിലൂടെയും, COP-കൾ വ്യക്തികളെ അവരുടെ കമ്മ്യൂണിറ്റികൾക്കുള്ളിൽ സുസ്ഥിരതയുടെ വക്താക്കളാകാൻ പ്രാപ്തരാക്കുന്നു, ഇത് നല്ല മാറ്റത്തിന്റെ അലയൊലികൾ സൃഷ്ടിക്കുന്നു.


</li>
    <li><span>ഗ്ലോബൽ നെറ്റ്‌വർക്ക് : </span>COP-കൾ വൈവിധ്യമാർന്ന പശ്ചാത്തലങ്ങളിൽ നിന്നുള്ള ആളുകളെ ബന്ധിപ്പിക്കുന്നു, ആഗോള ഉത്തരവാദിത്തബോധം വളർത്തുന്നു, വ്യക്തിഗത പ്രവർത്തനങ്ങൾ ഗ്രഹത്തെ എങ്ങനെ സ്വാധീനിക്കുന്നു എന്ന് ചിത്രീകരിക്കുന്നു.

</li>
    <li><span>യുഎൻ ലക്ഷ്യങ്ങളുമായുള്ള വിന്യാസം : </span>ഴയുടെ COP-കൾ യുണൈറ്റഡ് നേഷൻസിന്റെ സുസ്ഥിര ലക്ഷ്യങ്ങളുമായി നേരിട്ട് യോജിക്കുന്നു, വിശാലമായ ആഗോള ലക്ഷ്യങ്ങൾ കൈവരിക്കുന്നതിനുള്ള ഒരു അടിസ്ഥാന സമീപനം വാഗ്ദാനം ചെയ്യുന്നു.

</li>
    <li><span>ഇൻക്ലൂസീവ് പങ്കാളിത്തം :</span>സാധാരണക്കാരെ ലക്ഷ്യം വെച്ചുകൊണ്ട്, COP-കൾ ഉൾക്കൊള്ളൽ ഉറപ്പാക്കുന്നു, വിശാലമായ ജനസംഖ്യാശാസ്‌ത്രത്തിൽ ഏർപ്പെടുന്നു, ഭാഷയോ സുസ്ഥിരതയുടെ ആശയങ്ങളോ പരിചിതമല്ലാത്തവരെ ഒഴിവാക്കുന്നത് ഒഴിവാക്കുന്നു.
</li>

<li><span>പ്രതിമാസ ഫോറങ്ങൾ :</span>സ്ഥിരമായ ചർച്ചകൾക്കും പഠനത്തിനും സ്ഥിരമായ ഒരു പ്ലാറ്റ്ഫോം പ്രദാനം ചെയ്യുന്നു, കാലക്രമേണ സുസ്ഥിരതയുടെ പ്രാധാന്യം ശക്തിപ്പെടുത്തുകയും പങ്കെടുക്കുന്നവരുടെ നിരന്തരമായ ബോധത്തിന്റെ ഭാഗമാക്കുകയും ചെയ്യുന്നു.
</li>


    <li><span>പ്രായോഗിക പരിഹാരങ്ങൾ :</span>സാധാരണക്കാർക്ക് അവരുടെ ദൈനംദിന ജീവിതത്തിൽ നടപ്പിലാക്കാൻ കഴിയുന്ന പ്രായോഗികവും കൈവരിക്കാവുന്നതുമായ പരിഹാരങ്ങളിൽ COP-കൾ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു, സുസ്ഥിരത കൂടുതൽ ആക്സസ് ചെയ്യാവുന്നതും കുറച്ച് അമൂർത്തവുമാക്കുന്നു.
</li>
    <li><span>പൊരുത്തപ്പെടുത്തലും പ്രതിരോധശേഷിയും : </span>COP-കളിലൂടെ സാധാരണക്കാരെ ബോധവൽക്കരിക്കുന്നത്, മാറിക്കൊണ്ടിരിക്കുന്ന പാരിസ്ഥിതിക സാഹചര്യങ്ങളുമായി പൊരുത്തപ്പെടാനും കാലാവസ്ഥാ വ്യതിയാനത്തിന്റെ ആഘാതങ്ങൾക്കെതിരെ പ്രതിരോധശേഷി വികസിപ്പിക്കാനും കമ്മ്യൂണിറ്റികളെ സഹായിക്കുന്നു.
</li>
 
 </ol>
 
 <p>സാരാംശത്തിൽ, കാലാവസ്ഥാ വ്യതിയാനത്തിന്റെയും സുസ്ഥിര ലക്ഷ്യങ്ങളുടെയും അമൂർത്ത ആശയങ്ങളും ദൈനംദിന വ്യക്തികളുടെ പ്രായോഗിക പ്രവർത്തനങ്ങളും തമ്മിലുള്ള വിടവ് നികത്തുന്നതിൽ ഴയുടെ കോമൺ മാൻ COP-കൾ നിർണായക പങ്ക് വഹിക്കുന്നു.</p>
 </div>

        </div>

    );
}

