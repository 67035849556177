import react from "react";
import React, { useState } from 'react';
import Video from './Videocarousel';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";
import Testimonal from './Testimonal';
import Recue from './Recue';
import Card from 'react-bootstrap/Card';
import './Feedback.css';
import Awards from './Feedback copy';
import Recent from './Recent';
import Feedback from './Feedback';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';


export default function Home() {
    return (
        <div>
<div className="hmain">
<div  id="language" >
        
        <a href=""><NavLink to="/">English</NavLink></a>

        </div>
    <div className="main-content">
        <h1>
            ZHA Foundation Charitable Trust
        </h1>
        <p>"ಸಸ್ಟೈನಬಿಲಿಟಿ ಮೈಂಡ್ಸೆಟ್ ಹೊಂದಿರುವ ವೃತ್ತಿಪರರು"</p>
        
        {/* <p> Our Zha Foundation is a Non Profit Organization which was founded in 2017, to help the Indian farmers through our Innovative Tech Consulting for free, that helps farmers have value added benefits in scaling their practices which promotes sustainable life of farmers, enables the youth interest in agriculture activities, thus creates food sustainability in the world. </p> */}
    </div>
    <div className="img-align">
          <img src="../../img/hero.webp"></img>
         
        </div>
        <div className="img-align">
        <NavLink to="/hh"><Button variant="success">Zha Sustainability Mindset Self Assesment</Button></NavLink>
        </div>
      
      </div>

<section className="visionmi">
{/* <div className="whoweare">
        <div className="bar"></div>
        <h4>Our Vision</h4>
    </div> */}


<div className="purpose">
<h4>ದೃಷ್ಟಿ</h4>
<p>ಮುಂದಿನ ಪೀಳಿಗೆಗೆ ಉತ್ತಮ ಭವಿಷ್ಯವನ್ನು ನಿರ್ಮಿಸಲು, ZHA ಫೌಂಡೇಶನ್ ಸುಸ್ಥಿರ ಮನಸ್ಥಿತಿಯನ್ನು ಪೋಷಿಸುವ ಮತ್ತು ನಮ್ಮ ಗ್ರಹದ ನೈಸರ್ಗಿಕ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಸಂರಕ್ಷಿಸುವ ದ್ವಂದ್ವ ಉದ್ದೇಶಕ್ಕೆ ಸಮರ್ಪಿಸಲಾಗಿದೆ.</p>
</div>

<div className="missi">
  <h4>ಮಿಷನ್</h4>
<p>2017 ರಲ್ಲಿ ಸ್ಥಾಪಿಸಲಾದ ಝಾ ಫೌಂಡೇಶನ್, ಸುಸ್ಥಿರ ಮನಸ್ಥಿತಿಯನ್ನು ಬೆಳೆಸಲು ಬದ್ಧವಾಗಿರುವ ಜಾಗತಿಕ ಸಮುದಾಯವನ್ನು ಬೆಳೆಸಲು ಶ್ರಮಿಸುತ್ತದೆ. ನಾವು ಅನಿಯಂತ್ರಿತ ದುರಾಶೆ ಮತ್ತು ಅಸೂಯೆಯ ಋಣಾತ್ಮಕ ಪರಿಣಾಮಗಳನ್ನು ಮೀರುವ ಗುರಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ, ರಾಷ್ಟ್ರಗಳಿಗೆ ಮತ್ತು ಗ್ರಹದ ಸೂಕ್ಷ್ಮ ಸಮತೋಲನಕ್ಕೆ ಅವುಗಳ ಸಂಭಾವ್ಯ ಹಾನಿಯನ್ನು ಗುರುತಿಸುತ್ತೇವೆ. ವೃತ್ತಿಪರರು ಮತ್ತು ವ್ಯಾಪಾರ ಮುಖಂಡರೊಂದಿಗೆ ಸಹಯೋಗದೊಂದಿಗೆ, ನಮ್ಮ ಪ್ರತಿಷ್ಠಾನವು ಸುಸ್ಥಿರ ಭವಿಷ್ಯಕ್ಕಾಗಿ ಪ್ರತಿಪಾದಿಸುತ್ತದೆ, ಸಂಘರ್ಷಗಳಿಂದ ದೂರವಿರಿ ಮತ್ತು ನಮ್ಮ ಝಾ ಸದಸ್ಯರಿಗೆ ಮೂರು ಮಾರ್ಗದರ್ಶಿ ಸೂತ್ರಗಳ ಮೂಲಕ ಏಕತೆಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ: ಯಾವುದೇ ರಾಜಕೀಯ ಆಧಾರಿತ ಚರ್ಚೆಗಳಿಲ್ಲ, ಧರ್ಮ ಅಥವಾ ಜಾತಿ ಆಧಾರಿತ ಚರ್ಚೆಗಳಿಲ್ಲ, ಮತ್ತು ತಡೆಯುವ ಬದ್ಧತೆ ಸಹ ಸದಸ್ಯರ ಬಗ್ಗೆ ನಕಾರಾತ್ಮಕವಾಗಿ ಮಾತನಾಡುವುದರಿಂದ.

</p>
 
<p>
ಭವಿಷ್ಯದ ಪೀಳಿಗೆಯ ಯೋಗಕ್ಷೇಮಕ್ಕೆ ಧಕ್ಕೆಯಾಗದಂತೆ ಪ್ರಸ್ತುತ ಅಗತ್ಯಗಳನ್ನು ಸಮತೋಲನಗೊಳಿಸುವುದು ಉತ್ತಮ ನಾಳೆಗಾಗಿ ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿ ಅತ್ಯಗತ್ಯ. ನಮ್ಮ ಸಮಾಜಗಳು ಮತ್ತು ಹಂಚಿಕೆಯ ಗ್ರಹಗಳ ಉಳಿವು ಸುಸ್ಥಿರ ಮನಸ್ಥಿತಿಯನ್ನು ಬೆಳೆಸುವುದರ ಮೇಲೆ ಅವಲಂಬಿತವಾಗಿದೆ. ಹದಿನೇಳು ಅಂತರ್ಸಂಪರ್ಕಿತ ಉದ್ದೇಶಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿ ಗುರಿಗಳು (SDGs), "ಈಗ ಮತ್ತು ಭವಿಷ್ಯದಲ್ಲಿ ಜನರು ಮತ್ತು ಗ್ರಹಕ್ಕೆ ಶಾಂತಿ ಮತ್ತು ಸಮೃದ್ಧಿಗಾಗಿ ಹಂಚಿಕೆಯ ನೀಲನಕ್ಷೆಯಾಗಿ" ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತವೆ. ಇದರಲ್ಲಿ, ನಾವು ಈ ಕೆಳಗಿನವುಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತೇವೆ
</p>
{/*  
<p className="goalpoints">
<span>ಗುರಿ 1: </span>ಹಂಚಿಕೆ ಮತ್ತು ಜೀವನ ಮಾದರಿ ಪರಿಕಲ್ಪನೆಗಳ ಪ್ರಚಾರದ ಮೂಲಕ ಎಲ್ಲಾ ರೀತಿಯ ಬಡತನವನ್ನು ನಿರ್ಮೂಲನೆ ಮಾಡಿ.<br />
<span>ಗುರಿ 2: </span>ಆಹಾರ ಸುಸ್ಥಿರತೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ ಶೂನ್ಯ ಹಸಿವನ್ನು ಸಾಧಿಸಿ.<br />
<span>ಗುರಿ 3: </span>ಸಾವಯವ ಆಹಾರ ಸೇವನೆಯ ಪ್ರಚಾರದ ಮೂಲಕ ಆರೋಗ್ಯ ಸಮೃದ್ಧಿಯನ್ನು ಪೋಷಿಸುವುದು ಮತ್ತು ಸಮಗ್ರ ಯೋಗಕ್ಷೇಮಕ್ಕಾಗಿ ಮಕ್ಕಳು, ಪೋಷಕರು ಇತ್ಯಾದಿಗಳಿಗೆ ಪ್ರವೇಶಿಸಬಹುದಾದ ಕ್ರೀಡಾ ಕ್ಲಬ್‌ಗಳನ್ನು ಒದಗಿಸುವುದು. <br />
<span>ಗುರಿ 4: </span>ಎಲ್ಲಾ ಹಂತದ ವಿದ್ಯಾರ್ಥಿಗಳು ಪ್ಲಾನ್ ಎ ಮತ್ತು ಪ್ಲಾನ್ ಬಿ ಪರಿಕಲ್ಪನೆಗಳನ್ನು ಗ್ರಹಿಸುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವ ಮೂಲಕ ಶಿಕ್ಷಣವನ್ನು ಹೆಚ್ಚಿಸಿ.<br />
<span>ಗುರಿ 5: </span>ಕುಟುಂಬದ ಸ್ವಾಸ್ಥ್ಯಕ್ಕಾಗಿ ಪರಸ್ಪರ ಗೌರವವನ್ನು ಒತ್ತಿಹೇಳುವ ಮೂಲಕ ಲಿಂಗ ಸಮಾನತೆ ಮತ್ತು ಮಹಿಳಾ ಸಬಲೀಕರಣಕ್ಕಾಗಿ ಪ್ರತಿಪಾದಿಸಿ.<br />
<span>ಗುರಿ 6: </span>ಜಾಗತಿಕವಾಗಿ ಪರಿಸರದ ಪರಿಣಾಮಗಳನ್ನು ಕಡಿಮೆ ಮಾಡಲು ನೀರು ಮತ್ತು ನೈರ್ಮಲ್ಯವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.<br /></p> */}
</div>
</section>
      {/* <h4>Zha Sustainability Enabler Framework</h4>
      <div className="img-align-ce">
        <img src="../../img/homepa.png"></img>
      </div> */}





      <h1 id="About-head">Our Offerings</h1>
      <h3>Contributing towards Sustainability Social Services</h3>


      <div className="listprimary">
        <div className="listprimarylist">
          <a href="">  <NavLink to="/Rural-Primary-Health-Care"><img src="../../img/primary/1.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"><img src="../../img/primary/2.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Disaster-Relief"><img src="../../img/primary/3.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Environment"><img src="../../img/primary/4.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/primary/9.png"></img></NavLink></a>

        </div>

        <div className="listprimarylist">
          <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/primary/11.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students"> <img src="../../img/primary/6.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Pathfinder-Course">  <img src="../../img/primary/7.png"></img></NavLink></a>
          <a href="">  <NavLink to="/zha-farmers-transformation"> <img src="../../img/primary/8.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/primary/10.png"></img></NavLink></a>
        </div>

      </div>

      <div className="listsecondary">
        <Carousel>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Rural-Primary-Health-Care"> <img src='../../img/primary/1.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"> <img src='../../img/primary/2.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Disaster-Relief"> <img src='../../img/primary/3.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Environment">  <img src='../../img/primary/4.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">  <img src='../../img/primary/6.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Pathfinder-Course"> <img src='../../img/primary/7.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/zha-farmers-transformation"> <img src='../../img/primary/8.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/primary/9.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/primary/10.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/primary/11.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>



      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recognition & Awards</h4>
      </div>

      <div className="feedback">
        <Awards />
      </div>





      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recent Events</h4>
      </div>
      <div className="feedback">
        <Recent />
      </div>

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Feedback</h4>
      </div>

      <div className="feedback">
        <Feedback />
      </div>



      <Video />


      {/* 
<section>
<div className="whoweare">
        <div className="bar"></div>
        <h4>Testimonial</h4>
    </div>
</section>

 
<div className="videoplayerr">
        <div className="ovall-container">
      <video className="ovall-video"  loop  controls>
     
        <source src={'../img/testimonial.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div> */}


    </div>

  );
}



