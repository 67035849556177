import React from "react";
import "./Socialservices.css"
import { NavLink } from "react-router-dom";
export default function Socialservices(){

    return(

        <div>
            <div className="social-main">
            <div  id="language" >
        
        <a href=""><NavLink to="/Socialservices">English</NavLink></a>

        </div>
            <h1>Social Service</h1>
            </div>
            <div className="social-content">

        <div className="social-box">
        <img src="../../img/leaf.webp"></img>
        <h2>స్థిరత్వం
</h2>
        <p>
        భవిష్యత్ తరాల కోసం గ్రహాన్ని రక్షించడానికి ఝా ఫౌండేషన్ సుస్థిరతకు ప్రాధాన్యత ఇస్తుంది. వారు శక్తి, వ్యవసాయం, రవాణా మరియు నిర్మాణంలో స్థిరమైన పరిష్కారాలను అమలు చేస్తారు. సుస్థిరత బాధ్యతాయుతమైన వనరుల వినియోగం, పునరుత్పాదక శక్తి, వ్యర్థాల తగ్గింపు మరియు ప్రకృతితో సామరస్యాన్ని ప్రోత్సహిస్తుంది. ప్రజలు మరియు పర్యావరణం సమతుల్యతతో అభివృద్ధి చెందే స్థితిస్థాపకమైన, పర్యావరణ అనుకూల ప్రపంచాన్ని సృష్టించడం ఈ ఫౌండేషన్ లక్ష్యం. అలాగే, గ్రామీణ మరియు సబర్బన్ ప్రాంతాలలో ఉపాధిని సృష్టించడం. అత్యాశ లేని జీవనం కోసం ఆరోగ్యకరమైన సమాజాన్ని సృష్టించడం.
           

        </p>
        </div>
       
        <div className="social-box">
        <img src="../../img/heal.webp"></img>
        <h2>ఆరోగ్య శిబిరాలు </h2>
        <p>
        ఝా ఫౌండేషన్ ప్రాథమిక ఆరోగ్య సంరక్షణను పొందేందుకు వెనుకబడిన వారి సవాళ్లను గుర్తిస్తుంది. అందువల్ల మేము భౌగోళిక ప్రాంతాలలో ఉచిత మల్టీస్పెషాలిటీ హెల్త్ క్యాంపులను నిర్వహిస్తాము, ఇందులో వైద్యునిచే క్షుణ్ణంగా తనిఖీ చేయడం, బిపి, బ్లడ్ షుగర్ మరియు హేమోగ్లోబిన్ (హెచ్‌బి) పర్యవేక్షణ వంటి ప్రత్యేక పరీక్షలతో పాటు ఉచిత మందుల పంపిణీ ఉంటుంది. ఈ సమయానుకూల జోక్యాలు వ్యాధిగ్రస్తత మరియు మరణాల సంభావ్యతను తగ్గించడం ద్వారా ముందస్తు రోగ నిర్ధారణ మరియు చికిత్సను అందిస్తాయి. ఇటువంటి ఆరోగ్య శిబిరాలు అట్టడుగు వర్గాల తక్షణ ఆరోగ్య సంరక్షణ అవసరాలను పరిష్కరిస్తాయి
            </p>
        </div>
        <div className="social-box">
        <img src="../../img/youth.webp"></img>
        <h2>యువత ఆరోగ్యం కోసం క్రీడలను సులభతరం చేయండి </h2>

        <p>
        ప్రతి ఒక్కరికీ మరియు ముఖ్యంగా పిల్లలు మరియు యువత ఫిట్‌గా ఉండటానికి, నైపుణ్యాలను పెంపొందించుకోవడానికి, స్నేహితులను సంపాదించడానికి మరియు భావోద్వేగ ఎత్తులు మరియు అల్పాలను నిర్వహించడం నేర్చుకోవడంలో క్రీడ సహాయపడుతుంది. మంచి రోల్ మోడల్‌గా ఉండటం, సానుకూల దృక్పథాన్ని ప్రోత్సహించడం మరియు ఆసక్తిని కనబరచడం ద్వారా మీ బిడ్డ మంచి క్రీడలో సహాయపడండి. గెలుపు ఓటములపై ​​కాకుండా కృషి, భాగస్వామ్యం మరియు వినోదంపై దృష్టి పెట్టండి. పిల్లలు క్రీడలు ఆడకూడదనుకుంటే, వారికి శారీరక శ్రమ కోసం అనేక ఇతర ఎంపికలు ఉన్నాయి. ఝా సబర్బన్ మరియు గ్రామీణ ప్రభుత్వ పాఠశాలల పిల్లలకు క్రీడా పోటీలు మొదలైన వాటిపై వేదికను కలిగి ఉండటానికి ప్రతి ఒక్కరికి సహాయం చేయడానికి వివిధ పోటీలను నిర్వహిస్తారు.
            </p>
        </div>
            </div>
            <div className="goals">
                <h1 >మాసుస్థిరత లక్ష్యాలు <sapn className="goalsspan"></sapn></h1>
                <div className="susgoal">
                 <div>
                 <img src="../../img/sustainabilitygoals.webp"></img>
                    </div>   
                    <p>
                <ul>
        <li> <span>పేదరికం లేదు:</span> పేదరికాన్ని అన్ని రకాలుగా అంతం చేయండి మరియు పేదలు మరియు బలహీనవర్గాలకు సామాజిక రక్షణ కల్పించండి.</li>
        <li><span>శూన్య ఆకలి: </span>  ఆహార భద్రతను సాధించడం, పోషకాహారాన్ని మెరుగుపరచడం మరియు స్థిరమైన వ్యవసాయాన్ని ప్రోత్సహించడం.</li>
        <li><span>మంచి ఆరోగ్యం మరియు శ్రేయస్సు:  </span> ఆరోగ్యకరమైన జీవితాలను నిర్థారించండి మరియు అన్ని వయసుల వారందరికీ శ్రేయస్సును ప్రోత్సహించండి.</li>
        <li> <span>నాణ్యమైన విద్య:</span>  సమగ్రమైన మరియు సమానమైన నాణ్యమైన విద్యను నిర్ధారించండి మరియు అందరికీ జీవితకాల అభ్యాస అవకాశాలను ప్రోత్సహించండి.</li>
        <li> <span>లింగ సమానత్వం: </span> లింగ సమానత్వాన్ని సాధించండి మరియు మహిళలు మరియు బాలికలందరికీ సాధికారత కల్పించండి.
</li>
            </ul>
                </p>

                </div>
            </div>
        </div>
    
    );
}