import react from "react";
import React, { useState } from 'react';
import './memebrsjip.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
 


export default function Health() {




    
    return (
        <div>
            <div className="hmain">
                <h1 id="mhead">Zha Sustainability Practitioners Club Memberships
</h1>

                <div className='second-mcontent'>
                    <img className="medalheroimg" src='../../img/member.jpg'></img>
                </div>

 
            </div>


            <section className="visionmi">
            <p className="medalparagraph">Join the ZHA Sustainability Practitioners Club and unlock a world of benefits! Our membership offers exclusive access to knowledge, networking opportunities, recognition, professional development, and impactful engagement. Stay informed, connect with industry leaders, enhance your reputation, develop your skills, and make a real difference in driving positive social and environmental change. Become a member today and be part of the movement towards a sustainable future!</p>
                <div className="purpose">       

                    {/* <p>The membership model of the ZHA Sustainability Practitioners Club offers various levels of participation: */}



                
                </div>

                
                <h2>The membership model of the ZHA Sustainability Practitioners Club offers various levels of participation:</h2>
  

                <Container  className="medalcards-container">
    <Row>
        <Col>
        <div className="medalcards">
   
        <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/icon/platinum.png" />
      <Card.Body>
        <Card.Title>Platinum Level Society Development Contributor</Card.Title>
        <Card.Text>
        This tier represents the highest level of commitment and contribution to societal development through sustainability initiatives. Members at this level may enjoy exclusive benefits such as VIP access to events, personalized consultations with sustainability experts, and recognition as leaders in the field.
        </Card.Text>
        <Button variant="primary" href="https://wa.link/gg77d5">Contribute</Button>
      </Card.Body>
</Card>
 
</div>
        </Col>




        <Col>
        
        <div className="medalcards">
<Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/icon/gold.png" />
      <Card.Body>
        <Card.Title>Gold Level Society Development Contributor</Card.Title>
        <Card.Text>
        Gold-level members are recognized for their significant contributions to sustainability efforts. They may receive perks such as priority registration for workshops and seminars, networking opportunities with industry leaders, and access to premium educational resources on sustainability practices.
        </Card.Text>
        <Button variant="primary" href="https://wa.link/tqa1ml">Contribute</Button>
      </Card.Body>
</Card>
</div>
        </Col>

        <Col>

        <div className="medalcards">
<Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/icon/silver.png" />
      <Card.Body>
        <Card.Title>Silver Level Society Development Contributor</Card.Title>
        <Card.Text>
        Silver-level members are valued contributors to sustainability initiatives. They may receive benefits such as discounted rates for events and training programs, access to online forums and discussion groups, and regular updates on the latest trends and developments in sustainability.
        </Card.Text>
        <Button variant="primary" href="https://wa.link/vhri3w">Contribute</Button>
      </Card.Body>
</Card>
</div>
        </Col>

        <Col>
        <div className="medalcards">
        <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/icon/bronze.png" />
      <Card.Body>
        <Card.Title>Bronze Level Society Development Contributor</Card.Title>
        <Card.Text>
        Bronze-level members are essential supporters of sustainability efforts. They may receive benefits such as access to curated content and resources on sustainability topics, invitations to local networking events, and recognition for their contributions to the club.
        </Card.Text>
        <Button variant="primary" href="https://wa.link/lz0poy">Contribute</Button>
       
  

      </Card.Body>
</Card>
</div>
        </Col>
      </Row>
      
    </Container>
              


 

 

            </section>


































 
























            <section className="visionmi">
            <Container className="benefits-table">
      <h2 className="medaltitle">Benefits of Contributing Through Membership Subscription Fees</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Membership Level</th>
            <th>Benefits</th>
             
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Platinum Level Society Development Contributor</td>
            <td>- VIP Access to Events<br />- Personalized Consultations with Sustainability Experts<br />- Recognition as Leaders in the Field</td>
          </tr>

          <tr>
            <td>Gold Level Society Development Contributor</td>
            <td>- Priority Registration for Workshops and Seminars<br />- Networking Opportunities with Industry Leaders<br />- Access to Premium Educational Resources</td>
          </tr>

          <tr>
            <td>Silver Level Society Development Contributor</td>
            <td>- Discounted Rates for Events and Training Programs<br />- Access to Online Forums and Discussion Groups<br />- Regular Updates on Sustainability Trends</td>
          </tr>

          <tr>
            <td>Bronze Level Society Development Contributor</td>
            <td>- Access to Curated Content and Resources on Sustainability Topics<br />- Invitations to Local Networking Events<br />- Recognition for Contributions to the Club</td>
          </tr>
          {/* Similarly add rows for Gold, Silver, and Bronze levels */}
        </tbody>
      </Table>
    </Container>

    </section>




{/* <section className="visionmi">

<div className="purpose">       
<h4>Benefits</h4>
<p>Benefits of contributing through membership subscription fees may include:

</p>
</div>





<ol className="goalpoints">
<li><span>Access to Knowledge : </span>Members gain access to a wealth of knowledge, resources, and expertise in sustainability practices, helping them stay informed and up-to-date on industry trends and best practices.


</li>
<li><span>Networking Opportunities : </span>Membership provides opportunities to connect and collaborate with like-minded professionals, fostering partnerships and alliances that can drive meaningful change in sustainability efforts.


</li>
<li><span>Recognition and Visibility : </span>Members receive recognition for their contributions to societal development through sustainability initiatives, enhancing their reputation and credibility within the industry.

</li>
<li><span>Professional Development : </span>Membership offers opportunities for professional development through workshops, seminars, and training programs focused on sustainability topics, enabling members to enhance their skills and expertise in the field.
</li>

<li><span>Impactful Engagement : </span>By contributing through membership fees, members directly support the ZHA Sustainability Practitioners Club's mission and initiatives, playing a vital role in advancing sustainability practices and driving positive social and environmental change.

</li>


</ol>
 
</section> */}




<section className="visionmi">

<div className="purpose">       
<h2 className="medaltitle">Outcome</h2>
 
</div>





<ol className="goalpoints">
<li><span>Access to Knowledge : </span>Gain access to a wealth of knowledge, resources, and expertise in sustainability practices, helping you stay informed and up-to-date on industry trends and best practices.

</li>
<li><span>Networking Opportunities : </span>Connect and collaborate with like-minded professionals, fostering partnerships and alliances that can drive meaningful change in sustainability efforts.


</li>
<li><span>Recognition and Visibility : </span>Receive recognition for your contributions to societal development through sustainability initiatives, enhancing your reputation and credibility within the industry.
</li>
<li><span>Professional Development : </span>Take advantage of opportunities for professional development through workshops, seminars, and training programs focused on sustainability topics, enabling you to enhance your skills and expertise in the field.
</li>

<li><span>Impactful Engagement : </span>Contribute directly to the ZHA Sustainability Practitioners Club's mission and initiatives by supporting them through membership fees, playing a vital role in advancing sustainability practices and driving positive social and environmental change.

</li>


</ol>
 
</section>

 
        </div>


    );
}

