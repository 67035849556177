import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">



            <div  id="language" >
        
        <a href=""><NavLink to="/Environment">English</NavLink></a>

        </div>


                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ఝా ఫౌండేషన్: గ్రీనర్ రేపటిని పెంపొందించడం</h1>

                <div className='second-mcontent'>
                <img src='../../img/environment.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>ఝా ఫౌండేషన్‌కు స్వాగతం, ఇక్కడ మేము ప్రస్తుత మరియు భవిష్యత్తు తరాల కోసం పర్యావరణాన్ని రక్షించడం పట్ల మక్కువ చూపుతున్నాము. పర్యావరణ పరిరక్షణ పట్ల మన నిబద్ధత ప్రతి వ్యక్తి మరియు సమాజం మనం ఇల్లు అని పిలుస్తున్న గ్రహాన్ని సంరక్షించడంలో కీలక పాత్ర పోషిస్తుందనే నమ్మకంతో నడపబడుతుంది.
                    </p>
                </div>

                <h3>మా దృష్టి</h3>
                <div className="missi">
                    <h4 className="toleftneed">సుస్థిర భవిష్యత్తు:</h4>
                    <p>ఝా ఫౌండేషన్‌లో, మానవత్వం ప్రకృతితో సామరస్యపూర్వకంగా సహజీవనం చేసే ప్రపంచాన్ని మేము ఊహించాము. మన దృష్టి స్థిరత్వంలో పాతుకుపోయింది, ఇక్కడ పర్యావరణ వ్యవస్థలు వృద్ధి చెందుతాయి, జీవవైవిధ్యం రక్షించబడుతుంది మరియు ప్రతి చర్య ఆరోగ్యకరమైన గ్రహానికి దోహదం చేస్తుంది.</p>


                </div>


                <h3>మా మిషన్</h3>
                <div className="missi">
                    <h4 className="toleftneed">సాధికారత మార్పు:</h4>
                    <p>వ్యక్తులు మరియు సంఘాలను పర్యావరణ పరిరక్షకులుగా మార్చడం మా లక్ష్యం. విద్య, న్యాయవాద మరియు ప్రయోగాత్మక కార్యక్రమాల ద్వారా, మేము బాధ్యతాయుతమైన మరియు స్థిరమైన అభ్యాసాలకు సమిష్టి నిబద్ధతను పెంపొందించుకోవాలని లక్ష్యంగా పెట్టుకున్నాము.</p>
 
                </div>

                <h3>మా చొరవ</h3>
                <div className="missi">
                    <h4 className="toleftneed">పరిరక్షణ మరియు పునరుద్ధరణ:</h4>
                    <p>ఝా ఫౌండేషన్ పరిరక్షణ మరియు పునరుద్ధరణ ప్రాజెక్టులలో చురుకుగా పాల్గొంటుంది. అటవీ నిర్మూలన ప్రయత్నాల నుండి అంతరించిపోతున్న జాతులను రక్షించడం వరకు, మా కార్యక్రమాలు భూమిపై జీవాన్ని నిలబెట్టే సహజ ఆవాసాలను సంరక్షించడం మరియు పునరుద్ధరించడంపై దృష్టి సారిస్తున్నాయి.</p>

                    <h4 className="toleftneed">వ్యర్థాలను తగ్గించే కార్యక్రమాలు:</h4>
                    <p>మా పర్యావరణ పాదముద్రను తగ్గించడం యొక్క ప్రాముఖ్యతను మేము విశ్వసిస్తున్నాము. మా వ్యర్థాల తగ్గింపు కార్యక్రమాలు పర్యావరణంపై మానవ కార్యకలాపాల ప్రభావాన్ని తగ్గించడానికి బాధ్యతాయుతమైన వ్యర్థాల నిర్వహణ, రీసైక్లింగ్ మరియు అప్‌సైక్లింగ్‌ను ప్రోత్సహిస్తాయి.</p>

                    <h4 className="toleftneed">గ్రీన్ ఎనర్జీ అడ్వకేసీ:</h4>
                    <p>పునరుత్పాదక శక్తికి మారడం అనేది వాతావరణ మార్పులను తగ్గించడంలో కీలకమైన అంశం. ఝా ఫౌండేషన్ గ్రీన్ ఎనర్జీ సొల్యూషన్స్ యొక్క స్వీకరణ కోసం వాదిస్తుంది మరియు మద్దతు ఇస్తుంది, ఇది మరింత స్థిరమైన మరియు స్వచ్ఛమైన శక్తి ల్యాండ్‌స్కేప్‌కు దోహదం చేస్తుంది.</p>
                </div>


                <h3>మన విధానం</h3>
                <div className="missi">
                    <h4 className="toleftneed">విద్య మరియు అవగాహన:</h4>
                    <p>జ్ఞానం మార్పుకు శక్తివంతమైన ఉత్ప్రేరకం. పర్యావరణ సమస్యలు, వాతావరణ మార్పులు మరియు స్థిరమైన జీవనం గురించి అవగాహన పెంచే విద్యా కార్యక్రమాలలో మేము పెట్టుబడి పెడతాము. సమాచారంతో వ్యక్తులకు సాధికారత కల్పించడం ద్వారా, మేము అర్థవంతమైన చర్యను ప్రేరేపించగలమని మేము విశ్వసిస్తున్నాము.</p>

                    <h4 className="toleftneed">కమ్యూనిటీ ఎంగేజ్‌మెంట్:</h4>
                    <p>పర్యావరణ పరిరక్షణ అనేది సమష్టి కృషి. మేము పర్యావరణ కార్యక్రమాలలో చురుకైన భాగస్వామ్యాన్ని ప్రోత్సహిస్తూ స్థానిక సంఘాలతో నిమగ్నమై ఉంటాము. సంఘం నేతృత్వంలోని ప్రాజెక్ట్‌లు మరియు ఈవెంట్‌ల ద్వారా, మేము పర్యావరణ ఛాంపియన్‌ల నెట్‌వర్క్‌ను నిర్మించాలని లక్ష్యంగా పెట్టుకున్నాము.</p>

                     
                </div>

            </section>
        </div>


    );
}

