import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
   <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Family-Wellness-Program">English</NavLink></a>

        </div>
         
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>झा परिवार कल्याण कार्यक्रम: सामंजस्यपूर्ण जीवन के लिए सक्रिय परामर्श</h1>

<div className='second-mcontent'>
<img src='../../img/membershipwellness.webp'></img>
                </div>
 
 


 
<h3>उद्देश्य:</h3>
<p>झा फैमिली वेलनेस प्रोग्राम एक व्यापक पहल है जिसे तनाव, चिंता और तर्क-वितर्क को रोकने के लिए सक्रिय परामर्श प्रदान करके सामंजस्यपूर्ण जीवन को बढ़ावा देने के लिए डिज़ाइन किया गया है। यह कार्यक्रम पतियों, पत्नियों और बच्चों की भावनात्मक भलाई को मजबूत करने, एक साथ चुनौतियों से निपटने में सक्षम एक लचीली परिवार इकाई को बढ़ावा देने के लिए तैयार किया गया है।
</p>

 

<ol className="goalpoints">
    <h3>कार्यक्रम घटक:</h3>

<li><span>सक्रिय परिवार परामर्श सत्र: </span>  
<ul>
    <li>व्यक्तिगत और सामूहिक तनावों के समाधान के लिए पतियों, पत्नियों और बच्चों के लिए विशेष परामर्श सत्र।



</li>
    <li>प्रभावी संचार बनाने, एक-दूसरे के दृष्टिकोण को समझने और पारिवारिक गतिशीलता को बढ़ाने पर जोर दिया गया।



</li>
</ul>
</li> 

<li><span>समग्र कल्याण कार्यशालाएँ: </span>  
<ul>
    <li>तनाव प्रबंधन, संघर्ष समाधान और भावनात्मक लचीलेपन को बढ़ावा देने सहित कई विषयों को कवर करने वाली अनुकूलित कार्यशालाएँ।




</li>
    <li>पूरे परिवार के लिए एक साथ भाग लेने और सीखने के लिए इंटरैक्टिव सत्र।




</li>
</ul>
</li> 

<li><span>पारिवारिक जुड़ाव गतिविधियाँ:</span>  
<ul>
    <li>ऐसी गतिविधियाँ शामिल करें जो पारिवारिक बंधन और टीम वर्क को बढ़ावा दें, एकता की भावना को मजबूत करें।

</li>
    <li>पारिवारिक संबंधों को मजबूत करने के लिए बाहरी कार्यक्रम, रचनात्मक कार्यशालाएँ और सहयोगी परियोजनाएँ।




</li>
</ul>
</li> 

<li><span>व्यक्तिगत कल्याण योजनाएँ: </span>  
<ul>
    <li>परिवार के प्रत्येक सदस्य के लिए उनकी विशिष्ट आवश्यकताओं और चुनौतियों के आधार पर वैयक्तिकृत कल्याण योजनाएँ विकसित की गईं।
</li>
    <li>परिस्थितियाँ विकसित होने पर इन योजनाओं को लागू करने और समायोजित करने के लिए निरंतर समर्थन।
</li>
</ul>
</li> 

<li><span>पालन-पोषण सहायता और शिक्षा: </span>  
<ul>
    <li>माता-पिता के पालन-पोषण कौशल को बढ़ाने के लिए विशेष रूप से डिज़ाइन की गई कार्यशालाएँ और संसाधन।
</li>
    <li>बच्चों के साथ प्रभावी संचार, सकारात्मक अनुशासन रणनीतियों और एक पोषणयुक्त घरेलू वातावरण को बढ़ावा देने पर मार्गदर्शन।
</li>
</ul>
</li> 
<li><span>युवा सशक्तिकरण सत्र: </span>  
<ul>
    <li>बच्चों और किशोरों के सामने आने वाली अनूठी चुनौतियों के समाधान के लिए समर्पित विशेष परामर्श सत्र और कार्यशालाएँ।
</li>
    <li>खुले संवाद को प्रोत्साहन और मुकाबला तंत्र का विकास।
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>फ़ायदे:</h3>
<ul className="goalpoints">
  <li>प्रभावी संचार और समझ के माध्यम से पारिवारिक बंधन मजबूत हुए।</li>  
  <li>परिवार में तनाव, चिंता और वाद-विवाद में कमी।</li>  
  <li>माता-पिता और बच्चों दोनों के लिए भावनात्मक लचीलापन बढ़ाया।</li>  
  <li>परिवार इकाई के भीतर टीम वर्क और समस्या-समाधान कौशल का विकास।</li>  
  <li>सकारात्मक और सहायक घरेलू वातावरण को बढ़ावा देना।
</li>  
 
</ul>


<h3>कैसे भाग लें:</h3>
<ul className="goalpoints">
  <li>झा परिवार कल्याण कार्यक्रम में रुचि रखने वाले परिवार अपनी समर्पित परामर्श सेवाओं हॉटलाइन या वेबसाइट के माध्यम से झा फाउंडेशन तक पहुंचकर नामांकन कर सकते हैं।</li>  
  <li>भागीदारी स्वैच्छिक, गोपनीय है और समग्र कल्याण के लिए सक्रिय समर्थन चाहने वाले परिवारों के लिए खुली है।</li>  
 
 
</ul>
     
       
       <p>झा फैमिली वेलनेस प्रोग्राम को परिवारों को एक एकजुट टीम के रूप में जीवन की चुनौतियों से निपटने के लिए सशक्त बनाने, सभी सदस्यों के लिए एक स्वस्थ और सहायक वातावरण को बढ़ावा देने के लिए डिज़ाइन किया गया है। इस कार्यक्रम पर प्रतिक्रिया या सुधार के लिए सुझावों का स्वागत है।</p>
       
       
        </div>


        </div>




 
        


    );
}

