import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    ನಮ್ಮ ಫೌಂಡೇಶನ್ ಲಾಭರಹಿತ ಸಂಸ್ಥೆಯಾಗಿದ್ದು, ಕೃಷಿ ವ್ಯವಹಾರದಲ್ಲಿ ಉಚಿತವಾಗಿ ಡೈನಾಮಿಕ್ ಕನ್ಸಲ್ಟಿಂಗ್ ಮೂಲಕ ಭಾರತೀಯ ರೈತರಿಗೆ ಸಹಾಯ ಮಾಡಲು, ರೈತರಿಗೆ ಮೌಲ್ಯವರ್ಧಿತ ಪ್ರಯೋಜನಗಳನ್ನು ನೀಡಲು ಮತ್ತು ವಿನಂತಿಗಳ ಆಧಾರದ ಮೇಲೆ ಅವರ ಹೊಲಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಪ್ರಾರಂಭಿಸಲಾಗಿದೆ. ಆದ್ದರಿಂದ ನಾವು ನಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಮೌಲ್ಯವನ್ನು ತಲುಪಿಸುವ ಉತ್ಪನ್ನವನ್ನು ಆವಿಷ್ಕರಿಸಬೇಕು ಎಂದು ನಾವು ನೋಡಿದ್ದೇವೆ.
                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     ನಾವು ಸುಸ್ಥಿರ ಮತ್ತು ಅಂತರ್ಗತ ಬೆಳವಣಿಗೆಯನ್ನು ವೇಗಗೊಳಿಸುತ್ತೇವೆ ಮತ್ತು ದಕ್ಷತೆಯನ್ನು ಚಾಲನೆ ಮಾಡುವ ಮೂಲಕ, ಬೆಳವಣಿಗೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ, ಕೈಗಾರಿಕೆಗಳನ್ನು ರೂಪಿಸುವ ಮತ್ತು ಸಾಂಸ್ಥಿಕ ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ಹೆಚ್ಚಿಸುವ ಮೂಲಕ ಆಹಾರ ಮತ್ತು ಕೃಷಿ ಕಂಪನಿಗಳು ಮತ್ತು ಸಂಸ್ಥೆಗಳಿಗೆ ಪರಿವರ್ತನೆಯ ಪರಿಣಾಮವನ್ನು ನೀಡುತ್ತೇವೆ. ನಮ್ಮ ಸ್ವಾಮ್ಯದ ಪರಿಕರಗಳು ಮತ್ತು ಡಿಜಿಟಲ್ ಮತ್ತು ಸುಧಾರಿತ ವಿಶ್ಲೇಷಣಾ ಸಾಮರ್ಥ್ಯಗಳು, ವ್ಯಾಪಕವಾದ ಅನುಭವದೊಂದಿಗೆ ಸೇರಿ ನಾವು ನಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ನಾವು ತರುವ ಮೌಲ್ಯದ ಮೂಲಾಧಾರಗಳಾಗಿವೆ.
     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     ಝಾ ಫೌಂಡೇಶನ್ ಬಹು-ಶಿಸ್ತಿನ ಸಂಶೋಧನೆ, ಸಾಮರ್ಥ್ಯ ವರ್ಧನೆ, ಸ್ವಯಂ ಸುಧಾರಣೆಗಳಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿದೆ ಮತ್ತು ಯುವ ಮನಸ್ಸು ಮತ್ತು ಹೃದಯದ ಆರೋಗ್ಯಕ್ಕೆ ಸಹಾಯ ಮಾಡುವ ಅಭಿಯಾನಗಳನ್ನು ಕೈಗೊಳ್ಳುತ್ತದೆ. ಇಡೀ ಪೀಳಿಗೆಯು ಹೆಚ್ಚು ಆತಂಕಕ್ಕೊಳಗಾಗುತ್ತದೆ, ಹೆಚ್ಚು ದುರ್ಬಲವಾಗಿರುತ್ತದೆ, ಹೆಚ್ಚು ಖಿನ್ನತೆಗೆ ಒಳಗಾಗುತ್ತದೆ. ಅವರು ಅಪಾಯಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಲ್ಲಿ ಕಡಿಮೆ ಆರಾಮದಾಯಕರಾಗಿದ್ದಾರೆ. ಇದು ಒಂದು ಪೀಳಿಗೆಯಲ್ಲಿ ನಿಜವಾದ ಬದಲಾವಣೆಯಾಗಿದೆ. ಆದ್ದರಿಂದ, ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ, ಪ್ರೀತಿ, ಭರವಸೆ, ಸಾಂಸ್ಕೃತಿಕ ಗುರುತು, ವಿಮರ್ಶಾತ್ಮಕ ಪ್ರಜ್ಞೆ, ಸ್ವ-ನಿರ್ಣಯ ಮತ್ತು ಕೃಷಿ ಸುಧಾರಣೆಗಳ ಕೆಲಸದ ವಿಧಾನಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಕ್ಷೇಮವನ್ನು ಅಳೆಯುವ ಮಾರ್ಗಗಳನ್ನು ನಾವು ಅಭಿವೃದ್ಧಿಪಡಿಸುತ್ತಿದ್ದೇವೆ.
     </p>
 </div>

            </div>
            

          

            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">ಆಪರೇಟಿಂಗ್ ಮಾಡೆಲ್</h2>
  {/* <h4>Operating Model</h4> */}
  <p>ಭವಿಷ್ಯದ ಪೀಳಿಗೆಗೆ ಸುಸ್ಥಿರ ಜೀವನವನ್ನು ಸೃಷ್ಟಿಸಲು ಮತ್ತು ಗ್ರಹವನ್ನು ಸಂರಕ್ಷಿಸಲು, ಝಾ ಸಸ್ಟೈನಬಿಲಿಟಿ ಪ್ರೊಫೆಷನಲ್ಸ್ ಟವರ್/ಕ್ಲಬ್‌ನ ಧ್ಯೇಯ ಮತ್ತು ದೃಷ್ಟಿಗೆ ಹೊಂದಿಕೆಯಾಗಲು ಜಾಗತಿಕ ಆಸಕ್ತಿಗಳನ್ನು ನಿರ್ಣಯಿಸಲು ನಾವು ವಿವಿಧ ರೀತಿಯ ಮನಸ್ಸಿನ ಮಾನವರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಬೇಕಾಗುತ್ತದೆ.
</p>
<p>ಸುಸ್ಥಿರ ಜೀವನ ಎಂದರೆ ನಮ್ಮ ಮಾನವರ ಜೀವನಶೈಲಿಯ ಆಯ್ಕೆಗಳು ನಮ್ಮ ಸುತ್ತಲಿನ ಪ್ರಪಂಚದ ಮೇಲೆ ಹೇಗೆ ಪರಿಣಾಮ ಬೀರುತ್ತವೆ ಎಂಬುದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಮತ್ತು ಪ್ರತಿಯೊಬ್ಬರೂ ಉತ್ತಮವಾಗಿ ಮತ್ತು ಹಗುರವಾಗಿ ಬದುಕಲು ಮಾರ್ಗಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುವುದು. ZHA ಸಮರ್ಥನೀಯ ವೃತ್ತಿಪರರ ಮಂಡಳಿಯ ಸದಸ್ಯರ ನಮ್ಮ ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು ಸಕ್ರಿಯ, ಸಲಹಾ ಮತ್ತು ಸಾಕ್ಷಿ ಮಂಡಳಿ ಸದಸ್ಯತ್ವದಿಂದ ವಿಂಗಡಿಸಲಾಗಿದೆ. ಆದಾಗ್ಯೂ ಝಾ ಮಂಡಳಿಯ ಸದಸ್ಯರು ಈ ಕೆಳಗಿನ ನಾಲ್ಕು ಸ್ತಂಭಗಳಿಂದ ತಮ್ಮ ಸಮರ್ಥನೀಯತೆಯ ಕಾರಣದ ಆಸಕ್ತಿಯನ್ನು ಕಂಡುಕೊಳ್ಳಲು ನಿರ್ಬಂಧವನ್ನು ಹೊಂದಿರುತ್ತಾರೆ
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) ಮಾನವ ಕ್ಷೇಮ ಪಿಲ್ಲರ್</p>
<p>2) ಪ್ರಕೃತಿ ಕ್ಷೇಮ ಪಿಲ್ಲರ್  </p>
<p>3) ಭೂಮಿಯ ಕ್ಷೇಮ ಪಿಲ್ಲರ್</p>
<p>4) ಸ್ಥಿರವಾದ ಲಾಭದ ಪಿಲ್ಲರ್</p>
</div>
</span>
<p>ಸುಸ್ಥಿರತೆಯ ಕ್ಷೇಮದ ಮೇಲಿನ ನಾಲ್ಕು ಸ್ತಂಭಗಳಿಂದ, ಪ್ರತಿಯೊಬ್ಬ ಮಂಡಳಿಯ ಸದಸ್ಯರು ಭವಿಷ್ಯದ ಪೀಳಿಗೆಯ ಭೂಮಿ, ಪ್ರಕೃತಿ ಮತ್ತು ಮಾನವ ಸ್ವಾಸ್ಥ್ಯಕ್ಕಾಗಿ ಸುಸ್ಥಿರ ಜೀವನವನ್ನು ನಿರ್ಮಿಸಲು ಯಾವುದೇ ಗ್ಯಾಮಿಫೈಡ್ ಸಾಮಾಜಿಕ ಉಪಕ್ರಮಗಳನ್ನು ಕಂಡುಕೊಳ್ಳುತ್ತಾರೆ</p>
<p>ಆದ್ದರಿಂದ ಈ ಜೀವನದಲ್ಲಿ ಸುಸ್ಥಿರ ಜೀವನದ ಫಲಿತಾಂಶವನ್ನು ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿಯ ತತ್ವದ ಮೂಲಕ ನಿರ್ಧರಿಸಲಾಗುತ್ತದೆ, ಇದು ಪರಿಸರವನ್ನು ರಕ್ಷಿಸುವ ಮತ್ತು ಸಮಾನತೆಯನ್ನು ಬೆಂಬಲಿಸುವ ಸಾಮಾಜಿಕ ಮತ್ತು ಆರ್ಥಿಕ ಸುಧಾರಣೆಯನ್ನು ತಿಳಿಸುತ್ತದೆ ಮತ್ತು ಆದ್ದರಿಂದ ಆರ್ಥಿಕತೆ ಮತ್ತು ಸಮಾಜ ಮತ್ತು ಪರಿಸರ ವ್ಯವಸ್ಥೆಯು ಪರಸ್ಪರ ಅವಲಂಬಿಸಿದೆ.</p>
 
 
 


<p>ಝಾ ಫೌಂಡೇಶನ್ ಸಸ್ಟೈನಬಿಲಿಟಿ ವೃತ್ತಿಪರರ ಕ್ಲಬ್‌ಗೆ ಬೋರ್ಡ್ ಸದಸ್ಯತ್ವ ಚಂದಾದಾರಿಕೆ ಶುಲ್ಕವನ್ನು ಹೇಗೆ ಬಳಸಿಕೊಳ್ಳಲಾಗುತ್ತದೆ ಎಂಬುದರ ಕುರಿತು ಪಾರದರ್ಶಕವಾಗಿರುವುದು ಮುಖ್ಯವಾಗಿದೆ. ನಾವು ಸದಸ್ಯರಿಗೆ ತ್ರೈಮಾಸಿಕ ನಿಯಮಿತ ಹಣಕಾಸು ವರದಿಗಳು ಮತ್ತು ನವೀಕರಣಗಳನ್ನು ಒದಗಿಸುತ್ತೇವೆ ನಂಬಿಕೆಯನ್ನು ಬೆಳೆಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಮತ್ತು ಅವರು ತಮ್ಮ ಕೊಡುಗೆಗಳಿಗೆ ಪ್ರತಿಯಾಗಿ ಅವರು ಪಡೆಯುವ ಮೌಲ್ಯವನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ ಮತ್ತು ನಮ್ಮ ಉಪಕ್ರಮಗಳ ಪ್ರಗತಿ ಮತ್ತು ಸಾಧನೆಗಳನ್ನು ಅದೇ ವೇದಿಕೆಯಲ್ಲಿ ಆಚರಿಸುತ್ತಾರೆ</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">ಫಲಿತಾಂಶ - ಮೌಲ್ಯ</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿಯ ಅಪೇಕ್ಷಿತ ಫಲಿತಾಂಶಗಳು ಆರ್ಥಿಕ ಸಮೃದ್ಧಿ, ಪರಿಸರ ಸಂರಕ್ಷಣೆ, ಸಾಮಾಜಿಕ ಸಮಾನತೆ ಮತ್ತು ಬಡತನ ಕಡಿತವನ್ನು ಒಳಗೊಳ್ಳುತ್ತವೆ. ಈ ಫಲಿತಾಂಶಗಳನ್ನು ಸಾಧಿಸಲು ಸರ್ಕಾರಗಳು, ವ್ಯವಹಾರಗಳು ಮತ್ತು ವ್ಯಕ್ತಿಗಳಿಂದ ಸಾಮೂಹಿಕ ಪ್ರಯತ್ನದ ಅಗತ್ಯವಿದೆ. </li>
  <li>ಜಾ ಫೌಂಡೇಶನ್ ಎಲ್ಲಾ ಹಂತದ ಮಕ್ಕಳು ಮತ್ತು ವಯಸ್ಕರಿಗೆ ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ.</li>
  <li>ZHA ತಮ್ಮ ಸುಸ್ಥಿರತೆಯ ಮನಸ್ಥಿತಿಯನ್ನು ಬೆಳೆಸಲು, ವ್ಯಾಪಾರ ನೆಟ್‌ವರ್ಕ್‌ಗಳನ್ನು ಬೆಳೆಸಲು ಮತ್ತು ಜಗತ್ತಿನಾದ್ಯಂತ ಸಮಾನ ಮನಸ್ಕ ವೃತ್ತಿಪರರೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಲು ಅಮೂಲ್ಯವಾದ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ನೀವು ಈಗಷ್ಟೇ ಪ್ರಾರಂಭಿಸುತ್ತಿದ್ದೀರೋ ಅಥವಾ ವರ್ಷಗಳಿಂದ ವ್ಯಾಪಾರ ಮಾಡುತ್ತಿದ್ದೀರೋ, ಈ ಫೋರಮ್ ಒದಗಿಸುವ ಜ್ಞಾನ, ಬೆಂಬಲ ಮತ್ತು ಸಂಪರ್ಕಗಳಿಂದ ನೀವು ಪ್ರಯೋಜನ ಪಡೆಯಬಹುದು.</li>
  <li>ಝಾ ಫೌಂಡೇಶನ್ ಸಾಮಾಜಿಕ ಸೇವೆ + ವ್ಯಾಪಾರ + ಜ್ಞಾನ + ಸ್ನೇಹ ಮತ್ತು ಫೆಲೋಶಿಪ್ ಅನ್ನು ಆಧರಿಸಿದೆ ಅದು ಸುಸ್ಥಿರ ಜೀವನವನ್ನು ಸಂಪರ್ಕಿಸುತ್ತದೆ ಮತ್ತು ಜಗತ್ತಿನಾದ್ಯಂತ ಗುಣಮಟ್ಟದ ಜನರನ್ನು ಹುಡುಕುತ್ತದೆ
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">ಝಾ ರೂಪಾಂತರ ವಿಧಾನ</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}