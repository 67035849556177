import React, { useState } from 'react';
import GooglePayButton from '@google-pay/button-react';
import styles from './Gapy.module.css';

export default function Gapy() {
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [clickedButton, setClickedButton] = useState(null);

    const handleAmountButtonClick = (amount) => {
        setSelectedAmount(amount);
        setClickedButton(amount); // Set the clicked button amount
    };

    const handleDonateButtonClick = () => {
        // Perform action to initiate payment with selectedAmount
        console.log("Initiating payment of amount:", selectedAmount);
    };

    const upiId = 'zhafoundation2017@okhdfcbank';

    const handleCopyUPI = () => {
        navigator.clipboard.writeText(upiId)
            .then(() => {
                alert('UPI ID copied to clipboard');
            })
            .catch((error) => {
                console.error('Failed to copy UPI ID: ', error);
            });
    };

    return (
        <div className='backwalldonate'>
            <div className='backwalldonate'></div>
            <div className={styles.container}>
                <div id='backwall' className={styles.box}>
                    <h2>Donate to Zha Foundation</h2>
                    <input
                        type='number'
                        placeholder='Enter amount'
                        value={selectedAmount || ''}
                        onChange={(e) => setSelectedAmount(Math.max(100, parseInt(e.target.value)))}
                        // Ensure the entered value is at least 100
                    />
                    <div className={styles['amount-buttons']}>
                        <button
                            onClick={() => handleAmountButtonClick(300)}
                            className={clickedButton === 300 ? styles.clicked : ''}
                        >
                            <div>₹300</div>
                            <div className={styles['premium-text']}>Basic Membership - Monthly</div>
                        </button>
                        <button
                            onClick={() => handleAmountButtonClick(600)}
                            className={clickedButton === 600 ? styles.clicked : ''}
                        >
                            <div>₹600</div>
                            <div className={styles['premium-text']}>Professionals Board Membership - Monthly</div>
                        </button>
                        <button
                            onClick={() => handleAmountButtonClick(1000)}
                            className={clickedButton === 1000 ? styles.clicked : ''}
                        >
                            <div>₹1000</div>
                            <div className={styles['premium-text']}>Strategic Board Membership - Monthly</div>
                        </button>
                        <button
                            onClick={() => handleAmountButtonClick(10000)}
                            className={clickedButton === 10000 ? styles.clicked : ''}
                        >
                            <div>₹10,000</div>
                            <div className={styles['premium-text']}>NRI Board Membership - Yearly</div>
                        </button>
                    </div>
                    <GooglePayButton
    environment='PRODUCTION' // Change environment to PRODUCTION
    paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
            {
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA']
                },
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'example',
                        gatewayMerchantId: 'exampleGateway'
                    }
                }
            }
        ],
        merchantInfo: {
            merchantId: 'BCR2DN4T7WR4TXIY', // Replace with your Google Pay production merchant ID
            merchantName: 'Zha Foundation Charitable Trust'
        },
        transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'TOTAL',
            totalPrice: selectedAmount ? selectedAmount.toString() : '0',
            currencyCode: 'INR',
            countryCode: 'IN'
        },
        shippingAddressRequired: true,
        callbackIntents: ['PAYMENT_AUTHORIZATION']
    }}
    onLoadPaymentData={paymentRequest => {
        console.log(paymentRequest);
    }}
    onPaymentAuthorized={paymentData => {
        console.log(paymentData);
        return { transactionState: 'SUCCESS' };
    }}
    existingPaymentMethodRequired={false} // Remove quotes around false
    buttonColor='black'
    buttonType='donate'
    onClick={handleDonateButtonClick}
/>

                </div>
            </div>

            <div className='backwalldonate'></div>
            <div className={styles.container}>
                <div id='backwall' className={styles.box}>
                    <h2>Donate via Paytm/Google Pay/PhonePe</h2>
                    <div>
                        <p className='gpaytexts'>Scan the QR code from the app and make payment</p>
                        <img src="../img/gpay.png"></img>
                        <div>
                            <p className='gpaytexts'>UPI ID: {upiId}</p>
                            <button onClick={handleCopyUPI}>Copy UPI ID</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
