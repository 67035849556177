import React from 'react';
import './sustainabilitymebership.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/tamil-m">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/tamil-mma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/tamil-mhi">Hindi</NavLink></a>
        <a href=""><NavLink to="/tamil-mte">Telugu</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}

<div  id="language" >
        
                <a href=""><NavLink to="/Zha-Sustainability-Club-Membership">English</NavLink></a>



        </div>
       
        <h1 id="mhead">Zha Sustainability Board Membership</h1>
   
        <div className='second-mcontent'>
        <img src='../../img/membership.webp'></img>
            </div>


        <p className='tone'>तीन अलग-अलग झा बोर्ड सदस्यता प्रदान की जाती है,
</p>
                <span>
<div className='wellnesspoints'> 
<p>1) सक्रिय बोर्ड
</p>
<p>2) सलाहकार बोर्ड
 </p>
<p>3) साक्षी बोर्ड</p>
</div>
</span>

        <div className='second-mcontent'>
           
           
            <div>
             

           <p>यह स्थिरता में नवीनतम रुझानों और सर्वोत्तम प्रथाओं पर अपडेट रहने और दुनिया भर में समान विचारधारा वाले व्यक्तियों से जुड़ने का एक शानदार तरीका है। ध्यान रखें कि विभिन्न संगठनों के बीच विशिष्ट पेशकशें अलग-अलग हो सकती हैं, इसलिए सदस्यता कार्यक्रम चुनने से पहले विकल्पों पर शोध करना और तुलना करना एक अच्छा विचार है।

            </p>
<p>
एक सस्टेनेबिलिटी क्लब में शामिल होना एक सस्टेनेबिलिटी मानसिकता विकसित करने का एक शक्तिशाली तरीका हो सकता है। सदस्यता के माध्यम से, आपके चर्चाओं में शामिल होने, कार्यक्रमों में भाग लेने और टिकाऊ प्रथाओं पर केंद्रित परियोजनाओं पर सहयोग करने की संभावना है। यह प्रदर्शन पर्यावरणीय, सामाजिक और आर्थिक प्रभावों के बारे में आपकी समझ को गहरा कर सकता है और आपको अपने पेशेवर और व्यक्तिगत जीवन में स्थायी सोच को एकीकृत करने में मदद कर सकता है। इसके अतिरिक्त, साथी सदस्यों के साथ नेटवर्किंग मूल्यवान अंतर्दृष्टि और दृष्टिकोण प्रदान कर सकती है, जिससे स्थिरता के लिए समग्र दृष्टिकोण को बढ़ावा मिलता है। अपने क्लब की सदस्यता का अधिकतम लाभ उठाने के लिए सक्रिय रूप से भाग लेना और योगदान देना याद रखें। हमारी ZHA स्थिरता पेशेवर सदस्यता तीन स्तंभों पर केंद्रित है जैसे कि
</p>
<p>
</p>
<span>
<div className='wellnesspoints'> 
<p>1) मानव कल्याण सदस्यता</p>
<p>2) प्रकृति कल्याण सदस्यता</p>
<p>3) पृथ्वी कल्याण सदस्यता</p>
</div>
</span>
           </div>
        </div>





        <h2>सतत मानव कल्याण सदस्यता
</h2>      
        <div className='second-mcontent'>
           
            <div>
            <img src='../../img/membershipwellness.webp'></img>
            </div>
            <div>
           <p>सतत मानव कल्याण में ऐसी प्रथाएं और जीवनशैली शामिल हैं जो व्यक्तियों और ग्रह के दीर्घकालिक स्वास्थ्य पर विचार करते हुए कल्याण को बढ़ावा देती हैं। इसमें ऐसे विकल्प चुनना शामिल है जो शारीरिक, मानसिक और भावनात्मक स्वास्थ्य का समर्थन करते हैं, साथ ही उन विकल्पों के पर्यावरणीय और सामाजिक प्रभावों पर भी विचार करते हैं।

            </p>
            <h4>इसमें निम्न चीज़ें शामिल हो सकती हैं:

</h4>

<ol>
    <li><span>पोषण : </span>पर्यावरणीय प्रभाव को कम करने और व्यक्तिगत स्वास्थ्य का समर्थन करने के लिए स्थानीय रूप से प्राप्त, जैविक और पौधों पर आधारित खाद्य पदार्थों का चयन करना।

</li>
    <li><span>शारीरिक गतिविधि : </span>व्यायाम और बाहरी गतिविधियों में संलग्न होना जो फिटनेस और मानसिक कल्याण को बढ़ावा देता है, साथ ही पर्यावरण को होने वाले नुकसान को भी कम करता है।
</li>
    <li><span>मानसिक स्वास्थ्य : </span>मानसिक और भावनात्मक कल्याण का समर्थन करने के लिए माइंडफुलनेस, ध्यान और प्रकृति में समय बिताने जैसी प्रथाओं को शामिल करना।

</li>
    <li><span>सामुदायिक और सामाजिक संबंध : </span>मजबूत सामाजिक संबंधों का निर्माण और रखरखाव, साथ ही उन गतिविधियों में भाग लेना जो समुदाय और समाज में बड़े पैमाने पर सकारात्मक योगदान देते हैं।

</li>
    <li><span>सचेत उपभोग : </span>आपके द्वारा उपभोग किए जाने वाले उत्पादों और सेवाओं के प्रति सचेत रहना, टिकाऊ और नैतिक रूप से उत्पादित विकल्पों का चयन करना।
</li>
    <li><span>अपशिष्ट को कम करना : </span>अपने पर्यावरणीय प्रभाव को कम करने के लिए पुनर्चक्रण, खाद बनाना और एकल-उपयोग वाली वस्तुओं को कम करना जैसी प्रथाओं को अपनाना।

</li>
    <li><span>संतुलित कार्य-जीवन एकीकरण : </span>समग्र कल्याण का समर्थन करने के लिए काम, व्यक्तिगत जीवन और अवकाश गतिविधियों के बीच एक स्वस्थ संतुलन खोजना।
</li>
    <li><span>स्वयं की देखभाल और स्वयं से प्यार करना और दूसरों की सराहना करना : </span>उन नीतियों की वकालत करना और उनका समर्थन करना जो यह सुनिश्चित करती हैं कि हर किसी को गुणवत्तापूर्ण स्वास्थ्य देखभाल तक पहुंच प्राप्त हो।
</li> 
</ol>

<p>स्थायी मानव कल्याण को बढ़ावा देने वाली प्रथाओं को अपनाकर, व्यक्ति स्वस्थ, अधिक पूर्ण जीवन जी सकते हैं, साथ ही ग्रह और उसके समुदायों के स्वास्थ्य और कल्याण में सकारात्मक योगदान भी दे सकते हैं।</p>
           </div>
        </div>







        
        <h2>सतत प्रकृति कल्याण सदस्यता
</h2>      
        <div className='second-mcontent'>
           
            <div>
            <img src='../../img/membershipnature.webp'></img>
            </div>
            <div>
           <p>सतत प्रकृति कल्याण में मनुष्यों और प्राकृतिक दुनिया के बीच सामंजस्यपूर्ण और पारस्परिक रूप से लाभप्रद संबंध को बढ़ावा देना शामिल है। यह न केवल प्रकृति के संरक्षण और संरक्षण के महत्व पर जोर देता है, बल्कि हमारे शारीरिक, मानसिक और भावनात्मक कल्याण पर इसके गहरे प्रभाव को भी पहचानता है।


            </p>
            <h4>स्थायी प्रकृति कल्याण के प्रमुख तत्वों में शामिल हैं:

</h4>

<ol>
    <li><span>प्रकृति में बिताया गया समय : </span>बाहर के चिकित्सीय लाभों का अनुभव करने के लिए नियमित रूप से प्राकृतिक वातावरण में खुद को डुबोना, चाहे वह पार्क, जंगल, समुद्र तट या बगीचे हों।
प्रकृति संरक्षण और पुनर्स्थापना: प्राकृतिक आवासों की रक्षा और पुनर्स्थापित करने के प्रयासों में सक्रिय रूप से भाग लेना, जिसमें पुनर्वनीकरण, आर्द्रभूमि संरक्षण और वन्यजीव संरक्षण जैसी पहल शामिल हैं।

</li>
    <li><span>बायोफिलिक डिजाइन और वास्तुकला : </span>भलाई को बढ़ाने और प्राकृतिक दुनिया से जुड़ाव की भावना को बढ़ावा देने के लिए रहने और काम करने की जगहों के डिजाइन में प्रकृति के तत्वों को शामिल करना।

</li>
    <li><span>वन्यजीव और जैव विविधता की सराहना : </span>हमारे साथ ग्रह साझा करने वाली प्रजातियों की विविध श्रृंखला को महत्व देना और उनका सम्मान करना, और पारिस्थितिक संतुलन बनाए रखने में उनकी महत्वपूर्ण भूमिका को समझना।

</li>
    <li><span>वन्यजीव और जैव विविधता की सराहना : </span>हमारे साथ ग्रह साझा करने वाली प्रजातियों की विविध श्रृंखला को महत्व देना और उनका सम्मान करना, और पारिस्थितिक संतुलन बनाए रखने में उनकी महत्वपूर्ण भूमिका को समझना।

</li>
    <li><span>प्रकृति-आधारित थेरेपी : </span>मानसिक और भावनात्मक कल्याण को बढ़ावा देने के लिए वन स्नान, इकोथेरेपी और प्रकृति ध्यान जैसी प्रथाओं में संलग्न होना।.

</li>
    <li><span>जिम्मेदार बाहरी गतिविधियाँ : </span>लंबी पैदल यात्रा, पक्षी देखने और शिविर लगाने जैसी मनोरंजक गतिविधियों में इस तरह से भाग लेना जिससे पर्यावरण पर प्रभाव कम से कम हो और वन्यजीवों का सम्मान हो।


</li>
    <li><span>पर्यावरण शिक्षा और जागरूकता : </span>हमारे आसपास की दुनिया को बनाने वाले पारिस्थितिक तंत्र, प्रजातियों और प्राकृतिक प्रक्रियाओं के बारे में लगातार सीखना और उनकी सुरक्षा की वकालत करना।

</li>
    <li><span>सतत कृषि और खाद्य प्रणालियों का समर्थन करना : </span>ऐसे खाद्य विकल्पों का चयन करना जो इस तरह से प्राप्त और उत्पादित किए जाते हैं जो प्राकृतिक पारिस्थितिकी प्रणालियों का सम्मान करते हैं और उन्हें बढ़ाते हैं
</li>
    <li><span>संरक्षण नीतियों की वकालत : </span>नीतियों और पहल का समर्थन करना स्थानीय, राष्ट्रीय और वैश्विक स्तर पर स्थायी भूमि उपयोग, वन्यजीव संरक्षण और संरक्षण प्रयासों को बढ़ावा देने वाले मूल निवासी।
</li>
</ol>
<p>सतत प्रकृति कल्याण यह मानता है कि हमारी भलाई प्राकृतिक दुनिया के स्वास्थ्य और जीवन शक्ति से गहराई से जुड़ी हुई है। इस रिश्ते को पोषित करके और प्रकृति की रक्षा और संरक्षण के लिए कार्रवाई करके, हम न केवल अपने स्वास्थ्य और खुशी को बढ़ाते हैं,</p>

           </div>
        </div>






        <h2>सतत पृथ्वी कल्याण सदस्यता
</h2>      
        <div className='second-mcontent'>
           
            <div>
            <img src='../../img/membership earth.webp'></img>
            </div>
            <div>
           <p>सतत पृथ्वी कल्याण में समग्र रूप से ग्रह के स्वास्थ्य और कल्याण का पोषण करना शामिल है, यह सुनिश्चित करना कि प्राकृतिक प्रणालियाँ पनप सकें और आने वाली पीढ़ियों के लिए जीवन का समर्थन कर सकें। इसमें पारिस्थितिक तंत्र, प्रजातियों और मानव समुदायों के अंतर्संबंध पर विचार करते हुए पर्यावरणीय प्रबंधन के लिए एक समग्र दृष्टिकोण शामिल है।

            </p>
            <h4>सतत पृथ्वी कल्याण के प्रमुख पहलुओं में शामिल हैं:
</h4>

<ol>
    <li><span>जैव विविधता संरक्षण : </span>जीवन रूपों और पारिस्थितिकी प्रणालियों की विविधता की रक्षा और संरक्षण करना, प्रत्येक प्रजाति के मूल्य और संतुलित पारिस्थितिकी तंत्र को बनाए रखने में उनकी भूमिकाओं को पहचानना।
</li>
    <li><span>जलवायु प्रबंधन : </span>ग्रीनहाउस गैस उत्सर्जन को कम करके, नवीकरणीय ऊर्जा स्रोतों में परिवर्तन करके और बदलती जलवायु के प्रभावों को अपनाकर जलवायु परिवर्तन को कम करने के लिए कार्रवाई करना।
</li>
    <li><span>संसाधन दक्षता : </span>संसाधनों (जैसे पानी, ऊर्जा और सामग्री) का उपयोग इस तरह से करना कि अपशिष्ट और पर्यावरणीय गिरावट को कम किया जा सके, और इन संसाधनों की दीर्घकालिक उपलब्धता का समर्थन किया जा सके।
</li>
    <li><span>पुनर्योजी कृषि : </span>समुदायों के लिए पौष्टिक भोजन प्रदान करते हुए मिट्टी के स्वास्थ्य, जैव विविधता और पारिस्थितिकी तंत्र के लचीलेपन को बढ़ाने वाली कृषि प्रथाओं को बढ़ावा देना।
</li>
    <li><span>प्राकृतिक आवासों का संरक्षण : </span>महत्वपूर्ण पारिस्थितिकी तंत्र सेवाएं प्रदान करने और वन्य जीवन का समर्थन करने के लिए जंगलों, आर्द्रभूमि और महासागरों सहित प्राकृतिक आवासों को संरक्षित और पुनर्स्थापित करना।
</li>
    <li><span>सर्कुलर इकोनॉमी प्रैक्टिस : </span>एक सर्कुलर इकोनॉमी मॉडल को अपनाना जो अपशिष्ट को कम करता है, रीसाइक्लिंग को प्रोत्साहित करता है और संसाधनों के स्थायी उपयोग को बढ़ावा देता है।
</li>
    <li><span>नैतिक और टिकाऊ व्यावसायिक प्रथाएँ : </span>व्यवसायों को पारदर्शिता, निष्पक्षता और पर्यावरण और सामाजिक जिम्मेदारी के प्रति प्रतिबद्धता के साथ संचालित करने के लिए प्रोत्साहित करना।
</li>
    <li><span>वैश्विक सहयोग : </span>वैश्विक पर्यावरणीय चुनौतियों से निपटने के लिए अंतर्राष्ट्रीय सहयोग को बढ़ावा देना, यह स्वीकार करते हुए कि कई पर्यावरणीय मुद्दे राष्ट्रीय सीमाओं से परे हैं।</li>
    <li><span>पर्यावरण शिक्षा और वकालत : </span>जागरूकता, शिक्षा और को बढ़ावा देना</li>
</ol>

           </div>
        </div>









               
        
       

    </div>
);
}