// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import './constquestschool.css'


const Home = () => {
  return (
    <div className="questbox"> 
      <h2>Assessment for School Management on Sustainability Practices</h2>
  

      
      <div className="level-boxes">
            <div className="level-box">
              <div className="level-text">
                <h2 className="level-name">Level 1</h2>
                <span>20 Questions</span>
              </div>
              <Link to="/School-Contact-Form"> 
              <button className="level-link" >
                <span>Start </span> <i className="bi bi-arrow-right"></i>
              </button>
              </Link>
            </div>
          </div>

 
    </div>
  );
};

export default Home;
