import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">


            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Establishment-Story">English</NavLink></a>

        </div>
            

                <h1 id="mhead">ఝా స్థాపన కథ</h1>

                <div className='second-mcontent'>
                <img src='../../img/story.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">

                    <p>ఝా ఫౌండేషన్ యొక్క కథ వివిధ పరిశ్రమలలోని విజయవంతమైన నిపుణులతో ప్రారంభమవుతుంది, వారు స్థిరమైన జీవనం యొక్క ప్రపంచ అభివృద్ధికి తోడ్పడాలని కోరుకున్నారు. అయినప్పటికీ, వారి విలువలకు అనుగుణంగా వినూత్న ప్లాట్‌ఫారమ్‌ల కొరతను వారు కనుగొన్నారు. అదే సమయంలో, విజయవంతమైన వ్యాపారవేత్తలు ప్రత్యేకమైన వ్యాపార క్లబ్‌లను కలిగి ఉన్నారు, అది వారి విజయాలలో గర్వాన్ని నింపింది.
                    </p>

                    <p>ఈ అంతరాన్ని గుర్తించి, ఝా వ్యవస్థాపకుడు ఒక వ్యూహాత్మక సామాజిక కార్యవర్గాన్ని ఏర్పాటు చేయవలసిన అవసరాన్ని విశ్వసించారు. ఈ బోర్డు అన్ని స్థాయిలలో మరియు విభిన్న స్థానాల నుండి నిష్ణాతులైన నిపుణులు మరియు వ్యాపార నాయకులను కలిగి ఉంటుంది. ఝా సుస్థిరత ఉద్యమాన్ని ప్రచారం చేయడం, సాధారణ ప్రజలలో అవగాహన పెంచడం మరియు ప్రపంచ ప్రభావాన్ని పెంపొందించడం దీని లక్ష్యం.</p>

                    <p>Zha వ్యవస్థాపకుడు గ్రహం మరియు ప్రతి ఒక్కరి శ్రేయస్సుకు ప్రయోజనం చేకూర్చే ఉద్దేశ్యపూర్వక అభిరుచిని రూపొందించాలని ఊహించారు, సుస్థిరత సూత్రాలలో పొందుపరిచిన శ్రద్ధ మరియు భాగస్వామ్య నమూనా ద్వారా ప్రేరణ పొందారు. అందువలన, ఝా ఫౌండేషన్ యొక్క విస్తృతమైన థీమ్ స్థిరత్వంగా మారింది.</p>

                    <p>ఝా ఫౌండేషన్ కేవలం కార్పొరేషన్లకే కాకుండా వ్యక్తులందరికీ వర్తించే స్థిరత్వం యొక్క బెంచ్‌మార్క్ సిద్ధాంతాన్ని స్థాపించడానికి కట్టుబడి ఉంది. ఐక్యరాజ్యసమితి యొక్క స్థిరమైన లక్ష్యాలు మరియు ESG కొలతలకు అనుగుణంగా, ఝా సాధారణ ప్రజల కోసం రూపొందించిన పద్దతిని అభివృద్ధి చేశారు.</p>

                    <p>ఝా సస్టైనబిలిటీ ఎనేబుల్ ఫ్రేమ్‌వర్క్ యొక్క సూత్రాలు మరియు విలువలను స్వీకరించడానికి సాధారణ వ్యక్తులు, మహిళలు, పురుషులు మరియు విద్యార్థులతో సహా ప్రజలకు అధికారం కల్పించడం ఝా సస్టైనబిలిటీ ప్రోగ్రామ్‌ల లక్ష్యం. ఈ ఫ్రేమ్‌వర్క్ మానవులందరికీ అవలంబించడానికి ఒక పద్దతిగా ఉపయోగపడుతుంది, ఇది వారి స్థిరత్వ మనస్తత్వ సామర్థ్యాలను కొలవడానికి వీలు కల్పిస్తుంది. సాంకేతికత మరియు సాధనాలను ప్రభావితం చేస్తూ, ఐక్యరాజ్యసమితి యొక్క స్థిరత్వ లక్ష్యాలను ప్రజలకు అర్థం చేసుకోవడానికి ఝా ఫౌండేషన్ ఉచిత వనరులను అందిస్తుంది. మెథడాలజీలో ఝా సస్టైనబిలిటీ ఎనేబుల్ ఫ్రేమ్‌వర్క్‌లోని కీలక ప్రాక్టీస్ ప్రాంతాలు ఉన్నాయి, స్థిరమైన జీవనానికి సమగ్ర విధానాన్ని రూపొందించడం.</p>
                    
                    {/* <p>The story of the Zha Foundation begins with successful professionals across various industries who desired to contribute to the global development of sustainable living. However, they found a lack of innovative platforms aligning with their values. Simultaneously, successful businessmen had exclusive business clubs that instilled pride in their achievements.</p>

                    <p>Recognizing this gap, the founder of Zha believed in the necessity of forming a strategic social work board. This board would consist of accomplished professionals and business leaders at all levels and from diverse locations. The aim was to propagate the Zha sustainability movement, raising awareness among the general public and fostering a global impact.</p> */}
                </div>

                {/* <h3>Our Vision</h3> */}
                <div className="missi">
                    <h4  >ఝా ఫౌండేషన్ కార్యక్రమాల యొక్క సుస్థిరత ఫలితాలు:</h4>
                     

                </div>
                
<ol className="goalpoints">
    <li><span>వ్యక్తిగత సాధికారత : </span>ఝా యొక్క సుస్థిరత కార్యక్రమాల ద్వారా, సాధారణ వ్యక్తులు, మహిళలు, పురుషులు మరియు విద్యార్థులు స్థిరమైన మనస్తత్వాన్ని అలవర్చుకోవడానికి అధికారం పొందారు. చేరికపై ఫౌండేషన్ యొక్క దృష్టి ప్రతి ఒక్కరూ గ్రహం యొక్క అభివృద్ధికి తోడ్పడగలదని నిర్ధారిస్తుంది.

 </li>
    <li><span>గ్లోబల్ ఇంపాక్ట్ :</span>ఐక్యరాజ్యసమితి యొక్క స్థిరమైన లక్ష్యాలు మరియు ESG కొలతలతో సమలేఖనం చేయడం ద్వారా, ఝా ఫౌండేషన్ స్థిరమైన అభివృద్ధి కోసం ప్రపంచ కార్యక్రమాలకు దోహదం చేస్తుంది. ప్రపంచవ్యాప్తంగా వ్యక్తులకు వర్తించే స్థిరత్వం యొక్క బెంచ్‌మార్క్ సిద్ధాంతం, సానుకూల మార్పు కోసం సమిష్టి కృషిని నొక్కి చెబుతుంది.
 </li>
    <li><span>కొలవదగిన ప్రభావం : </span>ఝా యొక్క పద్దతి వ్యక్తులు వారి స్థిరత్వ మనస్తత్వ సామర్థ్యాలను కొలవడానికి ఒక స్పష్టమైన మార్గాన్ని అందిస్తుంది. ఇది స్థిరమైన జీవనానికి వారి సహకారాన్ని ట్రాక్ చేయడానికి మరియు అర్థం చేసుకోవడానికి ప్రజలను అనుమతిస్తుంది.

 </li>
    <li><span>విద్య మరియు అవగాహన : </span>ఐక్యరాజ్యసమితి సుస్థిరత లక్ష్యాలపై ప్రజల అవగాహనను పెంపొందించడం ద్వారా ఉచిత వనరులను అందించడానికి ఝా ఫౌండేషన్ సాంకేతికత మరియు సాధనాలను ప్రభావితం చేస్తుంది. ఈ విద్యా అంశం అవగాహనను ప్రోత్సహిస్తుంది మరియు స్థిరమైన పద్ధతులను విస్తృతంగా స్వీకరించడాన్ని ప్రోత్సహిస్తుంది.
 </li>
    <li><span>సమగ్ర ఫ్రేమ్‌వర్క్ :</span>ఝా సస్టైనబిలిటీ ఎనేబుల్ ఫ్రేమ్‌వర్క్, దాని కీలక ప్రాక్టీస్ ప్రాంతాలతో, స్థిరమైన జీవనానికి నిర్మాణాత్మకమైన మరియు సమగ్రమైన విధానాన్ని అందిస్తుంది. ఈ ఫ్రేమ్‌వర్క్ వ్యక్తులు వారి జీవితంలోని వివిధ అంశాలలో స్థిరత్వాన్ని చేర్చడంలో మార్గనిర్దేశం చేస్తుంది.

 </li>
    <li><span>కమ్యూనిటీ బిల్డింగ్ : </span>సుస్థిరత సూత్రాలలో పొందుపరచబడిన సంరక్షణ మరియు భాగస్వామ్య నమూనా సంఘం మరియు సామూహిక బాధ్యత యొక్క భావాన్ని పెంపొందిస్తుంది. ఝా ఫౌండేషన్ వ్యక్తులను సహకరించమని ప్రోత్సహిస్తుంది, స్థిరమైన అభ్యాసాలకు అంకితమైన ఇలాంటి మనస్సు గల వ్యక్తుల నెట్‌వర్క్‌ను సృష్టిస్తుంది.

 </li>
     
  </ol>

 <p>మొత్తంమీద, ఝా ఫౌండేషన్ కథనాన్ని అనుసరించడం వలన వ్యక్తులు, సంఘాలు మరియు ప్రపంచ పర్యావరణంపై స్థిరత్వం, విద్య మరియు సామూహిక చర్యను ప్రోత్సహించడం ద్వారా సానుకూల ప్రభావం చూపుతుంది.</p>

 

            </section>
        </div>


    );
}

