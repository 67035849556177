import React from "react";
import "./Footer.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footfull">
      <div className="footer2">
        <Container>
          <Row >
            <Col id="flexcontainer" md={3} className="footercol">
              <h3>Follow Up</h3>
              <div className="firstcol">
                <NavLink to={'/'}>
                  <img className="zhafootlogo" src="../../img/footerlogo.webp" alt="Logo" />
                </NavLink>
                <div className="social">
                  <div className="socialicon">
                  <a href="https://www.facebook.com/ZHAFoundation1/">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                  </div>
                  <div className="socialicon"> 
                  <a href="https://instagram.com/zha_foundation?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D">
                    <i className="fa-brands fa-instagram" />
                  </a>
                  </div>
                  <div className="socialicon"> 
                  <a href="https://www.youtube.com/@zhafoundation">
                    <i className="fa-brands fa-youtube" />
                  </a>
                  </div>
                  <div className="socialicon">
                  <a href="https://www.linkedin.com/company/zha-foundation-charitable-trust/">
                    <i className="fa-brands fa-linkedin" />
                  </a>
                  </div>
                </div>
              </div>
            </Col>

            <Col id="flexcontainer" md={3} className="footercol">
              <h3>Site Map</h3>
              <ul className="footersite">
                <Nav.Link><NavLink to="#home">Zha Vision</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/about">How Zha Function</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Zha-Sports-Training-Club">Zha Sports Training Club</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Zha-Ecofriendly-Certification">Zha Eco-friendly Certification</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Zha-Sustainability-Club-Membership">Zha Board Membership</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/ResearchandDevelopement">Zha Sustainability R&D</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Socialservices">Zha Social Services</NavLink></Nav.Link>
                <Nav.Link><NavLink to="/Contact">Contact Us</NavLink></Nav.Link>
              </ul>
            </Col>

            <Col id="flexcontainer" md={3} className="footercol">
              <h3>Reach Us</h3>
              <div className="towhithe">
                <div className="rachlist">
                  <a href="https://www.facebook.com/karkiashokkumarauthor?mibextid=LQQJ4d">
                    <i className="fa-brands fa-facebook-f" />
                    <h6>Founders’ Author Thoughts</h6>
                  </a>
                </div>
                <div className="rachlist">
                  <i className="fa-solid fa-phone"></i>
                  <h6>+91 7305359417</h6>
                </div>
                <div className="rachlist">
                  <i className="fa-solid fa-envelope"></i>
                  <h6>admin@zhafoundation.in</h6>
                </div>
              </div>
            </Col>

            <Col id="flexcontainer" md={3} className="footercol">
         <div className="towhithe">

              <h3>Address</h3>
              <div className="rachlist">
                <i className="fa-solid fa-house"></i>
                <h6>ZHA Sustainability Practitioners Club, 55, Juniper Way, Romford, London, United Kingdom</h6>
              </div>
              <br></br>
              <div  className="rachlist">
                <i className="fa-solid fa-house"></i>
                <h6>5401 Butterfly lane, Allentown PA 18104</h6>
              </div>
              <br></br>
              <div  className="rachlist">
                <i className="fa-solid fa-house"></i>
                <h6>72a, Nava India Road, Sri Ramakrishna College Rd, Peelamedu, Coimbatore, Tamil Nadu 641004</h6>
              </div>
              </div>
            </Col>
          </Row>
        </Container>


        



        <div className="footercopyright">
          <div className="footerlinee"></div>
          <div className="footercopyrightcontent">
            <NavLink to={'/'}>Copyright@ZhaFoundationCharitableTrust</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
