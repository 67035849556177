import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                <img src="../../img/fabout.webp" />
                    <p>
                    எங்கள் அறக்கட்டளை ஒரு இலாப நோக்கற்ற அமைப்பாகும், இது இந்திய விவசாயிகளுக்கு விவசாய வணிகத்தில் இலவச ஆலோசனை மூலம் உதவவும், விவசாயிகளுக்கு மதிப்பு கூட்டப்பட்ட நன்மைகளை வழங்கவும் மற்றும் கோரிக்கைகளின் அடிப்படையில் அவர்களின் பண்ணைகளை நிர்வகிக்கவும் தொடங்கப்பட்டது. எனவே எங்கள் நுகர்வோருக்கு மதிப்பை வழங்கும் ஒரு பொருளைக் கண்டுபிடிக்க வேண்டும் என்று நாங்கள் பார்த்தோம்.

                    </p>
                </div>
                <div className="about-card">
                <img src="../../img/sabout.webp" />
     <p>
     நாங்கள் நிலையான மற்றும் உள்ளடக்கிய வளர்ச்சியை விரைவுபடுத்துகிறோம் மற்றும் உணவு மற்றும் விவசாய நிறுவனங்கள் மற்றும் நிறுவனங்களுக்கு மாற்றியமைக்கும் தாக்கத்தை வழங்குகிறோம். எங்கள் தனியுரிம கருவிகள் மற்றும் டிஜிட்டல் மற்றும் மேம்பட்ட பகுப்பாய்வு திறன்கள், விரிவான அனுபவத்துடன் இணைந்து எங்கள் வாடிக்கையாளர்களுக்கு நாங்கள் கொண்டு வரும் மதிப்பின் மூலக்கல்லாகும்.
     </p>
 </div>
 <div className="about-card">
 <img src="../../img/tabout.webp" />
     <p>
     ழ அறக்கட்டளை (ZHA foundation) பல துறை சார்ந்த ஆராய்ச்சி, திறன் மேம்பாடு, சுய மேம்பாடுகள் மற்றும் இளைஞர்களின் மனம் மற்றும் இதய ஆரோக்கியத்திற்கு உதவும் வகையில் பிரச்சாரங்களை மேற்கொள்கிறது. ஒரு முழு தலைமுறையும் அதிக கவலையுடனும், பலவீனமாகவும், மனச்சோர்வுடனும் இருக்கிறது. அவர்கள் ரிஸ்க் எடுப்பதில் மிகவும் குறைவான வசதியானவர்கள். இது ஒரு தலைமுறையின் உண்மையான மாற்றம். எனவே, ஆரோக்கியத்தை அளவிடுவதற்கான வழிகளை நாங்கள் உருவாக்கி வருகிறோம், அதில் பின்னடைவு, அன்பு, நம்பிக்கை, கலாச்சார அடையாளம், விமர்சன உணர்வு, சுயநிர்ணயம் மற்றும் விவசாயம் சார்ந்த முன்னேற்றங்கள் ஆகியவை அடங்கும்.
     </p>
 </div>
            </div>  


            

            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">இயக்க மாதிரி</h2>
  {/* <h4>Operating Model</h4> */}
  <p>எதிர்கால சந்ததியினருக்கான நிலையான வாழ்க்கையை உருவாக்குவதற்கும், கிரகத்தைப் பாதுகாப்பதற்கும், Zha நிலைத்தன்மை வல்லுநர்கள் டவர்/கிளப்பின் நோக்கம் மற்றும் பார்வையுடன் இணைவதற்கான உலகளாவிய நலன்களை மதிப்பிடுவதற்கு, பல்வேறு வகையான எண்ணம் கொண்ட மனிதர்களுடன் நாம் தொடர்பு கொள்ள வேண்டும்.
</p>
<p>நிலையான வாழ்க்கை என்பது நமது மனிதர்களின் வாழ்க்கை முறை தேர்வுகள் நம்மைச் சுற்றியுள்ள உலகத்தை எவ்வாறு பாதிக்கிறது என்பதைப் புரிந்துகொள்வது மற்றும் அனைவரும் சிறப்பாகவும் இலகுவாகவும் வாழ்வதற்கான வழிகளைக் கண்டறிவது. ZHA நிலைத்தன்மை வல்லுநர்கள் குழு உறுப்பினர்களின் எங்கள் செயல்பாடுகள் செயலில், ஆலோசனை மற்றும் சாட்சி குழு உறுப்பினர்களால் பிரிக்கப்படுகின்றன. எவ்வாறாயினும், Zha குழு உறுப்பினர்கள் பின்வரும் நான்கு தூண்களில் இருந்து அவர்களின் நிலைத்தன்மைக்கான ஆர்வத்தைக் கண்டறிய கடமைப்பட்டுள்ளனர்
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) மனித ஆரோக்கிய தூண்</p>
<p>2) இயற்கை ஆரோக்கிய தூண் </p>
<p>3) பூமி ஆரோக்கிய தூண்</p>
<p>4) நிலையான இலாபத் தூண்</p>
</div>
</span>
<p>நிலையான ஆரோக்கியத்தின் மேற்கூறிய நான்கு தூண்களில் இருந்து, ஒவ்வொரு குழு உறுப்பினர்களும் எதிர்கால சந்ததியினருக்கு பூமி, இயற்கை மற்றும் மனித ஆரோக்கியத்திற்கான நிலையான வாழ்க்கையை உருவாக்க ஏதேனும் சூதாட்ட சமூக முன்முயற்சிகளைக் கண்டுபிடிப்பார்கள்</p>
<p>இவ்வாறு இந்த வாழ்வில் நிலையான வாழ்வின் விளைவு, சுற்றுச்சூழலைப் பாதுகாக்கும் மற்றும் சமத்துவத்தை ஆதரிக்கும் சமூக மற்றும் பொருளாதார மேம்பாட்டைக் குறிக்கும் நிலையான வளர்ச்சியின் கொள்கையின் மூலம் தீர்மானிக்கப்படுகிறது, எனவே பொருளாதாரம் மற்றும் சமூகம் மற்றும் சுற்றுச்சூழல் அமைப்பு ஆகியவை ஒன்றுக்கொன்று சார்ந்துள்ளது.</p>
 
 
 


<p>வாரிய உறுப்பினர் சந்தா கட்டணம் எவ்வாறு பயன்படுத்தப்படுகிறது என்பது குறித்து ழ அறக்கட்டளை நிலைத்தன்மை வல்லுநர்கள் கிளப் வெளிப்படையாக இருப்பது முக்கியம். உறுப்பினர்களுக்கு காலாண்டு வழக்கமான நிதி அறிக்கைகள் மற்றும் புதுப்பிப்புகளை வழங்குவோம், நம்பிக்கையை வளர்க்க உதவுவதுடன், அவர்களின் பங்களிப்புகளுக்கு ஈடாக அவர்கள் பெறும் மதிப்பை அவர்கள் புரிந்துகொள்வதை உறுதிசெய்து, அதே மன்றத்தில் எங்கள் முன்முயற்சிகள் முன்னேற்றம் மற்றும் சாதனைகளைக் கொண்டாடுவோம்</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">விளைவு - மதிப்பு</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>நிலையான வளர்ச்சியின் விரும்பிய முடிவுகள் பொருளாதார செழிப்பு, சுற்றுச்சூழல் பாதுகாப்பு, சமூக சமத்துவம் மற்றும் வறுமைக் குறைப்பு ஆகியவற்றை உள்ளடக்கியது. இந்த விளைவுகளை அடைவதற்கு அரசாங்கங்கள், வணிகங்கள் மற்றும் தனிநபர்களின் கூட்டு முயற்சி தேவை.</li>
  <li>ழ அறக்கட்டளை அனைத்து நிலை குழந்தைகள் மற்றும் பெரியவர்களுக்கு சமூகப் பொறுப்பை செயல்படுத்துகிறது.</li>
  <li>எவருக்கும் அவர்களின் நிலைத்தன்மை மனப்பான்மையை வளர்ப்பதற்கும், வணிக நெட்வொர்க்குகளை வளர்ப்பதற்கும் மற்றும் உலகம் முழுவதும் உள்ள ஒத்த எண்ணம் கொண்ட நிபுணர்களுடன் இணைவதற்கும் ழ மதிப்புமிக்க ஆதாரங்களை வழங்குகிறது. நீங்கள் இப்போது தொடங்கினாலும் அல்லது பல ஆண்டுகளாக வணிகத்தில் ஈடுபட்டிருந்தாலும், இந்த மன்றம் வழங்கும் அறிவு, ஆதரவு மற்றும் இணைப்புகளிலிருந்து நீங்கள் பயனடையலாம்.</li>
  <li>Zha அறக்கட்டளையானது சமூக சேவை + வணிகம் + அறிவு + நட்பு மற்றும் கூட்டுறவு ஆகியவற்றை அடிப்படையாகக் கொண்டது
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">ழ மாற்றும் முறை</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}