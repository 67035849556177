import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About() {
    return (

        <div>
            <div id="language" >

                <a href=""><NavLink to="/about">English</NavLink></a>

            </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                <img src="../../img/fabout.webp" />
                    <p>
                        మా ఫౌండేషన్ అనేది లాభాపేక్ష లేని సంస్థ, ఇది వ్యవసాయ వ్యాపారంలో ఉచితంగా డైనమిక్ కన్సల్టింగ్ ద్వారా భారతీయ రైతులకు సహాయం చేయడానికి, రైతులకు విలువ జోడించిన ప్రయోజనాలను అందించడానికి మరియు అభ్యర్థనల ఆధారంగా వారి పొలాలను నిర్వహించడానికి ప్రారంభించబడింది. కాబట్టి మేము మా వినియోగదారులకు విలువను అందించే ఉత్పత్తిని కనుగొనవలసి ఉందని మేము చూశాము.



                    </p>
                </div>
                <div className="about-card">
                <img src="../../img/sabout.webp" />
                    <p>
                        మేము సుస్థిరమైన మరియు సమ్మిళిత వృద్ధిని వేగవంతం చేస్తాము మరియు ఆహార మరియు వ్యవసాయ కంపెనీలు మరియు సంస్థలకు సామర్థ్యాలను పెంచడం, వృద్ధికి ఆజ్యం పోయడం, పరిశ్రమలను రూపొందించడం మరియు సంస్థాగత ప్రభావాన్ని పెంచడం ద్వారా పరివర్తన ప్రభావాన్ని అందిస్తాము. మా యాజమాన్య సాధనాలు మరియు డిజిటల్ మరియు అధునాతన అనలిటిక్స్ సామర్థ్యాలు, విస్తృతమైన అనుభవంతో కలిపి మేము మా క్లయింట్‌లకు అందించే విలువకు మూలస్తంభాలు.


                    </p>
                </div>
                <div className="about-card">
                <img src="../../img/tabout.webp" />
                    <p>
                        ఝా ఫౌండేషన్ మల్టీ-డిసిప్లినరీ రీసెర్చ్, కెపాసిటీ బిల్డింగ్, సెల్ఫ్ ఇంప్రూవ్‌మెంట్‌లలో నిమగ్నమై ఉంది మరియు యువత మనస్సు & గుండె ఆరోగ్యానికి సహాయపడటానికి లింక్ చేయబడిన ప్రచారాలను చేపట్టింది. మొత్తం తరం మరింత ఆత్రుతగా, మరింత పెళుసుగా, మరింత నిరాశకు గురవుతుంది. వారు రిస్క్ తీసుకోవడం చాలా తక్కువ సౌకర్యంగా ఉంటారు. ఇది ఒక తరంలో నిజమైన మార్పు. ” కాబట్టి, మేము స్థితిస్థాపకత, ప్రేమ, ఆశ, సాంస్కృతిక గుర్తింపు, విమర్శనాత్మక స్పృహ, స్వీయ-నిర్ణయం మరియు పని మెరుగుదలల వ్యవసాయ మార్గాలను కలిగి ఉన్న ఆరోగ్యాన్ని కొలవడానికి మార్గాలను అభివృద్ధి చేస్తున్నాము.


                    </p>
                </div>
            </div>
            

            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">ఆపరేటింగ్ మోడల్</h2>
  {/* <h4>Operating Model</h4> */}
  <p>భవిష్యత్ తరాలకు స్థిరమైన జీవితాన్ని సృష్టించడం కోసం & గ్రహాన్ని పరిరక్షించడం కోసం, ఝా సస్టైనబిలిటీ ప్రొఫెషనల్స్ టవర్/క్లబ్ యొక్క మిషన్ మరియు విజన్‌తో సమలేఖనం చేయడానికి ప్రపంచ ప్రయోజనాలను అంచనా వేయడానికి మేము వివిధ రకాల మనస్సుగల మనుషులతో పరస్పర చర్య చేయాల్సి ఉంటుంది.
</p>
<p>స్థిరమైన జీవనం అంటే మన మనుషుల జీవనశైలి ఎంపికలు మన చుట్టూ ఉన్న ప్రపంచాన్ని ఎలా ప్రభావితం చేస్తాయో అర్థం చేసుకోవడం మరియు ప్రతి ఒక్కరూ మెరుగ్గా మరియు తేలికగా జీవించడానికి మార్గాలను కనుగొనడం. ZHA సస్టైనబిలిటీ ప్రొఫెషనల్స్ బోర్డ్ మెంబర్‌ల యొక్క మా కార్యకలాపాలు యాక్టివ్, అడ్వైజరీ మరియు సాక్షి బోర్డ్ మెంబర్‌షిప్ ద్వారా విభజించబడ్డాయి. అయితే ఝా బోర్డు సభ్యులు ఈ క్రింది నాలుగు స్తంభాల నుండి తమ సుస్థిరత కారణం యొక్క ఆసక్తిని కనుగొనవలసి ఉంటుంది
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) మానవ ఆరోగ్య స్తంభం</p>
<p>2) ప్రకృతి సంరక్షణ స్తంభం  </p>
<p>3) ఎర్త్ వెల్నెస్ పిల్లర్</p>
<p>4) స్థిరమైన లాభం స్తంభం</p>
</div>
</span>
<p>సుస్థిరత ఆరోగ్యం యొక్క పై నాలుగు స్తంభాల నుండి, బోర్డు సభ్యులు ప్రతి ఒక్కరూ భవిష్యత్ తరాల భూమి, ప్రకృతి & మానవ ఆరోగ్యం కోసం స్థిరమైన జీవితాన్ని నిర్మించడానికి ఏదైనా గేమిఫైడ్ సామాజిక కార్యక్రమాలను కనుగొంటారు</p>
<p>అందువల్ల ఈ జీవితంలో స్థిరమైన జీవితం యొక్క ఫలితం పర్యావరణాన్ని రక్షించే మరియు సమానత్వానికి మద్దతు ఇచ్చే సామాజిక మరియు ఆర్థిక మెరుగుదలని సూచించే స్థిరమైన అభివృద్ధి సూత్రం ద్వారా నిర్ణయించబడుతుంది, అందువల్ల ఆర్థిక వ్యవస్థ మరియు సమాజం మరియు పర్యావరణ వ్యవస్థ పరస్పరం ఆధారపడి ఉంటాయి.</p>
 
 
 


<p>బోర్డు మెంబర్‌షిప్ సబ్‌స్క్రిప్షన్ ఫీజు ఎలా ఉపయోగించబడుతుందనే దాని గురించి Zha ఫౌండేషన్ సస్టైనబిలిటీ ప్రొఫెషనల్స్ క్లబ్ పారదర్శకంగా ఉండటం ముఖ్యం. మేము సభ్యులకు త్రైమాసిక సాధారణ ఆర్థిక నివేదికలు మరియు అప్‌డేట్‌లను అందజేస్తాము మరియు విశ్వాసాన్ని పెంపొందించడంలో సహాయపడతాము మరియు వారి సహకారాలకు ప్రతిఫలంగా వారు పొందే విలువను వారు అర్థం చేసుకున్నారని నిర్ధారిస్తాము మరియు అదే ఫోరమ్‌లో మా కార్యక్రమాల పురోగతి మరియు విజయాలను జరుపుకుంటాము</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">ఫలితం - విలువ</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>స్థిరమైన అభివృద్ధి యొక్క కావలసిన ఫలితాలు ఆర్థిక శ్రేయస్సు, పర్యావరణ పరిరక్షణ, సామాజిక సమానత్వం మరియు పేదరికం తగ్గింపును కలిగి ఉంటాయి. ఈ ఫలితాలను సాధించడానికి ప్రభుత్వాలు, వ్యాపారాలు మరియు వ్యక్తుల నుండి సమిష్టి కృషి అవసరం.</li>
  <li>Zha ఫౌండేషన్ అనేది పిల్లలు మరియు పెద్దల యొక్క అన్ని స్థాయిల కోసం సామాజిక బాధ్యతను ఎనేబుల్ చేస్తుంది.</li>
  <li>ZHA ఎవరికైనా వారి సుస్థిరత మైండ్‌సెట్‌ను పెంపొందించడానికి, వ్యాపార నెట్‌వర్క్‌లను పెంచుకోవడానికి మరియు ప్రపంచవ్యాప్తంగా సమాన ఆలోచనలు కలిగిన నిపుణులతో కనెక్ట్ అవ్వడానికి విలువైన వనరులను అందిస్తుంది. మీరు ఇప్పుడే ప్రారంభించినా లేదా సంవత్సరాలుగా వ్యాపారంలో ఉన్నా, ఈ ఫోరమ్ అందించే జ్ఞానం, మద్దతు మరియు కనెక్షన్‌ల నుండి మీరు ప్రయోజనం పొందవచ్చు.</li>
  <li>Zha ఫౌండేషన్ సామాజిక సేవ + వ్యాపారం + జ్ఞానం + స్నేహం & ఫెలోషిప్ ఆధారంగా స్థిరమైన జీవితాన్ని కలుపుతుంది మరియు ప్రపంచవ్యాప్తంగా నాణ్యమైన వ్యక్తులను కనుగొంటుంది
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">Zha పరివర్తన పద్ధతి</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}