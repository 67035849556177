import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
 

export default function Health() {
    return (
        <div>
            <div className="hmain">

{/* 
            <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

                <h1 id="mhead">Successful NRI Free Virtual Nation Social Services Program</h1>

                <div className='second-mcontent'>
                    <img src='../../img/nri.jpg'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>"Successful NRI Free Virtual Nation Social Services Program" aims to empower Non-Resident Indians (NRIs) through comprehensive personality and career development sessions. Here's an overview of the NGO's offerings:

                    </p>
                </div>

                
 


 
<ol className="goalpoints">
    <li><span>Free Virtual Workshops : </span>The program conducts regular virtual workshops on various topics such as communication skills, confidence building, values-based life, and aligning with global trends. These workshops are interactive and tailored to the needs of NRIs seeking personal and professional growth.
 </li>
    <li><span>Personality Development Sessions : </span>Through structured sessions, participants receive guidance on enhancing their personality traits, including leadership, emotional intelligence, adaptability, and resilience. These sessions help NRIs build a strong foundation for personal and professional success.
 </li>
    <li><span>Career Development Guidance : </span>The program offers career counseling and guidance to help NRIs navigate their career paths effectively. From resume writing and interview skills to exploring new career opportunities and industries, participants receive valuable insights and support to excel in their chosen fields.
 </li>
    <li><span>Mentorship Program : </span>The NGO facilitates mentorship opportunities where experienced professionals and industry experts guide NRIs in achieving their career goals. Mentors provide personalized advice, share industry insights, and offer support to help participants overcome challenges and seize opportunities.
 </li>
    <li><span>Networking Opportunities : </span>The program creates a platform for NRIs to connect with like-minded individuals, professionals, and organizations worldwide. Networking events, online forums, and discussion groups enable participants to expand their professional network and explore collaboration opportunities.
 </li>
    <li><span>Resource Library : </span>A curated collection of resources, including articles, e-books, webinars, and online courses, is available to program participants. These resources cover a wide range of topics related to personal development, career advancement, entrepreneurship, and more, empowering NRIs with valuable knowledge and skills.
 </li>
    <li><span>Community Engagement Initiatives : </span>The program encourages community engagement and volunteerism among NRIs, fostering a sense of belonging and social responsibility. Participants have the opportunity to contribute to meaningful projects, initiatives, and social causes that make a positive impact locally and globally.
 </li>
    <li><span>Feedback and Evaluation : </span>Continuous feedback mechanisms and program evaluations ensure that the offerings remain relevant, impactful, and responsive to the evolving needs of the NRI community. The program values input from participants and stakeholders, striving for excellence in service delivery and outcomes.
 </li>
   
</ol>

<p>Through these initiatives, the "Successful NRI Free Virtual Nation Social Services Program" aims to empower NRIs to thrive personally, professionally, and socially in an interconnected and dynamic global landscape.</p>

            </section>
        </div>


    );
}

