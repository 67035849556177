import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../Login/useAuth";
import "./home.css";
import "./quiz.css";
import "./result.css";
import "./style.css";
import { Provider } from "./QuizContext";
import { getFirestore, collection, addDoc } from 'firebase/firestore';

function Hometwo() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const startQuiz = () => {
    if (currentUser) {
      navigate("/quiz");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="home">
      <div className="quizhome">
        <Provider>
          <div className="intro-box">
            <div className="intro-texts">
              <h1 className="intro-title">Zha Foundation Charitable Trust</h1>
              <p className="intro-description">
                Zha Sustainability Mindset Assessment Test
              </p>
            </div>
            <div className="intro-icon">
              <i className="bi bi-question-circle"></i>
            </div>
          </div>

          <div className="level-boxes">
            <div className="level-box">
              <div className="level-text">
                <h2 className="level-name">Level 1</h2>
                <span>20 Questions</span>
              </div>
              <button className="level-link" onClick={startQuiz}>
                <span>Start </span> <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </Provider>
      </div>
    </div>
  );
}

export default Hometwo;

const db = getFirestore();

// Assuming 'users' is a Firestore collection
const addUser = async (userData) => {
  try {
    const docRef = await addDoc(collection(db, 'users'), userData);
    console.log('User added with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding user: ', e);
  }
};

const recordScore = async (userId, quizId, score) => {
  try {
    const docRef = await addDoc(collection(db, 'scores'), {
      userId,
      quizId,
      score,
    });
    console.log('Score recorded with ID: ', docRef.id);
  } catch (e) {
    console.error('Error recording score: ', e);
  }
};
