import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">




            <div  id="language" >
        
        <a href=""><NavLink to="/Environment">English</NavLink></a>

        </div>
        
                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ഴ ഫൗണ്ടേഷൻ: ഒരു ഹരിത നാളെയെ പരിപോഷിപ്പിക്കുന്നു</h1>

                <div className='second-mcontent'>
                <img src='../../img/environment.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>ഴ ഫൗണ്ടേഷനിലേക്ക് സ്വാഗതം, അവിടെ നിലവിലുള്ളതും ഭാവി തലമുറയ്ക്കും വേണ്ടി പരിസ്ഥിതിയെ സംരക്ഷിക്കുന്നതിൽ ഞങ്ങൾ അഭിനിവേശമുള്ളവരാണ്. നാം വീട് എന്ന് വിളിക്കുന്ന ഗ്രഹത്തെ സംരക്ഷിക്കുന്നതിൽ ഓരോ വ്യക്തിയും സമൂഹവും നിർണായക പങ്ക് വഹിക്കുന്നുവെന്ന വിശ്വാസമാണ് പരിസ്ഥിതി സംരക്ഷണത്തോടുള്ള നമ്മുടെ പ്രതിബദ്ധതയെ നയിക്കുന്നത്.
                    </p>
                </div>

                <h3>ഞങ്ങളുടെ വീക്ഷണം</h3>
                <div className="missi">
                    <h4 className="toleftneed">സുസ്ഥിര ഭാവി:</h4>
                    <p>ഴ ഫൗണ്ടേഷനിൽ, മാനവികത പ്രകൃതിയുമായി യോജിച്ച് നിലകൊള്ളുന്ന ഒരു ലോകമാണ് ഞങ്ങൾ വിഭാവനം ചെയ്യുന്നത്. നമ്മുടെ കാഴ്ചപ്പാട് സുസ്ഥിരതയിൽ വേരൂന്നിയതാണ്, അവിടെ ആവാസവ്യവസ്ഥകൾ തഴച്ചുവളരുന്നു, ജൈവവൈവിധ്യം സംരക്ഷിക്കപ്പെടുന്നു, ഓരോ പ്രവർത്തനവും ആരോഗ്യകരമായ ഒരു ഗ്രഹത്തിന് സംഭാവന ചെയ്യുന്നു.</p>


                </div>


                <h3>ഞങ്ങളുടെ ദൗത്യം</h3>
                <div className="missi">
                    <h4 className="toleftneed">ശാക്തീകരിക്കുന്ന മാറ്റം:</h4>
                    <p>പരിസ്ഥിതിയുടെ കാര്യസ്ഥരാകാൻ വ്യക്തികളെയും സമൂഹങ്ങളെയും ശാക്തീകരിക്കുക എന്നതാണ് ഞങ്ങളുടെ ദൗത്യം. വിദ്യാഭ്യാസം, അഭിഭാഷകർ, കൈത്താങ്ങ് സംരംഭങ്ങൾ എന്നിവയിലൂടെ, ഉത്തരവാദിത്തവും സുസ്ഥിരവുമായ പ്രവർത്തനങ്ങളോടുള്ള കൂട്ടായ പ്രതിബദ്ധത വളർത്തിയെടുക്കാൻ ഞങ്ങൾ ലക്ഷ്യമിടുന്നു.</p>
 
                </div>

                <h3>ഞങ്ങളുടെ സംരംഭങ്ങൾ</h3>
                <div className="missi">
                    <h4 className="toleftneed">സംരക്ഷണവും പുനരുദ്ധാരണവും:</h4>
                    <p>ഴ ഫൗണ്ടേഷൻ സംരക്ഷണ, പുനരുദ്ധാരണ പദ്ധതികളിൽ സജീവമായി ഇടപെടുന്നു. വനനശീകരണ ശ്രമങ്ങൾ മുതൽ വംശനാശഭീഷണി നേരിടുന്ന ജീവികളെ സംരക്ഷിക്കുന്നത് വരെ, ഭൂമിയിലെ ജീവൻ നിലനിർത്തുന്ന പ്രകൃതിദത്ത ആവാസ വ്യവസ്ഥകളെ സംരക്ഷിക്കുന്നതിലും പുനഃസ്ഥാപിക്കുന്നതിലും ഞങ്ങളുടെ സംരംഭങ്ങൾ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു.</p>

                    <h4 className="toleftneed">മാലിന്യ നിർമാർജന പരിപാടികൾ:</h4>
                    <p>നമ്മുടെ പാരിസ്ഥിതിക കാൽപ്പാടുകൾ കുറയ്ക്കേണ്ടതിന്റെ പ്രാധാന്യത്തിൽ ഞങ്ങൾ വിശ്വസിക്കുന്നു. പരിസ്ഥിതിയിൽ മനുഷ്യന്റെ പ്രവർത്തനങ്ങളുടെ ആഘാതം കുറയ്ക്കുന്നതിന് ഞങ്ങളുടെ മാലിന്യ നിർമാർജന പരിപാടികൾ ഉത്തരവാദിത്ത മാലിന്യ സംസ്കരണം, പുനരുപയോഗം, അപ്സൈക്ലിംഗ് എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്നു.</p>

                    <h4 className="toleftneed">ഗ്രീൻ എനർജി അഡ്വക്കസി:</h4>
                    <p>കാലാവസ്ഥാ വ്യതിയാനം ലഘൂകരിക്കുന്നതിനുള്ള പ്രധാന വശമാണ് പുനരുപയോഗ ഊർജ്ജത്തിലേക്ക് മാറുന്നത്. ഴ ഫൗണ്ടേഷൻ കൂടുതൽ സുസ്ഥിരവും ശുദ്ധവുമായ ഊർജ്ജ ഭൂപ്രകൃതിക്ക് സംഭാവന നൽകിക്കൊണ്ട് ഹരിത ഊർജ്ജ പരിഹാരങ്ങൾ സ്വീകരിക്കുന്നതിന് വേണ്ടി വാദിക്കുകയും പിന്തുണയ്ക്കുകയും ചെയ്യുന്നു.</p>
                </div>


                <h3>ഞങ്ങളുടെ സമീപനം</h3>
                <div className="missi">
                    <h4 className="toleftneed">വിദ്യാഭ്യാസവും അവബോധവും:</h4>
                    <p>അറിവ് മാറ്റത്തിനുള്ള ശക്തമായ ഉത്തേജകമാണ്. പാരിസ്ഥിതിക പ്രശ്നങ്ങൾ, കാലാവസ്ഥാ വ്യതിയാനം, സുസ്ഥിര ജീവിതം എന്നിവയെക്കുറിച്ച് അവബോധം വളർത്തുന്ന വിദ്യാഭ്യാസ പരിപാടികളിൽ ഞങ്ങൾ നിക്ഷേപം നടത്തുന്നു. വിവരങ്ങൾ ഉപയോഗിച്ച് വ്യക്തികളെ ശാക്തീകരിക്കുന്നതിലൂടെ, അർത്ഥവത്തായ പ്രവർത്തനത്തിന് പ്രചോദനമാകുമെന്ന് ഞങ്ങൾ വിശ്വസിക്കുന്നു.</p>

                    <h4 className="toleftneed">കമ്മ്യൂണിറ്റി ഇടപെടൽ:</h4>
                    <p>പരിസ്ഥിതി സംരക്ഷണം ഒരു കൂട്ടായ പരിശ്രമമാണ്. ഞങ്ങൾ പ്രാദേശിക കമ്മ്യൂണിറ്റികളുമായി ഇടപഴകുന്നു, പരിസ്ഥിതി സംരംഭങ്ങളിൽ സജീവമായ പങ്കാളിത്തം പ്രോത്സാഹിപ്പിക്കുന്നു. കമ്മ്യൂണിറ്റി നേതൃത്വം നൽകുന്ന പ്രോജക്ടുകളിലൂടെയും ഇവന്റുകളിലൂടെയും പരിസ്ഥിതി ചാമ്പ്യന്മാരുടെ ഒരു ശൃംഖല കെട്ടിപ്പടുക്കാൻ ഞങ്ങൾ ലക്ഷ്യമിടുന്നു.</p>

                     
                </div>

            </section>
        </div>


    );
}

