import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
         <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Lifetime-Achievement-Award">English</NavLink></a>

        </div>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}

       
        <h1 id="mhead">ழ வாழ்நாள் சாதனையாளர் விருது</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
            <img src='../../img/lifetimeaward.webp'></img>
            </div>
            <div>
           <p>விவசாய சமூகத்தில் குறிப்பிடத்தக்க பங்களிப்பை வழங்கிய தனிநபர்களுக்கான வாழ்நாள் சாதனையாளர் விருதை அடையாளம் காண பல படிகள்உள்ளது:


            </p>
           
<ol>
    <li><span> வரையறுக்கப்படும் அளவுகோல்கள் : </span>ஆண்டுகால சேவை, விவசாயிகளின் வாழ்வில் தாக்கம், புதுமைகள் மற்றும் விவசாய வளர்ச்சிக்கான ஒட்டுமொத்த பங்களிப்புகள் போன்ற காரணிகளைக் கருத்தில் கொண்டு, விருதுக்கான அளவுகோல்கள் தெளிவாகக் வரையறுக்க படுகின்றன.
</li>
    <li><span>நியமனச் செயல்முறை : </span>விருதுக்கான வேட்பாளர்களை முன்மொழிய தனிநபர்கள் அல்லது நிறுவனங்களை அனுமதிக்கும் ஒரு நியமனச் செயல்முறையை நிறுவுதல். துணை ஆவணங்கள் மற்றும் சான்றுகளைச் சேகரிப்பதற்கான ஒரு பொறிமுறையைச் சேர்த்தல்.

</li>
    <li><span>நிபுணர் குழு : </span>விவசாயத்தில் அறிவும் அனுபவமும் உள்ள நபர்களைக் கொண்டு நிபுணர் குழு அமைக்கவும். இந்தக் குழு பரிந்துரைகளை மதிப்பீடு செய்து மிகவும் தகுதியான வேட்பாளரை தீர்மானம் செய்கிறது.

</li>
    <li><span>ஆராய்ச்சி மற்றும் சரிபார்ப்பு :  </span>பரிந்துரைக்கப்பட்டவர்களின் சாதனைகள் பற்றிய முழுமையான ஆராய்ச்சி மற்றும் சரிபார்ப்பு. இது தள வருகைகள், நேர்காணல்கள் மற்றும் அவர்களின் பங்களிப்புகள் பற்றிய விரிவான மதிப்பாய்வு ஆகியவற்றை உள்ளடக்கியிருக்கலாம்.

</li>
    <li><span>சமூக கருத்து : </span>வேட்பாளரின் தாக்கம் மற்றும் நற்பெயரைப் புரிந்து கொள்ள விவசாய சமூகம் அல்லது தொடர்புடைய பங்குதாரர்களிடமிருந்து கருத்துக்களைப் பெறவும். இது முடிவெடுக்கும் செயல்முறைக்கு மதிப்புமிக்க முன்னோக்குகளை சேர்க்கலாம்.

</li>
    <li><span>தேர்வு அளவுகோல் : </span>ஒவ்வொரு காரணிக்கும் தேர்வு அளவுகோல் மற்றும் எடையை தெளிவாக வரையறுக்கவும். இது நியாயமான மற்றும் வெளிப்படையான மதிப்பீட்டு செயல்முறையை உறுதி செய்கிறது.
</li>
    <li><span>அறிவிப்பு மற்றும் அங்கீகாரம் : </span>விருது பெற்றவர் தேர்ந்தெடுக்கப்பட்டதும், முறையான அறிவிப்பு மற்றும் அங்கீகார விழாவை திட்டமிடுங்கள். இது ஒரு பொது நிகழ்வு, செய்தி வெளியீடுகள் அல்லது பெறுநரின் சாதனைகளைக் கொண்டாடுவதற்கான பிற வகையான ஒப்புகைகளை உள்ளடக்கியுள்ளது.
</li>
    <li><span>ஆவணங்கள் மற்றும் விருது வழங்குதல் : </span>தேவையான ஆவணங்கள், சான்றிதழ்கள் மற்றும் விருதுகளைத் தயாரிக்கவும். தேர்ந்தெடுக்கப்பட்ட தனிநபருக்கு வாழ்நாள் சாதனையாளர் விருது வழங்கும் விழாவை ஏற்பாடு செய்து, விவசாய சமூகத்திற்கு அவர்கள் ஆற்றிய பங்களிப்பைச் சிறப்பித்தல்.
</li>
    <li><span>விருதுக்குப் பிந்தைய நோக்கம் : </span>விவசாயத் துறையில் அவர்களின் நேர்மறையான தாக்கத்தைத் தொடர, தற்போதைய முயற்சிகள் அல்லது வழிகாட்டல் திட்டங்களில் விருது பெற்றவர்களை ஈடுபடுத்துவதற்கான வழிகளைக் கவனித்தல்.
</li>

<li><span>விளம்பரம் மற்றும் கொண்டாட்டம் : </span>விருது பெற்றவரின் வெற்றிக் கதைகள் மற்றும் சாதனைகளை பல்வேறு சேனல்கள் மூலம் மற்றவர்களுக்கு ஊக்குவிக்கவும் மற்றும் விவசாய சமூகத்திற்கு நேர்மறையான பங்களிப்புகளை முன்னிலை படுத்துவதையும் முக்கியமாக கொண்டுள்ளது.

</li>

</ol>

<p>விருதின் குறிப்பிட்ட சூழல் மற்றும் இலக்குகளின் அடிப்படையில் இந்தப் படிகளை மாற்றியமைக்க நினைவில் கொள்ளுங்கள், மேலும் செயல்முறை முழுவதும் வெளிப்படைத்தன்மை மற்றும் நேர்மையை உறுதிப்படுத்துகிறது.</p>
           </div>
        </div>









               
        
       

    </div>
);
}