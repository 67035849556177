import React from "react";
import "./Sponsors.css"
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
export default function Sponsors(){

    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/sponsors">English</NavLink></a>


        </div>
            <div className="social-main">

            <h1>Zha Sponsorship Program</h1>
            </div>

            <div className="titlesponsor">
                <div className="titleimg">
                <img src="../img/sponsorship.webp"></img>
                </div>
                <p>ఝా ఫౌండేషన్ నిర్వహించే ఆర్గానిక్ ఫార్మర్స్ లీగ్ పోటీకి స్పాన్సర్‌లు ప్రముఖ ఎక్స్‌పోజర్ అవకాశాలను పొందుతారు. మీ బ్రాండ్ యొక్క ప్రకటనలు పోటీ వేదికల వద్ద వ్యూహాత్మకంగా ఉంచబడతాయి, అవి విభిన్నమైన మరియు నిమగ్నమైన ప్రేక్షకులచే చూడబడుతున్నాయని నిర్ధారిస్తుంది. అదనంగా, విలువైన స్పాన్సర్‌గా, మీ కంపెనీ మా వెబ్‌సైట్‌లోని అంకితమైన "స్పాన్సర్" విభాగంలో ప్రముఖంగా ప్రదర్శించబడుతుంది. ఈ ఆన్‌లైన్ ఉనికి మీ దృశ్యమానతను మరింత మెరుగుపరుస్తుంది మరియు సుస్థిర వ్యవసాయానికి మరియు నేచురల్ ఫార్మర్స్ లీగ్ యొక్క మిషన్‌కు మద్దతు ఇవ్వడానికి మీ నిబద్ధతను ప్రదర్శిస్తుంది. మాతో స్పాన్సర్‌గా చేరండి మరియు పర్యావరణ అనుకూల వ్యవసాయ పద్ధతుల పట్ల మక్కువ ఉన్న కమ్యూనిటీకి మీ బ్రాండ్‌ను ప్రదర్శించే అవకాశాన్ని పొందండి.</p>

            </div>
           

            <div className="freepik">
<h5 className="cardtitle">ఆర్గానిక్ ఫార్మర్స్ లీగ్ స్పాన్సర్‌లకు ఇక్కడ ప్రయోజనాలు ఉన్నాయి.</h5>
</div>

           
<div className="cards">
<Card style={{ width: '18rem' }}>
<Card.Body>
           <Card.Title>ప్రసార వార్తసేకరణ</Card.Title>
           <Card.Text>
           యూట్యూబ్ మీడియా కనీసం 30 నిమిషాల పాటు ఝా ఈవెంట్‌లను ప్రత్యక్ష ప్రసారం చేస్తుంది మరియు మా ఝా ఈవెంట్‌లు టెలివిజన్ న్యూస్ మీడియాలో కవర్ చేయబడతాయి  </Card.Text>
         </Card.Body>
       </Card>
       
   

   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>మెరుగైన దృశ్యమానత</Card.Title>
           <Card.Text>
           ఒక ప్రైమ్ ఫామ్ ఏరియా స్పాట్‌లో 3 నెలల పాటు హోర్డింగ్‌ను స్పాన్సర్ చేయడం ద్వారా, మీరు టార్గెట్ చేయబడిన ప్రేక్షకుల మధ్య గణనీయమైన దృశ్యమానతను పొందుతారు. ఈ ఎక్స్పోజర్ మీ బ్రాండ్ గుర్తింపును గణనీయంగా పెంచుతుంది.
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>కాస్ట్-ఎఫెక్టివ్ అడ్వర్టైజింగ్</Card.Title>
           <Card.Text>
           ఒక ఫామ్ ఏరియా స్పాట్‌లో 3 నెలల పాటు హోర్డింగ్‌ను స్పాన్సర్ చేయడం ద్వారా, మీరు కేవలం 12,000 రూపాయల ఖర్చుతో మీ లక్ష్య ప్రేక్షకులను సమర్థవంతంగా చేరుకోవచ్చు. మీ బ్రాండ్ లేదా ఉత్పత్తులను ప్రమోట్ చేయడానికి ఇది అత్యంత తక్కువ ఖర్చుతో కూడుకున్న మార్గం.
           </Card.Text>
         </Card.Body>
       </Card>
   
   
   </div>
   <div className="cards2">
   

   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>ఆన్‌లైన్ విజిబిలిటీ</Card.Title>
           <Card.Text>
           ఫిజికల్ హోర్డింగ్ ప్లేస్‌మెంట్‌తో పాటు, మీ స్పాన్సర్‌షిప్‌లో ఆన్‌లైన్ ఎక్స్‌పోజర్ ఉంటుంది. మీ బ్రాండ్ వెబ్‌సైట్‌లో ప్రదర్శించబడుతుంది, మీ డిజిటల్ ఉనికిని మరియు చేరువను పెంచుతుంది.
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>ఉమ్మడి ప్రకటనల అవకాశాలు</Card.Title>
           <Card.Text>
           మీరు హోర్డింగ్‌ను స్పాన్సర్ చేసినప్పుడు, ఇతర డీలర్‌లతో కలిసి ప్రకటనల ప్రయోజనం ఉంటుంది. ఈ భాగస్వామ్య ప్లాట్‌ఫారమ్ విజిబిలిటీని పెంచడానికి దారి తీస్తుంది, ఎందుకంటే కస్టమర్‌లు ఒకే లొకేషన్‌లో బహుళ వ్యాపారాలను గమనించే అవకాశం ఉంది.
           </Card.Text>
         </Card.Body>
       </Card>
   
      
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>అనుకూలీకరించిన ఎక్స్పోజర్</Card.Title>
           <Card.Text>
           మీ క్లయింట్‌కు బహుళ ఫార్మ్ గేమ్ స్పాట్‌లలో ప్రకటనలు అవసరమైతే, మీరు హోర్డింగ్ ప్లేస్‌మెంట్‌లను గుణించడం ద్వారా మీ స్పాన్సర్‌షిప్‌ను సులభంగా పెంచుకోవచ్చు. ఈ సౌలభ్యం మీ నిర్దిష్ట అవసరాలు మరియు బడ్జెట్‌కు అనుగుణంగా మీ ప్రకటనల వ్యూహాన్ని రూపొందించడానికి మిమ్మల్ని అనుమతిస్తుంది.
           </Card.Text>
         </Card.Body>
       </Card>
   
   </div>

   
        </div>
    
    );
}