import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import './Whyzha.css';
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability">English</NavLink></a>



</div>
            <div className="social-ma">

<h1>स्थिरता का कारण क्यों?</h1>
</div>


<div className="internship">
<img src="../img/360.webp"></img>
        </div>
<div className="goalpointsp">

<p>झा फाउंडेशन जैसे स्थिरता विकास क्लब का सभी देशों में विस्तार करना विविध पर्यावरणीय और सामाजिक चुनौतियों का समाधान करके वैश्विक प्रभाव डालने की अनुमति देता है। यह संसाधनों, ज्ञान और सर्वोत्तम प्रथाओं को साझा करने में सक्षम बनाता है, व्यापक पैमाने पर सतत विकास के लिए एक सहयोगात्मक दृष्टिकोण को बढ़ावा देता है। इसके अतिरिक्त, वैश्विक मुद्दों की परस्पर जुड़ी प्रकृति एक अधिक टिकाऊ और लचीली दुनिया बनाने के लिए व्यापक भागीदारी की आवश्यकता पर जोर देती है। अस्थिर मानव जीवन के कारण नीचे बताए गए हैं। मनुष्यों के लिए अस्थिर जीवनशैली को कई परस्पर जुड़े कारकों के लिए जिम्मेदार ठहराया जा सकता है:
</p>


<ol className="goalpoints">
    <li><span>अत्यधिक मोबाइल फोन का उपयोग : </span>बच्चे और माता-पिता तेजी से मोबाइल फोन पर काफी समय व्यतीत कर रहे हैं, जिसके कारण शारीरिक गतिविधि, पढ़ने की आदतों और सामाजिक संपर्क में गिरावट आई है।</li>
    <li><span>वीडियो गेम का अत्यधिक उपयोग : </span>विशेष रूप से बच्चे, खेल में भाग लेने और पढ़ने के प्रति प्रेम पैदा करने की कीमत पर, वीडियो गेम में अत्यधिक घंटे बिता रहे हैं।</li>
    <li><span>सामाजिक विश्वास का क्षरण : </span>डिजिटल विकर्षणों के कारण लोगों के बीच आमने-सामने की बातचीत में कमी विश्वास और पारस्परिक संबंधों में गिरावट में योगदान दे रही है, जिससे हमारे सामाजिक संबंधों की गुणवत्ता के बारे में चिंताएं बढ़ रही हैं।</li>


    <li><span>अत्यधिक उपभोग और भौतिकवाद : </span>अत्यधिक भौतिक संपत्ति की खोज और आरामदायक और पूर्ण जीवन के लिए आवश्यक संसाधनों से अधिक संसाधनों का उपभोग अस्थिर जीवन शैली को जन्म दे सकता है।
</li>
    <li><span>संसाधनों की कमी : </span>जीवाश्म ईंधन, खनिज और ताजे पानी सहित प्राकृतिक संसाधनों के अत्यधिक दोहन से उनकी कमी हो सकती है और पर्यावरणीय गिरावट में योगदान हो सकता है।


</li>
    <li><span>अपशिष्ट उत्पादन : </span>अकुशल संसाधन उपयोग और अनुचित अपशिष्ट निपटान प्रदूषण, लैंडफिल संचय और पर्यावरण में हानिकारक पदार्थों की रिहाई में योगदान करते हैं।


</li>
    <li><span>उच्च ऊर्जा खपत : </span>ऊर्जा-गहन प्रथाओं, जैसे बिजली का अत्यधिक उपयोग, हीटिंग और परिवहन पर निर्भरता से ग्रीनहाउस गैस उत्सर्जन और पर्यावरणीय तनाव में वृद्धि हो सकती है।


</li>
    <li><span>परिवहन और आवागमन की आदतें : </span>परिवहन के लिए निजी वाहनों पर निर्भरता, विशेष रूप से कुशल सार्वजनिक परिवहन की कमी वाले क्षेत्रों में, वायु प्रदूषण, भीड़भाड़ और जीवाश्म ईंधन की खपत में योगदान कर सकती है।
</li>
    <li><span>खाद्य उत्पादन और उपभोग : </span>मोनोकल्चर खेती, रासायनिक आदानों का अत्यधिक उपयोग और भोजन की लंबी दूरी के परिवहन सहित अस्थिर कृषि पद्धतियां, पर्यावरणीय गिरावट और खाद्य सुरक्षा चुनौतियों का कारण बन सकती हैं।
</li>
    <li><span>वनों की कटाई और आवास का विनाश : </span>कृषि, शहरीकरण और बुनियादी ढांचे के विकास के लिए भूमि को साफ़ करने से जैव विविधता का नुकसान हो सकता है, पारिस्थितिक तंत्र में व्यवधान हो सकता है और कार्बन अवशोषण में कमी आ सकती है।

</li>
<li><span>जीवन की जीत का जश्न मनाना : </span>जब तक हम जीवित हैं, हम दूसरों की उपलब्धियों का जश्न मनाने के लिए समय नहीं निकालते
</li>
<li><span>सहानुभूति की उपेक्षा : </span>हम दूसरों के नुकसान आदि के लिए अपनी संवेदनाएँ साझा करने के लिए समय नहीं निकालते हैं</li>
    <li><span>अत्यधिक मछली पकड़ना और समुद्री क्षरण : </span>अत्यधिक मछली पकड़ने और विनाशकारी मछली पकड़ने के तरीकों सहित मछली पकड़ने की अस्थिर प्रथाएं, मछली के भंडार को ख़त्म कर सकती हैं और समुद्री पारिस्थितिकी तंत्र को नुकसान पहुंचा सकती हैं।

</li> 

<li><span>स्थायी बुनियादी ढांचे की कमी : </span>अपर्याप्त शहरी नियोजन, अकुशल भवन डिजाइन और पुराना बुनियादी ढांचा संसाधन अक्षमता और पर्यावरणीय तनाव में योगदान कर सकता है।

</li> 

<li><span>उपभोक्ता व्यवहार और विकल्प : </span>उपभोक्ताओं द्वारा चुने गए विकल्प, जैसे गैर-टिकाऊ उत्पाद खरीदना, संसाधन-गहन और पर्यावरण की दृष्टि से हानिकारक वस्तुओं और सेवाओं की मांग को बढ़ा सकते हैं।

</li> 

<li><span>पर्यावरणीय जागरूकता और शिक्षा का अभाव : </span>कुछ व्यवहारों के पर्यावरणीय प्रभावों के बारे में अपर्याप्त ज्ञान और समझ अनजाने में अस्थिर प्रथाओं को जन्म दे सकती है।
</li> 

<li><span>नीति और नियामक ढांचे : </span>कमजोर या अपर्याप्त पर्यावरण नीतियां, प्रवर्तन की कमी, और सब्सिडी जो अस्थिर प्रथाओं को प्रोत्साहित करती है, अस्थिर जीवन शैली में योगदान कर सकती है।
</li> 
</ol>



<p>अस्थिर जीवनशैली को संबोधित करने के लिए बहुआयामी दृष्टिकोण की आवश्यकता है, जिसमें शिक्षा, नीति परिवर्तन, तकनीकी नवाचार और सांस्कृतिक और सामाजिक मानदंडों में बदलाव शामिल हैं। टिकाऊ प्रथाओं को प्रोत्साहित करना, जिम्मेदार उपभोग को बढ़ावा देना और पर्यावरण-अनुकूल प्रौद्योगिकियों को अपनाना अधिक टिकाऊ भविष्य की दिशा में महत्वपूर्ण कदम हैं।</p>



</div>
        
     

        </div>

    );
}

