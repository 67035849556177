import React from "react";
import "./Socialservices.css"
import { NavLink } from "react-router-dom";
export default function Socialservices(){

    return(

        <div>
               <div  id="language" >
        
        <a href=""><NavLink to="/Socialservices">English</NavLink></a>

        </div>
        
            <div className="social-main">

            <h1>Social Service</h1>
            </div>
            <div className="social-content">

        <div className="social-box">
        <img src="../../img/leaf.webp"></img>
        <h2>ಸಮರ್ಥನೀಯತೆ</h2>
        <p>ಝಾ ಫೌಂಡೇಶನ್ ಭವಿಷ್ಯದ ಪೀಳಿಗೆಗೆ ಗ್ರಹವನ್ನು ರಕ್ಷಿಸಲು ಸುಸ್ಥಿರತೆಗೆ ಆದ್ಯತೆ ನೀಡುತ್ತದೆ. ಅವರು ಶಕ್ತಿ, ಕೃಷಿ, ಸಾರಿಗೆ ಮತ್ತು ನಿರ್ಮಾಣದಲ್ಲಿ ಸಮರ್ಥನೀಯ ಪರಿಹಾರಗಳನ್ನು ಕಾರ್ಯಗತಗೊಳಿಸುತ್ತಾರೆ. ಸಮರ್ಥನೀಯತೆಯು ಜವಾಬ್ದಾರಿಯುತ ಸಂಪನ್ಮೂಲ ಬಳಕೆ, ನವೀಕರಿಸಬಹುದಾದ ಶಕ್ತಿ, ತ್ಯಾಜ್ಯ ಕಡಿತ ಮತ್ತು ಪ್ರಕೃತಿಯೊಂದಿಗೆ ಸಾಮರಸ್ಯವನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ. ಪ್ರತಿಷ್ಠಾನವು ಚೇತರಿಸಿಕೊಳ್ಳುವ, ಪರಿಸರ ಸ್ನೇಹಿ ಜಗತ್ತನ್ನು ಸೃಷ್ಟಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದೆ, ಅಲ್ಲಿ ಜನರು ಮತ್ತು ಪರಿಸರವು ಸಮತೋಲನದಲ್ಲಿ ಅಭಿವೃದ್ಧಿ ಹೊಂದುತ್ತದೆ. ಅಲ್ಲದೆ, ಗ್ರಾಮೀಣ ಮತ್ತು ಉಪನಗರ ಪ್ರದೇಶಗಳಲ್ಲಿ ಉದ್ಯೋಗವನ್ನು ಸೃಷ್ಟಿಸುತ್ತದೆ. ದುರಾಸೆಯಿಲ್ಲದ ಜೀವನಕ್ಕಾಗಿ ಆರೋಗ್ಯಕರ ಸಮುದಾಯವನ್ನು ರಚಿಸುವುದು.

        </p>
        </div>
       
        <div className="social-box">
        <img src="../../img/heal.webp"></img>
        <h2>ಆರೋಗ್ಯ ಶಿಬಿರಗಳು</h2>
        <p>ಝಾ ಫೌಂಡೇಶನ್ ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಸೇವೆಯನ್ನು ಪ್ರವೇಶಿಸಲು ಹಿಂದುಳಿದವರ ಸವಾಲುಗಳನ್ನು ಗುರುತಿಸುತ್ತದೆ. ಆದ್ದರಿಂದ ನಾವು ಭೌಗೋಳಿಕತೆಯಾದ್ಯಂತ ಉಚಿತ ಮಲ್ಟಿಸ್ಪೆಷಾಲಿಟಿ ಆರೋಗ್ಯ ಶಿಬಿರಗಳನ್ನು ಆಯೋಜಿಸುತ್ತೇವೆ ಇದರಲ್ಲಿ ವೈದ್ಯರಿಂದ ಸಂಪೂರ್ಣ ತಪಾಸಣೆ, ಉಚಿತ ಔಷಧಿಗಳ ವಿತರಣೆ ಜೊತೆಗೆ ವಿಶೇಷ ಪರೀಕ್ಷೆಗಳಾದ ಬಿಪಿ, ಬ್ಲಡ್ ಶುಗರ್ ಮತ್ತು ಹಿಮೋಗ್ಲೋಬಿನ್ (ಎಚ್‌ಬಿ) ಮಾನಿಟರಿಂಗ್. ಈ ಸಮಯೋಚಿತ ಮಧ್ಯಸ್ಥಿಕೆಗಳು ಆರಂಭಿಕ ರೋಗನಿರ್ಣಯ ಮತ್ತು ಚಿಕಿತ್ಸೆಯನ್ನು ಒದಗಿಸುತ್ತವೆ, ಇದು ರೋಗ ಮತ್ತು ಮರಣದ ಸಂಭವವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ. ಇಂತಹ ಆರೋಗ್ಯ ಶಿಬಿರಗಳು ಅಂಚಿನಲ್ಲಿರುವ ಸಮುದಾಯಗಳ ತಕ್ಷಣದ ಆರೋಗ್ಯ ಅಗತ್ಯಗಳನ್ನು ಪರಿಹರಿಸುತ್ತವೆ</p>
        </div>
        <div className="social-box">
        <img src="../../img/youth.webp"></img>
        <h2>ಯುವಕರ ಕ್ಷೇಮಕ್ಕಾಗಿ ಕ್ರೀಡೆಗಳನ್ನು ಸುಗಮಗೊಳಿಸುವುದು</h2>
        <p>ಕ್ರೀಡೆಯು ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಮತ್ತು ವಿಶೇಷವಾಗಿ ಮಕ್ಕಳು ಮತ್ತು ಯುವಕರು ಸದೃಢರಾಗಲು, ಕೌಶಲ್ಯಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು, ಸ್ನೇಹಿತರನ್ನು ಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಭಾವನಾತ್ಮಕ ಎತ್ತರ ಮತ್ತು ಕಡಿಮೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಕಲಿಯಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಮಗುವಿಗೆ ಉತ್ತಮ ರೋಲ್ ಮಾಡೆಲ್ ಆಗುವ ಮೂಲಕ, ಸಕಾರಾತ್ಮಕ ಮನೋಭಾವವನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುವ ಮೂಲಕ ಮತ್ತು ಆಸಕ್ತಿಯನ್ನು ತೋರಿಸುವ ಮೂಲಕ ಉತ್ತಮ ಕ್ರೀಡೆಯಾಗಲು ಸಹಾಯ ಮಾಡಿ. ಸೋಲು-ಗೆಲುವಿನ ಬದಲು ಪ್ರಯತ್ನ, ಭಾಗವಹಿಸುವಿಕೆ ಮತ್ತು ವಿನೋದದ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸಿ. ಮಕ್ಕಳು ಕ್ರೀಡೆಯನ್ನು ಆಡಲು ಬಯಸದಿದ್ದರೆ, ದೈಹಿಕ ಚಟುವಟಿಕೆಗಾಗಿ ಅವರಿಗೆ ಹಲವು ಆಯ್ಕೆಗಳಿವೆ. ಝಾ ಅವರು ಉಪನಗರ ಮತ್ತು ಗ್ರಾಮೀಣ ಸರ್ಕಾರಿ ಶಾಲಾ ಮಕ್ಕಳಿಗೆ ಕ್ರೀಡಾ ಸ್ಪರ್ಧೆ ಇತ್ಯಾದಿಗಳ ಬಗ್ಗೆ ವೇದಿಕೆ ಹೊಂದಲು ಎಲ್ಲರಿಗೂ ಸಹಾಯ ಮಾಡಲು ವಿವಿಧ ಸ್ಪರ್ಧೆಗಳನ್ನು ನಡೆಸುತ್ತಾರೆ.</p>
        </div>
            </div>
            <div className="goals">
                <h1 >ನಮ್ಮ <sapn className="goalsspan">ಸುಸ್ಥಿರತೆಯ ಗುರಿಗಳು</sapn></h1>
                <div className="susgoal">
                 <div>
                 <img src="../../img/sustainabilitygoals.webp"></img>
                    </div>   
                    <p>
                <ul>
        <li> <span>ಬಡತನವಿಲ್ಲ :</span> ಬಡತನವನ್ನು ಅದರ ಎಲ್ಲಾ ರೂಪಗಳಲ್ಲಿ ಕೊನೆಗೊಳಿಸಿ ಮತ್ತು ಬಡವರು ಮತ್ತು ದುರ್ಬಲರಿಗೆ ಸಾಮಾಜಿಕ ರಕ್ಷಣೆಯನ್ನು ಖಚಿತಪಡಿಸಿ.</li>
        <li><span>ಶೂನ್ಯ ಹಸಿವು :</span> ಆಹಾರ ಭದ್ರತೆಯನ್ನು ಸಾಧಿಸಿ, ಪೌಷ್ಟಿಕಾಂಶವನ್ನು ಸುಧಾರಿಸಿ ಮತ್ತು ಸುಸ್ಥಿರ ಕೃಷಿಯನ್ನು ಉತ್ತೇಜಿಸಿ.</li>
        <li><span>ಉತ್ತಮ ಆರೋಗ್ಯ ಮತ್ತು ಯೋಗಕ್ಷೇಮ : </span>ಆರೋಗ್ಯಕರ ಜೀವನವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಎಲ್ಲಾ ವಯಸ್ಸಿನ ಎಲ್ಲರಿಗೂ ಯೋಗಕ್ಷೇಮವನ್ನು ಉತ್ತೇಜಿಸಿ.</li>
        <li> <span>Quality Education : </span>ಅಂತರ್ಗತ ಮತ್ತು ಸಮಾನ ಗುಣಮಟ್ಟದ ಶಿಕ್ಷಣವನ್ನು ಖಾತ್ರಿಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಎಲ್ಲರಿಗೂ ಆಜೀವ ಕಲಿಕೆಯ ಅವಕಾಶಗಳನ್ನು ಉತ್ತೇಜಿಸಿ.</li>
        <li> <span>ಲಿಂಗ ಸಮಾನತೆ :</span> ಲಿಂಗ ಸಮಾನತೆಯನ್ನು ಸಾಧಿಸಿ ಮತ್ತು ಎಲ್ಲಾ ಮಹಿಳೆಯರು ಮತ್ತು ಹುಡುಗಿಯರನ್ನು ಸಬಲೀಕರಣಗೊಳಿಸಿ.
</li>
            </ul>
                </p>

                </div>
            </div>
        </div>
    
    );
}