import React from "react";
 import "./Member.css";
// import "./Transformation.css";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";

export default function Transformation(){

    return(
        <div>
            <div className="social-ma">

<h1>Welcome to our Membership Program!</h1>
</div>

<div className="internship">
<img src="../img/member.jpg"></img>
        </div> 

<div className="Donateus">
<NavLink to="/Donate"> <button className="donate-button">Donate </button></NavLink>
        </div>  

 

        <div className="goalpointsp">

<div className="membershipcolumn">
    <div>
<h3>Basic Membership</h3>
<p>The Basic Membership is tailored for individuals seeking to embrace sustainability in their lives. For just 300 rupees per month, members gain access to essential resources, participate in community discussions, and receive regular updates on sustainability practices.</p>
 
</div>
<div className="membershipimg">
    <img src="../img/basic.jpg"></img>
</div>
</div>

<div className="membershipcolumnt">
    <div>
<h3>Student Membership</h3>
<p>Geared towards students eager to learn and contribute, the Student Membership provides access to educational resources, mentorship programs, and student-led initiatives. For 100 rupees per month or 1000 rupees annually, students connect with peers, engage in projects, and gain valuable experience in the field.</p>
</div>
<div className="membershipimg">
    <img src="../img/student.jpg"></img>
</div>
</div>

<div className="membershipcolumn">
    <div>
<h3>Professionals Board Membership</h3>
<p>Dedicated to professionals committed to integrating sustainability into their careers, the Professionals Board Membership offers advanced workshops, specialized training sessions, and exclusive industry insights. For 600 rupees per month, members collaborate with seasoned practitioners, engage in projects, and stay updated on the latest trends.</p>
</div>
<div className="membershipimg">
    <img src="../img/professional.jpg"></img>
</div>
</div>

<div className="membershipcolumnt">
    <div>
<h3>Strategic Board Membership</h3>
<p>Designed for leaders catalyzing impactful change, the Strategic Board Membership provides access to strategic planning sessions, executive forums, and high-level networking events. For 1000 rupees per month, members receive personalized guidance on implementing sustainability initiatives and maximizing their influence.</p>
</div>
<div className="membershipimg">
    <img src="../img/strategic.jpg"></img>
</div>
</div>

<div className="membershipcolumn">
    <div>
<h3>NRI Board Membership</h3>
<p>For non-resident Indians passionate about supporting sustainability in India, the NRI Board Membership offers exclusive benefits like virtual networking opportunities and access to online resources. With an annual fee of 10,000 rupees, members demonstrate their commitment to sustainable development in India from anywhere in the world.</p>
</div>
<div className="membershipimg">
    <img src="../img/nrii.jpg"></img>
</div>
</div>
 

</div>
           
        {/* <div className="internship">
        <img src="../img/internship.png"></img>
        </div> */}

     

        </div>

    );
}

