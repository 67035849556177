import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Common-Humans-COPs">English</NavLink></a>

        </div>

        
{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ಝಾ ಕಾಮನ್ ಮ್ಯಾನ್ COP ಗಳು </h1>
</div>


<div className="internship">
<img src="../img/cops.webp"></img>
        </div>
<div className="goalpointsp">

<p>ಝಾ ಕಾಮನ್ ಮ್ಯಾನ್ ಕಮ್ಯುನಿಟಿ ಆಫ್ ಪ್ರಾಕ್ಟೀಸಸ್ (COPs) ಜಾಗತಿಕವಾಗಿ ವ್ಯಕ್ತಿಗಳಲ್ಲಿ ಸುಸ್ಥಿರತೆಯ ಮನಸ್ಥಿತಿಯನ್ನು ಬೆಳೆಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದೆ. ಝಾ ಫೌಂಡೇಶನ್‌ನ ಜಾಗತಿಕ ಮಂಡಳಿಯ ಸದಸ್ಯರಿಂದ ಮಾರ್ಗದರ್ಶನ ಪಡೆದ, COP ಗಳು ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳ ಕಡೆಗೆ ಕ್ರಮವನ್ನು ಶಿಕ್ಷಣ, ಚರ್ಚಿಸಲು ಮತ್ತು ಪ್ರೇರೇಪಿಸಲು ಮಾಸಿಕ ವೇದಿಕೆಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತವೆ. ಗುರಿಗಳಲ್ಲಿ ಜಾಗೃತಿ ಮೂಡಿಸುವುದು, ಉತ್ತಮ ಅಭ್ಯಾಸಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವುದು ಮತ್ತು ಸುಸ್ಥಿರತೆಗೆ ಬದ್ಧವಾಗಿರುವ ಜಾಗತಿಕ ನೆಟ್‌ವರ್ಕ್ ಅನ್ನು ಬೆಳೆಸುವುದು ಸೇರಿವೆ. ಪ್ರಯೋಜನಗಳು ಜ್ಞಾನ ವಿನಿಮಯ, ಸಹಯೋಗದ ಉಪಕ್ರಮಗಳು ಮತ್ತು ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಜಗತ್ತಿಗೆ ಕೊಡುಗೆ ನೀಡಲು ವ್ಯಕ್ತಿಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.
</p>
<p>
ಆದ್ದರಿಂದ ನಾವು ಪ್ರತಿ ದೇಶಕ್ಕೂ ನಮ್ಮ ವಿವಿಧ COP ಗಳನ್ನು ಹೊಂದಿದ್ದೇವೆ, ಅದು ಸೆಷನ್‌ಗಳು ಮತ್ತು ರೆಸಲ್ಯೂಶನ್ ಫಲಿತಾಂಶವನ್ನು ತಲುಪಿಸುವಲ್ಲಿ ಗಮನಹರಿಸಬೇಕು, ಈ ಕೆಳಗಿನ ಅಜೆಂಡಾಗಳ ಆಧಾರದ ಮೇಲೆ ಉಪಕ್ರಮಗಳನ್ನು ಆವಿಷ್ಕರಿಸಬೇಕು
</p>

<ol className="goalpoints">
    <li><span>ಶಿಕ್ಷಣ ಮತ್ತು ಜಾಗೃತಿ :</span>ಝಾ ಅವರ COP ಗಳು ಸಾಮಾನ್ಯ ಜನರಿಗೆ ಹವಾಮಾನ ಬದಲಾವಣೆ ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಬಗ್ಗೆ ಜ್ಞಾನವನ್ನು ಪಡೆಯಲು ವೇದಿಕೆಯನ್ನು ಒದಗಿಸುತ್ತವೆ, ಅವುಗಳ ಮಹತ್ವದ ಬಗ್ಗೆ ಆಳವಾದ ತಿಳುವಳಿಕೆಯನ್ನು ಬೆಳೆಸುತ್ತವೆ.</li>
    <li><span>ಸ್ಥಳೀಯ ಪರಿಣಾಮ : </span>ಸಾಮಾನ್ಯ ವ್ಯಕ್ತಿಗಳನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳುವ ಮೂಲಕ, COP ಗಳು ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳಿಗೆ ಕ್ರಮ ಕೈಗೊಳ್ಳಲು ಅಧಿಕಾರ ನೀಡುತ್ತವೆ, ಜಾಗತಿಕ ಸುಸ್ಥಿರತೆಯ ಪ್ರಯತ್ನಗಳಿಗೆ ಸಾಮೂಹಿಕವಾಗಿ ಕೊಡುಗೆ ನೀಡುತ್ತವೆ.
</li>
    <li><span>ನಡವಳಿಕೆಯ ಬದಲಾವಣೆ : </span>COP ಗಳು ದೈನಂದಿನ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ಅಭ್ಯಾಸಗಳ ಮೇಲೆ ಪ್ರಭಾವ ಬೀರುವ ಗುರಿಯನ್ನು ಹೊಂದಿವೆ, ಹವಾಮಾನ ಬದಲಾವಣೆಯ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ಆರಂಭದಲ್ಲಿ ಗ್ರಹಿಸದ ಭಾಗವಹಿಸುವವರಲ್ಲಿ ಸಮರ್ಥನೀಯ ಆಯ್ಕೆಗಳನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.</li>


    <li><span>ಸಮುದಾಯದ ಸಬಲೀಕರಣ:</span>ಮಾರ್ಗದರ್ಶನ ಮತ್ತು ಸಹಯೋಗದ ಮೂಲಕ, COP ಗಳು ವ್ಯಕ್ತಿಗಳು ತಮ್ಮ ಸಮುದಾಯಗಳಲ್ಲಿ ಸುಸ್ಥಿರತೆಗಾಗಿ ವಕೀಲರಾಗಲು ಅಧಿಕಾರ ನೀಡುತ್ತವೆ, ಧನಾತ್ಮಕ ಬದಲಾವಣೆಯ ಏರಿಳಿತದ ಪರಿಣಾಮವನ್ನು ಸೃಷ್ಟಿಸುತ್ತವೆ.

</li>
    <li><span>ಗ್ಲೋಬಲ್ ನೆಟ್‌ವರ್ಕ್ : </span>COPಗಳು ವೈವಿಧ್ಯಮಯ ಹಿನ್ನೆಲೆಯಿಂದ ಜನರನ್ನು ಸಂಪರ್ಕಿಸುತ್ತವೆ, ಜಾಗತಿಕ ಜವಾಬ್ದಾರಿಯ ಪ್ರಜ್ಞೆಯನ್ನು ಬೆಳೆಸುತ್ತವೆ ಮತ್ತು ವೈಯಕ್ತಿಕ ಕ್ರಿಯೆಗಳು ಒಟ್ಟಾರೆಯಾಗಿ ಗ್ರಹದ ಮೇಲೆ ಹೇಗೆ ಪರಿಣಾಮ ಬೀರುತ್ತವೆ ಎಂಬುದನ್ನು ವಿವರಿಸುತ್ತದೆ.


</li>
    <li><span>UN ಗುರಿಗಳೊಂದಿಗೆ ಹೊಂದಾಣಿಕೆ : </span>ಝಾ ಅವರ COP ಗಳು ವಿಶ್ವಸಂಸ್ಥೆಯ ಸುಸ್ಥಿರತೆಯ ಗುರಿಗಳೊಂದಿಗೆ ನೇರವಾಗಿ ಹೊಂದಿಕೆಯಾಗುತ್ತವೆ, ವಿಶಾಲವಾದ ಜಾಗತಿಕ ಉದ್ದೇಶಗಳನ್ನು ಸಾಧಿಸಲು ತಳಮಟ್ಟದ ವಿಧಾನವನ್ನು ನೀಡುತ್ತವೆ.


</li>
    <li><span>ಅಂತರ್ಗತ ಭಾಗವಹಿಸುವಿಕೆ : </span>ಸಾಮಾನ್ಯ ಜನರನ್ನು ಗುರಿಯಾಗಿಸುವ ಮೂಲಕ, COP ಗಳು ಒಳಗೊಳ್ಳುವಿಕೆಯನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ, ವ್ಯಾಪಕವಾದ ಜನಸಂಖ್ಯಾಶಾಸ್ತ್ರವನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳುತ್ತದೆ ಮತ್ತು ಸಮರ್ಥನೀಯತೆಯ ಭಾಷೆ ಅಥವಾ ಪರಿಕಲ್ಪನೆಗಳೊಂದಿಗೆ ಪರಿಚಯವಿಲ್ಲದವರನ್ನು ಹೊರಗಿಡುವುದನ್ನು ತಪ್ಪಿಸುತ್ತದೆ.


</li>
    <li><span>ಮಾಸಿಕ ವೇದಿಕೆಗಳು :</span>ನಿಯಮಿತ ವೇದಿಕೆಗಳು ನಡೆಯುತ್ತಿರುವ ಸಂವಾದ ಮತ್ತು ಕಲಿಕೆಗೆ ಸ್ಥಿರವಾದ ವೇದಿಕೆಯನ್ನು ಒದಗಿಸುತ್ತದೆ, ಕಾಲಾನಂತರದಲ್ಲಿ ಸಮರ್ಥನೀಯತೆಯ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ಬಲಪಡಿಸುತ್ತದೆ ಮತ್ತು ಭಾಗವಹಿಸುವವರ ನಿರಂತರ ಪ್ರಜ್ಞೆಯ ಭಾಗವಾಗಿ ಮಾಡುತ್ತದೆ.
</li>
    <li><span>ಪ್ರಾಯೋಗಿಕ ಪರಿಹಾರಗಳು : </span>COP ಗಳು ಪ್ರಾಯೋಗಿಕ, ಸಾಧಿಸಬಹುದಾದ ಪರಿಹಾರಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುತ್ತವೆ, ಸಾಮಾನ್ಯ ಜನರು ತಮ್ಮ ದೈನಂದಿನ ಜೀವನದಲ್ಲಿ ಅಳವಡಿಸಿಕೊಳ್ಳಬಹುದು, ಸುಸ್ಥಿರತೆಯನ್ನು ಹೆಚ್ಚು ಸುಲಭವಾಗಿ ಮತ್ತು ಕಡಿಮೆ ಅಮೂರ್ತವಾಗಿಸುತ್ತದೆ.
</li>
    <li><span>ಹೊಂದಾಣಿಕೆ ಮತ್ತು ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ : </span>COP ಗಳ ಮೂಲಕ ಸಾಮಾನ್ಯ ಜನರಿಗೆ ಶಿಕ್ಷಣ ನೀಡುವುದು ಸಮುದಾಯಗಳು ಬದಲಾಗುತ್ತಿರುವ ಪರಿಸರ ಪರಿಸ್ಥಿತಿಗಳಿಗೆ ಹೊಂದಿಕೊಳ್ಳಲು ಮತ್ತು ಹವಾಮಾನ ಬದಲಾವಣೆಯ ಪರಿಣಾಮಗಳ ವಿರುದ್ಧ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವವನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.


</li>
 
 </ol>
 
 <p>ಮೂಲಭೂತವಾಗಿ, ಝಾ ಅವರ ಕಾಮನ್ ಮ್ಯಾನ್ COP ಗಳು ಹವಾಮಾನ ಬದಲಾವಣೆಯ ಅಮೂರ್ತ ಪರಿಕಲ್ಪನೆಗಳು ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಗುರಿಗಳು ಮತ್ತು ದೈನಂದಿನ ವ್ಯಕ್ತಿಗಳ ಪ್ರಾಯೋಗಿಕ ಕ್ರಿಯೆಗಳ ನಡುವಿನ ಅಂತರವನ್ನು ಕಡಿಮೆ ಮಾಡುವಲ್ಲಿ ನಿರ್ಣಾಯಕ ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತವೆ, ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಮತ್ತು ತಿಳುವಳಿಕೆಯುಳ್ಳ ಜಾಗತಿಕ ಸಮಾಜವನ್ನು ರಚಿಸುತ್ತವೆ.</p>
 </div>

        </div>

    );
}

