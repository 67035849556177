import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">English</NavLink></a>

        </div>
        
<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
   <img src='../../img/certificate.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>பில்டர்கள் மற்றும் ரியல் எஸ்டேட்காரர்களுக்கான சூழல் நட்பு சான்றிதழைப் பெற, இந்த பொதுவான படிகளைப் பின்பற்றவும்:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>ஆராய்ச்சி ழ ஒப்புகை நிகழ்ச்சிகள் : </span> கட்டுமானத் துறைக்கு பொருந்தக்கூடிய எங்கள் மரியாதைக்குரிய சூழல் நட்பு சான்றிதழ் திட்டங்கள், சுற்றுச்சூழல் மதிப்பீட்டு தரநிலைகளை நிறுவுதல் பற்றிய ஆராய்ச்சி

</li>

<li><span>தேவைகளைப் புரிந்து கொள்ளுதல் : </span>  தேர்ந்தெடுக்கப்பட்ட சான்றிதழின் குறிப்பிட்ட அளவுகோல்கள் மற்றும் தேவைகளைப் பற்றி அறிந்து கொள்ளுங்கள். இதில் நிலையான கட்டுமானப் பொருட்கள், ஆற்றல் திறன், நீர் பாதுகாப்பு மற்றும் உட்புற காற்றின் தரம் ஆகியவை அடங்கும்.

</li>
<li><span>பயிற்சி மற்றும் கல்வி : </span>  நிலையான கட்டுமான நடைமுறைகள் குறித்து உங்கள் குழு கற்றிருப்பதை உறுதி செய்யவும். சில சான்றிதழ்களுக்கு முக்கிய பணியாளர்களுக்கு சில பயிற்சி தேவைப்படலாம்.

</li>
<li><span>நிலையான நடைமுறைகளை நடைமுறைப்படுத்துங்கள் : </span> உங்கள் கட்டுமான செயல்முறைகளில் சூழல் நட்பு நடைமுறைகளை ஒருங்கிணைக்கவும். இது மறுசுழற்சி செய்யப்பட்ட பொருட்களைப் பயன்படுத்துதல், ஆற்றல் பயன்பாட்டை மேம்படுத்துதல் மற்றும் பசுமை கட்டிட நுட்பங்களைப் பின்பற்றுதல் ஆகியவை அடங்கும்.

</li>
<li><span>ஆவணப்படுத்தல் : </span> உங்கள் நிலையான முயற்சிகள் பற்றிய முழுமையான பதிவுகளை வைத்திருங்கள். சான்றிதழுக்காக ஆவணப்படுத்தல் முக்கியமானது, நிறுவப்பட்ட தரநிலைகளுடன் இணங்குவதைக் காட்டுகிறது.

</li>
<li><span>பங்குதாரர்களை ஈடுபடுத்துங்கள் : </span> சுற்றுச்சூழல் நட்பு முயற்சிகளில் ஒப்பந்ததாரர்கள், சப்ளையர்கள் மற்றும் தொழிலாளர்கள் உட்பட அனைத்து பங்குதாரர்களையும் ஈடுபடுத்துங்கள். சான்றிதழை அடைவதே குறிக்கோளுடன் அனைவரும் இணைந்திருக்க வேண்டும்.

</li>
<li><span>முன் மதிப்பீடு : </span> உத்தியோகபூர்வ சான்றிதழ் செயல்முறைக்கு முன் முன்னேற்றம் தேவைப்படக்கூடிய பகுதிகளை அடையாளம் காண முன் மதிப்பீட்டைக் கவனியுங்கள். இந்த நடவடிக்கை ஏதேனும் குறைபாடுகளை நிவர்த்தி செய்து வெற்றிக்கான வாய்ப்புகளை அதிகரிக்க உதவும்.

</li>
<li><span>முறையான விண்ணப்பம் : </span> சான்றளிக்கும் அமைப்பிற்கு முறையான விண்ணப்பத்தை சமர்ப்பிக்கவும். தேவையான அனைத்து ஆவணங்கள், சான்றுகள் மற்றும் கட்டணங்களைச் சேர்க்கவும்.


</li>
<li><span>தள ஆய்வு : </span> சான்றளிப்பு அமைப்பின் பிரதிநிதிகளால் தள ஆய்வுக்குத் தயாராகுங்கள். உங்கள் கட்டுமானத் திட்டம் குறிப்பிட்ட சுற்றுச்சூழல் நட்பு தரநிலைகளுடன் ஒத்துப்போகிறதா என்பதை அவர்கள் மதிப்பிடுவார்கள்.

</li>
<li><span>சான்றிதழ் முடிவு : </span> சான்றிதழ் முடிவுக்காக காத்திருங்கள். வெற்றியடைந்தால், உங்கள் திட்டத்தின் சூழல் நட்பு நிலையைப் பற்றிய முறையான அங்கீகாரத்தைப் பெறுவீர்கள்.
</li>

<li><span>சான்றிதழை ஊக்குவிக்கவும் : </span> சான்றளிக்கப்பட்டவுடன், உங்கள் கட்டுமானத் திட்டங்களை விளம்பரப்படுத்த சூழல் நட்பு லேபிளைப் பயன்படுத்தவும். இது உங்கள் நிறுவனத்தின் நற்பெயரை அதிகரிக்கவும் சுற்றுச்சூழல் உணர்வுள்ள வாடிக்கையாளர்களை ஈர்க்கவும் முடியும்.
</li>

<li><span>விளம்பரங்களில் சேர்க்கலாம் : </span> ழ சுற்றுச்சூழல் நட்பு சான்றிதழில் ழ அறக்கட்டளையின் உறுதிப்படுத்தல் மின்னஞ்சலைப் பெற்றவுடன், அதை பில்டர் அதன் விளம்பரங்களிலும் வெளியிடலாம். ழ அறக்கட்டளை இணையதளம் ழ சுற்றுச்சூழல் நட்பு நிலைத்தன்மை கட்டமைப்பாளரில் சான்றிதழ் பெற்ற பில்டர்களை பட்டியலிடுகிறது.</li>

</ol>



<p>தேர்ந்தெடுக்கப்பட்ட சான்றிதழ் திட்டத்தைப் பொறுத்து குறிப்பிட்ட படிகள் மாறுபடலாம், எனவே தேர்ந்தெடுக்கப்பட்ட சான்றிதழ் அமைப்பின் தேவைகளை முழுமையாக மதிப்பாய்வு செய்வது அவசியம் என்பதை நினைவில் கொள்க. </p>


</section>
 

</div>
 
 
);
}

