import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
 

const questions = [
  {
    id: 1,
    category: "Human Wellness",
    question: "Do you engage in regular physical activity?",  
    customMessage: "Consider incorporating at least 30 minutes of moderate exercise into your daily routine. It could be as simple as a brisk walk or cycling."  ,
    answers: [
      { answer: "Yes", trueAnswer: true },
      { answer: "No", trueAnswer: false },
    ],
  },
    {
      id: 2,
      category: "Human Wellness",
      question: "Do you prioritize getting enough sleep each night?",
      customMessage: "Ensure you aim for 7-9 hours of quality sleep each night. Establish a consistent sleep schedule and create a relaxing bedtime routine."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 3,
      category: "Human Wellness",
      question: "Do you consume a balanced and nutritious diet?",
       customMessage: "Focus on incorporating a variety of fruits, vegetables, lean proteins, and whole grains into your diet for a well-rounded and nutritious intake."  , 
       answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
     
      
    {
      id: 4,
      category: "Human Wellness",
      question: "Do you schedule regular health check-ups?",
      customMessage: "Schedule routine health check-ups to monitor your well-being and address any potential health issues early on."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
 
    {
      id: 5,
      category: "Human Wellness",
      question: "Do you perceive professions such as farmers, road contractors, cleaners, common individuals, social workers, IT employees, technology enthusiasts, technology leaders, celebrities, doctors, and drivers with equality, comfort, and respect?",
      customMessage: "Perceive all professions, from farmers to technology leaders, with equality, comfort, and respect to foster an inclusive and harmonious society."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
     
 
    {
      id: 6,
      category: "Earth Wellness",
      question: "Do you recycle materials regularly?",
      customMessage: "Educate yourself on proper recycling practices in your area. Make a conscious effort to recycle paper, plastic, glass, and other materials."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 7,
      category: "Earth Wellness",
      question: "Do you use energy-efficient appliances in your home?",
      customMessage: "Consider upgrading to energy-efficient appliances to reduce your carbon footprint and lower energy consumption."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 8,
      category: "Earth Wellness",
      question: "Do you avoid single-use plastic products whenever possible?",
      customMessage: "Minimize the use of single-use plastic by opting for reusable alternatives and supporting businesses with eco-friendly packaging."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
     
     
    {
      id: 9,
      category: "Earth Wellness",
      question: "Do you participate in community clean-up events?",
      customMessage: "Participate in community clean-up events to contribute to a cleaner environment and promote a sense of responsibility."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 10,
      category: "Earth Wellness",
      question: "Do you conserve water by fixing leaks and using it responsibly?",
      customMessage: "Fix leaks promptly, use water responsibly, and explore water-saving practices to contribute to water conservation efforts."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    
  
    {
      id: 11,
      category: "Consistent Profit",
      question: "Do you have a diversified investment portfolio?",
      customMessage: "Explore various investment options to diversify your portfolio, spreading risk and potentially increasing returns."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 12,
      category: "Consistent Profit",
      question: "Do you actively manage and monitor your finances?",
      customMessage: "Regularly monitor your finances, create a budget, and track expenses to ensure financial stability and preparedness."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 13,
      category: "Consistent Profit",
      question: "Do you have an emergency fund for unexpected expenses?",
      customMessage: "Establish an emergency fund to cover unexpected expenses, providing a financial safety net during challenging times."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 14,
      category: "Consistent Profit",
      question: "Do you invest in your education and skills development?",
      customMessage: "Continuously invest in your education and skills development to stay competitive in your field and enhance career opportunities."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
      { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 15,
      category: "Consistent Profit",
      question: "Do you regularly review and adjust your financial goals?",
      customMessage: "Regularly review and adjust your financial goals based on changing circumstances and priorities to stay on the path to financial success."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    
 
    {
      id: 16,
      category: "Nature Wellness",
      question: "Do you spend regular time outdoors in nature?",
      customMessage: "Increase your time spent in nature, whether it's hiking, gardening, or simply enjoying outdoor activities to improve overall well-being."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 17,
      category: "Nature Wellness",
      question: "Do you practice sustainable and responsible outdoor activities?",
      customMessage: "Practice responsible outdoor activities, leaving nature as you found it and minimizing your impact on the environment."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    
    {
      id: 18,
      category: "Nature Wellness",
      question: "Do you support organizations working for wildlife conservation?",
      customMessage: "Contribute to wildlife conservation efforts by supporting organizations working towards protecting and preserving endangered species."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 19,
      category: "Nature Wellness",
      question: "Do you practice Leave No Trace principles in natural settings?",
      customMessage: "Follow Leave No Trace principles during outdoor adventures, ensuring minimal impact on natural environments and ecosystems."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
     
    {
      id: 20,
      category: "Nature Wellness",
      question: "Do you appreciate and respect the biodiversity around you?",
      customMessage: "Foster a deeper connection with nature by appreciating and respecting the biodiversity around you. Learn about the species in your area and their ecological importance."  ,
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    
];
 
function Quiz() {
  const { level } = useParams();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [time, setTime] = useState(30);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const isNextButton = currentQuestion < questions.length - 1;
  const isResultButton = currentQuestion >= questions.length - 1;

  const selectAnswer = (index) => {
    setSelectedIndex(index);
  };

  const nextQuestion = () => {
    addAnswer(selectedIndex);

    if (currentQuestion >= questions.length - 1) {
      setIsResult(true);
    } else {
      setTime(30);
      setCurrentQuestion(currentQuestion + 1);
      setSelectedIndex(null);
    }
  };

  const addAnswer = (index) => {
    const selectedAnswer =
      index !== null
        ? questions[currentQuestion].answers[index]
        : {
            answer: "Yes",
            trueAnswer: false,
          };
    const newAnswers = [...selectedAnswers, selectedAnswer];
    setSelectedAnswers(newAnswers);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    if (time <= 5) {
      setIsErrorMessage(true);
    } else {
      setIsErrorMessage(false);
    }

    if (time < 0) {
      clearInterval(timer);
      nextQuestion();
    }

    return () => clearInterval(timer);
  }, [time]);

 

  return isResult ? (
    navigate("/result", {
      state: {
        answers: selectedAnswers,
        questions,
      },
    })
  ) : (
    <div>
      <div className="quizhome">
        {/* Progress Box */}
        <div className="progress-box">
          {/* ... (unchanged) */}
        </div>

        {/* Question Box */}
        <div className="question-box">
          {/* Display category at the top */}
          <h2 className="category">{questions[currentQuestion].category}</h2>
          <br />
          <div className="question-text">
            <h2 className="question-title">Question: {currentQuestion + 1}</h2>
            <h3 className="question">
              {questions[currentQuestion].question}
            </h3>
          </div>
          <div
            className="progress-circle time"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": (time / 30) * 100 }}
          >
            <span className="time">{time}</span>
          </div>
        </div>

        {/* Answers Boxes */}
        <div className="answers-boxes">
          {questions[currentQuestion].answers.map((answer, index) => (
            <label
              onClick={() => selectAnswer(index)}
              key={index}
              htmlFor={index}
              className={
                selectedIndex === index
                  ? "answer-label selected"
                  : "answer-label"
              }
            >
              {answer.answer}
              <input type="radio" name="answer" id={index} />
            </label>
          ))}
        </div>

        {/* Next Button */}
        {isNextButton ? (
          <div className="next">
            <button
              onClick={nextQuestion}
              type="button"
              className="next-btn"
            >
              Next Question
              <div className="icon">
                <i className="bi bi-arrow-right"></i>
              </div>
            </button>
          </div>
        ) : null}

        {/* Result Button */}
        {isResultButton ? (
          <div className="next">
            <button
              onClick={nextQuestion}
              type="button"
              className="next-btn result-btn"
            >
              See Results
              <div className="icon">
                <i className="bi bi-bar-chart"></i>
              </div>
            </button>
          </div>
        ) : null}

        {/* Error Message */}
        {isErrorMessage ? (
          <div className="message animation">
            <div className="icon">
              <i className="bi bi-exclamation-triangle"></i>
            </div>
            <span>You must hurry up!</span>
          </div>
        ) : null}

        {/* Go To Home Page Link */}
        <div className="gotohomepage">
          <Link to="/hh" className="newquiz">
            Go To Home Page
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Quiz;