import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
{/* 
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>About Us</h1>
</div>



<div className="internship">
        <img src="../img/about.jpg"></img>
        </div>





        <section className="visionmi">

        <div className="purpose">
          <h4>Vision</h4>
          <p>To build a better future for the next generations, the ZHA Foundation is dedicated to the dual purpose of nurturing a sustainable mindset and conserving the natural resources of our planet.

          </p>
        </div>

        <div className="missi">
          <h4>Mission</h4>
          <p>The Zha Foundation, established in 2017, strives to cultivate a global community committed to fostering a sustainable mindset. We aim to transcend the negative impacts of unchecked greed and jealousy, recognizing their potential harm to nations and the planet's delicate balance. Collaborating with professionals and business leaders, our foundation advocates for a sustainable future, steering away from conflicts and promoting unity through three guiding principles for our zha members : no politics-based discussions, no discussions based on religion or caste, and a commitment to refrain from speaking negatively about fellow members.


          </p>

          <p>Sustainable development is imperative for a better tomorrow, balancing present needs without jeopardizing the well-being of future generations. The survival of our societies and shared planet hinges on fostering a sustainable mindset. The Sustainable Development Goals (SDGs), comprising seventeen interconnected objectives, serve as a "shared blueprint for peace and prosperity for people and the planet, now and into the future.
          </p>

         
        </div>
        


 
        </section>
     

        </div>

    );
}

