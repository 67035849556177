
import './App.css';
import Header from './components/Header/Header';
import Home from "./Home/Home";
import fire from './config/firebaseConfig';
import Login from './Login/Login';
import Signup from './Login/Signup';

import Donate from './Gpaypayment/Gpay'
import Payment from './Payment/Payment';

import Hometwo from './Mindset/Hometwo';
import Quiz from './Mindset/Quiz';
import Result from './Mindset/Result';
import Error from './Mindset/Error';

import Recogninition from './Nri/Recognition';

import Sustainabilitypractiners from './Health/Sustainabilitypractiners';

 
import Coaching from './Health/Coaching';
import Rescue from './Health/Rescue';
import Rescueta from './Health/Rescue-ta';
import Rescuete from './Health/Rescue-te';
import Rescuema from './Health/Rescue-ma';
import Rescuehi from './Health/Rescue-hi';
import Rescueka from './Health/Rescue-ka';


import Bluebeach from './Health/Bluebeach';
import Nri from './Nri/Nri';
import Story from './Health/Story';
import Storyta from './Health/Story-ta';
import Storyte from './Health/Story-te';
import Storyma from './Health/Story-ma';
import Storyka from './Health/Story-ka';
import Storyhi from './Health/Story-hi';
import Disaster from './Health/Disaster';
import Disasterta from './Health/Disaster-ta';
import Disasterte from './Health/Disaster-te';
import Disasterka from './Health/Disaster-ka';
import Disasterma from './Health/Disaster-ma';
import Disasterhi from './Health/Disaster-hi';
import Environment from './Health/Environment';
import Environmentta from './Health/Environment-ta';
import Environmentte from './Health/Environment-te';
import Environmenthi from './Health/Environment-hi';
import Environmentma from './Health/Environment-ma';
import Environmentka from './Health/Environment-ka';
import Women from './Health/Women';
import Womenta from './Health/Women-ta';
import Womente from './Health/Women-te';     
import Womenka from './Health/Women-ka';
import Womenhi from './Health/Women-hi';
import Womenma from './Health/Women-ma';
import Health from './Health/Health';
import Healthka from './Health/Health-ka';
import Healthta from './Health/Health-ta';
import Healthma from './Health/Health-ma';
import Healthhi from './Health/Health-hi';
import Healthte from './Health/Health-te';
import Wellness from './Wellness/Wellness';
import Wellnessta from './Wellness/Wellness-ta';
import Wellnesska from './Wellness/Wellness-ka';
import Wellnesste from './Wellness/Wellness-te';
import Wellnessma from './Wellness/Wellness-ma';
import Wellnesshi from './Wellness/Wellness-hi';
 

import Socialservices from "./Socialservices/Socialservices";
import Smalayalam from './Socialservices/Socialservices-ma';
import Shindi from './Socialservices/Socialservices-hi';
import Stelungu from './Socialservices/Socialservices-te';
import Skannada from './Socialservices/Socialservices-ka';
import Event from './Event/Event';
import Footer from './components/Footer/Footer';
import About from './About/About';
import Amalayalam from './About/About-ma';
import Atelungu from './About/About-te';
import Ahindi from './About/About-hi';
import Akannada from './About/About-ka';
import Contact from './Contact/Contact';
import Structure from './About/Structure';
import Export from './Howweworks/Export';
import Membership from './Membership/Membership';
import Mmalayalam from './Membership/Membership-ma';
import Mtelungu from './Membership/Membership-te';
import Mhindi from './Membership/Membership-hi';
import ResearchandDevelopement from './Howweworks/Howweworks'
import Rtelungu from './Howweworks/Howweworks-te';
import Rhindi from './Howweworks/Howweworks-hi';
import Rmalayalam from './Howweworks/Howweworks-ma';
import Rkannada from './Howweworks/Howweworks-ka';
import Htamil from "./Home/Home-ta"
import Atamil from './About/About-ta';
import Stamil from "./Socialservices/Socialservices-ta";
import Mtamil from './Membership/Membership-ta';
import Rtamil from './Howweworks/Howweworks-ta';
import Hmalayalam from './Home/Home-ma';

import Hkarnataka from './Home/Home-ka';
import Hhindi from './Home/Home-hi';
import Htelungu from './Home/Home-te';
import Millet from './Farmermilletmarathon/Millet';
import Tamillet from './Farmermilletmarathon/Millet-ta';
import Temillet from './Farmermilletmarathon/Millet-te';
import Himillet from './Farmermilletmarathon/Millet-hi';
import Mamillet from './Farmermilletmarathon/Millet-ma';
import Kamillet from './Farmermilletmarathon/Millet-ka';
import Paddy from './Farmerpaddymarathon/Paddy';
import Tapaddy from './Farmerpaddymarathon/Paddy-ta';
import Tepaddy from './Farmerpaddymarathon/Paddy-te';
import Hipaddy from './Farmerpaddymarathon/Paddy-hi';
import Mapaddy from './Farmerpaddymarathon/Paddy-ma';
import Kapaddy from './Farmerpaddymarathon/Paddy-ka';
import Sponsors from './Sponsors/Sponsors';
import Tasponsors from './Sponsors/Sponsors-ta';
import Tesponsors from './Sponsors/Sponsors-te';
import Hisponsors from './Sponsors/Sponsors-hi';
import Masponsors from './Sponsors/Sponsors-ma';
import Kasponsors from './Sponsors/Sponsors-ka';

import Operating from './Operatingmodel/Operating';
import Company from './Companies/Companies';
import Transformation from './Transformation/Transformation';
import NotFound from './NotFound';
import Geofensing from './Geofensing/Geofensing';
import Farmer from './Members/Farmer';
import Supporter from './Members/Farmersupporter';
import Vip from './Members/Vipdonator';
import Vvip from './Members/Vvipdonator';
import Scheme from './Zhasponsorscheme/Zhasponsorscheme';
import Innovation from './Innovation/Innovation';
import Zhasports from './Zhasports/Zhasports';
import Zhacycle from './Zhasports/Zhacycle';
import Zhaindoor from './Zhasports/Zhaindoor';
import Zhaathlete from './Zhasports/Zhaathlete';
import Zhamarathon from './Zhasports/Zhamarathon';
import Whyzha from './Whyzha/Whyzha';
import Zhasportsta from './Zhasports/Zhasportsother/Zhasports-ta';
import Zhasportste from './Zhasports/Zhasportsother/Zhasports-te';
import Zhasportsma from './Zhasports/Zhasportsother/Zhasports-ma';
import Zhasportshi from './Zhasports/Zhasportsother/Zhasports-hi';
import Zhasportska from './Zhasports/Zhasportsother/Zhasports-ka';

import Sustaian from './Sustainabilitymembership/sustaibailitymembership';
import Sustaianta from './Sustainabilitymembership/sustaibailitymembership-ta';
import Sustaianhi from './Sustainabilitymembership/sustaibailitymembership-hi';
import Sustaiante from './Sustainabilitymembership/sustaibailitymembership-te';
import Sustaianma from './Sustainabilitymembership/sustaibailitymembership-ma';
import Sustaianka from './Sustainabilitymembership/sustaibailitymembership-ka';


import Whyzhata from './Whyzha/Whyzha-ta';
import Whyzhama from './Whyzha/Whyzha-ma';
import Whyzhahi from './Whyzha/Whyzha-hi';
import Whyzhate from './Whyzha/Whyzha-te';
import Whyzhaka from './Whyzha/Whyzha-ka';


import Assesment from './Summit/Assesment';
import Summit from './Summit/Summit';
import Summitta from './Summit/Summit-ta';
import Summitka from './Summit/Summit-ka';
import Summitte from './Summit/Summit-te';
import Summitma from './Summit/Summit-ma';
import Summithi from './Summit/Summit-hi';

import Summitgoals from './Summit/Goals';

import Farm from './Farm/Farm';
import Sales from './Farm/Sales';



import Pathfinder from './Pathfinder/Pathfinder';

import Internship from './Intersnship/Internship';
import Role from './Role/Role';
import Roleta from './Role/Role-ta';
import Rolete from './Role/Role-te';
import Rolema from './Role/Role-ma';
import Rolehi from './Role/Role-hi';
import Roleka from './Role/Role-ka';
import Ecofriendly from './Ecofriendly/Ecofriendly';
import Ecofriendlyka from './Ecofriendly/Ecofriendly-ka';
import Ecofriendlyta from './Ecofriendly/Ecofriendly-ta';
import Ecofriendlyte from './Ecofriendly/Ecofriendly-te';
import Ecofriendlyma from './Ecofriendly/Ecofriendly-ma';
import Ecofriendlyhi from './Ecofriendly/Ecofriendly-hi';
import Certificate from './Ecofriendly/Certification';
import Certificateka from './Ecofriendly/Certification-ka';
import Certificatema from './Ecofriendly/Certification-ma';
import Certificatete from './Ecofriendly/Certification-te';
import Certificateta from './Ecofriendly/Certification-ta';
import Certificatehi from './Ecofriendly/Certification-hi';

import Award from './Award/Award'; 
import Awardta from './Award/Award-ta'; 
import Awardte from './Award/Award-te'; 
import Awardka from './Award/Award-ka'; 
import Awardma from './Award/Award-ma'; 
import Awardhi from './Award/Award-hi'; 
import Framework from './Framework/Framework';
import Frameworkta from './Framework/Framework-ta';
import Frameworkte from './Framework/Framework-te';
import Frameworkhi from './Framework/Framework-hi';
import Frameworkma from './Framework/Framework-ma';
import Frameworkka from './Framework/Framework-ka';

import Porfitable from './Summit/Profitable';

import Csr from './Csr/Csr';
import Csrta from './Csr/Csr-ta';
import Csrte from './Csr/Csr-te';
import Csrma from './Csr/Csr-ma';
import Csrka from './Csr/Csr-ka';
import Csrhi from './Csr/Csr-hi';


import Students from './Sustainability/Students';
import Studentsta from './Sustainability/Students-ta';
import Studentste from './Sustainability/Students-te';
import Studentsma from './Sustainability/Students-ma';
import Studentshi from './Sustainability/Students-hi';
import Studentska from './Sustainability/Students-ka';
import Professionals from './Sustainability/Professionals';
import Professionalsta from './Sustainability/Professionals';
import Professionalste from './Sustainability/Professionals';
import Professionalska from './Sustainability/Professionals';
import Professionalsma from './Sustainability/Professionals';
import Professionalshi from './Sustainability/Professionals';
import Invitation from './Invitation/Invitation';

import Practice from './Practice/Practice';
import Practiceta from './Practice/Practice-ta';
import Practicete from './Practice/Practice-te';
import Practicema from './Practice/Practice-ma';

import Practiceka from './Practice/Practice-ka';
import Practicehi from './Practice/Practice-hi';
import Commonman from './Commonman/Commonman';
import Commonmanta from './Commonman/Commonman-ta';
import Commonmante from './Commonman/Commonman-te';
import Commonmanhi from './Commonman/Commonman-hi';
import Commonmanma from './Commonman/Commonman-ma';
import Commonmanka from './Commonman/Commonman-ka';

import Member from './Member/Member';

import Welcomenri from './Welcomenri/Welcomenri';
import Aboutus from './Health/Aboutus';
import Contactform from './Form/Contactform';
import Professionalsaward from './Health/Professionalsawarding';

import Contactschool from './Form/Contactformtwo';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';



//School assesment start

import Schoolassesment from './Schoolassesment/SchoolAssesment';
import SchoolQuestionnair from './Schoolassesment/Questionnair';
import Successsubmit from './Schoolassesment/Success';
import Practitionersmembership from './Nri/Practitionersmembership';

 

import { BrowserRouter, Route ,Routes,HashRouter} from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

 
function App() {
  return (
    <div>
      <HashRouter>
      <Header></Header>

      
 

     <Routes>


     <Route path="/hh" element={<Hometwo />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/result" element={<Result />} />
          <Route path="*" element={<Error />} />
  
 
    
     <Route path='/signup' element={ <Signup />}></Route>
     <Route path='/login' element={ <Login />}></Route>

    
      <Route path='/' element={ <Home></Home>}></Route>
      <Route path='/about' element={ <About></About>}></Route>
      <Route path='/Socialservices' element={ <Socialservices></Socialservices>}></Route>
      <Route path='/structure' element={<Structure></Structure>}></Route>
      <Route path='/event' element={ <Event></Event>}></Route>
      <Route path='/zhafarmersmilletleague' element={<Millet />}></Route> 
      <Route path='/zhafarmerspaddyleague' element={<Paddy />}></Route> 
      <Route path='/sponsors' element={<Sponsors />}></Route>
      <Route path='/export' element={ <Export></Export>}></Route>
      <Route path='/membership' element={ <Membership></Membership>}></Route>
      <Route path='/researchanddevelopement' element={ <ResearchandDevelopement></ResearchandDevelopement>}></Route>
      <Route path='/contact' element={<Contact></Contact>}></Route>
      <Route path='/operatingmodel' element={<Operating></Operating>}></Route>
      <Route path='/researchers-sourcing-membership' element={<Company></Company>}></Route>
      <Route path='/zha-farmers-transformation' element={<Transformation></Transformation>}></Route>
      <Route path='/sustainable-energy-solutions' element={<Geofensing></Geofensing>}></Route>
      <Route path='/Zha-Sustainability-Club-Membership' element={<Sustaian></Sustaian>}></Route>
      <Route path='/Zha-Internship-Program' element={<Internship></Internship>}></Route>
      <Route path='/Zha-Internship-Program' element={<Internship></Internship>}></Route>

      <Route path='/Zha-Ecofriendly-Certification' element={<Ecofriendly></Ecofriendly>}></Route>

      <Route path='/Why-Zha-360-Sustainability' element={<Whyzha></Whyzha>}></Route>
      <Route path='/Why-Zha-360-Sustainability-Ta' element={<Whyzhata></Whyzhata>}></Route>
      <Route path='/Why-Zha-360-Sustainability-Te' element={<Whyzhate></Whyzhate>}></Route>
      <Route path='/Why-Zha-360-Sustainability-Ma' element={<Whyzhama></Whyzhama>}></Route>
      <Route path='/Why-Zha-360-Sustainability-Hi' element={<Whyzhahi></Whyzhahi>}></Route>
      <Route path='/Why-Zha-360-Sustainability-Ka' element={<Whyzhaka></Whyzhaka>}></Route>
      <Route component={NotFound} />

            <Route path='/Farmer-Members' element={<Farmer></Farmer>}></Route>
            <Route path='/Farmer-Supporters' element={<Supporter></Supporter>}></Route>
            <Route path='/Vip-Donors' element={<Vip></Vip>}></Route>
            <Route path='/Vvip-Donors' element={<Vvip></Vvip>}></Route>
            <Route path='/Vvip-Donors' element={<Vvip></Vvip>}></Route>

            <Route path='/Zha-sponsor-scheme' element={<Scheme></Scheme>}></Route>

      //Members

      //sustainabilitymebership
      <Route path='/Zha-Sustainability-Club-Membership-Ta' element={<Sustaianta></Sustaianta>}></Route>
      <Route path='/Zha-Sustainability-Club-Membership-Te' element={<Sustaiante></Sustaiante>}></Route>
      <Route path='/Zha-Sustainability-Club-Membership-Hi' element={<Sustaianhi></Sustaianhi>}></Route>
      <Route path='/Zha-Sustainability-Club-Membership-Ma' element={<Sustaianma></Sustaianma>}></Route>
      <Route path='/Zha-Sustainability-Club-Membership-Ka' element={<Sustaianka></Sustaianka>}></Route>

 //Zha Sports
 
      <Route path='/Zha-Sports-Training-Club' element={<Zhasports></Zhasports>}></Route>
       <Route path='/Zha-Cycle-Club' element={<Zhacycle></Zhacycle>}></Route>
        <Route path='/Zha-Indoor-Training-Club' element={<Zhaindoor></Zhaindoor>}></Route>
         <Route path='/Zha-Athletics-Training-Club' element={<Zhaathlete></Zhaathlete>}></Route>
         <Route path='/Zha-Volleyball-Training-Club' element={<Zhamarathon></Zhamarathon>}></Route>
         



         //Zha Sports other language 

         <Route path='/Zha-Sports-Training-Club-Tamil' element={<Zhasportsta></Zhasportsta>}></Route>
         <Route path='/Zha-Sports-Training-Club-Telugu' element={<Zhasportste></Zhasportste>}></Route>
         <Route path='/Zha-Sports-Training-Club-Malayalam' element={<Zhasportsma></Zhasportsma>}></Route>
         <Route path='/Zha-Sports-Training-Club-Hindi' element={<Zhasportshi></Zhasportshi>}></Route>
<Route path='/Zha-Sports-Training-Club-Kannada' element={<Zhasportska></Zhasportska>}></Route>


     


<Route path='/Role-of-Zha-Sustainability-Club' element={<Role />}></Route> 
<Route path='/Role-of-Zha-Sustainability-Club-ta' element={<Roleta />}></Route> 
<Route path='/Role-of-Zha-Sustainability-Club-hi' element={<Rolehi />}></Route> 
<Route path='/Role-of-Zha-Sustainability-Club-ma' element={<Rolema />}></Route> 
<Route path='/Role-of-Zha-Sustainability-Club-ka' element={<Roleka />}></Route> 
<Route path='/Role-of-Zha-Sustainability-Club-te' element={<Rolete />}></Route> 


<Route path='/Zha-Global-Sustainability-Summit-Invitation-2024' element={<Invitation />}></Route> 


     //Summit
     <Route path='/Zha-Sustainability-Assessment-Framework' element={<Assesment />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024' element={<Summit />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024-ta' element={<Summitta />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024-ka' element={<Summitka />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024-ma' element={<Summitma />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024-hi' element={<Summithi />}></Route> 
     <Route path='/Zha-Sustainability-Summit-2024-te' element={<Summitte />}></Route> 
     <Route path='/Pathfinder-Course' element={<Pathfinder />}></Route> 
      //Millet
      
      <Route path='/zhafarmersmilletleague-ta' element={<Tamillet />}></Route> 
      <Route path='/zhafarmersmilletleague-te' element={<Temillet />}></Route> 
      <Route path='/zhafarmersmilletleague-hi' element={<Himillet />}></Route> 
      <Route path='/zhafarmersmilletleague-ma' element={<Mamillet />}></Route> 
      <Route path='/zhafarmersmilletleague-ka' element={<Kamillet />}></Route> 

      //Paddy
      <Route path='/zhafarmerspaddyleague-ta' element={<Tapaddy />}></Route> 
      <Route path='/zhafarmerspaddyleague-te' element={<Tepaddy />}></Route> 
      <Route path='/zhafarmerspaddyleague-hi' element={<Hipaddy />}></Route> 
      <Route path='/zhafarmerspaddyleague-ma' element={<Mapaddy />}></Route> 
      <Route path='/zhafarmerspaddyleague-ka' element={<Kapaddy />}></Route> 

      //Sponsor
      <Route path='/sponsors-ta' element={<Tasponsors />}></Route> 
      <Route path='/sponsors-te' element={<Tesponsors />}></Route> 
      <Route path='/sponsors-hi' element={<Hisponsors />}></Route> 
      <Route path='/sponsors-ma' element={<Masponsors />}></Route> 
      <Route path='/sponsors-ka' element={<Kasponsors />}></Route> 

      //home 
      <Route path='/tamil-h' element={<Htamil></Htamil>}></Route>
       <Route path='/tamil-hma' element={<Hmalayalam></Hmalayalam>}></Route>
       <Route path='/tamil-hte' element={<Htelungu></Htelungu>}></Route>
       <Route path='/tamil-hhi' element={<Hhindi></Hhindi>}></Route>
       <Route path='/tamil-hka' element={< Hkarnataka></ Hkarnataka>}></Route>
    
    //About
      <Route path='/tamil-a' element={<Atamil></Atamil>}></Route>
      <Route path='/tamil-ama' element={<Amalayalam></Amalayalam>}></Route>
      <Route path='/tamil-ate' element={<Atelungu></Atelungu>}></Route>
      <Route path='/tamil-ahi' element={<Ahindi></Ahindi>}></Route>
      <Route path='/tamil-aka' element={<Akannada></Akannada>}></Route>

      

      //Award
      
      <Route path='/Zha-Lifetime-Achievement-Award' element={<Award />}></Route>
      <Route path='/Zha-Lifetime-Achievement-Award-ta' element={<Awardta />}></Route>
      <Route path='/Zha-Lifetime-Achievement-Award-ka' element={<Awardka />}></Route>
      <Route path='/Zha-Lifetime-Achievement-Award-ma' element={<Awardma />}></Route>
      <Route path='/Zha-Lifetime-Achievement-Award-te' element={<Awardte />}></Route>
      <Route path='/Zha-Lifetime-Achievement-Award-hi' element={<Awardhi />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework' element={<Framework />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework-ta' element={<Frameworkta />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework-te' element={<Frameworkte />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework-ma' element={<Frameworkma />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework-hi' element={<Frameworkhi />}></Route>
      <Route path='/Zha-Scaling-Sustainability-Maturity-Framework-ka' element={<Frameworkka />}></Route>


//social services
<Route path='/tamil-s' element={<Stamil></Stamil>}></Route>
<Route path='/tamil-sma' element={<Smalayalam></Smalayalam>}></Route>
<Route path='/tamil-ste' element={<Stelungu></Stelungu>}></Route>
<Route path='/tamil-shi' element={<Shindi></Shindi>}></Route>
<Route path='/tamil-ska' element={<Skannada></Skannada>}></Route>


// How we wrk
      <Route path='/tamil-ho' element={<Rtamil></Rtamil>}></Route>
      <Route path='/tamil-homa' element={<Rmalayalam></Rmalayalam>}></Route>
      <Route path='/tamil-hote' element={<Rtelungu></Rtelungu>}></Route>
      <Route path='/tamil-hohi' element={<Rhindi></Rhindi>}></Route>
      <Route path='/tamil-hohi' element={<Rhindi></Rhindi>}></Route>
      <Route path='/tamil-hoka' element={<Rkannada></Rkannada>}></Route>


      <Route path='/Zha-Sustainable-Certification-Process' element={<Certificate />}></Route> 
      <Route path='/Zha-Sustainable-Certification-Process-ka' element={<Certificateka />}></Route> 
      <Route path='/Zha-Sustainable-Certification-Process-te' element={<Certificatete />}></Route> 
      <Route path='/Zha-Sustainable-Certification-Process-ta' element={<Certificateta />}></Route> 
      <Route path='/Zha-Sustainable-Certification-Process-ma' element={<Certificatema />}></Route> 
      <Route path='/Zha-Sustainable-Certification-Process-hi' element={<Certificatehi />}></Route> 

      <Route path='/Zha-Ecofriendly-Certification' element={<Ecofriendly />}></Route>
      <Route path='/Zha-Ecofriendly-Certification-ka' element={<Ecofriendlyka />}></Route>
      <Route path='/Zha-Ecofriendly-Certification-ta' element={<Ecofriendlyta />}></Route>
      <Route path='/Zha-Ecofriendly-Certification-ma' element={<Ecofriendlyma />}></Route>
      <Route path='/Zha-Ecofriendly-Certification-hi' element={<Ecofriendlyhi />}></Route>
      <Route path='/Zha-Ecofriendly-Certification-te' element={<Ecofriendlyte />}></Route>

      


      <Route path='/Zha-Sustainability-Summit-Goals' element={<Summitgoals />}></Route>


      <Route path='/Profitable-for-producers-and-consumers' element={<Porfitable />}></Route> 
      
      <Route path='Zha-Farm-Management-Services' element={<Farm />}></Route>
      {/* <Route path='Zha-Farm-Management-Services-ta' element={<Farmta />}></Route>
      <Route path='Zha-Farm-Management-Services-ka' element={<Farmka />}></Route>
      <Route path='Zha-Farm-Management-Services-ma' element={<Farmma />}></Route>
      <Route path='Zha-Farm-Management-Services-hi' element={<Farmhi />}></Route>
      <Route path='Zha-Farm-Management-Services-te' element={<Farmte />}></Route> */}
      <Route path='/Zha-Agri-Sales-Solutions' element={<Sales/>}></Route>






      <Route path='/How-Zha-Use-CSR-Funds' element={<Csr/>}></Route>
      <Route path='/How-Zha-Use-CSR-Funds-ta' element={<Csrta/>}></Route>
      <Route path='/How-Zha-Use-CSR-Funds-te' element={<Csrte/>}></Route>
      <Route path='/How-Zha-Use-CSR-Funds-ma' element={<Csrma/>}></Route>

      <Route path='/How-Zha-Use-CSR-Funds-hi' element={<Csrhi/>}></Route>
      <Route path='/How-Zha-Use-CSR-Funds-ka' element={<Csrka/>}></Route>


//wellness

<Route path='/Zha-Wellness-Program' element={<Wellness/>}></Route>
<Route path='/Zha-Wellness-Program-ta' element={<Wellnessta/>}></Route>
<Route path='/Zha-Wellness-Program-te' element={<Wellnesste/>}></Route>
<Route path='/Zha-Wellness-Program-ma' element={<Wellnessma/>}></Route>
<Route path='/Zha-Wellness-Program-hi' element={<Wellnesshi/>}></Route>
<Route path='/Zha-Wellness-Program-ka' element={<Wellnesska/>}></Route>



 //member     
      <Route path='/tamil-m' element={<Mtamil></Mtamil>}></Route>
      <Route path='/tamil-mma' element={<Mmalayalam></Mmalayalam>}></Route>
      <Route path='/tamil-mte' element={<Mtelungu></Mtelungu>}></Route>
      <Route path='/tamil-mhi' element={<Mhindi></Mhindi>}></Route>


      <Route path='/Zha-Students-Sustainability-Club' element={<Students />}></Route> 
      <Route path='/Zha-Students-Sustainability-Club-ta' element={<Studentsta />}></Route> 
      <Route path='/Zha-Students-Sustainability-Club-te' element={<Studentste />}></Route> 
      <Route path='/Zha-Students-Sustainability-Club-ma' element={<Studentsma />}></Route> 
      <Route path='/Zha-Students-Sustainability-Club-hi' element={<Studentshi />}></Route> 
      <Route path='/Zha-Students-Sustainability-Club-ka' element={<Studentska />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club' element={<Professionals />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club-ta' element={<Professionalsta />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club-ka' element={<Professionalska />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club-ma' element={<Professionalsma />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club-te' element={<Professionalste />}></Route> 
      <Route path='/Zha-Professionals-Sustainability-Club-hi' element={<Professionalshi />}></Route> 
     

      <Route path='/Zha-Global-Community-of-Practice' element={<Practice />}></Route> 
      <Route path='/Zha-Global-Community-of-Practice-ta' element={<Practiceta />}></Route> 

      <Route path='/Zha-Global-Community-of-Practice-te' element={<Practicete />}></Route> 
      <Route path='/Zha-Global-Community-of-Practice-hi' element={<Practicehi />}></Route> 

      <Route path='/Zha-Global-Community-of-Practice-ma' element={<Practicema />}></Route> 
      <Route path='/Zha-Global-Community-of-Practice-ka' element={<Practiceka />}></Route> 

      <Route path='/Zha-Common-Humans-COPs' element={<Commonman />}></Route> 
      <Route path='/Zha-Common-Humans-COPs-ta' element={<Commonmanta />}></Route> 
      <Route path='/Zha-Common-Humans-COPs-te' element={<Commonmante />}></Route> 
      <Route path='/Zha-Common-Humans-COPs-ma' element={<Commonmanma />}></Route> 
      <Route path='/Zha-Common-Humans-COPs-hi' element={<Commonmanhi />}></Route> 
      <Route path='/Zha-Common-Humans-COPs-ka' element={<Commonmanka />}></Route> 


      <Route path='/Rural-Primary-Health-Care' element={<Health />}></Route> 
      <Route path='/Rural-Primary-Health-Care-ta' element={<Healthta />}></Route> 
      <Route path='/Rural-Primary-Health-Care-ma' element={<Healthma />}></Route> 
      <Route path='/Rural-Primary-Health-Care-ka' element={<Healthka />}></Route> 
      <Route path='/Rural-Primary-Health-Care-te' element={<Healthte />}></Route> 
      <Route path='/Rural-Primary-Health-Care-hi' element={<Healthhi />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment' element={<Women />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment-ta' element={<Women />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment-te' element={<Women />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment-ma' element={<Women />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment-hi' element={<Women />}></Route> 
      <Route path='/Gender-Equality-and-Women-Empowerment-ka' element={<Women />}></Route> 
      <Route path='/Disaster-Relief' element={<Disaster />}></Route> 
      <Route path='/Disaster-Relief-ta' element={<Disasterta />}></Route> 
      <Route path='/Disaster-Relief-te' element={<Disasterte />}></Route> 
      <Route path='/Disaster-Relief-ma' element={<Disasterma />}></Route> 
      <Route path='/Disaster-Relief-hi' element={<Disasterhi />}></Route> 
      <Route path='/Disaster-Relief-ka' element={<Disasterka />}></Route> 
      <Route path='/Environment' element={<Environment />}></Route>
      <Route path='/Environment-ta' element={<Environmentta />}></Route>
      <Route path='/Environment-te' element={<Environmentte />}></Route>
      <Route path='/Environment-ma' element={<Environmentma />}></Route>
      <Route path='/Environment-ka' element={<Environmentka />}></Route>
      <Route path='/Environment-hi' element={<Environmenthi />}></Route>

      <Route path='/Zha-Establishment-Story' element={<Story />}></Route> 
      <Route path='/Zha-Establishment-Story-ta' element={<Storyta />}></Route> 
      <Route path='/Zha-Establishment-Story-te' element={<Storyte />}></Route> 
      <Route path='/Zha-Establishment-Story-ma' element={<Storyma />}></Route> 
      <Route path='/Zha-Establishment-Story-hi' element={<Storyhi />}></Route> 
      <Route path='/Zha-Establishment-Story-ka' element={<Storyka />}></Route> 
      <Route path='/payment' element={<Payment />}></Route> 


      <Route path='/Zha-Free-Mind-Wellness-Counselling' element={<Rescue />}></Route> 
      <Route path='/Zha-Free-Mind-Wellness-Counselling-ta' element={<Rescueta />}></Route> 
      <Route path='/Zha-Free-Mind-Wellness-Counselling-ma' element={<Rescuema />}></Route> 
      <Route path='/Zha-Free-Mind-Wellness-Counselling-hi' element={<Rescuehi />}></Route> 
      <Route path='/Zha-Free-Mind-Wellness-Counselling-ka' element={<Rescueka />}></Route> 
      <Route path='/Zha-Free-Mind-Wellness-Counselling-te' element={<Rescuete />}></Route> 

      <Route path='/Zha-Free-Coaching-Center-for-Rural-Students' element={<Coaching />}></Route> 
      <Route path='/Professionals-Awarding-Program' element={<Professionalsaward />}></Route> 
      <Route path='/Tell-About-Yourself' element={<Contactform />}></Route>  

      <Route path='/Zha-Clean-Blue-Beach' element={<Bluebeach />}></Route>  

      <Route path='/Successful-NRI-Free-Virtual-Nation-Social-Services-Program' element={<Nri />}></Route> 
      <Route path='/The-Zha-Sustainability-Practitioners-Club' element={<Sustainabilitypractiners />}></Route>  

      <Route path='/Membership-Cost' element={<Member />}></Route>  
      <Route path='/Aboutus' element={<Aboutus />}></Route>  
      <Route path='/Donate' element={<Donate />}></Route>  
      <Route path='/Welcome-NRI' element={<Welcomenri />}></Route>  


      <Route path='/School-Assesment-Test' element={<Schoolassesment />}></Route>  
      <Route path='/School-Questions' element={<SchoolQuestionnair />}></Route>  
      <Route path='/School-Submit' element={<Successsubmit />}></Route>  
      <Route path='/School-Contact-Form' element={<Contactschool  />}></Route>  

      <Route path='/Zha-Recognition-Awards' element={<Recogninition  />}></Route>  
      <Route path='/ZHA-Sustainability-Practitioners-Club-Memberships' element={<Practitionersmembership  />}></Route>  
     
       
 
      </Routes>
      <Footer></Footer>
      </HashRouter>
    </div>

  );
}

export default App;






