import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    കാർഷിക ബിസിനസിൽ സൗജന്യമായി ഡൈനാമിക് കൺസൾട്ടിങ്ങിലൂടെ ഇന്ത്യൻ കർഷകരെ സഹായിക്കാനും കർഷകർക്ക് മൂല്യവർധിത നേട്ടങ്ങൾ നൽകാനും അഭ്യർത്ഥനകളെ അടിസ്ഥാനമാക്കി അവരുടെ ഫാമുകൾ കൈകാര്യം ചെയ്യാനും ആരംഭിച്ച ഒരു ലാഭേച്ഛയില്ലാത്ത സ്ഥാപനമാണ് ഞങ്ങളുടെ ഫൗണ്ടേഷൻ. അതിനാൽ ഞങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് മൂല്യം നൽകുന്ന ഒരു ഉൽപ്പന്നം കണ്ടുപിടിക്കേണ്ടതുണ്ടെന്ന് ഞങ്ങൾ കണ്ടു.



                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     ഞങ്ങൾ സുസ്ഥിരവും ഉൾക്കൊള്ളുന്നതുമായ വളർച്ച ത്വരിതപ്പെടുത്തുകയും കാര്യക്ഷമത വർദ്ധിപ്പിക്കുകയും വളർച്ചയ്ക്ക് ഇന്ധനം നൽകുകയും വ്യവസായങ്ങളെ രൂപപ്പെടുത്തുകയും ഓർഗനൈസേഷണൽ ഫലപ്രാപ്തി വർദ്ധിപ്പിക്കുകയും ചെയ്തുകൊണ്ട് ഭക്ഷ്യ-കാർഷിക കമ്പനികൾക്കും ഓർഗനൈസേഷനുകൾക്കും പരിവർത്തനപരമായ സ്വാധീനം നൽകുന്നു. ഞങ്ങളുടെ പ്രൊപ്രൈറ്ററി ടൂളുകളും ഡിജിറ്റൽ, അഡ്വാൻസ്ഡ് അനലിറ്റിക്സ് കഴിവുകളും, വിപുലമായ അനുഭവവും കൂടിച്ചേർന്നതാണ് ഞങ്ങളുടെ ക്ലയന്റുകൾക്ക് ഞങ്ങൾ നൽകുന്ന മൂല്യത്തിന്റെ അടിസ്ഥാന ശിലകൾ.


     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     ഴ ഫൗണ്ടേഷൻ മൾട്ടി-ഡിസിപ്ലിനറി റിസർച്ച്, കപ്പാസിറ്റി ബിൽഡിംഗ്, സ്വയം മെച്ചപ്പെടുത്തൽ എന്നിവയിൽ ഏർപ്പെടുന്നു, കൂടാതെ യുവാക്കളുടെ മനസ്സിനെയും ഹൃദയാരോഗ്യത്തെയും സഹായിക്കുന്നതിനായി ബന്ധപ്പെട്ട കാമ്പെയ്‌നുകൾ ഏറ്റെടുക്കുന്നു. ഒരു തലമുറ മുഴുവൻ കൂടുതൽ ഉത്കണ്ഠയുള്ളതും കൂടുതൽ ദുർബലവും കൂടുതൽ വിഷാദവുമാണ്. റിസ്‌ക് എടുക്കുന്നതിൽ അവർക്ക് സുഖം കുറവാണ്. ഇത് ഒരു തലമുറയിലെ യഥാർത്ഥ മാറ്റമാണ്. അതിനാൽ, പ്രതിരോധശേഷി, സ്നേഹം, പ്രത്യാശ, സാംസ്കാരിക ഐഡന്റിറ്റി, വിമർശനാത്മക ബോധം, സ്വയം നിർണ്ണയം, കാർഷിക രീതികൾ എന്നിവ ഉൾപ്പെടുന്ന ആരോഗ്യം അളക്കുന്നതിനുള്ള വഴികൾ ഞങ്ങൾ വികസിപ്പിക്കുകയാണ്.


     </p>
 </div>
            </div>
          

            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">ഓപ്പറേറ്റിംഗ് മോഡൽ</h2>
  {/* <h4>Operating Model</h4> */}
  <p>ഭാവി തലമുറകൾക്ക് സുസ്ഥിരമായ ജീവിതം സൃഷ്ടിക്കുന്നതിനും ഭൂമിയെ സംരക്ഷിക്കുന്നതിനും, ഴ സുസ്ഥിര പ്രൊഫഷണലുകൾ ടവറിൻ്റെ/ക്ലബിൻ്റെ ദൗത്യവും ദർശനവുമായി ഒത്തുചേരാനുള്ള ആഗോള താൽപ്പര്യങ്ങൾ വിലയിരുത്തുന്നതിന് സമാന ചിന്താഗതിക്കാരായ വിവിധ മനുഷ്യരുമായി ഞങ്ങൾ സംവദിക്കേണ്ടതുണ്ട്.
</p>
<p>സുസ്ഥിരമായ ജീവിതം എന്നാൽ നമ്മുടെ മനുഷ്യരുടെ ജീവിതശൈലി തിരഞ്ഞെടുപ്പുകൾ നമുക്ക് ചുറ്റുമുള്ള ലോകത്തെ എങ്ങനെ സ്വാധീനിക്കുന്നുവെന്ന് മനസിലാക്കുകയും എല്ലാവർക്കും മെച്ചപ്പെട്ടതും ഭാരം കുറഞ്ഞതുമായ ജീവിക്കാനുള്ള വഴികൾ കണ്ടെത്തുകയും ചെയ്യുന്നു. ZHA സസ്റ്റൈനബിലിറ്റി പ്രൊഫഷണലുകളുടെ ബോർഡ് അംഗങ്ങളുടെ ഞങ്ങളുടെ പ്രവർത്തനങ്ങൾ സജീവ, ഉപദേശക, സാക്ഷി ബോർഡ് അംഗത്വത്താൽ വിഭജിച്ചിരിക്കുന്നു. എന്നിരുന്നാലും Zha ബോർഡ് അംഗങ്ങൾ താഴെ പറയുന്ന നാല് തൂണുകളിൽ നിന്ന് അവരുടെ സുസ്ഥിരതയുടെ താൽപ്പര്യം കണ്ടെത്താൻ ബാധ്യസ്ഥരാണ്
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) മനുഷ്യൻ്റെ ആരോഗ്യ സ്തംഭം</p>
<p>2) പ്രകൃതി ആരോഗ്യ സ്തംഭം  </p>
<p>3) എർത്ത് വെൽനസ് സ്തംഭം</p>
<p>4) സ്ഥിരമായ ലാഭ സ്തംഭം</p>
</div>
</span>
<p>സുസ്ഥിര ക്ഷേമത്തിൻ്റെ മേൽപ്പറഞ്ഞ നാല് തൂണുകളിൽ നിന്ന്, ഓരോ ബോർഡ് അംഗങ്ങളും ഭാവി തലമുറകൾക്ക് ഭൂമി, പ്രകൃതി, മനുഷ്യ ക്ഷേമം എന്നിവയ്ക്കായി സുസ്ഥിരമായ ജീവിതം കെട്ടിപ്പടുക്കുന്നതിനുള്ള ഏതെങ്കിലും ഗാമിഫൈഡ് സാമൂഹിക സംരംഭങ്ങൾ കണ്ടെത്തും</p>
<p>അങ്ങനെ ഈ ജീവിതത്തിലെ സുസ്ഥിര ജീവിതത്തിൻ്റെ ഫലം നിർണ്ണയിക്കുന്നത് പരിസ്ഥിതിയെ സംരക്ഷിക്കുകയും സമത്വത്തെ പിന്തുണയ്ക്കുകയും ചെയ്യുന്ന സാമൂഹികവും സാമ്പത്തികവുമായ പുരോഗതിയെ അഭിസംബോധന ചെയ്യുന്ന സുസ്ഥിര വികസനത്തിൻ്റെ തത്വത്തിലൂടെയാണ്, അതിനാൽ സമ്പദ്‌വ്യവസ്ഥയും സമൂഹവും പാരിസ്ഥിതിക വ്യവസ്ഥയും പരസ്പരം ആശ്രിതമാണ്.</p>
 
 
 


<p>ബോർഡ് അംഗത്വ സബ്‌സ്‌ക്രിപ്‌ഷൻ ഫീസ് എങ്ങനെ വിനിയോഗിക്കപ്പെടുന്നു എന്നതിനെക്കുറിച്ച് Zha ഫൗണ്ടേഷൻ സുസ്ഥിര പ്രൊഫഷണലുകൾ ക്ലബ്ബിന് സുതാര്യത ഉണ്ടായിരിക്കേണ്ടത് പ്രധാനമാണ്. അംഗങ്ങൾക്ക് ഞങ്ങൾ ത്രൈമാസ ക്രമമായ സാമ്പത്തിക റിപ്പോക്കുകയും അവരുടെ സംഭാവനകൾക്ക് പ്രതിഫലമായി ലഭിക്കുന്ന മൂല്യം അവർ മനർട്ടുകളും അപ്‌ഡേറ്റുകളും നൽകും, വിശ്വാസം വളർത്തിയെടുക്കാൻ സഹായിസ്സിലാക്കുന്നുവെന്ന് ഉറപ്പാക്കുകയും ഞങ്ങളുടെ സംരംഭങ്ങളുടെ പുരോഗതിയും നേട്ടങ്ങളും ഒരേ ഫോറത്തിൽ ആഘോഷിക്കുകയും ചെയ്യും</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">Outcome - Value</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>സുസ്ഥിര വികസനത്തിൻ്റെ ആവശ്യമുള്ള ഫലങ്ങൾ സാമ്പത്തിക അഭിവൃദ്ധി, പരിസ്ഥിതി സംരക്ഷണം, സാമൂഹിക സമത്വം, ദാരിദ്ര്യം കുറയ്ക്കൽ എന്നിവ ഉൾക്കൊള്ളുന്നു. ഈ ഫലങ്ങൾ കൈവരിക്കുന്നതിന് സർക്കാരുകൾ, ബിസിനസുകൾ, വ്യക്തികൾ എന്നിവരിൽ നിന്നുള്ള കൂട്ടായ പരിശ്രമം ആവശ്യമാണ്.</li>
  <li>എല്ലാ തലത്തിലുള്ള കുട്ടികൾക്കും മുതിർന്നവർക്കും സാമൂഹിക ഉത്തരവാദിത്തം സാധ്യമാക്കുന്നതാണ് Zha ഫൗണ്ടേഷൻ.</li>
  <li>ഏതൊരാൾക്കും അവരുടെ സുസ്ഥിരതാ മനോഭാവം വളർത്തുന്നതിനും ബിസിനസ് നെറ്റ്‌വർക്കുകൾ വളർത്തുന്നതിനും ലോകമെമ്പാടുമുള്ള സമാന ചിന്താഗതിക്കാരായ പ്രൊഫഷണലുകളുമായി ബന്ധപ്പെടുന്നതിനും ZHA വിലയേറിയ വിഭവങ്ങൾ നൽകുന്നു. നിങ്ങൾ ഇപ്പോൾ തുടങ്ങുകയാണെങ്കിലും അല്ലെങ്കിൽ വർഷങ്ങളായി ബിസിനസ്സിൽ ഏർപ്പെട്ടിരിക്കുകയാണെങ്കിലും, ഈ ഫോറം നൽകുന്ന അറിവ്, പിന്തുണ, കണക്ഷനുകൾ എന്നിവയിൽ നിന്ന് നിങ്ങൾക്ക് പ്രയോജനം നേടാം.</li>
  <li>സുസ്ഥിര ജീവിതത്തെ ബന്ധിപ്പിക്കുകയും ലോകമെമ്പാടുമുള്ള ഗുണനിലവാരമുള്ള ആളുകളെ കണ്ടെത്തുകയും ചെയ്യുന്ന സാമൂഹിക സേവനം + ബിസിനസ് + അറിവ് + സൗഹൃദം, കൂട്ടായ്മ എന്നിവയെ അടിസ്ഥാനമാക്കിയുള്ളതാണ് ഴ ഫൗണ്ടേഷൻ
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">Zha പരിവർത്തന രീതി</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}