// ContactForm.js

import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';
import firebaseConfig from './Contactformconfig'; // Adjust the path accordingly
import { useNavigate } from 'react-router'; // Change the import based on your routing library


const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const ContactForm = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form data before submitting if needed

    // Send data to Firebase
    push(ref(database, 'schoolform'), formData);

    // Reset form after submission
    setFormData({
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
    });

    // Redirect to the home page
    navigate('/School-Questions'); // Change the URL to the desired home page URL
  };

  const formStyles = {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const labelStyles = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  };

  const inputStyles = {
    width: '100%',
    padding: '8px',
    marginBottom: '5px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

//   const buttonStyles = {
//     backgroundColor: '#0056b3',
//     color: '#fff',
//     padding: '10px 15px',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   };

  const buttonStyles = {
     backgroundColor: '#66bb6a',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  return (
    <form onSubmit={handleSubmit} style={formStyles}>
      <h4>Tell About Yourself</h4>
      <label style={labelStyles}>
        Name:
        <input type="text" name="name" value={formData.name} onChange={handleChange} style={inputStyles}  required />
      </label>
      <br />
      <label style={labelStyles}>
        Phone Number:
        <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} style={inputStyles}  required />
      </label>
      <br />
      <label style={labelStyles}>
        Email:
        <input type="email" name="email" value={formData.email} onChange={handleChange} style={inputStyles}   required />
      </label>
      <br />
      <label style={labelStyles}>
        Address:
        <input type="text" name="address" value={formData.address} onChange={handleChange} style={inputStyles}  required />
      </label>
      <br />
      <button
        type="submit"
        style={buttonStyles}
         
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
