import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';
import { NavLink } from 'react-router-dom';
import firebaseConfig from './Schoolconfig'; // Adjust the path accordingly
import { useNavigate } from 'react-router-dom';
import './constquestschool.css'

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
 

const Questionnaire = () => {
  // Define your custom questions
  const customQuestions = [
    'How do you promote the physical and mental health of students and staff within the school community?',
    'What programs or initiatives are in place to address bullying, harassment, and promote a positive school climate?',
    'How do you support students social-emotional development and well-being?',
    'What resources are available for students who may need counseling or mental health support?',
    'How do you ensure that students with diverse backgrounds and needs are included and supported within the school environment?',
    'How does the school incorporate environmental education into its curriculum and extracurricular activities?',
    'What measures are taken to conserve energy and reduce waste within the school premises?',
    'How does the school promote outdoor learning and engagement with nature?',
    'Are there any initiatives in place to create green spaces or wildlife habitats on school grounds?',
    'How does the school encourage students to become stewards of the environment and participate in conservation efforts?',
    'How does the school manage its carbon footprint and greenhouse gas emissions?',
    'What steps are taken to reduce water consumption and prevent pollution of local water sources?',
    'How does the school handle waste management and recycling?',
    'Are there any efforts to incorporate sustainable building practices and materials in school construction and maintenance?',
    'How does the school address environmental justice issues and promote environmental equity within the community?',
    'What social initiatives or community service projects does the school undertake, and how are they funded?',
    'How does the school measure the impact of its social work activities on the local community?',
    'Are there any partnerships with local organizations or businesses to support social initiatives?',
    'How does the school involve students, staff, and parents in volunteering and community engagement opportunities?',
    'How does the school ensure that its social work activities align with its mission and values, while also being financially sustainable?',
  ];
  const navigate = useNavigate();
  // Initialize answers state with empty strings for each question
  const [answers, setAnswers] = useState(Array(customQuestions.length).fill(''));

  // Handle input change for each question
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };
 
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send data to Firebase
    push(ref(database, 'school_assessment'), answers);

    // Reset answers after submission
    setAnswers(Array(customQuestions.length).fill(''));

    // Redirect to success page after submission
    navigate('/School-Submit');
  
 
  };

  // Handle form reset
  const handleReset = () => {
    // Reset answers to empty strings
    setAnswers(Array(customQuestions.length).fill(''));
  };

  return (
    <div className='questbox'>
     <h2 style={{ margin: '40px' }}>Assessment for School Management on Sustainability Practices</h2>  
      <form onSubmit={handleSubmit} style={formStyles}>
        {/* Render input fields for each custom question */}
        {customQuestions.map((question, index) => (
          <div key={index} style={questionContainerStyles}>
            <label htmlFor={`question-${index}`} style={labelStyles}>{`${question}`}</label>
            <input
              type="text"
              id={`question-${index}`}
              value={answers[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              style={inputStyles}
            />
          </div>
        ))}
        <div style={{ textAlign: 'center' }}>
          <button type="reset" onClick={handleReset} style={{ ...buttonStyles, backgroundColor: 'red', marginRight: '10px' }}>Reset</button>
          <button type="submit" style={{ ...buttonStyles, backgroundColor: 'green' }}>Submit</button>
        </div>
      </form>
    </div>
  );
};

 
const formStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
  borderRadius: '8px',  
};

const questionContainerStyles = {
  marginBottom: '20px',
};

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold',
};

const inputStyles = {
  width: '100%',
  padding: '8px',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  borderRadius: '4px',
};

const buttonStyles = {
  backgroundColor: '#green',
  color: '#fff',
  padding: '10px 15px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default Questionnaire;
