import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Common-Humans-COPs">English</NavLink></a>

        </div>


{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ఝా కామన్ మ్యాన్ COP లు </h1>
</div>


<div className="internship">
<img src="../img/cops.webp"></img>
        </div>
<div className="goalpointsp">

<p>ఝా కామన్ మ్యాన్ కమ్యూనిటీ ఆఫ్ ప్రాక్టీసెస్ (COPలు) ప్రపంచవ్యాప్తంగా వ్యక్తులలో స్థిరత్వ ఆలోచనను పెంపొందించడం లక్ష్యంగా పెట్టుకుంది. ఝా ఫౌండేషన్ యొక్క గ్లోబల్ బోర్డు సభ్యులచే మార్గదర్శకత్వం చేయబడిన, COPలు స్థిరమైన అభ్యాసాల పట్ల అవగాహన కల్పించడానికి, చర్చించడానికి మరియు ప్రేరేపించడానికి నెలవారీ ఫోరమ్‌లపై దృష్టి పెడతాయి. లక్ష్యాలలో అవగాహన పెంచడం, ఉత్తమ అభ్యాసాలను పంచుకోవడం మరియు స్థిరత్వానికి కట్టుబడి ఉన్న ప్రపంచ నెట్‌వర్క్‌ను పెంపొందించడం వంటివి ఉన్నాయి. ప్రయోజనాలలో జ్ఞాన మార్పిడి, సహకార కార్యక్రమాలు మరియు మరింత స్థిరమైన ప్రపంచానికి సహకరించడానికి వ్యక్తులను శక్తివంతం చేయడం వంటివి ఉన్నాయి.
</p>
<p>
కాబట్టి మేము ప్రతి దేశం కోసం మా వివిధ COPలను కలిగి ఉన్నాము, ఇవి సెషన్‌లు & రిజల్యూషన్ ఫలితాన్ని అందించడం, కింది ఎజెండాల ఆధారంగా చొరవలను ఆవిష్కరించడంపై దృష్టి పెట్టాలి
</p>

<ol className="goalpoints">
    <li><span>విద్య మరియు అవగాహన :</span>ఝా యొక్క COPలు సాధారణ ప్రజలకు వాతావరణ మార్పు మరియు స్థిరత్వం గురించి జ్ఞానాన్ని పొందేందుకు ఒక వేదికను అందిస్తాయి, వాటి ప్రాముఖ్యతపై లోతైన అవగాహనను పెంపొందించాయి.</li>
    <li><span>స్థానిక ప్రభావం :</span> సాధారణ వ్యక్తులను నిమగ్నం చేయడం ద్వారా, COP లు స్థానిక కమ్యూనిటీలు చర్య తీసుకోవడానికి అధికారం ఇస్తాయి, ప్రపంచ సుస్థిరత ప్రయత్నాలకు సమిష్టిగా సహకరిస్తాయి.
</li>
    <li><span>ప్రవర్తనా మార్పు : </span>COPలు రోజువారీ అలవాట్లు మరియు అభ్యాసాలను ప్రభావితం చేయడాన్ని లక్ష్యంగా పెట్టుకుంటాయి, వాతావరణ మార్పు యొక్క ప్రాముఖ్యతను ప్రారంభంలో గ్రహించలేని పాల్గొనేవారిలో స్థిరమైన ఎంపికలను ప్రోత్సహిస్తుంది.</li>


    <li><span>కమ్యూనిటీ సాధికారత : </span>మార్గదర్శకత్వం మరియు సహకారం ద్వారా, COP లు వ్యక్తులు తమ కమ్యూనిటీలలో స్థిరత్వం కోసం న్యాయవాదులుగా మారడానికి అధికారం ఇస్తాయి, ఇది సానుకూల మార్పు యొక్క అలల ప్రభావాన్ని సృష్టిస్తుంది.

</li>
    <li><span>గ్లోబల్ నెట్‌వర్క్ :</span>COPలు విభిన్న నేపథ్యాల నుండి ప్రజలను కలుపుతాయి, ప్రపంచ బాధ్యత యొక్క భావాన్ని పెంపొందిస్తాయి మరియు వ్యక్తిగత చర్యలు సమిష్టిగా గ్రహంపై ఎలా ప్రభావం చూపుతాయి.


</li>
    <li><span>UN లక్ష్యాలతో సమలేఖనం : </span>ఝా యొక్క COPలు ఐక్యరాజ్యసమితి యొక్క స్థిరత్వ లక్ష్యాలతో నేరుగా సమలేఖనం చేస్తాయి, విస్తృత ప్రపంచ లక్ష్యాలను సాధించడానికి అట్టడుగు విధానాన్ని అందిస్తాయి.


</li>
    <li><span>సమ్మిళిత భాగస్వామ్యం : </span>సాధారణ ప్రజలను లక్ష్యంగా చేసుకోవడం ద్వారా, COPలు సమగ్రతను నిర్ధారిస్తాయి, విస్తృత జనాభాలో నిమగ్నమై ఉంటాయి మరియు భాష లేదా స్థిరత్వం యొక్క భావనలతో పరిచయం లేని వారిని మినహాయించకుండా చేస్తుంది.

</li>
    <li><span>నెలవారీ ఫోరమ్‌లు :</span>రెగ్యులర్ ఫోరమ్‌లు కొనసాగుతున్న సంభాషణలు మరియు అభ్యాసం కోసం స్థిరమైన వేదికను అందిస్తాయి, కాలక్రమేణా స్థిరత్వం యొక్క ప్రాముఖ్యతను బలోపేతం చేస్తాయి మరియు పాల్గొనేవారి కొనసాగుతున్న స్పృహలో భాగంగా చేస్తాయి.
</li>
    <li><span>ప్రాక్టికల్ సొల్యూషన్స్ : </span>COP లు సాధారణ ప్రజలు తమ దైనందిన జీవితంలో అమలు చేయగల ఆచరణాత్మక, సాధించగల పరిష్కారాలపై దృష్టి పెడతాయి, స్థిరత్వాన్ని మరింత అందుబాటులోకి మరియు తక్కువ వియుక్తంగా చేస్తుంది.
</li>
    <li><span>అనుసరణ మరియు స్థితిస్థాపకత : </span>COPల ద్వారా సాధారణ ప్రజలకు అవగాహన కల్పించడం వలన కమ్యూనిటీలు మారుతున్న పర్యావరణ పరిస్థితులకు అనుగుణంగా మరియు వాతావరణ మార్పుల ప్రభావాలకు వ్యతిరేకంగా స్థితిస్థాపకతను అభివృద్ధి చేయడంలో సహాయపడతాయి.


</li>
 
 </ol>
 
 <p>సారాంశంలో, వాతావరణ మార్పు మరియు సుస్థిరత లక్ష్యాలు మరియు రోజువారీ వ్యక్తుల ఆచరణాత్మక చర్యల యొక్క వియుక్త భావనల మధ్య అంతరాన్ని తగ్గించడంలో ఝా యొక్క కామన్ మ్యాన్ COPలు కీలక పాత్ర పోషిస్తాయి, మరింత స్థిరమైన మరియు సమాచారంతో కూడిన ప్రపంచ సమాజాన్ని సృష్టిస్తాయి.</p>
 
 </div>

        </div>

    );
}

