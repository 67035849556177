import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">

            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Establishment-Story">English</NavLink></a>

        </div>
            


                <h1 id="mhead">झा स्थापना कहानी</h1>

                <div className='second-mcontent'>
                <img src='../../img/story.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">

                    <p>झा फाउंडेशन की कहानी विभिन्न उद्योगों के सफल पेशेवरों से शुरू होती है जो टिकाऊ जीवन के वैश्विक विकास में योगदान देना चाहते हैं। हालाँकि, उन्हें अपने मूल्यों के अनुरूप नवोन्मेषी प्लेटफार्मों की कमी महसूस हुई। इसके साथ ही, सफल व्यवसायियों के पास विशेष बिजनेस क्लब होते थे जो उनकी उपलब्धियों पर गर्व पैदा करते थे।
                    </p>

                    <p>इस अंतर को पहचानते हुए झा के संस्थापक ने एक रणनीतिक सामाजिक कार्य बोर्ड बनाने की आवश्यकता पर विश्वास किया। इस बोर्ड में सभी स्तरों पर और विभिन्न स्थानों से निपुण पेशेवर और व्यापारिक नेता शामिल होंगे। इसका उद्देश्य झा स्थिरता आंदोलन का प्रचार करना, आम जनता के बीच जागरूकता बढ़ाना और वैश्विक प्रभाव को बढ़ावा देना था।</p>

                    <p>झा के संस्थापक ने स्थिरता सिद्धांतों में अंतर्निहित देखभाल और साझाकरण मॉडल से प्रेरित होकर ग्रह और सभी की भलाई के लिए एक उद्देश्यपूर्ण शौक के निर्माण की कल्पना की। इस प्रकार, झा फाउंडेशन का व्यापक विषय स्थिरता बन गया।</p>

                    <p>झा फाउंडेशन स्थिरता का एक बेंचमार्क सिद्धांत स्थापित करने के लिए प्रतिबद्ध है जो केवल निगमों पर ही नहीं, बल्कि सभी व्यक्तियों पर लागू होता है। संयुक्त राष्ट्र के स्थायी लक्ष्यों और ईएसजी मापों के अनुरूप, झा ने आम जनता के लिए अनुकूलित एक पद्धति विकसित की है।</p>

                    <p>झा स्थिरता कार्यक्रमों का उद्देश्य आम व्यक्तियों, महिलाओं, पुरुषों और छात्रों सहित जनता को झा स्थिरता सक्षम ढांचे के सिद्धांतों और मूल्यों को अपनाने के लिए सशक्त बनाना है। यह ढाँचा सभी मनुष्यों के लिए अपनाने के लिए एक पद्धति के रूप में कार्य करता है, जिससे उन्हें अपनी स्थिरता मानसिकता क्षमताओं को मापने की अनुमति मिलती है। प्रौद्योगिकी और उपकरणों का लाभ उठाते हुए, झा फाउंडेशन जनता को संयुक्त राष्ट्र के स्थिरता लक्ष्यों को समझने में मदद करने के लिए मुफ्त संसाधन प्रदान करता है। कार्यप्रणाली में झा स्थिरता सक्षम ढांचे के भीतर प्रमुख अभ्यास क्षेत्र शामिल हैं, जो टिकाऊ जीवन के लिए एक व्यापक दृष्टिकोण को आकार देते हैं।</p>
                    
                    {/* <p>The story of the Zha Foundation begins with successful professionals across various industries who desired to contribute to the global development of sustainable living. However, they found a lack of innovative platforms aligning with their values. Simultaneously, successful businessmen had exclusive business clubs that instilled pride in their achievements.</p>

                    <p>Recognizing this gap, the founder of Zha believed in the necessity of forming a strategic social work board. This board would consist of accomplished professionals and business leaders at all levels and from diverse locations. The aim was to propagate the Zha sustainability movement, raising awareness among the general public and fostering a global impact.</p> */}
                </div>

                {/* <h3>Our Vision</h3> */}
                <div className="missi">
                    <h4  >झा फाउंडेशन पहल की स्थिरता के परिणाम:</h4>
                     

                </div>
                
<ol className="goalpoints">
    <li><span>व्यक्तिगत सशक्तिकरण : </span>झा के स्थिरता कार्यक्रमों के माध्यम से, आम व्यक्तियों, महिलाओं, पुरुषों और छात्रों को एक स्थायी मानसिकता अपनाने के लिए सशक्त बनाया जाता है। फाउंडेशन का समावेशिता पर ध्यान यह सुनिश्चित करता है कि हर कोई ग्रह की बेहतरी में योगदान दे सके।

 </li>
    <li><span>वैश्विक प्रभाव : </span>संयुक्त राष्ट्र के सतत लक्ष्यों और ईएसजी मापों के साथ तालमेल बिठाकर, झा फाउंडेशन सतत विकास के लिए वैश्विक पहल में योगदान देता है। स्थिरता का बेंचमार्क सिद्धांत, जो दुनिया भर के व्यक्तियों पर लागू होता है, सकारात्मक बदलाव की दिशा में सामूहिक प्रयास पर जोर देता है।
 </li>
    <li><span>मापने योग्य प्रभाव : </span>झा की कार्यप्रणाली व्यक्तियों को उनकी स्थिरता मानसिकता क्षमताओं को मापने का एक ठोस तरीका प्रदान करती है। यह लोगों को स्थायी जीवन में उनके योगदान को ट्रैक करने और समझने की अनुमति देता है।

 </li>
    <li><span>शिक्षा और जागरूकता :</span>झा फाउंडेशन संयुक्त राष्ट्र के स्थिरता लक्ष्यों की सार्वजनिक समझ को बढ़ावा देने, मुफ्त संसाधनों की पेशकश करने के लिए प्रौद्योगिकी और उपकरणों का लाभ उठाता है। यह शैक्षिक पहलू जागरूकता को बढ़ावा देता है और टिकाऊ प्रथाओं को व्यापक रूप से अपनाने के लिए प्रोत्साहित करता है।
 </li>
    <li><span>व्यापक ढांचा : </span>झा स्थिरता सक्षम ढांचा, अपने प्रमुख अभ्यास क्षेत्रों के साथ, टिकाऊ जीवन के लिए एक संरचित और व्यापक दृष्टिकोण प्रदान करता है। यह ढाँचा व्यक्तियों को उनके जीवन के विभिन्न पहलुओं में स्थिरता को शामिल करने में मार्गदर्शन करता है।

 </li>
    <li><span>सामुदायिक निर्माण :</span>स्थिरता सिद्धांतों में अंतर्निहित देखभाल और साझाकरण मॉडल समुदाय और सामूहिक जिम्मेदारी की भावना को बढ़ावा देता है। झा फाउंडेशन स्थायी प्रथाओं के लिए समर्पित समान विचारधारा वाले लोगों का एक नेटवर्क बनाकर व्यक्तियों को सहयोग करने के लिए प्रोत्साहित करता है।

 </li>
     
  </ol>

 <p>कुल मिलाकर, झा फाउंडेशन की कहानी का अनुसरण करने से स्थिरता, शिक्षा और सामूहिक कार्रवाई को बढ़ावा देकर व्यक्तियों, समुदायों और वैश्विक पर्यावरण पर सकारात्मक प्रभाव पड़ता है।</p>

 

            </section>
        </div>


    );
}

