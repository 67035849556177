import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification">English</NavLink></a>

        </div>


<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
   <img src='../../img/ecofriendly.webp'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>విజన్</h4>
	<p>"రియల్ ఎస్టేట్ వ్యాపార పద్ధతులలో స్థిరత్వ ఆలోచనా విధానాన్ని అభివృద్ధి చేయడానికి ఝా ఫౌండేషన్"
</p>
	</div>

<div className="missi">
  <h4>ఝా ఫౌండేషన్ కాన్సెప్ట్ :
</h4>
<p> 
సుస్థిరత మౌలిక సదుపాయాలను కలిగి ఉన్న బిల్డర్‌లు పర్యావరణ అనుకూలమైన నిర్మాణ పద్ధతులు, శక్తి-సమర్థవంతమైన డిజైన్‌లు మరియు పర్యావరణ బాధ్యత గల ఇళ్లను రూపొందించడానికి స్థిరమైన పదార్థాల వినియోగంపై దృష్టి పెడతారు. ఈ పద్ధతులు నిర్మాణం యొక్క పర్యావరణ ప్రభావాన్ని తగ్గించడం మరియు గృహాల దీర్ఘకాలిక స్థిరత్వాన్ని పెంచడం లక్ష్యంగా పెట్టుకున్నాయి.

</p>

<h4>ఝా విలువ సృష్టి ఫలితం :
</h4>
<p> 
స్థిరమైన రియల్ ఎస్టేట్ భావనలను ధృవీకరించడం అనేది నిర్మాణ స్థాపన అనేది zha మదింపుదారులచే నిర్వహించబడే పర్యావరణ మదింపు పద్ధతిపై ఆధారపడి ఉండే విధంగా ధృవీకరణలను పొందడం. మా ZHA ఫౌండేషన్ ధృవపత్రాలు రియల్ ఎస్టేట్ ప్రాజెక్ట్ నిర్దిష్ట పర్యావరణ అనుకూలమైన మరియు స్థిరమైన ప్రమాణాలకు కట్టుబడి ఉందని గుర్తించి మరియు ధృవీకరిస్తాయి, భవనాల నిర్మాణం మరియు నిర్వహణలో పారదర్శకత మరియు జవాబుదారీతనాన్ని ప్రోత్సహిస్తాయి. కాబట్టి బిల్డర్లు తమ మార్కెటింగ్ లేదా ప్రాజెక్ట్ యొక్క ప్రమోటింగ్ అంశాలలో మా లోగోను ఉపయోగించవచ్చు.

</p>
 

</div>
<p>పర్యావరణ అనుకూల భవన నిర్మాణం పర్యావరణ ప్రభావాన్ని తగ్గించడానికి మరియు స్థిరత్వాన్ని ప్రోత్సహించడానికి వివిధ లక్షణాలను కలిగి ఉంటుంది. కొన్ని ముఖ్య లక్షణాలు:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>శక్తి సామర్థ్యం : </span> మొత్తం శక్తి వినియోగాన్ని తగ్గించడానికి శక్తి-సమర్థవంతమైన సాంకేతికతలు, ఉపకరణాలు మరియు లైటింగ్‌ల ఏకీకరణ.
</li>

<li><span>పునరుత్పాదక శక్తి వనరులు : </span>  సైట్‌లో క్లీన్ ఎనర్జీని ఉత్పత్తి చేయడానికి సోలార్ ప్యానెల్‌లు, విండ్ టర్బైన్‌లు లేదా జియోథర్మల్ సిస్టమ్‌ల వంటి పునరుత్పాదక ఇంధన వ్యవస్థలను చేర్చడం.
</li>
<li><span>సస్టైనబుల్ మెటీరియల్స్ : </span> వనరుల క్షీణతను తగ్గించడానికి మరియు వ్యర్థాల ఉత్పత్తిని తగ్గించడానికి పర్యావరణ అనుకూలమైన మరియు రీసైకిల్ చేసిన పదార్థాల ఉపయోగం.
</li>
<li><span>నీటి సంరక్షణ : </span> నీటి వినియోగాన్ని తగ్గించడానికి నీటి-పొదుపు అమరికలు, రెయిన్వాటర్ హార్వెస్టింగ్ సిస్టమ్స్ మరియు సమర్థవంతమైన నీటిపారుదల పద్ధతుల అమలు.
</li>
<li><span>గ్రీన్ రూఫ్ మరియు గోడలు : </span> మెరుగైన ఇన్సులేషన్, తగ్గిన ఉష్ణ శోషణ మరియు మెరుగైన జీవవైవిధ్యం కోసం ఆకుపచ్చ పైకప్పులు మరియు గోడల సంస్థాపన.
</li>
<li><span>సహజ వెంటిలేషన్ : </span>యాంత్రిక శీతలీకరణ వ్యవస్థల అవసరాన్ని తగ్గించడానికి మరియు ఇండోర్ గాలి నాణ్యతను పెంచడానికి సహజ వెంటిలేషన్ కోసం రూపకల్పన.
</li>
<li><span>వ్యర్థాల తగ్గింపు మరియు రీసైక్లింగ్ : </span> రీసైక్లింగ్ మరియు బాధ్యతాయుతమైన పారవేయడం పద్ధతుల ద్వారా నిర్మాణ వ్యర్థాలను తగ్గించే వ్యూహాలు.
</li>
<li><span>తక్కువ-ప్రభావ సైట్ అభివృద్ధి : </span> సహజ ప్రకృతి దృశ్యాల సంరక్షణ, బాధ్యతాయుతమైన మురికినీటి నిర్వహణ మరియు నిర్మాణ సమయంలో స్థానిక పర్యావరణ వ్యవస్థలకు అంతరాయాన్ని తగ్గించడం.

</li>
<li><span>స్మార్ట్ బిల్డింగ్ సిస్టమ్స్ : </span> వనరుల వినియోగాన్ని ఆప్టిమైజ్ చేయడానికి శక్తి నిర్వహణ, సమర్థవంతమైన లైటింగ్ మరియు ఉష్ణోగ్రత నియంత్రణ కోసం స్మార్ట్ టెక్నాలజీల ఏకీకరణ.
</li>
<li><span>రసీదు : </span> తమ భవనం కోసం స్థిరత్వ వ్యూహాలతో ప్రణాళికను కలిగి ఉన్న బిల్డర్స్ అసోసియేషన్‌ను అధికారికంగా గుర్తించే ఝా ఫౌండేషన్ వంటి సంస్థ నుండి రసీదు కోరడం.
</li>

</ol>



<p>ఈ లక్షణాలు సమిష్టిగా పర్యావరణ బాధ్యత గల భవనాలను రూపొందించడానికి దోహదం చేస్తాయి, ఇవి వారి జీవితచక్రం అంతటా వారి పర్యావరణ పాదముద్రను తగ్గించే లక్ష్యంతో ఉంటాయి. </p>


</section>
 

</div>
 
 
);
}

