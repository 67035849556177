import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification">English</NavLink></a>

        </div>


<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
   <img src='../../img/ecofriendly.webp'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>दृष्टि</h4>
	<p>"रियलएस्टेट व्यवसाय के तरीकों में स्थिरता मानसिकता विकसित करने के लिए झा फाउंडेशन"
</p>
	</div>

<div className="missi">
  <h4>झा फाउंडेशन की अवधारणा :
</h4>
<p> 
स्थिरता संबंधी बुनियादी ढांचे को शामिल करने वाले बिल्डर्स पर्यावरण के अनुकूल निर्माण विधियों, ऊर्जा-कुशल डिजाइनों और पर्यावरण की दृष्टि से जिम्मेदार घर बनाने के लिए टिकाऊ सामग्रियों के उपयोग पर ध्यान केंद्रित करते हैं। इन प्रथाओं का उद्देश्य निर्माण के पारिस्थितिक प्रभाव को कम करना और घरों की दीर्घकालिक स्थिरता को बढ़ाना है।

</p>

<h4>झा मूल्य निर्माण परिणाम :
</h4>
<p> 
टिकाऊ रियल एस्टेट अवधारणाओं को प्रमाणित करने में निर्माण प्रतिष्ठान को सुनिश्चित करने के तरीके में प्रमाणन प्राप्त करना शामिल है जो पर्यावरण मूल्यांकन पद्धति पर आधारित है, जो झा मूल्यांकनकर्ताओं द्वारा किया जाता है। हमारे ZHA फाउंडेशन प्रमाणपत्र स्वीकार करते हैं और सत्यापित करते हैं कि एक रियल एस्टेट परियोजना विशिष्ट पर्यावरण-अनुकूल और टिकाऊ मानकों का पालन करती है, जो इमारतों के निर्माण और संचालन में पारदर्शिता और जवाबदेही को बढ़ावा देती है। इसलिए बिल्डर हमारे लोगो का उपयोग अपनी मार्केटिंग या प्रोजेक्ट के प्रचार पहलुओं में कर सकते हैं।

</p>
 

</div>
<p>पर्यावरण-अनुकूल भवन निर्माण में पर्यावरणीय प्रभाव को कम करने और स्थिरता को बढ़ावा देने के लिए विभिन्न विशेषताएं शामिल हैं। कुछ प्रमुख विशेषताओं में शामिल हैं:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>ऊर्जा दक्षता : </span> समग्र ऊर्जा खपत को कम करने के लिए ऊर्जा-कुशल प्रौद्योगिकियों, उपकरणों और प्रकाश व्यवस्था का एकीकरण।
</li>

<li><span>नवीकरणीय ऊर्जा स्रोत : </span>  साइट पर स्वच्छ ऊर्जा उत्पन्न करने के लिए सौर पैनल, पवन टरबाइन, या भूतापीय प्रणाली जैसी नवीकरणीय ऊर्जा प्रणालियों का समावेश।
</li>
<li><span>टिकाऊ सामग्री : </span> संसाधनों की कमी को कम करने और अपशिष्ट उत्पादन को कम करने के लिए पर्यावरण-अनुकूल और पुनर्नवीनीकरण सामग्री का उपयोग।
</li>
<li><span>जल संरक्षण : </span> पानी की खपत को कम करने के लिए जल-बचत फिक्स्चर, वर्षा जल संचयन प्रणाली और कुशल सिंचाई प्रथाओं का कार्यान्वयन।
</li>
<li><span>हरी छत और दीवारें : </span> बेहतर इन्सुलेशन, कम गर्मी अवशोषण और बढ़ी हुई जैव विविधता के लिए हरी छतों और दीवारों की स्थापना।
</li>
<li><span>प्राकृतिक वेंटिलेशन : </span> यांत्रिक शीतलन प्रणालियों की आवश्यकता को कम करने और इनडोर वायु गुणवत्ता को बढ़ाने के लिए प्राकृतिक वेंटिलेशन के लिए डिज़ाइन करना।
</li>
<li><span>अपशिष्ट न्यूनीकरण और पुनर्चक्रण : </span> पुनर्चक्रण और जिम्मेदार निपटान प्रथाओं के माध्यम से निर्माण अपशिष्ट को कम करने की रणनीतियाँ।
</li>
<li><span>कम प्रभाव वाली साइट का विकास : </span> प्राकृतिक परिदृश्यों का संरक्षण, जिम्मेदार तूफानी जल प्रबंधन, और निर्माण के दौरान स्थानीय पारिस्थितिक तंत्र में व्यवधान को कम करना।

</li>
<li><span>स्मार्ट बिल्डिंग सिस्टम : </span> संसाधन उपयोग को अनुकूलित करने के लिए ऊर्जा प्रबंधन, कुशल प्रकाश व्यवस्था और तापमान नियंत्रण के लिए स्मार्ट प्रौद्योगिकियों का एकीकरण।
</li>
<li><span>पावती : </span> झा फाउंडेशन आदि जैसे किसी संगठन से पावती की मांग करना जो आधिकारिक तौर पर बिल्डर्स एसोसिएशन को स्वीकार करता है जो अपने भवन के लिए स्थिरता रणनीतियों के साथ योजना बना रहे हैं।
</li>

</ol>



<p>ये सुविधाएँ सामूहिक रूप से पर्यावरणीय रूप से जिम्मेदार इमारतें बनाने में योगदान देती हैं जिनका उद्देश्य उनके पूरे जीवनचक्र में पारिस्थितिक पदचिह्न को कम करना है। </p>


</section>
 

</div>
 
 
);
}

