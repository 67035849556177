import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">


            <div  id="language" >
        
        <a href=""><NavLink to="/Disaster-Relief">English</NavLink></a>

        </div>
                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">झा फाउंडेशन द्वारा आपदा राहत प्रयास</h1>

                <div className='second-mcontent'>
                <img src='../../img/disasterrelief.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>झा फाउंडेशन में आपका स्वागत है, जहां हम भूकंप, तूफान, बाढ़ और अन्य आपदाओं से प्रभावित लोगों के लिए समर्थन के प्रतीक के रूप में खड़े हैं। तीव्र और प्रभावी आपदा राहत प्रदान करने की हमारी प्रतिबद्धता इस विश्वास पर आधारित है कि किसी को भी संकट के बाद अकेले सामना नहीं करना चाहिए।
                    </p>
                </div>

                <h3>हमारा विशेष कार्य</h3>
                <div className="missi">
                    <h4 className="toleftneed">तीव्र प्रतिक्रिया, स्थायी प्रभाव:</h4>
                    <p>झा फाउंडेशन में, हम आपदा स्थितियों की तात्कालिकता और गंभीरता को समझते हैं। हमारा मिशन प्राकृतिक आपदाओं से प्रभावित समुदायों को त्वरित प्रतिक्रिया और सहायता प्रदान करना है, दीर्घकालिक राहत और लचीलेपन की दिशा में काम करते हुए तत्काल राहत प्रदान करना है।</p>


                </div>


                <h3>हमारा दृष्टिकोण</h3>
                <div className="missi">
                    <h4 className="toleftneed">व्यापक आपदा राहत:</h4>
                    <p>झा फाउंडेशन आपदा राहत के लिए एक व्यापक दृष्टिकोण अपनाता है। प्रारंभिक प्रतिक्रिया चरण से, जहां हम आपातकालीन आश्रय, भोजन और चिकित्सा सहायता प्रदान करते हैं, पुनर्प्राप्ति चरण तक, जहां हम समुदायों के पुनर्निर्माण और आजीविका बहाल करने पर ध्यान केंद्रित करते हैं, हमारी टीमें यह सुनिश्चित करने के लिए समर्पित हैं कि प्रभावित व्यक्तियों को हर चरण में आवश्यक सहायता मिले। .</p>

                    <h4 className="toleftneed">समुदाय-केंद्रित समाधान:</h4>
                    <p>हम समुदायों की ताकत में विश्वास करते हैं। हमारे आपदा राहत प्रयास स्थानीय निवासियों के सहयोग से, उनकी अद्वितीय आवश्यकताओं, सांस्कृतिक संदर्भ और इलाके के ज्ञान का सम्मान करते हुए तैयार किए गए हैं। समुदायों के साथ मिलकर काम करके, हमारा लक्ष्य स्थायी समाधान तैयार करना है जो व्यक्तियों को अपने जीवन के पुनर्निर्माण के लिए सशक्त बनाता है।</p>
                </div>

                <h3>हमारी पहल</h3>
                <div className="missi">
                    <h4 className="toleftneed">आपातकालीन आश्रय और सहायता:</h4>
                    <p>झा फाउंडेशन आपदाओं से प्रभावित लोगों को आपातकालीन आश्रय, भोजन, स्वच्छ पानी और चिकित्सा सहायता प्रदान करने के लिए तेजी से जुटा हुआ है। हमारी टीमें यह सुनिश्चित करने के लिए चौबीसों घंटे काम करती हैं कि किसी संकट के तुरंत बाद बुनियादी ज़रूरतें पूरी हों।</p>

                    <h4 className="toleftneed">जीवन का पुनर्निर्माण:</h4>
                    <p>प्रारंभिक प्रतिक्रिया से परे, हम जीवन के पुनर्निर्माण पर ध्यान केंद्रित करते हैं। इसमें पुनर्निर्माण के प्रयास, आजीविका बहाली के लिए संसाधन उपलब्ध कराना और समुदायों को सामान्य स्थिति की भावना हासिल करने में मदद करने के लिए शिक्षा और स्वास्थ्य सेवाओं का समर्थन करना शामिल है।</p>

                    <h4 className="toleftneed">सामुदायिक लचीलापन कार्यक्रम:</h4>
                    <p>झा फाउंडेशन उन कार्यक्रमों में निवेश करता है जो सामुदायिक लचीलेपन को बढ़ाते हैं। इसमें भविष्य की आपदाओं के प्रभाव को कम करने के लिए स्थानीय निवासियों को आपदा तैयारियों, प्रारंभिक चेतावनी प्रणालियों और टिकाऊ प्रथाओं में प्रशिक्षण देना शामिल है।</p>
                </div>


            </section>
        </div>


    );
}

